<template>
    <div>
      <el-row style="margin: 0 10px">
        <el-col :span="2" style="font-size: 18px">维修点设置</el-col>
        <el-col :span="5" style="float: right;">
          <!--面包屑导航-->
          <el-breadcrumb separator="/">
            <el-breadcrumb-item>售后管理</el-breadcrumb-item>
            <el-breadcrumb-item><a>维修项目设置</a></el-breadcrumb-item>
            <el-breadcrumb-item><a>维修点设置</a></el-breadcrumb-item>
          </el-breadcrumb>
        </el-col>
      </el-row>
      <el-card>
        <el-row :gutter="20">
          <el-col :span="7">
            <el-input placeholder="维修点名称" v-model="queryInfo.query" clearable @clear="getMaintenanceList">
<!--              <template #append>-->
<!--                <el-button icon="el-icon-search" @click="getMaintenanceList"></el-button>-->
<!--              </template>-->
            </el-input>
          </el-col>
          <el-col :span="4">
            <el-button type="primary" @click="getMaintenanceList">查询</el-button>
          </el-col>
          <el-col :span="10">
            <addBtn
              @gainMsgs="PageChange"
              :addForm="addProductForm"
              :addRules="addProductRules"
              :addApi="path"
              :addProductFormLists="addProductFormList"
              :addName="addProductName"
              :adminLists = "adminList"
              style="float: left"
            ></addBtn>
<!--            <el-button style="margin-left: 10px" type="warning">导出</el-button>-->
            <el-button style="margin-left: 10px" type="success" @click="returnMsg">返回</el-button>
          </el-col>
        </el-row>
        <el-table border :data="maintenanceList" height="670px">
          <el-table-column label="序号" type="index" width="80px"></el-table-column>
          <el-table-column label="所属区域">
            <el-table-column prop="province" label="省份"></el-table-column>
            <el-table-column prop="city" label="城市"></el-table-column>
            <el-table-column prop="district" label="地区"></el-table-column>
          </el-table-column>
          <el-table-column label="维修点名称" prop="title"></el-table-column>
          <el-table-column label="维修点电话" prop="mobile"></el-table-column>
          <el-table-column label="维修点地址" prop="addressed"></el-table-column>
<!--          <el-table-column label="维修员" prop="create_time"></el-table-column>-->
          <el-table-column label="操作" width="150px">
            <template #default="scope">
              <!--              编辑的按钮-->
              <editBtn
                :editId="scope.row.id"
                :editForm="editProductForm"
                :addProductFormList="addProductFormList"
                :editRules="editProductFormRules"
                @gainMsg="PageChange"
                :editApi="path"
                :editProductFormLists="editProductFormList"
                :editName="addProductName"
                :adminLists = "adminList"
              ></editBtn>
              <!--              删除的按钮-->
              <deleteBtn :deleteId="scope.row.id" @gainMsg="PageChange" :deleteApi="path"></deleteBtn>
            </template>
          </el-table-column>
        </el-table>
      </el-card>
      <page :queryInfos="queryInfo" :queryUrl="path" @pageChange="queryChange2"></page>
    </div>
</template>

<script>
import * as maintenance from '../../interface/AfterSalesManage/MaintenancePointSetting'
import Pagination from '../common/Pagination'
import EditBtn from '../common/EditBtn'
import DeleteBtn from '../common/DeleteBtn'
import AddBtn from '../common/AddBtn'

export default {
  name: 'MaintenancePointSetting',
  components: {
    page: Pagination,
    editBtn: EditBtn,
    deleteBtn: DeleteBtn,
    addBtn: AddBtn
  },
  data: function () {
    var checkMobile = (rule, value, cb) => {
      const regMobile = /^1[3-9]\d{9}$/
      if (regMobile.test(value)) {
        return cb()
      }
      // 校验不通过,则回调错误
      cb(new Error('请输入合法的手机号码'))
    }
    return {
      adminList: [],
      maintenanceList: [],
      path: 'AfterSalesManage/MaintenancePointSetting',
      queryInfo: {
        query: '',
        pageNo: 1,
        pageSize: 2,
        total: 0
      },
      addProductName: '维修点',
      addProductForm: {
        province: '',
        city: '',
        district: '',
        address: '',
        title: '',
        mobile: '',
        user_ids: []
      },
      addProductFormList: [
        {
          title: '选择省市区',
          prop: 'selectedOptions'
        },
        {
          title: '详细地址',
          prop: 'address'
        },
        {
          title: '维修点名称',
          prop: 'title'
        },
        {
          title: '维修点电话',
          prop: 'mobile'
        },
        {
          title: '维修点人员',
          prop: 'user_ids'
        }
      ],
      addProductRules: {
        address: [
          { required: true, message: '请输入详细地址', trigger: 'blur' },
          { min: 2, message: '长度至少2个字符', trigger: 'blur' }
        ],
        title: [
          { required: true, message: '请输入维修点名称', trigger: 'blur' },
          { min: 2, message: '长度至少2个字符', trigger: 'blur' }
        ],
        mobile: [
          { required: true, message: '请输入维修点电话', trigger: 'blur' },
          { validator: checkMobile, message: '维修点电话不正确,请重新输入', trigger: 'blur' }
        ]
      },
      editDialogVisible: false,
      editProductForm: {
        id: '',
        province: '',
        city: '',
        district: '',
        address: '',
        title: '',
        mobile: '',
        user_ids: []
      },
      editProductFormList: [
        {
          title: '选择省市区',
          prop: 'selectedOptions'
        },
        {
          title: '详细地址',
          prop: 'address'
        },
        {
          title: '维修点名称',
          prop: 'title'
        },
        {
          title: '维修点电话',
          prop: 'mobile'
        },
        {
          title: '维修点人员',
          prop: 'user_ids'
        }
      ],
      editProductFormRules: {
        address: [
          { required: true, message: '请输入详细地址', trigger: 'blur' },
          { min: 2, message: '长度至少2个字符', trigger: 'blur' }
        ],
        title: [
          { required: true, message: '请输入维修点名称', trigger: 'blur' },
          { min: 2, message: '长度至少2个字符', trigger: 'blur' }
        ],
        mobile: [
          { required: true, message: '请输入维修点电话', trigger: 'blur' },
          { validator: checkMobile, message: '维修点电话不正确,请重新输入', trigger: 'blur' }
        ]
      }
    }
  },
  created () {
    this.getMaintenanceList()
    this.getAllList()
  },
  methods: {
    getAllList: async function () {
      const data = await maintenance.adminList(this)
      if (data.code !== 0) return this.$message.error('获取管理人员失败')
      this.adminList = data.result
      console.log(this.adminList)
    },
    getMaintenanceList: async function () {
      var p = ''
      if (this.queryInfo.query) p = '?title=' + this.queryInfo.query
      const res = await maintenance.maintenanceList(this, p)
      if (res.code !== 0) return this.$message.error('获取维修点信息失败')
      console.log(res)
      var maintenanceList = res.result.data
      res.result.data.forEach(function (item, index) {
        maintenanceList[index].addressed = item.province + item.city + item.district + item.address
      })
      this.maintenanceList = maintenanceList
      this.queryInfo.total = res.result.totalCount
      this.queryInfo.pageNo = res.result.pageNo
      this.queryInfo.pageSize = res.result.pageSize
    },
    returnMsg: function () {
      this.$router.go(-1)
    },
    PageChange: function () {
      this.getMaintenanceList()
    },
    queryChange2: function (e) {
      this.companyList = e.productList
    }
  }
}
</script>

<style scoped>

</style>
