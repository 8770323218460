// 地址
const api = {
  EquipmentList: 'admin/Equipment/list',
  getItemAll: 'admin/ApiAgent/getItemAll',
  getAgentAll: 'admin/ApiAgent/getAgentAll',
  getCityAll: 'admin/ApiAgent/getCityAll',
  getSceneAll: 'admin/ApiAgent/getSceneAll',
  addProduct: 'admin/equipment/add',
  editProductDialog: 'admin/equipment/info?id=',
  delEquipment: 'admin/equipment/del?id=',
  eleQuantity: 'admin/Equipment/eleQuantity',
  backlight: 'admin/Equipment/backlight',
  timeStart: 'admin/Equipment/timeStart',
  reset: 'admin/equipment/reset?equipment_no=',
  editProduct: 'admin/equipment/edit',
  getTypeList: 'admin/equipment/getTypeList'
}
// 列表
export async function EquipmentList (ele, params) {
  const { data } = await ele.$http.get(api.EquipmentList + params)
  return data
}
// 一级代理
export async function getItemAll (ele, params) {
  const { data } = await ele.$http.get(api.getItemAll + params)
  return data
}
// 二级代理
export async function getAgentAll (ele, params) {
  const { data } = await ele.$http.get(api.getAgentAll + params)
  return data
}
// 三级代理
export async function getCityAll (ele, params) {
  const { data } = await ele.$http.get(api.getCityAll + params)
  return data
}
// 场地商户
export async function getSceneAll (ele, params) {
  const { data } = await ele.$http.get(api.getSceneAll + params)
  return data
}
// 添加
export async function addProduct (ele, params) {
  const { data } = await ele.$http.post(api.addProduct, params)
  return data
}
// 获取详情
export async function editProductDialog (ele, params) {
  const { data } = await ele.$http.get(api.editProductDialog + params)
  return data
}
// 删除
export async function delEquipment (ele, params) {
  const { data } = await ele.$http.delete(api.delEquipment + params)
  return data
}
// 设置电量
export async function eleQuantity (ele, params) {
  const { data } = await ele.$http.put(api.eleQuantity, params)
  return data
}
// 设置亮度
export async function backlight (ele, params) {
  const { data } = await ele.$http.put(api.backlight, params)
  return data
}
// 设置启动时间
export async function timeStart (ele, params) {
  const { data } = await ele.$http.put(api.timeStart, params)
  return data
}
// 重启
export async function reset (ele, params) {
  const { data } = await ele.$http.get(api.reset + params)
  return data
}
// 编辑
export async function editProduct (ele, id, params) {
  const { data } = await ele.$http.put(api.editProduct + '?id=' + id, params)
  return data
}
export async function pageChanges (ele, param, params) {
  var p = []
  for (var i in param) {
    p.push(i + '=' + param[i])
  }
  var { data } = await ele.$http.get(api.EquipmentList + '?' + p[0] + '&' + p[1] + '&' + params)
  return data
}
// 类型列表
export async function getTypeList (ele, params) {
  const { data } = await ele.$http.get(api.getTypeList + params)
  return data
}
