// api 地址
const api = {
  roleListAll: 'admin/adminRole/getAll',
  roleList: 'admin/adminRole/list',
  addProduct: 'admin/adminRole/add',
  editProductDialog: 'admin/adminRole/info?id=',
  editProduct: 'admin/adminRole/edit?id=',
  deleteProduct: 'admin/adminRole/del?id=',
  editPowerManage: 'admin/adminRole/addRoleNode'
}
// 获取所有产品列表
export async function roleListAll (ele) {
  var { data } = await ele.$http.get(api.roleListAll)
  return data
}
// 获取产品列表
export async function roleList (ele) {
  var { data } = await ele.$http.get(api.roleList)
  return data
}

// 添加产品
export async function addProduct (ele, param) {
  var { data } = await ele.$http.post(api.addProduct, param)
  return data
}

// 根据id查询产品信息
export async function editProductDialog (ele, param) {
  var { data } = await ele.$http.get(api.editProductDialog + param)
  return data
}

// 提交产品修改的信息
export async function editProduct (ele, param1, param2) {
  var { data } = await ele.$http.put(api.editProduct + param1, param2)
  return data
}
// 删除产品的信息
export async function deleteProduct (ele, param) {
  var { data } = await ele.$http.delete(api.deleteProduct + param)
  return data
}
// 编辑角色管理
export async function editPowerManage (ele, param) {
  var { data } = await ele.$http.post(api.editPowerManage, param)
  return data
}
export async function pageChanges (ele, param) {
  var p = []
  for (var i in param) {
    p.push(i + '=' + param[i])
  }
  var { data } = await ele.$http.get(api.roleList + '?' + p[0] + '&' + p[1])
  return data
}
