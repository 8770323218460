// api 地址
const api = {
  maintenanceList: 'admin/repair/list',
  addProduct: 'admin/repair/add',
  editProductDialog: 'admin/repair/info?id=',
  editProduct: 'admin/repair/edit?id=',
  deleteProduct: 'admin/repair/del?id=',
  adminList: 'admin/adminUser/getAll'
}
// 获取产品列表
export async function maintenanceList (ele, params) {
  var { data } = await ele.$http.get(api.maintenanceList + params)
  return data
}
// 获取根据页面显示的条数(页数)来查询产品列表
export async function pageChange (ele, param) {
  for (var i in param) {
    var { data } = await ele.$http.get(api.maintenanceList + '?' + i + '=' + param[i])
  }
  return data
}

// 添加产品
export async function addProduct (ele, param) {
  var { data } = await ele.$http.post(api.addProduct, param)
  return data
}

// 根据id查询产品信息
export async function editProductDialog (ele, param) {
  var { data } = await ele.$http.get(api.editProductDialog + param)
  return data
}

// 提交产品修改的信息
export async function editProduct (ele, param1, param2) {
  var { data } = await ele.$http.put(api.editProduct + param1, param2)
  return data
}
// 删除产品的信息
export async function deleteProduct (ele, param) {
  var { data } = await ele.$http.delete(api.deleteProduct + param)
  return data
}

// 获取管理员列表
export async function adminList (ele) {
  var { data } = await ele.$http.get(api.adminList)
  return data
}
