<template>
  <div class="editContainer">
    <el-button v-if="type == 1 || type == 2 || type == 3" size="mini" type="success" @click="infoData">查看</el-button>
    <!--    修改的按钮-->
    <a v-else hover="text-decoration: underline;" @click="infoData">{{ order_no }}</a>
    <!--    修改的弹出框-->
    <el-dialog
      :title="title + '详情'"
      :visible.sync="DialogVisible"
      width="50%"
      @close="editDialogClosed"
    >
      <!--编辑用户的表单-->
      <table frame="border" style="width: 80%;margin: 0 auto;text-align: center;border-color: #EBEEF5">
        <tr v-for="item in ProductFormList" :key="item.index">
          <td style="text-align: center">{{item.title}}</td>
          <td>
            <el-input v-if="item.title === '回复'" v-model="to_content"></el-input>
            <div v-else-if="item.title === '图片'">
              <div class="showImg">
                <!--              <img v-for="(item1, index1) in item.value" :key="index1" :src="url + item1">-->
                <div class="demo-image__preview">
                  <el-image
                    v-for="(item1, index1) in item.value"
                    :key="index1"
                    :z-index="99999"
                    style="width: 100px; height: 100px"
                    :src="url + item1"
                    :preview-src-list="item.imgs">
                  </el-image>
                </div>
              </div>
            </div>
            <div v-else :class="type == 3 ? 'textCenter' : 'textLeft'">{{item.value}}</div>
          </td>
        </tr>
      </table>
<!--      <el-form style="width: 60%;margin: 0 auto" label-width="40%">-->
<!--        <el-form-item-->
<!--          v-for="item in ProductFormList"-->
<!--          :key="item.index"-->
<!--          :label="item.title">-->
<!--          <el-input v-if="item.title === '回复'" v-model="to_content"></el-input>-->
<!--          <div v-else-if="item.title === '图片'">-->
<!--            <div class="showImg">-->
<!--              <div class="demo-image__preview">-->
<!--                <el-image-->
<!--                  v-for="(item1, index1) in item.value"-->
<!--                  :key="index1"-->
<!--                  :z-index="99999"-->
<!--                  style="width: 100px; height: 100px"-->
<!--                  :src="url + item1"-->
<!--                  :preview-src-list="item.imgs">-->
<!--                </el-image>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div v-else class="textLeft">{{item.value}}</div>-->
<!--        </el-form-item>-->
<!--      </el-form>-->

      <template #footer>
        <span class="dialog-footer" style="display: flex;justify-content: center">
<!--          <el-button @click="DialogVisible = false">取 消</el-button>-->
          <el-button type="primary" @click="sureBtn">确 定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import * as getComplaint from '../../interface/Complaint/ComplaintList'
export default {
  props: ['ProductFormList', 'id', 'order_no', 'title', 'type'],
  data: function () {
    return {
      DialogVisible: false,
      url: 'http://wl.zhenyoukeji.com',
      to_content: '',
      imgShow: []
    }
  },
  created () {
  },
  methods: {
    // 弹出层关闭
    editDialogClosed: function () {
      // this.$refs.editProductFormRef.resetFields()
      this.DialogVisible = false
    },
    // 查询
    infoData: function () {
      this.DialogVisible = true
      this.$emit('infoData', this.id)
    },
    // 确认
    sureBtn: async function () {
      if (this.type === 1) {
        this.fromData = {
          to_content: this.to_content,
          id: this.id
        }
        const data = await getComplaint.handleComplaint(this, this.fromData)
        if (data.code !== 0) return this.$message.error(data.msg)
        this.$message.success('提交成功')
        this.$emit('submitBtn')
      }
      this.DialogVisible = false
    }
  }
}
</script>

<style scoped>
  .editContainer{
    /*float: left;*/
    display: inline-block;
    margin-right: 10px;
  }
  a:hover{
    text-decoration: underline;
    cursor: pointer;
  }
  .textLeft{
    text-align: left;
    width: 70%;
  }
  .textCenter{
    text-align: center;
  }
  .el-input{
    float: left;
    width: 100%;
  }
  .showImg{
    display: flex;
  }
  .showImg img{
    width: 100px;
    height: 100px;
    margin-right: 10px;
  }
</style>
