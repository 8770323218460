<template>
  <div>
    <el-row style="margin: 0 10px">
      <el-col :span="5" style="font-size: 18px">{{$t('system.xtrz')}}</el-col>
      <el-col :span="9" style="float: right;">
        <!--面包屑导航-->
        <el-breadcrumb separator="/">
          <el-breadcrumb-item>{{$t('role.xtgl')}}</el-breadcrumb-item>
          <el-breadcrumb-item><a>{{$t('system.xtrz')}}</a></el-breadcrumb-item>
        </el-breadcrumb>
      </el-col>
    </el-row>
    <el-card>
      <el-form :inline="true" :model="form" class="demo-form-inline">
        <el-form-item>
          <el-input v-model="form.ip" :placeholder="$t('system.qsrip')"></el-input>
        </el-form-item>
        <el-form-item>
          <el-date-picker
            v-model="form.create_time"
            type="datetimerange"
            :range-separator="$t('lygl.z')"
            :start-placeholder="$t('lygl.ksrq')"
            :end-placeholder="$t('lygl.jsrq')"
            value-format="yyyy-MM-dd HH:mm:ss">
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="getList">{{$t('home.xc')}}</el-button>
        </el-form-item>
      </el-form>
      <el-table border :data="logList" height="670px">
        <el-table-column label="ip" prop="ip" width="180px"></el-table-column>
        <el-table-column :label="$t('system.qqdz')" prop="url"></el-table-column>
        <el-table-column :label="$t('system.qqlx')" prop="type" width="100px"></el-table-column>
        <el-table-column :label="$t('system.qqsj')" prop="data"></el-table-column>
        <el-table-column :label="$t('system.yhm')" prop="admin_user_name" width="100px"></el-table-column>
        <el-table-column :label="$t('system.qqsjs')" prop="create_time"></el-table-column>
      </el-table>
      <page :queryInfos="queryInfo" @pageChange="PageChange" queryUrl="systemManage/SystemLog" :pamams="'ip=' + (form.ip || '') + '&create_time=' + (form.create_time || '')"></page>
    </el-card>
  </div>
</template>

<script>
import * as SystemLog from '../../interface/systemManage/SystemLog'
import Pagination from '../common/Pagination'

export default {
  name: 'SystemLog',
  components: {
    page: Pagination
  },
  data: function () {
    return {
      logList: [],
      queryInfo: {
        query: '',
        pageNo: 1,
        pageSize: 2,
        total: 0
      },
      form: {}
    }
  },
  created () {
    this.getList()
  },
  methods: {
    PageChange: function (e) {
      // console.log(e)
      var logList = e.productList
      for (var i = 0; i < e.productList.length; i++) {
        logList[i].data = JSON.stringify(e.productList[i].data)
      }
      this.logList = logList
      this.queryInfo = e.queryInfo
      // this.getList()
    },
    getList: async function () {
      var p = '?ip=' + (this.form.ip || '') + '&create_time=' + (this.form.create_time || [])
      const data = await SystemLog.ActionLogList(this, p)
      this.logList = data.result.data
      for (var i = 0; i < data.result.data.length; i++) {
        this.logList[i].data = JSON.stringify(data.result.data[i].data)
      }
      // console.log(this.logList)
      this.queryInfo.total = data.result.totalCount
      this.queryInfo.pageNo = data.result.pageNo
      this.queryInfo.pageSize = data.result.pageSize
    }
  }
}
</script>

<style scoped>

</style>
