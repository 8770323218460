// api 地址
const api = {
  productList: 'admin/equipmentType/getAll',
  companyList: 'admin/express/list',
  detailList: 'admin/WarehouseEquipment/list?id=',
  detailList1: 'admin/WarehouseEquipment/list',
  list: 'admin/RepairParts/list'
}
// 获取所有产品列表
export async function productList (ele) {
  var { data } = await ele.$http.get(api.productList)
  return data
}
// 获取物流公司列表
export async function companyList (ele) {
  var { data } = await ele.$http.get(api.companyList)
  return data
}

// 添加机身号
export async function detailList (ele, param) {
  var { data } = await ele.$http.get(api.detailList + param)
  return data
}

// 获取根据页面显示的条数(页数)来查询产品列表
export async function pageChange (ele, param) {
  for (var i in param) {
    var { data } = await ele.$http.get(api.detailList1 + '?' + i + '=' + param[i])
  }
  return data
}
export async function pageChanges (ele, param, pamams) {
  var p = []
  for (var i in param) {
    p.push(i + '=' + param[i])
  }
  // var api1 = ''
  // if (type === '1') {
  //   // 外观
  //   api1 = api.repairAppearance
  // } else if (type === '2') {
  //   // 检修
  //   api1 = api.RepairConclusion
  // } else if (type === '3') {
  //   // 故障
  //   api1 = api.RepairErrorDesc
  // } else if (type === '4') {
  //   // 处理意见
  //   api1 = api.RepairResolution
  // } else {
  //   api1 = api.list
  // }
  // console.log(api1)
  var { data } = await ele.$http.get(api.list + '?' + p[0] + '&' + p[1] + '&' + pamams)
  return data
}
