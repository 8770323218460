<template>
  <div style="display: inline-block;">
    <el-button size="mini" class="el-icon-delete" type="danger" @click="removeById()"></el-button>
  </div>
</template>

<script>
// import * as product from '../../interface/product/ProductList'
export default {
  props: ['deleteId', 'deleteApi', 'type'],
  methods: {
    async removeById () {
      var id = this.deleteId
      const result = await this.$confirm(this.$t('home.sfqrsc'), this.$t('home.ts'), {
        confirmButtonText: this.$t('home.qd'),
        cancelButtonText: this.$t('home.qx'),
        type: 'warning'
      }).catch(err => err) // 返回错误信息
      if (result !== 'confirm') return this.$message.info(this.$t('home.yqxsc'))
      // 点击确定删除,发起删除请求
      const resAll = await import('../../interface/' + this.deleteApi)
      if (this.type === '1') {
        // 仓库名称
        const res = await resAll.delWarehouse(this, id)
        if (res.code !== 0) return this.$message.error('删除仓库失败')
        this.$message.success('删除仓库成功')
        // 重新请求数据,刷新页面
        this.$emit('gainMsg')
      } else if (this.type === '2') {
        // 出库类型
        const res1 = await resAll.deleteProduct(this, id)
        if (res1.code !== 0) return this.$message.error('删除出库类型失败')
        this.$message.success('删除出库类型成功')
        // 重新请求数据,刷新页面
        this.$emit('gainMsg')
      } else if (this.type === '3') {
        // 入库类型
        const res2 = await resAll.delWarehouseInType(this, id)
        if (res2.code !== 0) return this.$message.error('删除入库类型失败')
        this.$message.success('删除入库类型成功')
        // 重新请求数据,刷新页面
        this.$emit('gainMsg')
      } else {
        const res3 = await resAll.deleteProduct(this, id)
        if (res3.code !== 0) return this.$message.error(this.$t('role.scsb'))
        this.$message.success(this.$t('home.sccg'))
        // 重新请求数据,刷新页面
        this.$emit('gainMsg')
      }
    }
  }
}
</script>

<style scoped>

</style>
