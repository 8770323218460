<template>
  <div style="display: inline-block;margin-right: 10px">
    <el-button v-if="addType == 'update'" type="primary" size="mini" @click="editInfoDialog" style="margin-top: 10px">
      编辑</el-button>
    <el-button v-else type="primary" @click="addAgentPop=true">添加{{title}}</el-button>
    <!--      新增的对话框-->
    <div class="addPop" v-if="addAgentPop">
      <el-dialog
        :title="title1"
        :visible.sync="addAgentPop"
        width="50%"
        @close="addClosed"
      >
        <el-form label-width="115px" :model="addForm" :rules="addFormRules" ref="addFormRef">
          <el-form-item :label="title + '名称'" prop="name">
            <el-input v-model="addForm.name"></el-input>
          </el-form-item>
          <el-form-item label="代理登陆账号" prop="mobile">
            <el-input v-model="addForm.mobile"></el-input>
          </el-form-item>
          <el-form-item label="代理登陆密码" prop="password">
            <el-input v-model="addForm.password"></el-input>
          </el-form-item>
          <el-form-item label="所属商户">
            <el-select v-model="addForm.merchant_id" placeholder="请选择商户">
              <el-option v-for="(item, index) in getMerchantAll" :key="index" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
<!--          <el-form-item label="所属代理" prop="parent_id">-->
          <el-form-item label="所属代理">
          <el-input v-model="agent_name" @click="selectPop" disabled></el-input>
            <el-button type="primary" @click="selectPop" style="margin-left: 10px;float: left">选择</el-button>
          </el-form-item>
          <el-form-item label="分成" prop="bonus">
            <el-input v-model="addForm.bonus"></el-input>
            <span style="margin-left: 10px;float: left">单位%</span>
          </el-form-item>
          <el-form-item label="收货人名称" prop="consignee">
            <el-input v-model="addForm.consignee"></el-input>
          </el-form-item>
          <el-form-item label="收货电话" prop="number">
            <el-input v-model="addForm.number" type="number"></el-input>
          </el-form-item>
          <el-form-item label="收货地址" class="inputAddress" prop="pcda">
            <el-cascader size="large" :options="options" v-model="selectedOptions" @change="handleChange" :placeholder="addressData"></el-cascader>
            <el-input v-model="addForm.address" placeholder="请输入详细地址"></el-input>
          </el-form-item>
<!--          <el-form-item label="门店id" prop="shop_id">-->
<!--            <el-input v-model="addForm.shop_id"></el-input>-->
<!--          </el-form-item>-->
<!--          <el-form-item label="合同编号" prop="contract_no">-->
<!--            <el-input v-model="addForm.contract_no"></el-input>-->
<!--          </el-form-item>-->
<!--          <el-form-item label="有效时间" prop="imei">-->
<!--            <el-date-picker-->
<!--              v-model="date"-->
<!--              type="datetimerange"-->
<!--              range-separator="至"-->
<!--              start-placeholder="开始日期"-->
<!--              end-placeholder="结束日期"-->
<!--              value-format="yyyy-MM-dd HH:mm:ss">-->
<!--            </el-date-picker>-->
<!--          </el-form-item>-->
<!--          <el-form-item label="类型" prop="type">-->
<!--            <el-radio-group v-model="addForm.type">-->
<!--              <el-radio :label="0">个人</el-radio>-->
<!--              <el-radio :label="1">企业</el-radio>-->
<!--            </el-radio-group>-->
<!--          </el-form-item>-->
          <el-form-item label="状态" prop="status">
            <el-radio-group v-model="addForm.status">
              <el-radio :label="0">正常</el-radio>
              <el-radio :label="1">禁用</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item>
            <el-button v-if="addType == 'update'" :type="isBtn ? 'primary' : ''" @click="onSubmitEdit('addForm')">编辑</el-button>
            <el-button v-else :type="isBtn ? 'primary' : ''" @click="onSubmit('addForm')">添加</el-button>
            <el-button @click="resetForm('addForm')">重置</el-button>
          </el-form-item>
        </el-form>
      </el-dialog>
    </div>
    <div class="getPop" v-if="getAgentPop">
      <div class="popModel">
        <el-tree :data="data" :props="defaultProps" @node-click="handleNodeClick" :default-expand-all="true"></el-tree>
        <div style="display: flex;justify-content: center">
          <el-button @click="sure" type="primary" style="margin: 20px 10px">确认</el-button>
          <el-button @click="clean" style="margin: 20px 10px">取消</el-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import * as agentList from '../../interface/agentManage/mercantileAgent'
import { regionData, CodeToText } from 'element-china-area-data'
export default {
  props: ['addType', 'isUpdate', 'Id', 'isBtns'],
  data: function () {
    return {
      date: [],
      addAgentPop: false,
      getAgentPop: false,
      CaseRowLists: [],
      addForm: { status: 0 },
      title: '',
      title1: '',
      data: [],
      dataId: '',
      dataName: '',
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      options: regionData,
      selectedOptions: [],
      addFormRules: {
        name: [
          { required: true, message: '请输入代理商名称', trigger: 'blur' },
          { min: 2, message: '长度为至少2个字符', trigger: 'blur' }
        ],
        mobile: [
          { message: '请输入代理登陆账号', trigger: 'blur' },
          { min: 2, message: '长度为至少2个字符', trigger: 'blur' }
        ],
        // password: [
        //   { required: true, message: '请输入代理登陆密码', trigger: 'blur' },
        //   { min: 6, message: '长度为至少6个字符', trigger: 'blur' }
        // ],
        parent_id: [
          { required: true, message: '请输入上一级代理', trigger: 'blur' }
        ],
        bonus: [
          { required: true, message: '请输入分成', trigger: 'blur' }
        ],
        pcda: [
          { message: '请输入完整地址', trigger: 'blur' }
        ],
        consignee: [
          { required: true, message: '请输入收货人名称', trigger: 'blur' }
        ],
        number: [
          { required: true, message: '请输入收货人电话', trigger: 'blur' }
        ],
        // shop_id: [
        //   { required: true, message: '请输入门店id', trigger: 'blur' }
        // ],
        contract_no: [
          { required: true, message: '请输入合同编号', trigger: 'blur' }
        ],
        type: [
          { required: true, message: '请选择类型', trigger: 'blur' }
        ],
        status: [
          { required: true, message: '请选择状态', trigger: 'blur' }
        ]
      },
      agent_name: '',
      addressData: '请选择',
      getMerchantAll: [],
      isBtn: true
    }
  },
  created () {
    this.getAllMerchant()
    switch (this.addType) {
      case 'agent' :
        this.title = '代理商'
        this.title1 = '添加代理商'
        this.addForm.agent_type = 1
        break
      case 'salesman' :
        this.title = '业务员'
        this.title1 = '添加业务员'
        this.addForm.agent_type = 2
        break
      case 'store' :
        this.title = '店铺管理员'
        this.title1 = '添加店铺管理员'
        this.addForm.agent_type = 3
        break
      case 'update' :
        this.isBtn = true
        if (this.isUpdate === 'agent') {
          this.title = '代理商'
          this.title1 = '编辑代理商'
        } else if (this.isUpdate === 'salesman') {
          this.title = '业务员'
          this.title1 = '编辑业务员'
        } else if (this.isUpdate === 'store') {
          this.title = '店铺管理员'
          this.title1 = '编辑店铺管理员'
        }
    }
  },
  methods: {
    addClosed: function () {
      this.$refs.addFormRef.resetFields()
      this.CaseRowLists = []
    },
    // 获取全部商户
    getAllMerchant: async function () {
      this.isBtn = this.isBtns
      console.log(this.isBtn)
      const data = await agentList.getAllMerchant(this)
      if (data.code !== 0) return this.$message.error('获取全部商户')
      this.getMerchantAll = data.result
    },
    // 选择上一级
    selectPop: async function () {
      var that = this
      that.getAgentPop = true
      const res = await agentList.getList(this)
      if (res.code !== 0) return that.$message.error('获取信息失败')
      console.log(res)
      that.data = res.result
      res.result.forEach(function (item, index, array) {
        that.data[index].label = item.name
        if (item.children) {
          item.children.forEach(function (item1, index1, array1) {
            that.data[index].children[index1].label = item1.name
            if (item1.children) {
              item1.children.forEach(function (item2, index2, array2) {
                that.data[index].children[index1].children[index2].label = item2.name
                delete that.data[index].children[index1].children[index2].children
              })
            }
          })
        }
      })
      console.log(that.data)
    },
    // 选择上一级id
    handleNodeClick: function (e) {
      console.log(e)
      this.dataId = e.id
      this.dataName = e.label
      this.addAgentPop = true
    },
    // 地址
    handleChange: function (value) {
      console.log(value)
      this.addForm.province = CodeToText[value[0]]
      this.addForm.city = CodeToText[value[1]]
      this.addForm.district = CodeToText[value[2]]
    },
    // 添加
    onSubmit: function (addForm) {
      if (!this.isBtn) return false
      // console.log(this.addForm)
      // this.addForm.equipment_start_time = this.date[0]
      // this.addForm.equipment_end_time = this.date[1]
      // console.log(this.date)
      this.isBtn = false
      if (addForm.province === '' || addForm.city === '' || addForm.district === '' || addForm.address === '') {
        this.isBtn = true
        return this.$message.error('请填写完整地址')
      }
      this.$refs.addFormRef.validate(async valid => {
        // console.log(valid)
        if (!valid) {
          this.isBtn = true
          return this.$message.error('请填写完整信息')
        }
        var addForm = this.addForm
        addForm.parent_id = this.dataId
        const data = await agentList.addAgent(this, addForm)
        // console.log(data)
        if (data.code !== 0) {
          this.isBtn = true
          return this.$message.error(data.msg)
        }
        this.$message.success('添加成功')
        // 关闭对话框
        this.addAgentPop = false
        // 重新请求数据
        this.getLists()
        this.addClosed()
        // this.$refs.addFormRef.resetFields()
        this.agent_name = ''
        this.selectedOptions = []
        this.addForm.address = ''
      })
    },
    getLists: function () {
      this.$emit('getLists')
    },
    // 编辑
    onSubmitEdit: function (addForm) {
      if (!this.isBtn) return false
      this.isBtn = false
      console.log(this.addForm)
      if (addForm.province === '' || addForm.city === '' || addForm.district === '' || addForm.address === '') {
        this.isBtn = true
        return this.$message.error('请填写完整地址')
      }
      this.$refs.addFormRef.validate(async valid => {
        console.log(valid)
        if (!valid) {
          this.isBtn = true
          return this.$message.error('请填写完整信息')
        }
        var addForm = this.addForm
        addForm.parent_id = this.dataId
        const data = await agentList.editAgent(this, addForm)
        console.log(data)
        if (data.code !== 0) {
          this.isBtn = true
          return this.$message.error(data.msg)
        }
        this.$message.success('编辑成功')
        // 关闭对话框
        this.addAgentPop = false
        this.$refs.addFormRef.resetFields()
        // 重新请求数据
        this.getLists()
      })
    },
    // 修改
    editInfoDialog: async function () {
      console.log(this.Id)
      this.addAgentPop = true
      var id = '?id=' + this.Id
      const res = await agentList.getAgentListInfo(this, id)
      console.log(res)
      if (res.code !== 0) return this.$message.error('查询失败')
      // this.addForm = res.result
      // this.selectedOptions = [res.result.province, res.result.city, res.result.district]
      // console.log(this.selectedOptions)
      var addForm = res.result
      this.dataId = addForm.parent_id
      this.agent_name = addForm.agent_name
      addForm.password = ''
      this.date = []
      this.addressData = addForm.province + '/' + addForm.city + '/' + addForm.district
      addForm.mch_id = addForm.merchant_id
      delete addForm.create_time
      delete addForm.parents
      delete addForm.update_time
      delete addForm.level
      delete addForm.agent_name
      delete addForm.equipment_end_time
      delete addForm.equipment_start_time
      this.addForm = addForm
      this.isBtn = true
      console.log(this.addForm)
    },
    // 重置
    resetForm: function () {
      this.$refs.addFormRef.resetFields()
      this.agent_name = ''
      this.selectedOptions = []
      this.addForm.address = ''
    },
    // 确认选择
    sure: function () {
      // this.addForm.parent_id = this.dataId
      this.addForm.parent_id = this.dataId
      this.agent_name = this.dataName
      this.getAgentPop = false
    },
    // 取消
    clean: function () {
      this.getAgentPop = false
    }
  }
}
</script>

<style scoped>
  .el-dialog {
    margin-top: 0 !important;
  }
  .el-input, .el-select {
    width: 70% !important;
    max-width: 620px;
    float: left;
  }
  .addPop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.2);
    z-index: 990;
  }
  .popModel{
    width: 40%;
    position: relative;
    margin: 20% auto 50px;
    background: #FFF;
    border-radius: 2px;
    box-sizing: border-box;
  }
  .getPop{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.2);
    z-index: 999;
  }
  .el-tree {
    padding: 10px 0;
  }
  .el-cascader {
    display: flex;
    margin-bottom: 10px;
  }
  .el-radio-group {
    line-height: 40px;
    float: left;
  }
  .el-radio, .el-radio__input {
    line-height: 40px;
  }
</style>
