// api 地址
const api = {
  login: 'admin/login',
  getAdminUserInfo: '/admin/AdminUser/getAdminUserInfo'
}
// 登录
export async function loginCheck (ele, param) {
  var { data } = await ele.$http.post(api.login, param)
  return data
}
export async function getAdminUserInfo (ele) {
  var { data } = await ele.$http.get(api.getAdminUserInfo)
  return data
}
