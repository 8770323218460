<template>
    <div>
      <el-row style="margin: 0 10px">
        <el-col :span="2" style="font-size: 18px">老化测试列表</el-col>
        <el-col :span="5" style="float: right;">
          <!--面包屑导航-->
          <el-breadcrumb separator="/">
            <el-breadcrumb-item>生产管理</el-breadcrumb-item>
            <el-breadcrumb-item><a>老化测试列表</a></el-breadcrumb-item>
          </el-breadcrumb>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="5" style="padding-right: 20px">
          <el-card style="padding: 0 !important;">
            <div slot="header" class="clearfix">
              <span>产品名称</span>
              <el-button style="float: right; padding: 3px 0" type="text" @click="collapseBtn"><i class="el-icon-s-operation" style="font-size: 18px"></i></el-button>
            </div>
            <div v-for="(item,index) in productList" :key="index" @click="chooseClick(index)" :class="{active:index==isActive}" :style="productListcss" class="productList">
              <i class="el-icon-s-grid" style="padding-right: 10px"></i>{{item.title}}
            </div>
          </el-card>
          <el-card style="display: flex;justify-content: center">
            <el-button type="primary" @click="goAgingFaultList">老化故障列表</el-button>
          </el-card>
        </el-col>
        <el-col :span="19" style="float: right">
          <el-card style="padding: 20px">
            <div v-for="(item,index) in productContent" :key="index"><i class="el-icon-s-grid" style="padding-right: 10px;margin-bottom: 10px"></i>{{item.title}}</div>
            <el-row style="display: flex;justify-content: space-between">
              <el-col :span="7">
                <el-row style="margin-top: 10px">
                  <el-col :span="5" style="float: left;line-height: 40px;font-size: 18px">预选列表</el-col>
                  <el-button type="primary">开始老化</el-button>
                </el-row>
                <el-table
                  ref="filterTable"
                  :data="preEntryData"
                  height="550px"
                  border
                  style="width: 100%">
                  <el-table-column type="selection" width="55" label="全选"></el-table-column>
                  <el-table-column type="index" label="序号" width="60"></el-table-column>
                  <el-table-column prop="equipment_no" label="机身编号"></el-table-column>
                </el-table>
                <div>数量：{{preEntryData.length}}</div>
              </el-col>
              <el-col :span="12">
                <el-row style="margin-top: 10px">
                  <el-col :span="3" style="float: left;line-height: 40px;font-size: 18px">正在老化</el-col>
                  <el-button type="primary">结束老化</el-button>
                </el-row>
                <el-table
                  ref="filterTable"
                  :data="preEntryDatas"
                  height="550px"
                  border
                  style="width: 100%">
                  <el-table-column type="selection" width="55" label="全选"></el-table-column>
                  <el-table-column type="index" label="序号" width="60"></el-table-column>
                  <el-table-column prop="machineID" label="机身编号"></el-table-column>
                  <el-table-column prop="machineID" label="开始时间"></el-table-column>
                  <el-table-column prop="machineID" label="结束时间"></el-table-column>
                  <el-table-column prop="machineID" label="老化时长"></el-table-column>
                </el-table>
                <div>数量：</div>
              </el-col>
            </el-row>
            <!--            <el-tabs v-model="activeName" @tab-click="handleClick">-->
<!--              <el-tab-pane label="预选列表" name="first">-->
<!--                <el-row style="margin-top: 10px">-->
<!--                  <el-col :span="3" style="float: left;line-height: 40px;font-size: 18px">预选列表</el-col>-->
<!--                  <el-button type="primary">开始老化</el-button>-->
<!--                </el-row>-->
<!--                <el-table-->
<!--                  ref="filterTable"-->
<!--                  :data="preEntryData"-->
<!--                  height="550px"-->
<!--                  border-->
<!--                  style="width: 100%">-->
<!--                  <el-table-column type="selection" width="55" label="全选"></el-table-column>-->
<!--                  <el-table-column type="index" label="序号" width="60"></el-table-column>-->
<!--                  <el-table-column prop="equipment_no" label="机身编号"></el-table-column>-->
<!--                </el-table>-->
<!--                <div>数量：{{preEntryData.length}}</div>-->
<!--              </el-tab-pane>-->
<!--              <el-tab-pane label="正在老化" name="second">-->
<!--                <el-row style="margin-top: 10px">-->
<!--                  <el-col :span="3" style="float: left;line-height: 40px;font-size: 18px">正在老化</el-col>-->
<!--                  <el-button type="primary">结束老化</el-button>-->
<!--                </el-row>-->
<!--                <el-table-->
<!--                  ref="filterTable"-->
<!--                  :data="preEntryData"-->
<!--                  height="550px"-->
<!--                  border-->
<!--                  style="width: 100%">-->
<!--                  <el-table-column type="selection" width="55" label="全选"></el-table-column>-->
<!--                  <el-table-column type="index" label="序号" width="60"></el-table-column>-->
<!--                  <el-table-column prop="machineID" label="机身编号"></el-table-column>-->
<!--                  <el-table-column prop="machineID" label="开始时间"></el-table-column>-->
<!--                  <el-table-column prop="machineID" label="结束时间"></el-table-column>-->
<!--                  <el-table-column prop="machineID" label="老化时长"></el-table-column>-->
<!--                </el-table>-->
<!--                <div>数量：</div>-->
<!--              </el-tab-pane>-->
<!--            </el-tabs>-->

          </el-card>
        </el-col>
      </el-row>
    </div>
</template>

<script>
import * as equipmentBind from '../../interface/processManage/EquipmentBind'

export default {
  name: 'AgingList',
  data () {
    return {
      productList: [],
      productContent: [],
      isActive: 0,
      preEntryForm: {
        imei: '',
        mobile: ''
      },
      preEntryData: [],
      preEntryDatas: [],
      productListcss: 'display:block',
      activeName: 'first'
    }
  },
  created () {
    this.getProductLists()
  },
  methods: {
    // 获取产品列表
    getProductLists: async function () {
      const data = await equipmentBind.productList(this)
      if (data.code !== 0) return this.$message.error('获取产品列表失败')
      console.log(data)
      this.productList = data.result
      this.productContent.push(this.productList[0])
      this.getList(this.productContent[0].id)
    },
    // 点击的时候切换内容
    chooseClick (index) {
      this.productContent = []
      this.isActive = index
      this.productContent.push(this.productList[index])
      this.getList(this.productContent[0].id)
    },
    // 点击按钮实现产品列表的显示和隐藏
    collapseBtn: function () {
      if (this.productListcss === 'display:block') {
        this.productListcss = 'display:none'
        return
      }
      this.productListcss = 'display:block'
    },
    getPreEntry: async function () {
      const data = await equipmentBind.getPreEntry(this)
      // const { data } = await this.$http.get('admin/modular/list')
      console.log(data)
      this.preEntryData = data.result.data
    },
    preEn: function () {
      this.$refs.preEntrRef.validate(async valid => {
        if (!valid) {
          return false
        }
        const { data } = await this.$http.post('admin/modular/add', this.preEntryForm)
        console.log(data)
        if (data.code !== 0) {
          this.$message.error(data.msg)
          return
        }
        this.$refs.preEntrRef.resetFields()
        this.$message.success('预录入成功')
        this.getPreEntry()
      })
    },
    handleClick (tab, event) {
      console.log(tab, event)
    },
    // 获取预选列表
    getList: async function (id) {
      const data = await equipmentBind.getEquipmentServer(this, id)
      if (data.code !== 0) return this.$message.error('获取预选列表')
      this.preEntryData = data.result.data
    },
    // 老化故障
    goAgingFaultList: function () {
      this.$router.push('AgingFaultList')
    }
  }
}
</script>

<style scoped>
  .clearfix:before,
  .clearfix:after {
    display: table;
    content: "";
  }
  .clearfix:after {
    clear: both
  }
  .productList{
    padding: -20px !important;
    font-size: 14px;
    width: 100%;
    height: 40px;
    line-height: 40px;
    box-sizing: border-box;
    padding-left: 20px;
    text-align: left;
    display: block;
  }
  .active{
    background-color: #409EFF;
    color: white;
  }
  .el-card{
    border: none;
  }
  .el-button{
    float: right;
  }
</style>
