import Vue from 'vue'
// 按需导入. 用什么组件就导什么.  减小项目的体积
import {
  Button, Form, FormItem, Input, Message, Container, Header, Aside, Main, Menu, Submenu, MenuItem, Breadcrumb,
  BreadcrumbItem, Card, Row, Col, Table, TableColumn, Switch, Pagination, Dialog, MessageBox,
  Tooltip, Tag, Tree, Select, Option, Cascader, Alert, Tabs, TabPane, Step, Steps, Checkbox,
  CheckboxGroup, Upload, Timeline, TimelineItem, Dropdown, DropdownItem, DropdownMenu, Radio, RadioGroup, DatePicker, Transfer, Image, Popover, Progress
} from 'element-ui'
import locale from 'element-ui/lib/locale/lang/en'

Vue.use(Button) // 按钮
Vue.use(Form) // 表单
Vue.use(FormItem) // 表单选项
Vue.use(Input) // 输入框
Vue.use(Container) // 容器
Vue.use(Header) // 头
Vue.use(Aside) // 侧边栏
Vue.use(Main) // 主体
Vue.use(Menu) // 菜单
Vue.use(Submenu) // 子菜单
Vue.use(MenuItem) // 二级菜单
Vue.use(Breadcrumb) // 面包屑导航
Vue.use(BreadcrumbItem) // 面包屑导航项
Vue.use(Card) // 卡片视图
Vue.use(Row) // 行
Vue.use(Col) // 列
Vue.use(Table) // 表格
Vue.use(TableColumn) // 表格列
Vue.use(Switch) // 开关
Vue.use(Pagination, { locale }) // 分页器
Vue.use(Dialog) // 对话框
Vue.use(Tooltip) // 文字提示
Vue.use(Tag) // 标签
Vue.use(Tree) // 树状组件
Vue.use(Select) // 下拉框
Vue.use(Option) //  下拉选项
Vue.use(Cascader) // 级联选择器
Vue.use(Alert) // 警告框
Vue.use(Tabs) //  标签页组件
Vue.use(TabPane) // 每个标签页
Vue.use(Step) //  标签页组件
Vue.use(Steps) // 每个标签页
Vue.use(Checkbox) // 复选框
Vue.use(CheckboxGroup) // 复选框组
Vue.use(Upload) // 上传文件
Vue.use(Timeline) // 时间线
Vue.use(TimelineItem) // 时间线元素
Vue.use(Dropdown)
Vue.use(DropdownItem)
Vue.use(DropdownMenu)
Vue.use(Radio)
Vue.use(RadioGroup)
Vue.use(DatePicker)
Vue.use(Transfer)
Vue.use(Image)
Vue.use(Popover)
Vue.use(Progress)
// 全局挂载. 因为项目的任何一个位置都可以弹提示.且是通过js动态弹
Vue.prototype.$message = Message
// 使用的是有取消和确定两个按钮的提示框
Vue.prototype.$confirm = MessageBox.confirm
Vue.prototype.$prompt = MessageBox.prompt
