<template>
    <div>产品类型</div>
</template>

<script>
export default {
  name: 'ProductType'
}
</script>

<style scoped>

</style>
