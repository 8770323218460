<template>
    <div>
      <el-row style="margin: 0 10px">
        <el-col :span="2" style="font-size: 18px">成品列表</el-col>
        <el-col :span="5" style="float: right;">
          <!--面包屑导航-->
          <el-breadcrumb separator="/">
            <el-breadcrumb-item>生产管理</el-breadcrumb-item>
            <el-breadcrumb-item><a>统计列表</a></el-breadcrumb-item>
            <el-breadcrumb-item><a>成品列表</a></el-breadcrumb-item>
          </el-breadcrumb>
        </el-col>
      </el-row>
      <el-card>
        <el-form :inline="true" :model="form" class="demo-form-inline">
          <el-form-item label="产品名称">
            <el-select v-model="form.region" placeholder="请选择产品名称" @change="selectName">
              <el-option label="全部" value=""></el-option>
              <el-option v-for="(item,index) in name" :key="index" :label="item.title" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-input style="width: 170px !important;margin-right: 50px" v-model="queryInfo" placeholder="机身号/模块ID/手机号"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="getProductList">搜索</el-button>
            <el-button type="success" @click="returnMsg">返回</el-button>
          </el-form-item>
        </el-form>
        <el-table
          border
          :data="finishListData"
          style="width: 100%">
          <el-table-column type="index" label="序号" width="80"></el-table-column>
          <el-table-column prop="equipment_name" label="产品名称"></el-table-column>
          <el-table-column prop="equipment_no" label="机身编号"></el-table-column>
          <el-table-column prop="imei" label="模块ID号"></el-table-column>
          <el-table-column prop="ccid" label="手机号"></el-table-column>
          <el-table-column prop="create_time" label="生产日期"></el-table-column>
        </el-table>
      </el-card>
    </div>
</template>

<script>
import * as countList from '../../../interface/processManage/CountList'
export default {
  name: 'finishList',
  data: function () {
    return {
      finishListData: [],
      queryInfo: '',
      form: {},
      name: [],
      list: []
    }
  },
  created () {
    this.getProductList()
    this.getAll()
  },
  methods: {
    getProductList: async function () {
      var p = '?keyword=' + this.queryInfo + '&id=' + (this.form.region || '')
      const data = await countList.finishList(this, p)
      // console.log(data)
      this.finishListData = data.result.data
      this.list = data.result.data
    },
    getAll: async function () {
      const data = await countList.finishListAll(this)
      if (data.code !== 0) this.$message.error('获取全部产品失败')
      this.name = data.result
    },
    returnMsg: function () {
      this.$router.go(-1)
    },
    selectName: function (e) {
      this.finishListData = []
      for (var i = 0; i < this.list.length; i++) {
        if (this.list[i].equipment_name === e) {
          this.finishListData.push(this.list[i])
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
