<template>
  <div>
    <el-row style="margin: 0 10px">
      <el-col :span="2" style="font-size: 18px">分成管理</el-col>
      <el-col :span="5" style="float: right;">
        <!--面包屑导航-->
        <el-breadcrumb separator="/">
          <el-breadcrumb-item>财务管理</el-breadcrumb-item>
          <el-breadcrumb-item>分成管理</el-breadcrumb-item>
        </el-breadcrumb>
      </el-col>
    </el-row>
    <el-card>
      <el-form :inline="true" :model="data" class="demo-form-inline">
        <el-form-item>
          <el-date-picker
            v-model="data.create_time"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd HH:mm:ss">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="类型">
          <el-select v-model="data.type" placeholder="类型">
            <el-option label="全部" value=""></el-option>
            <el-option label="收入" value="1"></el-option>
            <el-option label="支出" value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="订单编号">
          <el-input v-model="data.order_no" placeholder="订单编号"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="getList()">查询</el-button>
        </el-form-item>
      </el-form>
      <el-table
        ref="filterTable"
        :data="divideData"
        border
        style="width: 100%">
        <el-table-column prop="types" label="类型"></el-table-column>
        <el-table-column prop="order_no" label="订单编号" width="235px"></el-table-column>
        <el-table-column prop="total_price" label="订单金额"></el-table-column>
        <el-table-column prop="price" label="分成金额"></el-table-column>
        <el-table-column prop="" label="消息">
          <template slot-scope="scope">
            <el-popover trigger="hover" placement="top">
              <p>消息: {{ scope.row.msg }}</p>
              <div slot="reference" class="name-wrapper">
                <el-tag size="medium">展开</el-tag>
              </div>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column prop="equipment_no" label="设备编号"></el-table-column>
        <el-table-column prop="create_time" label="创建时间" width="235px"></el-table-column>
        <el-table-column prop="merchant_name" label="商户"></el-table-column>
        <el-table-column prop="agent_name" label="经销商"></el-table-column>
        <el-table-column prop="shop_name" label="门店名称"></el-table-column>
        <el-table-column prop="user_name" label="用户名称"></el-table-column>
        <el-table-column prop="equipment_name" label="设备类型"></el-table-column>
        <!--        <el-table-column prop="create_time" label="创建时间"></el-table-column>-->
<!--        <el-table-column label="操作">-->
<!--          <template #default="scope"></template>-->
<!--        </el-table-column>-->
      </el-table>
      <page :queryInfos="queryInfo" @pageChange="PageChanges" query-url="financialManage/Divide" :pamams="'type=' + (this.data.type || '') + '&order_no=' + (this.data.order_no || '') + '&create_time=' + (this.data.create_time || '')"></page>
    </el-card>
  </div>
</template>

<script>
import * as getDivide from '../../interface/financialManage/Divide'
import Pagination from '../common/Pagination'
export default {
  name: 'Divide',
  components: {
    page: Pagination
  },
  data: function () {
    return {
      queryInfo: {
        region: '',
        pageNo: 1,
        pageSize: 2,
        total: 0
      },
      divideData: [],
      data: {}
    }
  },
  created () {
    this.getList()
  },
  methods: {
    getList: async function () {
      var p = '?type=' + (this.data.type || '') + '&order_no=' + (this.data.order_no || '') + '&create_time=' + (this.data.create_time || '')
      const data = await getDivide.BranchLogList(this, p)
      if (data.code !== 0) return this.$message.error('获取分成记录失败')
      var divideData = data.result.data
      for (var i = 0; i < data.result.data.length; i++) {
        divideData[i].types = data.result.data[i].type === 1 ? '收入' : '支出'
      }
      this.divideData = divideData
      this.queryInfo.pageNo = data.result.pageNo
      this.queryInfo.pageSize = data.result.pageSize
      this.queryInfo.total = data.result.totalCount
    },
    PageChanges: function (e) {
      var divideData = e.productList
      for (var i = 0; i < divideData.length; i++) {
        divideData[i].types = divideData[i].type === 1 ? '收入' : '支出'
      }
      this.divideData = divideData
      this.queryInfo = e.queryInfo
    }
  }
}
</script>

<style scoped>
  .el-tag + .el-tag {
    margin-left: 10px;
  }
  .button-new-tag {
    margin-left: 10px;
    height: 32px;
    line-height: 30px;
    padding-top: 0;
    padding-bottom: 0;
  }
  .input-new-tag {
    width: 90px;
    margin-left: 10px;
    vertical-align: bottom;
  }
</style>
