// api地址
const api = {
  getLists: 'admin/Shop/list',
  addShop: 'admin/Shop/add',
  getShop: 'admin/Shop/info?id=',
  updateShop: 'admin/Shop/edit',
  deleteShop: 'admin/Shop/del?id=',
  getAllPackage: '/admin/Package/getAll'
}
// 获取全部套餐
export async function getAllPackage (ele) {
  var { data } = await ele.$http.get(api.getAllPackage)
  return data
}
// 获取门店列表
export async function getShopList (ele, param) {
  var { data } = await ele.$http.get(api.getLists + param)
  return data
}
// 添加门店
export async function addShopList (ele, param) {
  var { data } = await ele.$http.post(api.addShop, param)
  return data
}
// 获取门店详情
export async function getShopInfo (ele, param) {
  var { data } = await ele.$http.get(api.getShop + param)
  return data
}
// 编辑门店
export async function updateShopList (ele, param) {
  var { data } = await ele.$http.put(api.updateShop, param)
  return data
}
// 删除门店
export async function deleteShopList (ele, param) {
  var { data } = await ele.$http.delete(api.deleteShop + param)
  return data
}
export async function pageChanges (ele, param, params) {
  var p = []
  for (var i in param) {
    p.push(i + '=' + param[i])
  }
  var { data } = await ele.$http.get(api.getLists + '?' + p[0] + '&' + p[1] + '&' + params)
  return data
}
