// api 地址
const api = {
  productList: 'admin/RepairAppearance/getAll?equipment_type_id=',
  addProduct: 'admin/repairAppearance/add',
  editProductDialog: 'admin/repairAppearance/info?id=',
  editProduct: 'admin/repairAppearance/edit?equipment_type_id=',
  deleteProduct: 'admin/repairAppearance/del?id=',
  priceList: 'admin/RepairParts/list?equipment_type_id=',
  addPriceList: 'admin/RepairParts/add',
  updatePriceList: 'admin/RepairParts/edit',
  deletePriceList: 'admin/RepairParts/del?id='
}
// 删除收费标准
export async function deleteRepairPrice (ele, param) {
  var { data } = await ele.$http.delete(api.deletePriceList + param)
  return data
}
// 编辑收费标准
export async function updateRepairPrice (ele, param) {
  var { data } = await ele.$http.post(api.updatePriceList, param)
  return data
}

// 添加收费标准
export async function addRepairPrice (ele, param) {
  var { data } = await ele.$http.post(api.addPriceList, param)
  return data
}

// 获取收费标准列表
export async function repairPrice (ele, param) {
  var { data } = await ele.$http.get(api.priceList + param)
  return data
}

// 获取产品列表
export async function productList (ele, param) {
  var { data } = await ele.$http.get(api.productList + param)
  return data
}

// 获取根据页面显示的条数(页数)来查询产品列表
export async function pageChange (ele, param) {
  for (var i in param) {
    var { data } = await ele.$http.get(api.productList + '?' + i + '=' + param[i])
  }
  return data
}

// 添加产品
export async function addProduct (ele, param) {
  var { data } = await ele.$http.post(api.addProduct, param)
  return data
}

// 根据id查询产品信息
export async function editProductDialog (ele, param) {
  var { data } = await ele.$http.get(api.editProductDialog + param)
  return data
}

// 提交产品修改的信息
export async function editProduct (ele, param1, param2) {
  var { data } = await ele.$http.put(api.editProduct + param1, param2)
  return data
}
// 删除产品的信息
export async function deleteProduct (ele, param) {
  var { data } = await ele.$http.delete(api.deleteProduct + param)
  return data
}
