<template>
  <el-container class="home-container">
    <!--头部区域-->
    <el-header>
      <div class="titleHeader">
        <div :class="collapseP">{{collapseTitle}}</div>
        <div :class="toggleButton" @click="toggleCollapse"><i :class="iconMsg"></i></div>
        <div style="display: flex;align-items: center">
          <span class="langeShow">{{$t('lan')}}</span>
          <el-button type="primary" size="mini" @click="changeLang">{{$t('home.qh')}}</el-button>
        </div>
<!--        <div class="selectL">-->
<!--          <el-select v-model="valueYY" placeholder="请选择语言" @change="selectYY">-->
<!--            <el-option :label="$t('default.lan')" :value="$t('default.lan')"></el-option>-->
<!--            <el-option label="英文" value="en"></el-option>-->
<!--          </el-select>-->
<!--        </div>-->
      </div>
      <div class="titleContainer">
        <div class="reload"><i class="el-icon-refresh-left" @click="handleRefres"></i></div>
        <el-dropdown class="titleMsg">
          <span class="el-dropdown-link">
            <img src="../assets/picture.webp" alt="" width="30px" height="30px" style="border-radius: 15px;">
            <span style="font-size: 16px">{{name}}</span>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item icon="el-icon-plus"><span style="padding-left: 15px">{{$t('home.grzx')}}</span></el-dropdown-item>
            <el-dropdown-item icon="el-icon-circle-plus" @click="logout"><span style="padding-left: 15px" @click="logout">{{$t('home.tc')}}</span></el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>

    </el-header>
    <!--页面主体-->
    <el-container class="middleContainer">
      <!--侧边栏-->
<!--      <el-aside>-->
        <!-- 用户信息-->
<!--        <el-row style="margin:20px 10px;text-align: center">-->
<!--          <el-col :span="10" style="margin-top: 17px"><img src="../assets/picture.webp" alt="" width="50px" height="50px" style="border-radius: 25px"></el-col>-->
<!--          <el-col :span="14" style="color: #1a376a;font-size: 14px;" :class="loginMsg">-->
<!--            <p>超级管理员</p>-->
<!--            <i class="el-icon-s-home"><span style="padding-left: 10px">在线</span></i>-->
<!--          </el-col>-->
<!--        </el-row>-->
<!--        <el-row style="margin:10px;text-align: center">-->
<!--          <el-col style="margin-top: 17px"><img src="../assets/picture.webp" alt="" width="50px" height="50px" style="border-radius: 25px"></el-col>-->
<!--          <el-col style="color: #1a376a;font-size: 16px;font-weight: 600" :class="loginMsg">-->
<!--            <p>{{name}}</p>-->
<!--          </el-col>-->
<!--        </el-row>-->
        <!--菜单-->
        <!--unique-opened  只能打开一个菜单-->
        <!--collapse  是否需要收缩-->
        <!--collapse-transition  是否需要过渡动画-->
        <!--router 激活导航时以 index 作为 path 进行路由跳转-->
<!--        &lt;!&ndash;default-active  默认高亮的路由&ndash;&gt;-->
        <el-menu
          class="el-menu-vertical-demo"
          background-color="#ffffff"
          text-color="#1a376a"
          active-text-color="#409EFF"
          unique-opened
          :collapse="isCollapse"
          router
          :default-active="activePath"
        >
          <div v-for="item in menuList" :key="item.id">
            <!--一级菜单-->
            <!--index  唯一标记这个菜单,它的数据需要是字符类型-->
            <el-menu-item :index="item.v_rule" v-if="!item.children" @click="saveNavState(item.v_rule)">
              <i :class="item.icon"></i>
              <span slot="title" v-if="isName == 2">{{item.name}}</span>
              <span slot="title" v-if="isName == 1">{{item.name_english}}</span>
            </el-menu-item>
            <el-submenu :index="item.id+''" v-else>
              <template slot="title">
                <!--图标-->
                <i :class="item.icon"></i>
                <!--文本-->
                <span slot="title" v-if="isName == 2">{{item.name}}</span>
                <span slot="title" v-if="isName == 1">{{item.name_english}}</span>
              </template>
<!--              <el-menu-item-group></el-menu-item-group>-->
              <!--二级菜单-->
              <!--因为index要作为路由地址存在,所以需要成/user这样的表达式-->
              <el-menu-item
                :index="subItem.v_rule"
                v-for="subItem in item.children"
                :key="subItem.id"
                @click="saveNavState(subItem.v_rule)"
              >
                <!--图标-->
                <i :class="subItem.icon"></i>
                <!--文本-->
                <span slot="title" v-if="isName == 2">{{ subItem.name }}</span>
                <span slot="title" v-if="isName == 1">{{ subItem.name_english }}</span>
              </el-menu-item>
            </el-submenu>
          </div>
        </el-menu>
<!--      </el-aside>-->
      <!--右边区域-->
      <el-main>
        <router-view v-if="isRouterAlive"></router-view>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import Nprogress from 'nprogress'
import 'nprogress/nprogress.css'
export default {
  name: 'Home',
  provide () {
    return {
      reload: this.reload
    }
  },
  data () {
    return {
      isRouterAlive: true,
      menuList: '',
      isCollapse: false, // 默认没有折叠
      activePath: '',
      collapseTitle: this.$t('home.xmgl'),
      toggleButton: 'toggle-button',
      iconMsg: 'el-icon-s-fold',
      collapseP: 'collapseP1',
      loginMsg: 'loginMsg2',
      name: '',
      valueYY: '',
      isName: 2
    }
  },
  // 创见了vue实例,就获取数据
  created () {
    // 读取保存的激活路由地址
    this.activePath = window.sessionStorage.getItem('activePaths')
    // console.log('jksdjs')
    this.getMenuList() // 调用获取菜单数据的函数
    // this.menuList = power.menuList
    // 获取个人信息
    // this.getPersonInfo()
    this.getName()
  },
  methods: {
    // 切换语言
    changeLang: function () {
      if (this.$i18n.locale === 'en-US') {
        this.$i18n.locale = 'zh-CN'
        this.isName = 2
        document.title = '后台项目管理'
      } else {
        this.$i18n.locale = 'en-US'
        this.isName = 1
        document.title = 'Project Management'
      }
      this.collapseTitle = this.$t('home.xmgl')
      this.reload()
    },
    reload () {
      this.isRouterAlive = false
      this.$nextTick(function () {
        this.isRouterAlive = true
      })
    },
    handleRefres () {
      this.reload()
      Nprogress.start()
      setTimeout(function () {
        Nprogress.done()
      }, 800)
    },
    getPersonInfo: function () {
    },
    // 获取名字
    getName: function () {
      console.log(window.sessionStorage.getItem('name'))
      this.name = window.sessionStorage.getItem('name')
    },
    logout () {
      console.log('退出')
      // 清空sessionStorage.  删除token
      window.sessionStorage.clear()
      // 回到登录页
      this.$router.push('/login')
    },
    async getMenuList () {
      // 获取菜单数据
      const { data } = await this.$http.get('admin/AdminNode/getMenu')
      // console.log(data)
      // 获取失败报错
      if (data.code !== 0) return this.$message.error(data.msg)
      // 获取成功. 将数据存入menuList
      this.menuList = data.result
      // this.saveNavState(this.menuList[0].children[0].c_rule)
    },
    toggleCollapse () {
      if (!this.isCollapse) {
        this.collapseTitle = this.$t('home.gl')
        this.toggleButton = 'toggle-button1'
        this.iconMsg = 'el-icon-s-unfold'
        this.collapseP = 'collapseP2'
        this.loginMsg = 'loginMsg1'
      } else {
        this.collapseTitle = this.$t('home.xmgl')
        this.toggleButton = 'toggle-button'
        this.iconMsg = 'el-icon-s-fold'
        this.collapseP = 'collapseP1'
        this.loginMsg = 'loginMsg2'
      }
      // 点击将是否收缩的标志位取反
      this.isCollapse = !this.isCollapse
    },
    // path当前点击菜单的路径
    saveNavState (path) {
      console.log('jksdj', path)
      // 记录到sessionStorage里面.  记录到data中
      window.sessionStorage.setItem('activePaths', path)
      this.activePath = path
    }
  }
}
</script>

<style lang="less" scoped>
  .collapseP2{
    width: 150px !important;
  }
  .langeShow{
    padding: 0 20px;
  }
.el-menu--collapse {
    width: 64px !important;
}
  .el-menu-vertical-demo:not(.el-menu--collapse) {
    width: 200px;
  }
  .loginMsg1{
    display: none;
  }
  .loginMsg2{
    display: block;
  }
.home-container {
  height: 100%;
}

.el-header {
  padding: 0px !important;
  background: #1a376a;
  color: white;
  font-size: 20px;
  display: flex; /*弹性布局*/
  justify-content: space-between; /*主轴上的元素布局:两端对齐*/
  align-items: center; /*侧轴居中*/

  span {
    margin-left: 10px;
  }
}

.middleContainer{
  width: 100%;
  display: flex;
  position: absolute;
  top: 60px;
  bottom: 0;
  overflow: hidden;
}

.el-aside {
  flex: 0 0 200px;
  width: 200px;
  height: 100%;
  background: #ffffff;
  color: #ffffff;
  /*overflow-y: scroll;*/
  .el-menu {
    border-right: none;
  }
}

.el-main {
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  background: rgb(234, 237, 241);
  /*position: absolute;*/
  /*left: 200px;*/
  /*overflow-y: auto;*/
  /*width: 100%;*/
  /*height: 100%;*/
  /*margin-left: 200px;*/
}

.iconfont {
  margin-right: 10px;
}

.titleHeader{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.titleContainer{
  margin-right: 20px;
  width: 230px;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.reload{
  width: 50px;
  height: 60px;
  line-height: 60px;
  text-align: center;
}

.reload:hover,.titleMsg:hover,.toggle-button:hover,.toggle-button:hover,.toggle-button1:hover{
  background-color: rgba(255,255,255,.3);
}
.titleMsg{
  width: 180px;
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 0 23px;
}
// 点击切换按钮
.toggle-button {
  height: 60px;
  line-height: 60px;
  width: 40px;
  text-align: center;
  /*margin-left: 100px;*/
}
.toggle-button1 {
  height: 60px;
  line-height: 60px;
  width: 40px;
  text-align: center;
  /*margin-left: 10px;*/
}
.collapseP1{
  padding-left: -20px !important;
  width: 200px;
  text-align: center;
}
.collapseP2{
  padding-left: -20px !important;
  width: 64px;
  text-align: center;
}
.el-dropdown-link {
  cursor: pointer;
  color: white;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.el-icon-arrow-down {
  font-size: 12px;
}
.el-dropdown-menu{
  width: 180px;
}
.demonstration {
  display: block;
  color: #8492a6;
  font-size: 14px;
  margin-bottom: 20px;
}
  /*.el-menu--collapse{*/
  /*  width: 200px !important;*/
  /*}*/
  .el-menu--collapse>.el-menu-item span, .el-menu--collapse .el-submenu>.el-submenu__title span {
    height: 0;
    width: 0;
    overflow: hidden;
    visibility: hidden;
    display: inline-block;
  }
</style>
