<template>
  <div>
    <el-row style="margin: 0 10px">
      <el-col :span="6" style="font-size: 18px">{{$t('home.fzgl')}}</el-col>
      <el-col :span="9" style="float: right;">
        <!--面包屑导航-->
        <el-breadcrumb separator="/">
          <el-breadcrumb-item>{{$t('home.sbgl')}}</el-breadcrumb-item>
          <el-breadcrumb-item>{{$t('home.fzgl')}}</el-breadcrumb-item>
        </el-breadcrumb>
      </el-col>
    </el-row>
    <el-card>
<!--      <el-form :inline="true" :model="form" class="demo-form-inline">-->
<!--        <el-form-item>-->
<!--          <el-input v-model="form.keyword" placeholder="id/微信昵称"></el-input>-->
<!--        </el-form-item>-->
<!--        <el-form-item>-->
<!--          <el-select v-model="form.level" placeholder="会员级别">-->
<!--            <el-option label="全部" value=""></el-option>-->
<!--            <el-option label="普通" value="0"></el-option>-->
<!--            <el-option label="会员" value="1"></el-option>-->
<!--          </el-select>-->
<!--        </el-form-item>-->
<!--        <el-form-item>-->
<!--          <el-button type="primary" @click="getProductList">查询</el-button>-->
<!--        </el-form-item>-->
<!--      </el-form>-->
      <add-btn
        :addName=" $t('devDetail.fz')"
        @gainMsgs="getList"
        :addForm="addProductForm"
        :addRules="addProductRules"
        :addApi="path"
        :addProductFormLists="addProductFormList"
      ></add-btn>
<!--      <el-button type="primary" @click="addGroup">新增分组</el-button>-->
      <el-table border :data="productList" height="670px">
        <el-table-column label="id" prop="id"></el-table-column>
        <el-table-column :label="$t('group.mc')" prop="name"></el-table-column>
        <el-table-column :label="$t('group.ms')" prop="desc"></el-table-column>
        <el-table-column :label="$t('group.px')" prop="sort"></el-table-column>
        <el-table-column :label="$t('home.cz')" width="180px">
          <template #default="scope">
<!--            <editBtn-->
<!--              :editId="scope.row.id"-->
<!--              :editForm="editProductForm"-->
<!--              :addProductFormList="addProductFormList"-->
<!--              :editRules="addProductRules"-->
<!--              @gainMsg="getList"-->
<!--              :editApi="path"-->
<!--              :editProductFormLists="addProductFormList"-->
<!--              editName="分组"-->
<!--            ></editBtn>-->
            <el-button size="mini" type="primary" @click="updateGroup(scope.row.id)">{{$t('edit.xg')}}</el-button>
            <el-button size="mini" type="danger" @click="delGroup(scope.row.id)">{{$t('home.sc')}}</el-button>
            <!--           <deleteBtn size="mini" :deleteId="scope.row.id" @gainMsg="PageChange" :deleteApi="path"></deleteBtn>-->
          </template>
        </el-table-column>
      </el-table>
      <pagination :queryInfos="queryInfo" @pageChange="PageChange" queryUrl="Img/FileGroup"></pagination>
    </el-card>
    <el-dialog
      :title="$t('group.xgfz')"
      :visible.sync="editDialogVisible"
      width="50%"
      @close="editDialogClosed"
    >
      <el-form label-width="150px" :model="infoData" :rules="addProductRules" ref="editProductFormRef">
        <el-form-item :label="$t('group.mc')" prop="name">
          <el-input v-model="infoData.name"></el-input>
        </el-form-item>
        <el-form-item :label="$t('group.ms')" prop="desc">
          <el-input v-model="infoData.desc"></el-input>
        </el-form-item>
        <el-form-item :label="$t('group.px')" prop="sort">
          <el-input v-model="infoData.sort"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button @click="editDialogClosed">{{this.$t('home.qx')}}</el-button>
          <el-button type="primary" @click="editProduct">{{this.$t('home.qd')}}</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
import * as getGroup from '../../../interface/Img/FileGroup'
import pagination from '../../common/Pagination'
import AddBtn from '../../common/AddBtn'
export default {
  name: 'FileGroup',
  components: { pagination, AddBtn },
  data: function () {
    return {
      path: 'Img/FileGroup',
      productList: [],
      queryInfo: {
        query: '',
        pageNo: 1,
        pageSize: 2,
        total: 0
      },
      addProductFormList: [
        {
          title: this.$t('group.mc'),
          prop: 'name'
        },
        {
          title: this.$t('group.ms'),
          prop: 'desc'
        },
        {
          title: this.$t('group.px'),
          prop: 'sort'
        }],
      addProductForm: {
        name: '',
        desc: '',
        sort: ''
      },
      addProductRules: {
        name: [
          { required: true, message: this.$t('group.qsrmc'), trigger: 'blur' }
        ],
        desc: [
          { required: true, message: this.$t('group.qsrms'), trigger: 'blur' }
        ],
        sort: [
          { required: true, message: this.$t('group.qsrpx'), trigger: 'blur' }
        ]
      },
      editDialogVisible: false,
      infoData: {}
      // editProductForm: {
      //   name: '',
      //   desc: '',
      //   sort: ''
      // }
    }
  },
  created () {
    this.getList()
  },
  methods: {
    // 下一页
    PageChange: function (e) {
      this.productList = e.productList
      this.queryInfo = e.queryInfo
    },
    // 获取列表
    getList: async function () {
      const data = await getGroup.fileGroupList(this)
      if (data.code !== 0) return this.$message.error(data.msg)
      this.productList = data.result.data
      this.queryInfo.total = data.result.totalCount
      this.queryInfo.pageNo = data.result.pageNo
      this.queryInfo.pageSize = data.result.pageSize
    },
    // 获取详情
    updateGroup: async function (id) {
      this.editDialogVisible = true
      const data = await getGroup.editProductDialog(this, id)
      console.log(data)
      if (data.code !== 0) return this.$message.error(data.msg)
      this.infoData = data.result
      console.log(this.infoData)
    },
    // 编辑
    editProduct: async function () {
      const data = await getGroup.editProduct(this, this.infoData)
      if (data.code !== 0) return this.$message.error(data.msg)
      this.$message.success(this.$t('group.bjcg'))
      this.editDialogClosed()
      this.editDialogVisible = false
      this.getList()
    },
    // 删除
    delGroup: function (id) {
      var that = this
      that.$confirm(this.$t('home.sfqrsc'), this.$t('home.ts'), {
        confirmButtonText: this.$t('home.qd'),
        cancelButtonText: this.$t('home.qx'),
        type: 'warning'
      }).then(async (res) => {
        console.log(res)
        const data = await getGroup.delProduct(this, id)
        if (data.code !== 0) return this.$message.error(data.msg)
        this.$message.success(this.$t('home.sccg'))
        that.getList()
      }).catch(() => {
        that.$message({
          type: 'info',
          message: this.$t('home.yqxsc')
        })
      })
    },
    editDialogClosed: function () {
      this.$refs.editProductFormRef.resetFields()
      this.editDialogVisible = false
    }
  }
}
</script>
<style scoped></style>
