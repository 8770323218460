// 地址
const api = {
  userVerify: 'admin/UserVerify/list',
  getUserInfo: 'admin/User/getUserInfo?mobile=',
  addUserInfo: 'admin/UserVerify/add',
  verify: 'admin/UserVerify/verify',
  userVerifyInfo: 'admin/UserVerify/info?id='
}
// 获取会员信息变更列表
export async function userVerifyList (ele) {
  var { data } = await ele.$http.get(api.userVerify)
  return data
}
// 获取会员信息
export async function getUserInfo (ele, param) {
  var { data } = await ele.$http.get(api.getUserInfo + param)
  return data
}
// 新增
export async function addUserInfo (ele, param) {
  var { data } = await ele.$http.post(api.addUserInfo, param)
  return data
}
// 审核
export async function verify (ele, param) {
  var { data } = await ele.$http.post(api.verify, param)
  return data
}
// 获取审核信息
export async function userVerifyInfo (ele, param) {
  var { data } = await ele.$http.post(api.userVerifyInfo + param)
  return data
}
export async function pageChange (ele, param) {
  for (var i in param) {
    var { data } = await ele.$http.get(api.userVerify + '?' + i + '=' + param[i])
  }
  return data
}
export async function pageChanges (ele, param) {
  var p = []
  for (var i in param) {
    p.push(i + '=' + param[i])
  }
  var { data } = await ele.$http.get(api.userVerify + '?' + p[0] + '&' + p[1])
  return data
}
