const api = {
  equipmentType: 'admin/equipmentType/getAll',
  expressList: 'admin/Express/getAll',
  repairList: 'admin/repair/list',
  repairAppearanceList: 'admin/RepairAppearance/getAll?equipment_type_id=',
  RepairConclusionList: 'admin/RepairConclusion/getAll?equipment_type_id=',
  RepairErrorDescList: 'admin/RepairErrorDesc/getAll?equipment_type_id=',
  RepairResolutionlist: 'admin/RepairResolution/getAll?equipment_type_id=',
  addRepairOrder: 'admin/RepairOrder/add',
  RepairOrderInfo: 'admin/RepairOrder/info?id=',
  RepairPartsAll: 'admin/RepairParts/getAll?equipment_type_id=',
  RepairOrderRepair: 'admin/RepairOrder/repair',
  repairOrderAddAddress: 'admin/repairOrder/addAddress',
  getOrderNoAndTime: 'admin/Tool/getOrderNoAndTime'
}
// 获取维修列表
export async function equipmentTypeAll (ele) {
  const { data } = await ele.$http.get(api.equipmentType)
  return data
}
// 物流公司列表
export async function getExpressList (ele) {
  const { data } = await ele.$http.get(api.expressList)
  return data
}
// 维修点列表
export async function getRepairList (ele) {
  const { data } = await ele.$http.get(api.repairList)
  return data
}
// 维修点-外观选项-列表
export async function repairAppearanceList (ele, param) {
  const { data } = await ele.$http.get(api.repairAppearanceList + param)
  return data
}
// 维修点-检修结论-列表
export async function RepairConclusionList (ele, param) {
  const { data } = await ele.$http.get(api.RepairConclusionList + param)
  return data
}
// 维修点-故障描述-列表
export async function RepairErrorDescList (ele, param) {
  const { data } = await ele.$http.get(api.RepairErrorDescList + param)
  return data
}
// 维修点-处理意见-列表
export async function RepairResolutionlist (ele, param) {
  const { data } = await ele.$http.get(api.RepairResolutionlist + param)
  return data
}
// 维修订单-新增
export async function addRepairOrder (ele, param) {
  const { data } = await ele.$http.post(api.addRepairOrder, param)
  return data
}
// 维修订单-详情
export async function RepairOrderInfo (ele, param) {
  const { data } = await ele.$http.get(api.RepairOrderInfo + param)
  return data
}
// 获取全部配件
export async function RepairPartsAll (ele, param) {
  const { data } = await ele.$http.get(api.RepairPartsAll + param)
  return data
}
// 维修订单-维修
export async function RepairOrderRepair (ele, param) {
  const { data } = await ele.$http.put(api.RepairOrderRepair, param)
  return data
}
// 维修订单-发货
export async function repairOrderAddAddress (ele, param) {
  const { data } = await ele.$http.post(api.repairOrderAddAddress, param)
  return data
}
// 获取单号
export async function getOrderNoAndTime (ele) {
  const { data } = await ele.$http.get(api.getOrderNoAndTime)
  return data
}
