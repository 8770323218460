const api = {
  getEquipmentByType: 'admin/equipmentServer/getEquipmentByType',
  agentBindEquipment: 'admin/equipment/agentBindEquipment',
  getAllShop: 'admin/Shop/getAll'
}
// 根据类型获取全部设备列表
export async function getEquipmentByType (ele, param) {
  const { data } = await ele.$http.get(api.getEquipmentByType + param)
  return data
}
// 经销商绑定设备
export async function agentBindEquipment (ele, param) {
  const { data } = await ele.$http.post(api.agentBindEquipment, param)
  return data
}
// 所有门店
export async function getAllShop (ele, param) {
  const { data } = await ele.$http.post(api.getAllShop, param)
  return data
}
