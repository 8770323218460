// api 地址
const api = {
  preEntryList: 'admin/modular/list',
  preEntryAdd: 'admin/modular/add'
}
// 获取预录入列表
export async function preEntryList (ele) {
  var { data } = await ele.$http.get(api.preEntryList)
  return data
}

// 添加产品
export async function preEntryAdd (ele, param) {
  var { data } = await ele.$http.post(api.preEntryAdd, param)
  return data
}
