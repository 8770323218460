<template>
    <div>
      <!--面包屑-->
      <el-row style="margin: 0 10px">
        <el-col :span="2" style="font-size: 18px">机身号绑定列表</el-col>
        <el-col :span="5" style="float: right">
          <!--面包屑导航-->
          <el-breadcrumb separator="/">
            <el-breadcrumb-item>生产管理</el-breadcrumb-item>
            <el-breadcrumb-item><a>机身号绑定</a></el-breadcrumb-item>
          </el-breadcrumb>
        </el-col>
      </el-row>
      <el-row>
          <el-col :span="5" style="padding-right: 20px">
            <el-card style="padding: 0 !important;">
              <div slot="header" class="clearfix">
                <span>产品名称</span>
                <el-button style="float: right; padding: 3px 0" type="text" @click="collapseBtn"><i class="el-icon-s-operation" style="font-size: 18px"></i></el-button>
              </div>
              <div v-for="(item,index) in productList" :key="index" @click="chooseClick(index)" :class="{active:index==isActive}" :style="productListcss" class="productList">
                <i class="el-icon-s-grid" style="padding-right: 10px"></i>{{item.title}}
              </div>
            </el-card>
          </el-col>
          <el-col :span="19" style="float: right">
            <el-card style="padding: 20px">
              <div v-for="(item,index) in productContent" :key="index"><i class="el-icon-s-grid" style="padding-right: 10px"></i>{{item.title}}</div>
              <el-row style="padding-top: 20px">
                <el-form :inline="true" class="login_form" :model="preEntryForm" ref="preEntryFormRef">
                  <el-form-item prop="imei">
                    <el-input style="width: 150px !important;margin-right: 50px" placeholder="请输入4G模块编号" type="text" v-model="preEntryForm.imei" @input="getModularList($event,'imei')"></el-input>
                  </el-form-item>
                  <el-form-item prop="ccid">
                    <el-input placeholder="请输入手机号" type="text" v-model="preEntryForm.ccid" @input="getModularList($event,'ccid')" disabled></el-input>
                  </el-form-item>
                  <el-form-item prop="equipment_no">
                    <el-input placeholder="请输入机身编号" type="text" v-model="preEntryForm.equipment_no"></el-input>
                  </el-form-item>
                  <el-form-item>
                    <el-button type="primary" @click="preEn">确认</el-button>
                  </el-form-item>
                </el-form>
              </el-row>
              <el-table
                ref="filterTable"
                :data="preEntryData"
                height="600px"
                border
                style="width: 100%">
                <el-table-column prop="equipment_no" label="机身编号"></el-table-column>
                <el-table-column prop="imei" label="4G模块ID号"></el-table-column>
                <el-table-column prop="ccid" label="手机号"></el-table-column>
                <el-table-column prop="create_time" label="绑定时间"></el-table-column>
                <el-table-column prop="username" label="操作员"></el-table-column>
              </el-table>
              <el-row style="float: right;margin-right: 20px">已绑定数量: {{preEntryNum}}</el-row>
            </el-card>
          </el-col>
      </el-row>
    </div>
</template>

<script>
import * as equipmentBind from '../../interface/processManage/EquipmentBind'
export default {
  name: 'EquipmentBind',
  data () {
    return {
      productList: [],
      productContent: [],
      isActive: 0,
      preEntryForm: {
        imei: '',
        ccid: '',
        equipment_no: '',
        type_id: 0
      },
      preEntryData: [],
      productListcss: 'display:block',
      preEntryNum: 0,
      isDisabled: false
    }
  },
  created () {
    this.getProductLists()
  },
  methods: {
    // 获取产品列表
    getProductLists: async function () {
      const data = await equipmentBind.productList(this)
      if (data.code !== 0) return this.$message.error('获取产品列表失败')
      console.log(data)
      this.productList = data.result
      this.productContent.push(this.productList[0])
    },
    getModularList: async function (e, ele) {
      console.log(e)
      const data = await equipmentBind.modularLists(this, e)
      console.log(data)
      if (data.result === null) {
        if (ele === 'imei') {
          // this.preEntryForm.ccid = ''
        } else if (ele === 'ccid') {
          // this.preEntryForm.imei = ''
        }
        return false
      }
      this.preEntryForm.imei = data.result.imei
      this.preEntryForm.ccid = data.result.ccid
      this.isDisabled = true
    },
    // 点击的时候切换内容
    chooseClick (index) {
      this.productContent = []
      this.isActive = index
      this.productContent.push(this.productList[index])
      this.preEntryData = []
    },
    // 点击按钮实现产品列表的显示和隐藏
    collapseBtn: function () {
      if (this.productListcss === 'display:block') {
        this.productListcss = 'display:none'
        return
      }
      this.productListcss = 'display:block'
    },
    getPreEntry: async function () {
      const data = await equipmentBind.getPreEntry(this)
      // const { data } = await this.$http.get('admin/modular/list')
      console.log(data)
      this.preEntryData = data.result.data
      this.preEntryNum = this.preEntryData.length
    },
    preEn: function () {
      this.$refs.preEntryFormRef.validate(async valid => {
        if (!valid) {
          return false
        }
        this.preEntryForm.type_id = this.productContent[0].id
        const data = await equipmentBind.getEquipmentBind(this, this.preEntryForm)
        console.log(data)
        if (data.code !== 0) {
          this.$message.error(data.msg)
          this.$refs.preEntryFormRef.resetFields()
          return
        }
        this.$refs.preEntryFormRef.resetFields()
        this.$message.success('录入成功')
        this.preEntryData.push(data.result)
        this.preEntryNum = this.preEntryData.length
      })
    }
  }
}
</script>

<style scoped>
  .clearfix:before,
  .clearfix:after {
    display: table;
    content: "";
  }
  .clearfix:after {
    clear: both
  }
  .productList{
    padding: -20px !important;
    font-size: 14px;
    width: 100%;
    height: 40px;
    line-height: 40px;
    box-sizing: border-box;
    padding-left: 20px;
    text-align: left;
    display: block;
  }
  .active{
    background-color: #409EFF;
    color: white;
  }
  .el-card{
    border: none;
  }
</style>
