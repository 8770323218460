// api 地址
const api = {
  ComplaintList: 'admin/Complaint/list',
  ComplaintInfo: 'admin/Complaint/info?id=',
  handleComplaint: 'admin/Complaint/handle'
}
// 获取建议列表
export async function ComplaintLists (ele, params) {
  var { data } = await ele.$http.get(api.ComplaintList + params)
  return data
}
// 获取建议详情
export async function ComplaintInfo (ele, params) {
  var { data } = await ele.$http.get(api.ComplaintInfo + params)
  return data
}
// 处理投诉建议
export async function handleComplaint (ele, params) {
  var { data } = await ele.$http.put(api.handleComplaint, params)
  return data
}
export async function pageChanges (ele, param, pamams) {
  var p = []
  for (var i in param) {
    p.push(i + '=' + param[i])
  }
  var { data } = await ele.$http.get(api.ComplaintList + '?' + p[0] + '&' + p[1] + '&' + pamams)
  return data
}
