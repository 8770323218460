// 地址
const api = {
  BluetoothList: 'admin/Bluetooth/list',
  BluetoothAllList: 'admin/BluetoothLog/list'
}
// 获取列表
export async function BluetoothList (ele, params, type) {
  var BluetoothList = api.BluetoothList
  if (type === 1) BluetoothList = api.BluetoothAllList
  const { data } = await ele.$http.get(BluetoothList + params)
  return data
}
export async function pageChanges (ele, param, params, type) {
  var BluetoothList = api.BluetoothList
  if (type === 1) BluetoothList = api.BluetoothAllList
  var p = []
  for (var i in param) {
    p.push(i + '=' + param[i])
  }
  var { data } = await ele.$http.get(BluetoothList + '?' + p[0] + '&' + p[1] + '&' + params)
  return data
}
