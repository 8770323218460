// 地址
const api = {
  fileGroupAll: 'admin/fileGroup/getAll',
  uplodeImgs: 'admin/FileManagement/upload',
  FileManagement: 'admin/FileManagement/list',
  delFileManagement: '/admin/FileManagement/del?id='
}
// 分组全部分组列表
export async function fileGroupAll (ele) {
  const { data } = await ele.$http.get(api.fileGroupAll)
  return data
}
// 上传图片
export async function uplodeImgs (ele, params) {
  const { data } = await ele.$http.post(api.uplodeImgs, params)
  return data
}
// 获取全部列表
export async function FileManagement (ele, params) {
  const { data } = await ele.$http.get(api.FileManagement + params)
  return data
}
// 删除
export async function delFileManagement (ele, params) {
  const { data } = await ele.$http.delete(api.delFileManagement + params)
  return data
}
export async function pageChanges (ele, param, pamams) {
  var p = []
  for (var i in param) {
    p.push(i + '=' + param[i])
  }
  var { data } = await ele.$http.get(api.FileManagement + '?' + p[0] + '&' + p[1] + '&' + pamams)
  return data
}
