// api 地址
const api = {
  productList: 'admin/RepairErrorDesc/getAll?equipment_type_id=',
  addProduct: 'admin/RepairErrorDesc/add',
  editProductDialog: 'admin/RepairErrorDesc/info?id=',
  editProduct: 'admin/RepairErrorDesc/edit?equipment_type_id=',
  deleteProduct: 'admin/RepairErrorDesc/del?id='
}
// 获取产品列表
export async function productList (ele, param) {
  var { data } = await ele.$http.get(api.productList + param)
  return data
}

// 获取根据页面显示的条数(页数)来查询产品列表
export async function pageChange (ele, param) {
  for (var i in param) {
    var { data } = await ele.$http.get(api.productList + '?' + i + '=' + param[i])
  }
  return data
}

// 添加产品
export async function addProduct (ele, param) {
  var { data } = await ele.$http.post(api.addProduct, param)
  return data
}

// 根据id查询产品信息
export async function editProductDialog (ele, param) {
  var { data } = await ele.$http.get(api.editProductDialog + param)
  return data
}

// 提交产品修改的信息
export async function editProduct (ele, param1, param2) {
  var { data } = await ele.$http.put(api.editProduct + param1, param2)
  return data
}
// 删除产品的信息
export async function deleteProduct (ele, param) {
  var { data } = await ele.$http.delete(api.deleteProduct + param)
  return data
}
