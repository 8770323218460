<template>
    <div>
      <el-row style="margin: 0 10px">
        <el-col :span="2" style="font-size: 18px">绑定列表</el-col>
        <el-col :span="5" style="float: right;">
          <!--面包屑导航-->
          <el-breadcrumb separator="/">
            <el-breadcrumb-item>生产管理</el-breadcrumb-item>
            <el-breadcrumb-item><a>统计列表</a></el-breadcrumb-item>
            <el-breadcrumb-item><a>绑定列表</a></el-breadcrumb-item>
          </el-breadcrumb>
        </el-col>
      </el-row>
      <el-card>
        <el-row style="text-align: center;font-size: 20px;margin-bottom: 20px">
          <p>通讯模块绑定变更列表</p>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="7">
            <el-input placeholder="产品名称" v-model="queryInfo.query">
<!--              <template #append>-->
<!--                <el-button icon="el-icon-search" @click="getProductList"></el-button>-->
<!--              </template>-->
            </el-input>
          </el-col>
          <el-col :span="4">
            <el-button type="primary" @click="getProductList">查询</el-button>
          </el-col>
          <el-col :span="10">
            <el-button type="success" @click="returnMsg">返回</el-button>
          </el-col>
        </el-row>
        <el-table
          :data="changeModularListData"
          style="width: 100%">
          <el-table-column type="index" label="序号" width="80"></el-table-column>
          <el-table-column label="机身编号">
            <el-table-column prop="equipment_no" label="变更前"></el-table-column>
            <el-table-column prop="equipment_no_old" label="变更后"></el-table-column>
          </el-table-column>
          <el-table-column label="4G模块ID">
            <el-table-column prop="imei" label="变更前"></el-table-column>
            <el-table-column prop="imei_old" label="变更后"></el-table-column>
          </el-table-column>
          <el-table-column label="手机号">
            <el-table-column prop="ccid" label="变更前"></el-table-column>
            <el-table-column prop="ccid_old" label="变更后"></el-table-column>
          </el-table-column>
          <el-table-column prop="name" label="产品名称"></el-table-column>
        </el-table>
        <page :queryInfos="queryInfo" @pageChange="PageChange" queryUrl="processManage/CountList" :pamams="'name=' + queryInfo.query"></page>
      </el-card>
    </div>
</template>

<script>
import * as countList from '../../../interface/processManage/CountList'
import Pagination from '../../common/Pagination'
export default {
  name: 'bindList',
  components: {
    page: Pagination
  },
  data: function () {
    return {
      changeModularListData: [],
      queryInfo: {
        query: '',
        pageNo: 1,
        pageSize: 2,
        total: 0
      }
    }
  },
  created () {
    this.getProductList()
  },
  methods: {
    getProductList: async function () {
      var p = '?name=' + (this.queryInfo.query || '')
      const data = await countList.changeModularList(this, p)
      console.log(data)
      this.changeModularListData = data.result.data
      this.queryInfo.total = data.result.totalCount
      this.queryInfo.pageNo = data.result.pageNo
      this.queryInfo.pageSize = data.result.pageSize
    },
    PageChange: function (e) {
      this.changeModularListData = e.productList
      this.queryInfo = e.queryInfo
    },
    returnMsg: function () {
      this.$router.go(-1)
    }
  }
}
</script>

<style scoped>

</style>
