<template>
  <div>
    <el-row style="margin: 0 10px">
      <el-col :span="2" style="font-size: 18px">病例详细信息</el-col>
      <el-col :span="5" style="float: right;">
        <!--面包屑导航-->
        <el-breadcrumb separator="/">
          <el-breadcrumb-item>会员管理</el-breadcrumb-item>
          <el-breadcrumb-item><a>病例列表</a></el-breadcrumb-item>
          <el-breadcrumb-item><a>病例详细信息</a></el-breadcrumb-item>
        </el-breadcrumb>
      </el-col>
    </el-row>
    <el-card style="width: 50%">
      <el-row>
        <el-button type="success" @click="returnMsg">返回</el-button>
      </el-row>
      <el-form label-width="30%" class="demo-ruleForm">
        <el-form-item label="会员编号">{{detailData.user_id}}</el-form-item>
        <el-form-item label="会员姓名">{{detailData.card_name}}</el-form-item>
        <el-form-item label="性别">{{ detailData.sex == 0 ? '未知' : (detailData.sex == 1 ? '男' : '女')}}</el-form-item>
        <el-form-item label="联系电话">{{detailData.mobile}}</el-form-item>
        <div class="line"></div>
<!--        <el-form-item label="目前使用产品">1</el-form-item>-->
        <el-form-item label="病例类别">{{detailData.case_type}}</el-form-item>
        <el-form-item v-for="(item, index) in caseData" :key="index" :label="item.key">{{item.value}}</el-form-item>
<!--        <el-form-item label="用药情况">{{caseData}}</el-form-item>-->
      </el-form>
    </el-card>
  </div>
</template>

<script>
import * as getCaseDetail from '../../interface/memberManage/CaseList'
export default {
  name: 'CaseDetailList',
  data: function () {
    return {
      detailData: [],
      caseData: []
    }
  },
  created () {
    this.getDetail()
  },
  methods: {
    returnMsg: function () {
      this.$router.go(-1)
    },
    // 获取详情
    getDetail: async function () {
      var id = sessionStorage.getItem('caseId')
      const data = await getCaseDetail.getUserCaseInfo(this, id)
      if (data.code !== 0) this.$message.error('获取信息失败')
      this.detailData = data.result
      // var text = JSON.stringify(this.detailData.question_list)
      var list = []
      for (const item in this.detailData.question_list) {
        list.push({
          key: item,
          value: this.detailData.question_list[item] || '无'
        })
      }
      this.caseData = list
    }
  }
}
</script>

<style scoped>
.line{
  width: 100%;
  height: 1px;
  background-color: #eaeaea;
  margin-bottom: 22px;
}
</style>
