<template>
    <div>
      <!--面包屑-->
      <el-row style="margin: 0 10px">
        <el-col :span="2" style="font-size: 18px">入库列表</el-col>
        <el-col :span="5" style="float: right;">
          <!--面包屑导航-->
          <el-breadcrumb separator="/">
            <el-breadcrumb-item>仓库管理</el-breadcrumb-item>
            <el-breadcrumb-item><a>入库</a></el-breadcrumb-item>
          </el-breadcrumb>
        </el-col>
      </el-row>
      <el-card>
        <el-row :gutter="20">
          <el-col :span="20">
            <el-form class="login_form" :model="preEntryForm" ref="preEntrRef">
              <el-form-item prop="imei" style="width: 18%;float: left;margin-right: 20px;margin-left: 10px">
                <el-input placeholder="入库单号" type="text" v-model="preEntryForm.warehouse_no"></el-input>
              </el-form-item>
              <el-form-item prop="mobile" style="width: 18%;float: left;margin-right: 20px">
<!--                <el-input placeholder="产品名称" type="text" v-model="preEntryForm.equipment_type_name"></el-input>-->
                <el-select v-model="preEntryForm.equipment_type_id" placeholder="请选择产品">
                  <el-option label="全部" value=""></el-option>
                  <el-option v-for="(item, index) in getAllEqData" :key="index" :label="item.title" :value="item.id"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item prop="mobile" style="width: 18%;float: left;margin-right: 20px">
<!--                <el-input placeholder="入库类型" type="text" v-model="preEntryForm.warehouse_in_type_name"></el-input>-->
                <el-select v-model="preEntryForm.warehouse_in_type_id" placeholder="入库类型">
                  <el-option label="全部" value=""></el-option>
                  <el-option v-for="(item, index) in storeEnter" :key="index" :label="item.title" :value="item.id"></el-option>
                </el-select>
              </el-form-item>
<!--              <el-form-item prop="mobile" style="width: 18%;float: left;margin-right: 20px">-->
<!--                <el-input placeholder="机身编号" type="text" v-model="preEntryForm.imei"></el-input>-->
<!--              </el-form-item>-->
              <el-form-item :span="2" style="width: 10%;float: left">
                <el-button type="primary" @click="preEn">查询</el-button>
              </el-form-item>
            </el-form>
          </el-col>
          <el-col :span="2" style="float: right">
            <el-button type="primary" @click="addPage()">添加入库</el-button>
          </el-col>
        </el-row>

        <el-table border :data="wareHousingList" height="600px">
<!--          <el-table-column label="序号" type="index" width="80px"></el-table-column>-->
          <el-table-column label="入库单号" prop="warehouse_no" width="200px"></el-table-column>
          <el-table-column label="产品名称" prop="equipment_type_name"></el-table-column>
          <el-table-column label="单位" prop="unit"></el-table-column>
          <el-table-column label="入库数量" prop="num"></el-table-column>
          <el-table-column label="入库类型" prop="warehouse_in_type_name"></el-table-column>
          <el-table-column label="入库时间" prop="create_time"></el-table-column>
          <el-table-column label="入库仓库" prop="warehouse_name"></el-table-column>
          <el-table-column label="操作员" prop="admin_user_name"></el-table-column>
          <el-table-column label="操作">
            <template #default="scope">
              <el-button type="primary" @click="showMsg(scope.row.id)" size="mini">查看</el-button>
            </template>
          </el-table-column>
        </el-table>
        <page :queryInfos="queryInfo" :queryUrl="path" @pageChange="PageChange" :pamams="'warehouse_no=' + (preEntryForm.warehouse_no || '') + '&equipment_type_id=' + (preEntryForm.equipment_type_id || '') + '&warehouse_in_type_id=' + (preEntryForm.warehouse_in_type_id || '')"></page>
      </el-card>
    </div>
</template>

<script>
import * as store from '../../interface/storeHouse/WareHousing'
import Pagination from '../common/Pagination'
import * as getReceiptRegister from '../../interface/AfterSalesManage/ReceiptRegister'
import * as storeEnter from '../../interface/storeHouse/ProjectSettingEnter'

export default {
  name: 'WareHousing',
  components: {
    page: Pagination
  },
  data: function () {
    return {
      preEntryForm: {
        imei: '',
        mobile: ''
      },
      wareHousingList: [],
      editWareForm: {
        id: '',
        title: '',
        desc: ''
      },
      path: 'storeHouse/WareHousing',
      editWareFormRules: {
        title: [
          { required: true, message: '请输入产品标题', trigger: 'blur' },
          { min: 3, max: 10, message: '长度在 3 到 10 个字符', trigger: 'blur' }
        ],
        desc: [
          { required: true, message: '请输入产品描述', trigger: 'blur' },
          { min: 3, message: '长度至少3个字符', trigger: 'blur' }
        ]
      },
      queryInfo: {
        query: '',
        pageNo: 1,
        pageSize: 2,
        total: 0
      },
      getAllEqData: [],
      storeEnter: []
    }
  },
  created () {
    this.wareHouseList()
    this.getAll()
    this.getStoreEnterList()
  },
  methods: {
    // 获取全部产品
    getAll: async function () {
      const data = await getReceiptRegister.equipmentTypeAll(this)
      if (data.code !== 0) return this.$message.error('获取全部产品列表失败')
      this.getAllEqData = data.result
    },
    // 获取仓库入库类型
    getStoreEnterList: async function () {
      const data = await storeEnter.productListAll(this)
      this.storeEnter = data.result.data
    },
    // 获取入库列表
    wareHouseList: async function (p) {
      var p1 = ''
      if (p) p1 = p
      const data = await store.wareHousingList(this, p1)
      console.log(data)
      this.wareHousingList = data.result.data
      this.queryInfo.total = data.result.totalCount
      this.queryInfo.pageNo = data.result.pageNo
      this.queryInfo.pageSize = data.result.pageSize
    },
    PageChange (e) {
      console.log('刷新页面')
      console.log(e)
      this.wareHousingList = e.productList
    },
    showMsg: function (id) {
      this.$router.push({ path: 'showWareHousing', query: { ids: id } })
    },
    preEn: function () {
      var p = '?warehouse_no=' + (this.preEntryForm.warehouse_no || '') + '&equipment_type_id=' + (this.preEntryForm.equipment_type_id || '') + '&warehouse_in_type_id=' + (this.preEntryForm.warehouse_in_type_id || '')
      this.wareHouseList(p)
    },
    addPage: function () {
      this.$router.push('/addWareHousing')
    }
  }
}
</script>

<style scoped>

</style>
