<template>
  <div>
    <!--面包屑-->
    <el-row style="margin: 0 10px">
      <el-col :span="2" style="font-size: 18px">预录入列表</el-col>
      <el-col :span="5" style="float: right;">
        <!--面包屑导航-->
        <el-breadcrumb separator="/">
          <el-breadcrumb-item>生产管理</el-breadcrumb-item>
          <el-breadcrumb-item><a>预录入</a></el-breadcrumb-item>
        </el-breadcrumb>
      </el-col>
    </el-row>
    <el-card>
      <el-row style="font-size: 18px;">预录入</el-row>
      <el-row style="padding-top: 20px">
        <el-form :inline="true" class="login_form" :model="preEntryForm" ref="preEntrRef">
          <el-form-item prop="imei">
            <el-input placeholder="请输入4G模块编号" type="text" v-model="preEntryForm.imei" @blur="onInputBlur"></el-input>
          </el-form-item>
          <el-form-item prop="ccid">
            <el-input placeholder="请输入手机号" type="text" v-model="preEntryForm.ccid"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="preEn">确认</el-button>
          </el-form-item>
        </el-form>
      </el-row>
      <el-table
        ref="filterTable"
        :data="preEntryData"
        height="600px"
        border
        style="width: 100%">
        <el-table-column prop="imei" label="4G模块ID号"></el-table-column>
        <el-table-column prop="ccid" label="手机号"></el-table-column>
        <el-table-column prop="create_time" label="绑定时间"></el-table-column>
        <el-table-column prop="username" label="操作员"></el-table-column>
      </el-table>
      <el-row style="float: right;margin-right: 20px">已绑定数量: {{preEntryNum}}</el-row>
    </el-card>
  </div>
</template>

<script>
import * as preEntry from '../../interface/processManage/PreEntry'

export default {
  name: 'PreEntry',
  data: function () {
    return {
      preEntryForm: {
        imei: '',
        ccid: ''
      },
      preEntryData: [],
      preEntryNum: 0,
      phoneNum: []
    }
  },
  created () {
    // this.getPreEntry()
  },
  methods: {
    // getPreEntry: async function () {
    //   const data = await preEntry.preEntryList(this)
    //   console.log(data)
    //   this.preEntryData = data.result.data
    //   this.preEntryNum = this.preEntryData.length
    // },
    preEn: function () {
      this.$refs.preEntrRef.validate(async valid => {
        if (!valid) {
          return false
        }
        const data = await preEntry.preEntryAdd(this, this.preEntryForm)
        console.log(data)
        if (data.code !== 0) {
          this.$message.error(data.msg)
          return
        }
        this.$refs.preEntrRef.resetFields()
        this.$message.success('预录入成功')
        this.preEntryData.push(data.result)
        this.preEntryNum = this.preEntryData.length
      })
    },
    // 获取4G模块编号
    onInputBlur: function () {
      // var str = this.preEntryForm.imei
      // str = str.match(/IMEI:(\S*);/)[1]
      // console.log(str)
      this.preEntryForm.imei = this.preEntryForm.imei.match(/IMEI:(\S*);/)[1]
    }
  }
}
</script>

<style scoped>
.el-table{
  margin-top: -10px;
}
</style>
