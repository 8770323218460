// api 地址
const api = {
  BranchLogList: 'admin/BranchLog/list'
}
// 获取建议列表
export async function BranchLogList (ele, params) {
  var { data } = await ele.$http.get(api.BranchLogList + params)
  return data
}
export async function pageChanges (ele, param, pamams) {
  var p = []
  for (var i in param) {
    p.push(i + '=' + param[i])
  }
  var { data } = await ele.$http.get(api.BranchLogList + '?' + p[0] + '&' + p[1] + '&' + pamams)
  return data
}
