<template>
    <div>
      <el-row style="margin: 0 10px">
        <el-col :span="5" style="font-size: 18px">{{$t('power.qxgl')}}</el-col>
        <el-col :span="9" style="float: right;">
          <!--面包屑导航-->
          <el-breadcrumb separator="/">
            <el-breadcrumb-item>{{$t('role.xtgl')}}</el-breadcrumb-item>
            <el-breadcrumb-item><a>{{$t('power.qxgl')}}</a></el-breadcrumb-item>
          </el-breadcrumb>
        </el-col>
      </el-row>
      <el-card>
        <el-button type="primary" @click="dialogVisible=true">{{$t('power.tjqx')}}</el-button>
        <el-table
          :data="menuList"
          style="width: 100%;margin-bottom: 20px;"
          row-key="id"
          border
          :tree-props="{children: 'children', hasChildren: 'hasChildren'}">
          <el-table-column v-if="isName == 1" prop="name" :label="$t('power.jdmc')" sortable></el-table-column>
          <el-table-column v-if="isName == 2" prop="name_english" :label="$t('power.jdmc')" sortable></el-table-column>
          <el-table-column prop="desc" :label="$t('power.jdms')"></el-table-column>
          <el-table-column prop="icon" :label="$t('power.tb')"></el-table-column>
          <el-table-column prop="sort" :label="$t('group.px')"></el-table-column>
          <el-table-column prop="is_menu" :label="$t('power.zcd')">
            <template #default="scope">
              <span>{{scope.row.is_menu===1? $t('power.s'):$t('power.f')}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="v_rule" :label="$t('power.qdjdgz')"></el-table-column>
          <el-table-column prop="c_rule" :label="$t('power.hdjdgz')"></el-table-column>
          <el-table-column :label="$t('home.cz')" width="180">
            <template #default="scope">
              <el-button size="mini" class="el-icon-edit" type="primary" @click="showEditDialog(scope.row.id)" style="margin-right: 10px"></el-button>
              <deleteBtn :deleteId="scope.row.id" @gainMsg="PageChange" :deleteApi="path"></deleteBtn>
            </template>
          </el-table-column>
        </el-table>
      </el-card>
<!--      添加权限弹出框-->
      <el-dialog
        :title="$t('power.tjqx')"
        :visible.sync="dialogVisible"
        width="50%"
        @close="addProductDialogClosed"
      >
        <!--添加用户的表单-->
        <el-form
          label-width="200px"
          :model="addProductForm"
          :rules="addProductFormRules"
          ref="addProductFormRef"
        >
          <el-form-item :label="$t('power.fjcd')" prop="parent_id">
            <el-cascader
              v-model="addProductForm.parent_id"
              :options="menuLists"
              :props="menuProps"
              clearable
              style="width: 100%"
            >
            </el-cascader>
          </el-form-item>
          <el-form-item :label="$t('power.jdmc')" prop="name">
            <el-input v-model="addProductForm.name" :placeholder="$t('power.qsrjdmc')"></el-input>
          </el-form-item>
          <el-form-item :label="$t('power.jdmc') + '(english)'" prop="name_english">
            <el-input v-model="editProductForm.name_english" :placeholder="$t('power.qsrjdmc')"></el-input>
          </el-form-item>
          <el-form-item :label="$t('power.jdms')" prop="desc">
            <el-input v-model="addProductForm.desc" :placeholder="$t('power.qsrjdms')"></el-input>
          </el-form-item>
          <el-form-item :label="$t('power.qdjdgz')" prop="v_rule">
            <el-input v-model="addProductForm.v_rule" :placeholder="$t('power.qsrqdjd')"></el-input>
          </el-form-item>
          <el-form-item :label="$t('power.hdjdgz')" prop="c_rule">
            <el-input v-model="addProductForm.c_rule" :placeholder="$t('power.qsrhdjd')"></el-input>
          </el-form-item>
          <el-form-item :label="$t('power.tb')" prop="icon">
            <div style="float:left; margin-right:5px;border-radius:5px;height: 38px;line-height: 40px;width:50px;background-color: #f5f7fa;border: 1px solid #dcdfe6;">
              <i :class="this.addProductForm.icon" style="padding: 0 20px;height:40px;"></i>
            </div>
            <el-select v-model="addProductForm.icon" :placeholder="$t('power.qsrtb')" style="width: 90.6%">
              <el-option v-for="item in iconList" :key="item.value" :value="item.value">
                <i :class="item.label"><span style="margin-left: 10px">{{item.value}}</span></i>
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('group.px')" prop="sort">
            <el-input v-model="addProductForm.sort" :placeholder="$t('power.qsrpx')"></el-input>
          </el-form-item>
          <el-form-item :label="$t('power.sfcd')">
            <el-radio-group v-model="addProductForm.is_menu">
              <el-radio :label="1">{{$t('power.cd')}}</el-radio>
              <el-radio :label="0">{{$t('power.fcd')}}</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-form>

        <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogVisible = false">{{$t('home.qx')}}</el-button>
          <el-button type="primary" @click="addProduct">{{$t('home.qd')}}</el-button>
        </span>
        </template>
      </el-dialog>
<!--      编辑权限的弹出框-->
      <el-dialog
        :title="$t('power.bjqx')"
        :visible.sync="editdialogVisible"
        width="50%"
        @close="editProductDialogClosed"
      >
        <!--编辑权限的表单-->
        <el-form
          label-width="200px"
          :model="editProductForm"
          :rules="editProductFormRules"
          ref="editProductFormRef"
        >
<!--          <el-form-item label="父级菜单">-->
            <el-input v-model="editProductForm.parent_id" type="hidden"></el-input>
<!--          </el-form-item>-->
          <el-form-item :label="$t('power.jdmc')" prop="name">
            <el-input v-model="editProductForm.name" :placeholder="$t('power.qsrjdmc')"></el-input>
          </el-form-item>
          <el-form-item :label="$t('power.jdmc') + '(english)'" prop="name_english">
            <el-input v-model="editProductForm.name_english" :placeholder="$t('power.qsrjdmc')"></el-input>
          </el-form-item>
          <el-form-item :label="$t('power.jdms')" prop="desc">
            <el-input v-model="editProductForm.desc" :placeholder="$t('power.qsrjdms')"></el-input>
          </el-form-item>
          <el-form-item :label="$t('power.qdjdgz')" prop="v_rule">
            <el-input v-model="editProductForm.v_rule" :placeholder="$t('power.qsrqdjd')"></el-input>
          </el-form-item>
          <el-form-item :label="$t('power.hdjdgz')" prop="c_rule">
            <el-input v-model="editProductForm.c_rule" :placeholder="$t('power.qsrhdjd')"></el-input>
          </el-form-item>
          <el-form-item :label="$t('power.tb')" prop="icon">
            <div style="float:left; margin-right:5px;border-radius:5px;height: 38px;line-height: 40px;width:50px;background-color: #f5f7fa;border: 1px solid #dcdfe6;">
              <i :class="this.editProductForm.icon" style="padding: 0 20px;height:40px;"></i>
            </div>
            <el-select v-model="editProductForm.icon" :placeholder="$t('power.qsrtb')" style="width: 90.6%">
              <el-option v-for="item in iconList" :key="item.value" :value="item.value">
                <i :class="item.label"><span style="margin-left: 10px">{{item.value}}</span></i>
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('group.px')" prop="sort">
            <el-input v-model="editProductForm.sort" :placeholder="$t('power.qsrpx')"></el-input>
          </el-form-item>
          <el-form-item :label="$t('power.sfcd')">
            <el-radio-group v-model="editProductForm.is_menu">
              <el-radio :label="1">{{$t('power.cd')}}</el-radio>
              <el-radio :label="0">{{$t('power.fcd')}}</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-form>

        <template #footer>
        <span class="dialog-footer">
          <el-button @click="editdialogVisible = false">{{$t('home.qx')}}</el-button>
          <el-button type="primary" @click="editProduct">{{$t('home.qd')}}</el-button>
        </span>
        </template>
      </el-dialog>
    </div>
</template>

<script>
import DeleteBtn from '../common/DeleteBtn'
import * as power from '../../interface/systemManage/PowerManage'
import icons from '../../assets/fonts/iconfont.json'
export default {
  name: 'powerManage',
  components: {
    deleteBtn: DeleteBtn
  },
  data: function () {
    return {
      dialogVisible: false,
      menuList: [],
      menuLists: [],
      iconList: [],
      menuProps: {
        label: 'name',
        value: 'id',
        children: 'children',
        expandTrigger: 'hover',
        checkStrictly: true
      },
      addProductForm: {
        parent_id: 0,
        name: '',
        name_english: '',
        desc: '',
        c_rule: '',
        v_rule: '',
        icon: '',
        sort: '',
        is_menu: 1
      },
      addProductFormRules: {
        name: [
          { required: true, message: this.$t('power.qsrqxbt'), trigger: 'blur' },
          { min: 2, max: 10, message: this.$t('power.cdzf'), trigger: 'blur' }
        ],
        desc: [
          { required: true, message: this.$t('power.qsrqxms'), trigger: 'blur' },
          { min: 2, message: this.$t('power.cdzfs'), trigger: 'blur' }
        ],
        icon: [
          { required: true, message: this.$t('power.qxzqxtb'), trigger: 'blur' },
          { min: 1, message: this.$t('power.cdzfo'), trigger: 'blur' }
        ]
      },
      editProductForm: {
        parent_id: '',
        id: '',
        name: '',
        name_english: '',
        desc: '',
        c_rule: '',
        v_rule: '',
        icon: '',
        sort: '',
        is_menu: 1
      },
      editProductFormRules: {
        name: [
          { required: true, message: this.$t('power.qsrqxbt'), trigger: 'blur' },
          { min: 2, max: 10, message: this.$t('power.cdzf'), trigger: 'blur' }
        ],
        desc: [
          { required: true, message: this.$t('power.qsrqxms'), trigger: 'blur' },
          { min: 2, message: this.$t('power.cdzfs'), trigger: 'blur' }
        ],
        icon: [
          { required: true, message: this.$t('power.qxzqxtb'), trigger: 'blur' },
          { min: 1, message: this.$t('power.cdzfo'), trigger: 'blur' }
        ]
      },
      editdialogVisible: false,
      path: 'systemManage/PowerManage',
      isName: 2
    }
  },
  created () {
    this.getPowerList()
    this.getMenuList()
    var ico = JSON.parse(JSON.stringify(icons.glyphs))
    var iconText = []
    for (var i = 0; i < ico.length; i++) {
      var text = {}
      text.value = 'icon iconfont icon-' + ico[i].font_class
      text.label = 'icon iconfont icon-' + ico[i].font_class
      iconText.push(text)
    }
    this.iconList = iconText
  },
  methods: {
    // 获取权限列表
    getPowerList: async function () {
      if (this.$i18n.locale === 'en-US') {
        this.menuProps = {
          label: 'name_english',
          value: 'id',
          children: 'children',
          expandTrigger: 'hover',
          checkStrictly: true
        }
      } else {
        this.menuProps = {
          label: 'name',
          value: 'id',
          children: 'children',
          expandTrigger: 'hover',
          checkStrictly: true
        }
      }
      const data = await power.powerList(this)
      console.log(data)
      this.menuList = data.result
    },
    // 获取菜单列表
    getMenuList: async function () {
      if (this.$i18n.locale === 'en-US') {
        this.isName = 2
      } else {
        this.isName = 1
      }
      const data = await power.getMenuList(this)
      console.log('菜单列表')
      console.log(data)
      this.menuLists = data.result
    },
    // 页面刷新数据
    PageChange: function () {
      this.getPowerList()
    },
    // 关闭添加的弹出框
    addProductDialogClosed: function () {
      this.$refs.addProductFormRef.resetFields()
    },
    // 关闭编辑的弹出框
    editProductDialogClosed: function () {
      this.$refs.editProductFormRef.resetFields()
    },
    // 点击打开弹出框
    showEditDialog: async function (id) {
      const data = await power.editProductDialog(this, id)
      console.log('获取信息失败')
      console.log(data)
      if (data.code !== 0) return this.$message.error(this.$t('edit.hqxxsb'))
      var addProductD = {
        parent_id: data.result.parent_id,
        id: data.result.id,
        name: data.result.name,
        name_english: data.result.name_english,
        desc: data.result.desc,
        c_rule: data.result.c_rule,
        v_rule: data.result.v_rule,
        icon: data.result.icon,
        sort: data.result.sort,
        is_menu: data.result.is_menu
      }
      console.log(addProductD)
      this.editProductForm = addProductD
      this.editdialogVisible = true
    },
    // 点击添加的按钮
    async addProduct () {
      this.$refs.addProductFormRef.validate(async valid => {
        // 验证失败
        if (!valid) return this.$message.error(this.$t('edit.qtxwzxx'))
        // 提交添加用户的请求
        console.log('父级菜单的长度')
        console.log(this.addProductForm.parent_id)
        if (this.addProductForm.parent_id === 0) {
          this.addProductForm.parent_id = 0
        } else {
          this.addProductForm.parent_id = this.addProductForm.parent_id.pop()
        }
        const data = await power.addProduct(this, this.addProductForm)
        if (data.code !== 0) {
          this.$message.error(this.$t('add.tjsb'))
          this.dialogVisible = false
          return
        }
        this.$message.success(this.$t('add.tjcg'))
        // 关闭对话框
        this.dialogVisible = false
        // 重新请求数据
        this.getPowerList()
        this.$router.go(0)
      })
    },
    editProduct: function () {
      this.$refs.editProductFormRef.validate(async valid => {
        // 验证失败
        if (!valid) return this.$message.error(this.$t('power.qtxwzqx'))
        // 提交添加用户的请求
        const data = await power.editProduct(this, this.editProductForm.id, this.editProductForm)
        if (data.code !== 0) {
          this.$message.error(this.$t('edit.xgsb') + ',' + data.msg)
          this.dialogVisible = false
          return
        }
        this.$message.success(this.$t('edit.xgcg'))
        // 关闭对话框
        this.editdialogVisible = false
        // 重新请求数据
        this.getPowerList()
        this.$router.go(0)
      })
    }
  }
}
</script>

<style scoped>

</style>
