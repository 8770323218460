<template>
  <div>
    <el-row style="margin: 0 10px">
      <el-col :span="9" style="font-size: 18px">{{$t('lygl.lygl')}}</el-col>
      <el-col :span="9" style="float: right;">
        <!--面包屑导航-->
        <el-breadcrumb separator="/">
          <el-breadcrumb-item>{{$t('lygl.lygl')}}</el-breadcrumb-item>
        </el-breadcrumb>
      </el-col>
    </el-row>
    <el-card>
      <el-button :type="title == $t('lygl.yljl') ? 'success' : 'primary'" style="margin-bottom: 20px" @click="changeBtn">{{title}}</el-button>
            <el-form :inline="true" :model="form" class="demo-form-inline">
              <el-form-item :label="$t('devDetail.cjsj')">
                <el-date-picker
                  v-model="form.create_time"
                  type="datetimerange"
                  :range-separator="$t('lygl.z')"
                  :start-placeholder="$t('lygl.ksrq')"
                  :end-placeholder="$t('lygl.jsrq')"
                  value-format="yyyy-MM-dd HH:mm:ss">
                </el-date-picker>
              </el-form-item>
              <el-form-item>
                <el-input v-model="form.equipment_no" :placeholder="$t('home.sbbh')"></el-input>
              </el-form-item>
              <el-form-item>
                <el-input v-model="form.bluetooth_name" :placeholder="$t('lygl.lymc')"></el-input>
              </el-form-item>
              <el-form-item>
                <el-input v-model="form.bluetooth_address" :placeholder="$t('lygl.lydz')"></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="getList">{{$t('home.xc')}}</el-button>
              </el-form-item>
            </el-form>
      <!--      <el-button type="primary" @click="addGroup">新增分组</el-button>-->
      <el-table border :data="productList" height="670px">
<!--        <el-table-column label="id" prop="id"></el-table-column>-->
        <el-table-column :label="$t('home.sbbh')" prop="equipment_no"></el-table-column>
        <el-table-column :label="$t('lygl.lymc')" prop="bluetooth_name"></el-table-column>
        <el-table-column :label="$t('lygl.lydz')" prop="bluetooth_address"></el-table-column>
        <el-table-column :label="$t('devDetail.cjsj')" prop="create_time"></el-table-column>
<!--        <el-table-column label="更新时间" prop="update_time"></el-table-column>-->
      </el-table>
      <pagination :type="type" :queryInfos="queryInfo" @pageChange="PageChange" queryUrl="Img/Bluetooth" :pamams="'equipment_no=' + (form.equipment_no || '') + '&bluetooth_name=' + (form.bluetooth_name || '') + '&bluetooth_address=' + (form.bluetooth_address || '') + '&create_time=' + (form.update_time || '')"></pagination>
    </el-card>
  </div>
</template>
<script>
import * as getBluetooth from '../../../interface/Img/Bluetooth'
import pagination from '../../../components/common/Pagination'
export default {
  name: 'Bluetooth',
  components: { pagination },
  data: function () {
    return {
      type: 1,
      title: this.$t('lygl.yljl'),
      productList: [],
      queryInfo: {
        query: '',
        pageNo: 1,
        pageSize: 2,
        total: 0
      },
      form: {}
    }
  },
  created () {
    this.getList(0)
  },
  methods: {
    PageChange: function (e) {
      this.productList = e.productList
      this.queryInfo = e.queryInfo
    },
    getList: async function () {
      var p = '?equipment_no=' + (this.form.equipment_no || '') + '&bluetooth_name=' + (this.form.bluetooth_name || '') + '&bluetooth_address=' + (this.form.bluetooth_address || '') + '&update_time=' + (this.form.update_time || '')
      const data = await getBluetooth.BluetoothList(this, p, this.type)
      if (data.code !== 0) {
        this.title = this.title === this.$t('lygl.yljl') ? this.$t('lygl.qbjl') : this.$t('lygl.yljl')
        this.type = this.type === 0 ? 1 : 0
        return this.$message.error(data.msg)
      }
      this.productList = data.result.data
      this.queryInfo.total = data.result.totalCount
      this.queryInfo.pageNo = data.result.pageNo
      this.queryInfo.pageSize = data.result.pageSize
    },
    changeBtn: function () {
      this.title = this.title === this.$t('lygl.yljl') ? this.$t('lygl.qbjl') : this.$t('lygl.yljl')
      this.type = this.type === 0 ? 1 : 0
      this.getList()
    }
  }
}
</script>
<style scoped></style>
