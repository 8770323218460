<template>
    <div>
      <el-row style="margin: 0 10px">
        <el-col :span="2" style="font-size: 18px">模块列表</el-col>
        <el-col :span="5" style="float: right;">
          <!--面包屑导航-->
          <el-breadcrumb separator="/">
            <el-breadcrumb-item>生产管理</el-breadcrumb-item>
            <el-breadcrumb-item><a>统计列表</a></el-breadcrumb-item>
            <el-breadcrumb-item><a>模块列表</a></el-breadcrumb-item>
          </el-breadcrumb>
        </el-col>
      </el-row>
      <el-card>
<!--        <el-row style="text-align: center;font-size: 20px;margin-bottom: 20px">-->
<!--          <p>通讯模块列表</p>-->
<!--        </el-row>-->
        <el-form ref="form" :inline="true" :model="form" class="demo-form-inline">
          <el-form-item label="是否装机">
            <el-select v-model="form.region" placeholder="请选择是否装机">
              <el-option label="全部" value=""></el-option>
              <el-option label="是" value="1"></el-option>
              <el-option label="否" value="0"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-input v-model="queryInfo.query" placeholder="模块ID/手机号" clearable @clear="getProductList"></el-input>
<!--            <template #append>-->
<!--              <el-button icon="el-icon-search" @click="getProductList">搜索</el-button>-->
<!--            </template>-->
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="getProductList">搜索</el-button>
            <el-button type="success" @click="returnMsg">返回</el-button>
          </el-form-item>
        </el-form>
        <el-table
          border
          :data="finishListData"
          style="width: 100%">
          <el-table-column type="index" label="序号" width="80"></el-table-column>
          <el-table-column prop="imei" label="4G模块ID"></el-table-column>
          <el-table-column prop="ccid" label="手机号"></el-table-column>
          <el-table-column prop="create_time" label="绑定时间"></el-table-column>
          <el-table-column prop="admin_name" label="操作员"></el-table-column>
          <el-table-column prop="is_bind" label="是否装机">
            <template #default="scope">
              {{scope.row.is_bind===1?'是':'否'}}
            </template>
          </el-table-column>
        </el-table>
        <page :queryInfos="queryInfo" queryUrl="processManage/EquipmentBind" @pageChange="PageChange"></page>
      </el-card>
    </div>
</template>

<script>
import * as countList from '../../../interface/processManage/CountList'
import Pagination from '../../common/Pagination'

export default {
  name: 'ModuleList',
  components: {
    page: Pagination
  },
  data: function () {
    return {
      finishListData: [],
      form: {},
      list: [],
      path: '/CountList/ModuleList',
      queryInfo: {
        query: '',
        pageNo: 1,
        pageSize: 2,
        total: 0
      }
    }
  },
  created () {
    this.getProductList()
  },
  methods: {
    getProductList: async function () {
      var p = '?keyword=' + (this.queryInfo.query || '') + '&is_bind=' + (this.form.region || '')
      const data = await countList.modularList(this, p)
      console.log(data)
      this.finishListData = data.result.data
      // this.list = data.result.data
      this.queryInfo.total = data.result.totalCount
      this.queryInfo.pageNo = data.result.pageNo
      this.queryInfo.pageSize = data.result.pageSize
    },
    returnMsg: function () {
      this.$router.go(-1)
    },
    PageChange (e) {
      console.log('刷新页面')
      this.finishListData = e.productList
      this.queryInfo = e.productList
    },
    selectData: async function (e) {
      console.log(e)
      const lists = await countList.modularLists(this, e)
      console.log(lists)
      this.finishListData = lists.result.data
      this.queryInfo.total = lists.result.totalCount
      this.queryInfo.pageNo = lists.result.pageNo
      this.queryInfo.pageSize = lists.result.pageSize
    },
    getProductLists: function () {
      this.finishListData = []
      for (var i = 0; i < this.list.length; i++) {
        // eslint-disable-next-line eqeqeq
        if (this.list[i].ccid == this.queryInfo.query || this.list[i].imei == this.queryInfo.query) {
          this.finishListData.push(this.list[i])
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
