// 地址
const api = {
  fileGroupList: 'admin/fileGroup/list',
  addFileGroup: 'admin/fileGroup/add',
  editProductDialog: 'admin/fileGroup/info?id=',
  editProduct: 'admin/fileGroup/edit',
  delProduct: 'admin/fileGroup/del?id='
}
// 分组
export async function fileGroupList (ele) {
  const { data } = await ele.$http.get(api.fileGroupList)
  return data
}
// 新增
export async function addProduct (ele, params) {
  const { data } = await ele.$http.post(api.addFileGroup, params)
  return data
}
// 查询
export async function editProductDialog (ele, params) {
  const { data } = await ele.$http.get(api.editProductDialog + params)
  return data
}
// 编辑
export async function editProduct (ele, params) {
  const { data } = await ele.$http.put(api.editProduct, params)
  return data
}
// 删除
export async function delProduct (ele, params) {
  const { data } = await ele.$http.delete(api.delProduct + params)
  return data
}
export async function pageChanges (ele, param) {
  var p = []
  for (var i in param) {
    p.push(i + '=' + param[i])
  }
  var { data } = await ele.$http.get(api.fileGroupList + '?' + p[0] + '&' + p[1])
  return data
}
