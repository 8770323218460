<template>
  <div>
<!--    <el-form-item label="所属代理" prop="agent_id">-->
    <el-form-item label="所属代理">
      <el-input v-model="agent_id" @click="selectPop" disabled></el-input>
      <el-button type="primary" @click="selectPop" style="margin-left: 10px">选择</el-button>
    </el-form-item>
    <div class="getPop" v-if="getAgentPop">
      <div class="popModel">
        <el-tree :data="data" :props="defaultProps" @node-click="handleNodeClick" :default-expand-all="true"></el-tree>
        <div style="display: flex;justify-content: center">
          <el-button @click="sure" type="primary" style="margin: 20px 10px">确认</el-button>
          <el-button @click="clean" style="margin: 20px 10px">取消</el-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import * as agentList from '../../interface/agentManage/mercantileAgent'
export default {
  props: ['agent_id'],
  data: function () {
    return {
      getAgentPop: false,
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      data: [],
      dataId: '',
      dataName: ''
    }
  },
  methods: {
    // 选择上一级
    selectPop: async function () {
      var that = this
      that.getAgentPop = true
      const res = await agentList.getList(this)
      if (res.code !== 0) return that.$message.error('获取信息失败')
      console.log(res)
      that.data = res.result
      if (res.result) {
        res.result.forEach(function (item, index, array) {
          that.data[index].label = item.name
          if (item.children) {
            item.children.forEach(function (item1, index1, array1) {
              that.data[index].children[index1].label = item1.name
              if (item1.children) {
                item1.children.forEach(function (item2, index2, array2) {
                  that.data[index].children[index1].children[index2].label = item2.name
                })
              }
            })
          }
        })
      }
      console.log(that.data)
    },
    // 选择上一级id
    handleNodeClick: function (e) {
      console.log(e)
      this.dataId = e.id
      this.dataName = e.label
      this.addAgentPop = true
    },
    // 确认选择
    sure: function () {
      this.$emit('getId', { dataName: this.dataName, dataId: this.dataId })
      // this.addForm.parent_id = this.dataName
      this.getAgentPop = false
    },
    // 取消
    clean: function () {
      this.getAgentPop = false
    }
  }
}
</script>
<style scoped>
  .el-input {
    width: 70% !important;
    max-width: 620px;
  }
  .addPop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.2);
    z-index: 990;
  }
  .popModel{
    width: 40%;
    position: relative;
    margin: 20% auto 50px;
    background: #FFF;
    border-radius: 2px;
    box-sizing: border-box;
  }
  .getPop{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.2);
    z-index: 999;
  }
  .el-tree {
    padding: 10px 0;
  }
</style>
