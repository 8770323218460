const api = {
  ActionLogList: 'admin/ActionLog/list'
}
// 日志
export async function ActionLogList (ele, param) {
  const { data } = await ele.$http.put(api.ActionLogList + param)
  return data
}
export async function pageChange (ele, param) {
  for (var i in param) {
    var { data } = await ele.$http.get(api.ActionLogList + '?' + i + '=' + param[i])
  }
  return data
}
export async function pageChanges (ele, param, pamams) {
  var p = []
  for (var i in param) {
    p.push(i + '=' + param[i])
  }
  var { data } = await ele.$http.get(api.ActionLogList + '?' + p[0] + '&' + p[1] + '&' + pamams)
  return data
}
