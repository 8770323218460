// api 地址
const api = {
  batteryList: 'admin/EquipmentBattery/list',
  getBatteryList: 'admin/EquipmentBattery/getEquipmentBatteryList?equipment_no=',
  batteryEject: 'admin/EquipmentBattery/batteryEject',
  batteryBorrow: 'admin/EquipmentBattery/batteryBorrow'
}
// 获取电池列表
export async function batteryList (ele, params) {
  var { data } = await ele.$http.get(api.batteryList + params)
  return data
}
// 获取详情
export async function getBatteryList (ele, params) {
  var { data } = await ele.$http.get(api.getBatteryList + params)
  return data
}
// 强弹
export async function batteryEject (ele, params) {
  var { data } = await ele.$http.get(api.batteryEject + params)
  return data
}
// 模拟借用
export async function batteryBorrow (ele, params) {
  var { data } = await ele.$http.get(api.batteryBorrow + params)
  return data
}
export async function pageChanges (ele, param, pamams) {
  var p = []
  for (var i in param) {
    p.push(i + '=' + param[i])
  }
  var { data } = await ele.$http.get(api.batteryBorrow + '?' + p[0] + '&' + p[1] + '&' + pamams)
  return data
}
