module.exports = {
  lan: '中文',
  common: {
    type: '类型',
    sel: '请选择'
  },
  login: {
    htglxt: '后台管理系统',
    dl: '登录',
    cz: '重置',
    qsryhm: '请输入用户名',
    zfcd: '长度在 6 到 15 个字符'
  },
  home: {
    xmgl: '项目管理',
    qh: '切换',
    sblb: '设备列表',
    sbgl: '设备管理',
    fzgl: '分组管理',
    sbbh: '设备编号',
    xc: '查询',
    sb: '设备',
    xh: '序号',
    sfzx: '是否在线',
    zx: '在线',
    lx: '离线',
    khmc: '客户名称',
    zhdlsj: '最后登陆时间',
    bz: '备注',
    cz: '操作',
    xq: '详情',
    sc: '删除',
    qbfz: '全部分组',
    sfqrsc: '是否确认删除？',
    ts: '提示',
    qd: '确定',
    qx: '取消',
    sccg: '删除成功',
    yqxsc: '已取消删除',
    bj: '编辑',
    grzx: '个人中心',
    tc: '退出',
    gl: '管理',
    sbid: '设备id'
  },
  edit: {
    xg: '修改',
    hqxxsb: '获取信息失败',
    qtxwzxx: '请填写完整信息',
    xgsb: '修改失败,',
    xgcg: '修改成功'
  },
  add: {
    tj: '添加',
    tjsb: '添加失败',
    tjcg: '添加成功',
    qxz: '请选择'
  },
  devDetail: {
    sbxq: '设备详情',
    szyl: '设置音量',
    cq: '重启',
    sjgj: '升级固件',
    sjbb: '升级版本',
    qhfwq: '切换服务器',
    fh: '返回',
    dqbfwj: '当前播放文件',
    sbsywj: '设备所有文件',
    wjmc: '文件名称',
    wj: '文件',
    zt: '状态',
    dfb: '待发布',
    yfb: '已发布',
    cjsj: '创建时间',
    fb: '发布',
    yl: '音量',
    gjwj: '固件文件',
    xz: '选择',
    ym: '域名',
    ipdz: 'IP地址',
    dk: '端口',
    qsryl: '请输入音量',
    qsrld: '请输入亮度',
    qsrsj: '请输入时间',
    qsrwjmc: '请输入文件名称',
    qsrym: '请输入域名/IP地址',
    qsrdk: '请输入端口',
    qhfwqdzsb: '切换服务器地址失败',
    qhcg: '切换成功',
    sfqryjqh: '是否确认永久切换？',
    yjqhcg: '永久切换成功',
    yqxqh: '已取消切换',
    sjbbsb: '升级版本失败',
    sjcg: '升级成功',
    sjgjsb: '升级固件失败',
    sfqrfb: '是否确认发布？',
    fbcg: '发布成功',
    yqxfb: '已取消发布',
    sccg: '上传成功',
    fz: '分组',
    zhxtsj: '最后心跳时间',
    cqsj: '重启时间',
    gxsj: '更新时间',
    szcg: '设置成功',
    sfqrcq: '是否确认重启？',
    cqcg: '重启成功',
    yqxcq: '已取消重启'
  },
  img: {
    scsp: '上传视频',
    sctp: '上传图片',
    xzwj: '选择文件',
    qr: '确认',
    djtp: '（点击图片/视频选择）',
    qx: '全选',
    znscwj: '(只能上传mp4文件，且不超过100MB)',
    znsctwj: '(只能上传jpg/jpge文件，且不超过100MB)',
    wjscgs: '上传文件只能是mp4格式',
    wjsctgs: '上传文件只能是jpg/jpge格式',
    scwjdx: '上传文件大小不能超过100MB',
    scsb: '上传失败',
    dqxzsl: '当前选择数量:',
    qxzsl: ',请选择数量为1'
  },
  group: {
    mc: '名称',
    ms: '描述',
    px: '排序',
    xgfz: '修改分组',
    qsrmc: '请输入名称',
    qsrms: '请输入描述',
    qsrpx: '请输入排序',
    bjcg: '编辑成功'
  },
  video: {
    spgl: '视频管理',
    sp: '视频',
    dx: '大小',
    scsj: '上传时间'
  },
  imgM: {
    tpgl: '图片管理',
    tp: '图片'
  },
  lygl: {
    lygl: '蓝牙管理',
    yljl: '蓝牙记录',
    z: '至',
    ksrq: '开始日期',
    jsrq: '结束日期',
    lymc: '蓝牙名称',
    lydz: '蓝牙地址',
    qbjl: '全部记录'
  },
  sh: {
    sh: '审核',
    lx: '类型',
    qb: '全部',
    wjdx: '文件大小',
    zs: '展示',
    dsh: '待审核',
    shtg: '审核通过',
    shbtg: '审核不通过',
    sfqrsh: '是否确认审核？',
    shwc: '审核完成',
    yqxsh: '已取消审核'
  },
  khgl: {
    khgl: '客户管理',
    kh: '客户'
  },
  role: {
    jsgl: '角色管理',
    xtgl: '系统管理',
    jsmc: '角色名称',
    jsms: '角色描述',
    fpqx: '分配权限',
    qsrjsmc: '请输入角色名称',
    cdzf: '长度在 3 到 10 个字符',
    qsrjsms: '请输入角色描述',
    cdzfm: '长度至少3个字符',
    js: '角色',
    hqjssb: '获取当前角色的权限信息失败',
    hqqxsb: '获取权限树失败',
    fpqxsb: '分配权限失败',
    fpqxcg: '分配权限成功',
    scsb: '删除失败',
    hqjssbs: '获取角色失败'
  },
  power: {
    qxgl: '权限管理',
    tjqx: '添加权限',
    jdmc: '节点名称',
    jdms: '节点描述',
    tb: '图标',
    zcd: '主菜单',
    s: '是',
    f: '否',
    qdjdgz: '前端节点规则',
    hdjdgz: '后端节点规则',
    fjcd: '父级菜单',
    qsrjdmc: '请输入节点名称',
    qsrjdms: '请输入节点描述',
    qsrqdjd: '请输入前端节点规则',
    qsrhdjd: '请输入后端节点规则',
    qsrtb: '请输入图标',
    qsrpx: '请输入排序号',
    sfcd: '是否是菜单',
    cd: '菜单',
    fcd: '非菜单',
    bjqx: '编辑权限',
    qsrqxbt: '请输入权限标题',
    cdzf: '长度在 2 到 10 个字符',
    qsrqxms: '请输入权限描述',
    cdzfs: '长度为至少2个字符',
    qxzqxtb: '请选择权限图标',
    cdzfo: '长度为至少1个字符',
    qtxwzqx: '请填写完整的权限信息'
  },
  admin: {
    glylb: '管理员列表',
    sjh: '手机号',
    yhdlmc: '用户登录名称',
    gskh: '归属客户',
    yx: '邮箱',
    qsrhfyx: '请输入合法的邮箱',
    qsrhfsjhm: '请输入合法的手机号码',
    gly: '管理员',
    dlmc: '登录名称',
    mm: '密码',
    qsrdlmc: '请输入登录名称',
    qsrmm: '请输入密码',
    qsryx: '请输入邮箱',
    qsrsjh: '请输入手机号码',
    zc: '正常',
    jy: '禁用'
  },
  cp: {
    cplb: '产品列表',
    cpbt: '产品标题',
    cp: '产品',
    cpms: '产品描述',
    scrq: '生产日期',
    jg: '价格'
  },
  system: {
    xtrz: '系统日志',
    qsrip: '请输入ip',
    qqdz: '请求地址',
    qqlx: '请求类型',
    qqsj: '请求数据',
    yhm: '用户名',
    qqsjs: '请求时间'
  }
}
