<template>
  <div>
    <!--面包屑-->
    <el-row style="margin: 0 10px">
      <el-col :span="2" style="font-size: 18px">设备列表</el-col>
      <el-col :span="5" style="float: right;">
        <!--面包屑导航-->
        <el-breadcrumb separator="/">
          <el-breadcrumb-item>生产管理</el-breadcrumb-item>
          <el-breadcrumb-item><a>设备列表</a></el-breadcrumb-item>
        </el-breadcrumb>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="5" style="padding-right: 20px">
        <el-card style="padding: 0 !important;">
          <div slot="header" class="clearfix">
            <span>产品名称</span>
            <el-button style="float: right; padding: 3px 0" type="text" @click="collapseBtn"><i class="el-icon-s-operation" style="font-size: 18px"></i></el-button>
          </div>
          <div v-for="(item,index) in productList" :key="index" @click="chooseClick(index)" :class="{active:index==isActive}" :style="productListcss" class="productList">
            <i class="el-icon-s-grid" style="padding-right: 10px"></i>{{item.title}}
          </div>
        </el-card>
      </el-col>
      <el-col :span="19" style="float: right">
        <el-card style="padding: 20px">
          <div v-for="(item,index) in productContent" :key="index"><i class="el-icon-s-grid" style="padding-right: 10px"></i>{{item.title}}</div>
<!--          <el-input placeholder="机身编号" v-model="getTitle" clearable style="width: 30%;margin-top: 20px">-->
<!--            <template #append>-->
<!--              <el-button icon="el-icon-search" @click="getData"></el-button>-->
<!--            </template>-->
<!--          </el-input>-->
          <el-form :inline="true" :model="preEntryForm" class="demo-form-inline" style="margin-top: 20px">
            <el-form-item label="机身编号">
              <el-input v-model="preEntryForm.getTitle" clearable placeholder="机身编号"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="getData">查询</el-button>
              <el-button type="primary" @click="goSB">设备下拨</el-button>
            </el-form-item>
          </el-form>
          <el-table
            ref="filterTable"
            :data="preEntryData"
            height="500px"
            border
            style="width: 100%">
            <el-table-column type="index" label="序号" width="50px"></el-table-column>
            <el-table-column prop="equipment_no" label="设备编号"  width="180px"></el-table-column>
            <el-table-column prop="imei" label="imei" width="180px"></el-table-column>
<!--            <el-table-column prop="ccid" label="手机号"></el-table-column>-->
            <el-table-column prop="equipment_name" label="产品"></el-table-column>
<!--            <el-table-column prop="username" label="绑定会员"></el-table-column>-->
<!--            <el-table-column prop="agent_name" label="所属经销商"></el-table-column>-->
            <el-table-column prop="merchant_name" label="商户"></el-table-column>
            <el-table-column prop="agent_name" label="经销商"></el-table-column>
            <el-table-column prop="shop_name" label="门店"></el-table-column>
            <el-table-column label="可借/可还" width="150px" v-if="lastId == 2">
              <template #default="scope">
                <el-tag type="success" v-if="scope.row.usable_battery" style="margin-right: 10px">{{scope.row.usable_battery}}</el-tag>
                <el-tag type="danger" v-if="scope.row.empty_slot_count">{{scope.row.empty_slot_count}}</el-tag>
              </template>
            </el-table-column>
            <el-table-column prop="is_online" label="是否在线" v-if="lastId == 2">
              <template #default="scope">
                <el-tag v-if="scope.row.is_online" :type="scope.row.is_online == 0 ? 'danger' : 'success'">{{scope.row.is_online == 0 ? '离线' : '在线'}}</el-tag>
              </template>
            </el-table-column>
<!--            <el-table-column prop="agent_name2" label="二级代理"></el-table-column>-->
<!--            <el-table-column prop="agent_name3" label="三级代理"></el-table-column>-->
            <el-table-column label="操作" width="255px">
              <template #default="scope">
                <el-button v-if="lastId == 2" type="success" size="mini" @click="infoData(scope.row.equipment_no)">电池信息</el-button>
                <el-button type="primary" size="mini" @click="code(scope.row.equipment_qrcode_url)">查看二维码</el-button>
                <!--                <el-button type="primary" size="mini" @click="detailPop(scope.row.equipment_no)">设备操作</el-button>-->
              </template>
            </el-table-column>
          </el-table>
        </el-card>
      </el-col>
    </el-row>
<!--    二维码-->
    <el-dialog
      title="二维码"
      :visible.sync="isCodePop"
      width="40%"
      @close="isCodePopClosed"
    >
      <div class="qrCode">
        <img id="image" :src="qrcode">
        <a target="_blank" :href="'https://cli.im/api/qrcode/code?text=' + url"><p style="color:red;">{{url}}</p></a>
      </div>
    </el-dialog>
    <!--      新增的对话框-->
    <div class="addPop" v-if="isPop">
      <el-dialog
        title="设备操作"
        :visible.sync="isPop"
        width="50%"
        @close="addClosed"
      >
        <el-form label-width="115px" :model="detailForm" :rules="addFormRules" ref="addFormRef">
          <el-form-item label="激活时间" prop="time">
            <el-input v-model="detailForm.time"></el-input>
          </el-form-item>
          <el-form-item label="使用状态" prop="type">
            <el-radio-group v-model="detailForm.type">
              <el-radio :label="0">在线</el-radio>
              <el-radio :label="1">离线</el-radio>
              <el-radio :label="2">故障</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item>
            <el-button type="primary">激活设备</el-button>
            <el-button type="primary">特殊模式</el-button>
            <el-button type="primary">开机</el-button>
            <el-button type="primary">关机</el-button>
          </el-form-item>
        </el-form>
      </el-dialog>
    </div>
    <div class="detailPop" v-if="isDetailPop">
      <el-dialog
        :title="preEntryDatas[0].equipment_no + ' 电池信息'"
        :visible.sync="isDetailPop"
        width="60%"
        @close="detailClosed"
      >
        <el-button type="primary" @click="used(preEntryDatas[0].equipment_no)">模拟借用</el-button>
        <el-table border :data="preEntryDatas">
<!--          <el-table-column label="机柜编号" prop="equipment_no"></el-table-column>-->
          <el-table-column label="电池ID" prop="battery_id"></el-table-column>
          <el-table-column label="电量" prop="power"></el-table-column>
          <el-table-column label="槽位" prop="slot"></el-table-column>
          <el-table-column label="是否故障" prop="is_damage">
            <template #default="scope">
              <span>{{scope.row.is_damage == 0 ? '是' : '否'}}</span>
            </template>
          </el-table-column>
          <el-table-column label="是否被借出" prop="key">
            <template #default="scope">
<!--              <span>{{scope.row.key == 0 ? '是' : '否'}}</span>-->
              <el-tag :type="scope.row.key == 0 ? 'danger' : 'success'" >{{scope.row.key == 0 ? '借出' : '在机柜'}}</el-tag>
            </template>
          </el-table-column>
          <el-table-column label="更新时间" prop="update_time" width="180px"></el-table-column>
          <el-table-column label="操作" width="180px">
            <template #default="scope">
              <el-button size="mini" type="danger" @click="eject(scope.row.equipment_no,scope.row.slot)">强弹</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import QrCode from '../../assets/qrcode.min'
import * as device from '../../interface/deviceManage/DeviceList'
import * as getBattery from '../../interface/deviceManage/BatteryList'
export default {
  name: 'DeviceList',
  data () {
    return {
      productList: [],
      productContent: [],
      isActive: 0,
      preEntryForm: {
        imei: '',
        mobile: ''
      },
      preEntryData: [],
      productListcss: 'display:block',
      getTitle: '',
      isPop: false,
      detailForm: {},
      equipment_no: '',
      addFormRules: {},
      qrcode: '',
      isCodePop: false,
      url: '',
      ProductFormList: [],
      isDetailPop: false,
      preEntryDatas: [],
      lastId: -1
    }
  },
  created () {
    this.getProductLists()
  },
  methods: {
    // 模拟借用
    used: async function (id) {
      const data = await getBattery.batteryBorrow(this, '?equipment_no=' + id)
      if (data.code !== 0) return this.$message.error(data.msg)
      this.$message.success('借用成功')
    },
    isCodePopClosed: function () {

    },
    // 生产二维码
    code: function (url) {
      this.url = url
      this.isCodePop = true
      var imgData = QrCode.drawImg(url, {
        typeNumber: 4,
        errorCorrectLevel: 'L',
        size: 200
      })
      this.qrcode = imgData
    },

    // 重置
    addClosed: function () {
      // this.$refs.addFormRules.resetFields()
    },
    // 详情弹窗
    detailPop: function (e) {
      this.isPop = true
      this.equipment_no = e
      console.log(this.equipment_no)
    },
    // 获取产品列表
    getProductLists: async function () {
      const data = await device.equipmentTypeList(this)
      if (data.code !== 0) return this.$message.error('获取设备类型列表失败')
      console.log(data)
      this.productList = data.result
      this.productContent.push(this.productList[0])
      this.lastId = this.productList[0].id
      console.log(this.lastId)
      this.getDeviceList()
    },
    // 点击的时候切换内容
    chooseClick (index) {
      this.productContent = []
      this.isActive = index
      this.lastId = this.productList[index].id
      this.productContent.push(this.productList[index])
      this.getDeviceList()
    },
    // 点击按钮实现产品列表的显示和隐藏
    collapseBtn: function () {
      if (this.productListcss === 'display:block') {
        this.productListcss = 'display:none'
        return
      }
      this.productListcss = 'display:block'
    },
    getDeviceList: async function (e) {
      console.log(this.productContent)
      var id = this.productContent[0].id + (e || '')
      const data = await device.EquipmentList(this, id)
      console.log('根据设备类型查询设备')
      console.log(data)
      this.preEntryData = data.result.data
    },
    getData: function () {
      var p = '&equipment_no=' + (this.preEntryForm.getTitle || '')
      this.getDeviceList(p)
    },
    // 设备下拨
    goSB: function () {
      this.$router.push('EquipmentAllocation')
    },
    // 获取详情
    infoData: async function (id) {
      this.isDetailPop = true
      const data = await getBattery.getBatteryList(this, id)
      if (data.code !== 0) return this.$message.error('获取详情失败')
      this.preEntryDatas = data.result
    },
    // 强弹
    eject: async function (id, slot) {
      const data = await getBattery.batteryEject(this, '?equipment_no=' + id + '&slot=' + slot)
      if (data.code !== 0) return this.$message.error(data.msg)
      this.$message.success('强弹成功')
    }
  }
}
</script>

<style scoped>
  .clearfix:before,
  .clearfix:after {
    display: table;
    content: "";
  }
  .clearfix:after {
    clear: both
  }
  .productList{
    padding: -20px !important;
    font-size: 14px;
    width: 100%;
    height: 40px;
    line-height: 40px;
    box-sizing: border-box;
    padding-left: 20px;
    text-align: left;
    display: block;
  }
  .active{
    background-color: #409EFF;
    color: white;
  }
  .el-card{
    border: none;
  }
  .qrCode{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  .qrCode img{
    width: 200px !important;
    height: 200px !important;
  }
</style>
