<template>
    <div>
      <el-row style="margin: 0 10px">
        <el-col :span="2" style="font-size: 18px">维修项目设置</el-col>
        <el-col :span="5" style="float: right;">
          <!--面包屑导航-->
          <el-breadcrumb separator="/">
            <el-breadcrumb-item>售后管理</el-breadcrumb-item>
            <el-breadcrumb-item><a>维修项目设置</a></el-breadcrumb-item>
          </el-breadcrumb>
        </el-col>
      </el-row>
      <el-row style="margin-top: 20px;">
        <el-button type="primary" @click="CompanySet">物流公司设置</el-button>
        <el-button type="warning" @click="maintenceSet">维修点设置</el-button>
      </el-row>
      <el-row>
        <el-col :span="6" style="margin-right: 20px">
          <el-card>
            <div slot="header" class="clearfix">
              <span>项目维修</span>
              <el-button style="float: right; padding: 3px 0" type="text" @click="collapseBtn"><i class="el-icon-s-operation" style="font-size: 18px"></i></el-button>
            </div>
            <div v-for="(item,index) in productList" :key="index" @click="chooseClick(index)" :class="{active:index==isActive}" :style="productListcss" class="productList">
              <i class="el-icon-s-grid" style="padding-right: 10px"></i>{{item.title}}
            </div>
          </el-card>
        </el-col>
        <el-col :span="17">
          <el-card>
            <el-tabs v-model="activeName" @tab-click="handleClick">
<!--              维修项目-->
              <el-tab-pane label="维修项目" name="first">
                <el-row style="font-size: 18px;text-align: center;margin: 20px 0">
                  <el-col>维修项目：<span v-for="(item,index) in productContent" :key="index">{{item.title}}</span></el-col>
                </el-row>
<!--                外观-->
                <div class="proDiv">
                  <el-row>
                    <el-col :span="17" style="line-height: 40px;font-size: 20px;font-weight: 600">外观选项</el-col>
                    <el-col :span="6">
                      <addBtn
                        @gainMsgs="PageChange1"
                        :addForm="addProductForm1"
                        :addRules="addProductRules1"
                        :addApi="path1"
                        :addProductFormLists="addProductFormList1"
                        :addName="addProductName1"
                        style="float: left"
                      ></addBtn>
                    </el-col>
                  </el-row>
                  <el-table :data="appearanceList">
                    <el-table-column label="序号" type="index" width="80px"></el-table-column>
                    <el-table-column label="外观" prop="title"></el-table-column>
                    <el-table-column label="操作">
                      <template #default="scope">
                        <!--              编辑的按钮-->
                        <editBtn
                          :editId="scope.row.id"
                          :editForm="editProductForm1"
                          :addProductFormList="addProductFormList1"
                          :editRules="editProductFormRules1"
                          @gainMsg="PageChange1"
                          :editApi="path1"
                          :editProductFormLists="editProductFormList1"
                          :editName="addProductName1"
                        ></editBtn>
                        <!--              删除的按钮-->
                        <deleteBtn :deleteId="scope.row.id" @gainMsg="PageChange1" :deleteApi="path1"></deleteBtn>
                      </template>
                    </el-table-column>
                  </el-table>
<!--                  <page :queryInfos="queryInfo1" @pageChange="PageChanges1" queryUrl="AfterSalesManage/MaintenanceSetting" :pamams="'id=' + addForm.equipment_type_id" type="1"></page>-->
                </div>
<!--                检修结论-->
                <div class="proDiv">
                  <el-row>
                    <el-col :span="17" style="line-height: 40px;font-size: 20px;font-weight: 600">检修结论</el-col>
                    <el-col :span="6">
                      <addBtn
                        @gainMsgs="PageChange2"
                        :addForm="addProductForm2"
                        :addRules="addProductRules2"
                        :addApi="path2"
                        :addProductFormLists="addProductFormList2"
                        :addName="addProductName2"
                        style="float: left"
                      ></addBtn>
                    </el-col>
                  </el-row>
                  <el-table :data="conclusionList">
                    <el-table-column label="序号" type="index" width="80px"></el-table-column>
                    <el-table-column label="检修结论" prop="title"></el-table-column>
                    <el-table-column label="操作">
                      <template #default="scope">
                        <!--              编辑的按钮-->
                        <editBtn
                          :editId="scope.row.id"
                          :editForm="editProductForm2"
                          :addProductFormList="addProductFormList2"
                          :editRules="editProductFormRules2"
                          @gainMsg="PageChange2"
                          :editApi="path2"
                          :editProductFormLists="editProductFormList2"
                          :editName="addProductName2"
                        ></editBtn>
                        <!--              删除的按钮-->
                        <deleteBtn :deleteId="scope.row.id" @gainMsg="PageChange2" :deleteApi="path2"></deleteBtn>
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
<!--                故障描述-->
                <div class="proDiv">
                  <el-row>
                    <el-col :span="17" style="line-height: 40px;font-size: 20px;font-weight: 600">故障描述</el-col>
                    <el-col :span="6">
                      <addBtn
                        @gainMsgs="PageChange3"
                        :addForm="addProductForm3"
                        :addRules="addProductRules3"
                        :addApi="path3"
                        :addProductFormLists="addProductFormList3"
                        :addName="addProductName3"
                        style="float: left"
                      ></addBtn>
                    </el-col>
                  </el-row>
                  <el-table :data="faultList">
                    <el-table-column label="序号" type="index" width="80px"></el-table-column>
                    <el-table-column label="故障描述" prop="title"></el-table-column>
                    <el-table-column label="操作">
                      <template #default="scope">
                        <!--              编辑的按钮-->
                        <editBtn
                          :editId="scope.row.id"
                          :editForm="editProductForm3"
                          :addProductFormList="addProductFormList3"
                          :editRules="editProductFormRules3"
                          @gainMsg="PageChange3"
                          :editApi="path3"
                          :editProductFormLists="editProductFormList3"
                          :editName="addProductName3"
                        ></editBtn>
                        <!--              删除的按钮-->
                        <deleteBtn :deleteId="scope.row.id" @gainMsg="PageChange3" :deleteApi="path3"></deleteBtn>
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
<!--                处理意见-->
                <div class="proDiv">
                  <el-row>
                    <el-col :span="17" style="line-height: 40px;font-size: 20px;font-weight: 600">处理意见</el-col>
                    <el-col :span="6">
                      <addBtn
                        @gainMsgs="PageChange4"
                        :addForm="addProductForm4"
                        :addRules="addProductRules4"
                        :addApi="path4"
                        :addProductFormLists="addProductFormList4"
                        :addName="addProductName4"
                        style="float: left"
                      ></addBtn>
                    </el-col>
                  </el-row>
                  <el-table :data="handleList">
                    <el-table-column label="序号" type="index" width="80px"></el-table-column>
                    <el-table-column label="处理意见" prop="title"></el-table-column>
                    <el-table-column label="操作">
                      <template #default="scope">
                        <!--              编辑的按钮-->
                        <editBtn
                          :editId="scope.row.id"
                          :editForm="editProductForm4"
                          :addProductFormList="addProductFormList4"
                          :editRules="editProductFormRules4"
                          @gainMsg="PageChange4"
                          :editApi="path4"
                          :editProductFormLists="editProductFormList4"
                          :editName="addProductName4"
                        ></editBtn>
                        <!--              删除的按钮-->
                        <deleteBtn :deleteId="scope.row.id" @gainMsg="PageChange4" :deleteApi="path4"></deleteBtn>
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
              </el-tab-pane>
<!--              收费支付-->
              <el-tab-pane label="收费标准" name="second">
                <el-row>
                    <el-col :span="5" style="line-height: 40px">项目名称</el-col>
                    <el-col :span="10">
                      <el-button type="primary" @click="addData">新增</el-button>
                    </el-col>
                </el-row>
                <el-table border :data="repairPrice">
                  <el-table-column label="序号" type="index" width="80px"></el-table-column>
                  <el-table-column label="配件名称" prop="name"></el-table-column>
                  <el-table-column label="规格型号" prop="model"></el-table-column>
                  <el-table-column label="单位" prop="unit"></el-table-column>
                  <el-table-column label="单价" prop="price"></el-table-column>
                  <el-table-column label="操作" prop="desc">
                    <template #default="scope">
                      <el-button type="warning" @click="upDateData(scope.row)" size="mini" style="margin-left: 5px !important;margin-right: 5px;margin-bottom: 5px;">修改</el-button>
                      <el-button type="danger" @click="deleteData(scope.row.id)" size="mini" style="margin-left: 5px !important;margin-right: 5px;">删除</el-button>
                    </template>
                  </el-table-column>
                </el-table>
                <page :queryInfos="queryInfo" @pageChange="PageChanges" queryUrl="AfterSalesManage/MaintenanceSetting" :pamams="'equipment_type_id=' + addForm.equipment_type_id" type="1"></page>
              </el-tab-pane>
            </el-tabs>
          </el-card>
        </el-col>
      </el-row>
<!--      新增收费弹窗-->
      <div class="addPop" v-if="addPricePop">
        <el-dialog
          :title="addType == 'update' ? '编辑收费标准' : '新增收费标准'"
          :visible.sync="addPricePop"
          width="50%"
          @close="addClosed"
        >
          <el-form label-width="115px" :model="addForm" :rules="addFormRules" ref="addFormRef">
            <el-form-item label="名称" prop="name">
              <el-input v-model="addForm.name"></el-input>
            </el-form-item>
            <el-form-item label="规格类型" prop="model">
              <el-input v-model="addForm.model"></el-input>
            </el-form-item>
            <el-form-item label="单位" prop="unit">
              <el-input v-model="addForm.unit"></el-input>
            </el-form-item>
            <el-form-item label="金额" prop="price">
              <el-input v-model="addForm.price"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button v-if="addType == 'update'" type="primary" @click="onSubmitEdit('addForm')">编辑</el-button>
              <el-button v-else type="primary" @click="onSubmit('addForm')">添加</el-button>
              <el-button @click="resetForm('addForm')">重置</el-button>
            </el-form-item>
          </el-form>
        </el-dialog>
      </div>
    </div>
</template>

<script>
import * as maintenanceSet from '../../interface/AfterSalesManage/MaintenanceSetting'
import * as appearance from '../../interface/AfterSalesManage/maintenanceSet/appearance'
import * as conclusion from '../../interface/AfterSalesManage/maintenanceSet/conclusion'
import * as fault from '../../interface/AfterSalesManage/maintenanceSet/fault'
import * as handle from '../../interface/AfterSalesManage/maintenanceSet/handle'
import Pagination from '../common/Pagination'
import EditBtn from '../common/EditBtn'
import DeleteBtn from '../common/DeleteBtn'
import AddBtn from '../common/AddBtn'

export default {
  name: 'MaintenanceSetting',
  components: {
    page: Pagination,
    editBtn: EditBtn,
    deleteBtn: DeleteBtn,
    addBtn: AddBtn
  },
  data: function () {
    return {
      repairPrice: [],
      productListcss: 'display:block',
      activeName: 'first',
      productList: [],
      productContent: [],
      // productId: this.productContent.id,
      isActive: 0,
      path: 'AfterSalesManage/MaintenancePointSetting',
      queryInfo: {
        query: '',
        pageNo: 1,
        pageSize: 2,
        total: 0
      },
      // 外观选项
      path1: 'AfterSalesManage/maintenanceSet/appearance',
      queryInfo1: {
        query: '',
        pageNo: 1,
        pageSize: 2,
        total: 0
      },
      addProductName1: '外观选项',
      addProductForm1: {
        title: '',
        equipment_type_id: ''
      },
      addProductFormList1: [
        {
          title: '外观名称',
          prop: 'title'
        }
      ],
      addProductRules1: {
        title: [
          { required: true, message: '请输入外观名称', trigger: 'blur' },
          { min: 2, message: '长度至少2个字符', trigger: 'blur' }
        ]
      },
      editProductForm1: {
        id: '',
        title: '',
        equipment_type_id: ''
      },
      editProductFormList1: [
        {
          title: '外观名称',
          prop: 'title'
        }
      ],
      editProductFormRules1: {
        title: [
          { required: true, message: '请输入外观名称', trigger: 'blur' },
          { min: 2, message: '长度至少2个字符', trigger: 'blur' }
        ]
      },
      // 检修结论
      path2: 'AfterSalesManage/maintenanceSet/conclusion',
      addProductName2: '检修结论',
      addProductForm2: {
        title: '',
        equipment_type_id: ''
      },
      addProductFormList2: [
        {
          title: '检修结论',
          prop: 'title'
        }
      ],
      addProductRules2: {
        title: [
          { required: true, message: '请输入检修结论', trigger: 'blur' },
          { min: 2, message: '长度至少2个字符', trigger: 'blur' }
        ]
      },
      editProductForm2: {
        id: '',
        title: '',
        equipment_type_id: ''
      },
      editProductFormList2: [
        {
          title: '检修结论',
          prop: 'title'
        }
      ],
      editProductFormRules2: {
        title: [
          { required: true, message: '请输入检修结论', trigger: 'blur' },
          { min: 2, message: '长度至少2个字符', trigger: 'blur' }
        ]
      },
      // 故障描述
      path3: 'AfterSalesManage/maintenanceSet/fault',
      addProductName3: '故障描述',
      addProductForm3: {
        title: '',
        equipment_type_id: ''
      },
      addProductFormList3: [
        {
          title: '故障描述',
          prop: 'title'
        }
      ],
      addProductRules3: {
        title: [
          { required: true, message: '请输入故障描述', trigger: 'blur' },
          { min: 2, message: '长度至少2个字符', trigger: 'blur' }
        ]
      },
      editProductForm3: {
        id: '',
        title: '',
        equipment_type_id: ''
      },
      editProductFormList3: [
        {
          title: '故障描述',
          prop: 'title'
        }
      ],
      editProductFormRules3: {
        title: [
          { required: true, message: '请输入故障描述', trigger: 'blur' },
          { min: 2, message: '长度至少2个字符', trigger: 'blur' }
        ]
      },
      // 处理意见
      path4: 'AfterSalesManage/maintenanceSet/handle',
      addProductName4: '处理意见',
      addProductForm4: {
        title: '',
        equipment_type_id: ''
      },
      addProductFormList4: [
        {
          title: '处理意见',
          prop: 'title'
        }
      ],
      addProductRules4: {
        title: [
          { required: true, message: '请输入处理意见', trigger: 'blur' },
          { min: 2, message: '长度至少2个字符', trigger: 'blur' }
        ]
      },
      editProductForm4: {
        id: '',
        title: '',
        equipment_type_id: ''
      },
      editProductFormList4: [
        {
          title: '处理意见',
          prop: 'title'
        }
      ],
      editProductFormRules4: {
        title: [
          { required: true, message: '请输入处理意见', trigger: 'blur' },
          { min: 2, message: '长度至少2个字符', trigger: 'blur' }
        ]
      },
      appearanceList: [],
      conclusionList: [],
      faultList: [],
      handleList: [],
      // 收费标准
      addType: '',
      addPricePop: false,
      addForm: {},
      addFormRules: {
        name: [
          { required: true, message: '请输入名称', trigger: 'blur' },
          { min: 2, message: '长度至少2个字符', trigger: 'blur' }
        ],
        model: [
          { required: true, message: '请输入规格类型', trigger: 'blur' }
        ],
        unit: [
          { required: true, message: '请输入单位', trigger: 'blur' }
        ],
        price: [
          { required: true, message: '请输入金额', trigger: 'blur' }
        ]
      }
    }
  },
  created () {
    this.getProductList()
  },
  methods: {
    // 维修分页
    PageChanges: function (e) {
      this.repairPrice = e.productList
      this.queryInfo = e.queryInfo
    },
    // 外观分页
    PageChanges1: function (e) {
      this.appearanceList = e.productList
      this.queryInfo1 = e.queryInfo
    },
    // 删除收费标准
    deleteData: function (e) {
      this.$confirm('是否确认删除', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const data = await appearance.deleteRepairPrice(this, e)
        console.log(data)
        if (data.code !== 0) return this.$message.error('删除失败')
        this.$message.success('删除成功')
        this.getRepairPrice(this.addForm.equipment_type_id)
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    // 编辑收费标准
    onSubmitEdit: function () {
      this.$refs.addFormRef.validate(async valid => {
        console.log(valid)
        if (!valid) return this.$message.error('请填写完整信息')
        const data = await appearance.updateRepairPrice(this, this.addForm)
        console.log(data)
        if (data.code !== 0) return this.$message.error(data.msg)
        this.$message.success('编辑成功')
        this.addPricePop = false
        this.getRepairPrice(this.addForm.equipment_type_id)
      })
    },
    // 修改收费标准
    upDateData: async function (e) {
      this.addForm = e
      delete this.addForm.create_time
      delete this.addForm.update_time
      this.addType = 'update'
      this.addPricePop = true
    },
    // 新增
    addData: function () {
      this.addForm = { equipment_type_id: this.addForm.equipment_type_id }
      this.addType = 'add'
      this.addPricePop = true
    },
    // 添加收费标准
    onSubmit: function () {
      this.$refs.addFormRef.validate(async valid => {
        console.log(valid)
        if (!valid) return this.$message.error('请填写完整信息')
        console.log(this.addForm)
        const data = await appearance.addRepairPrice(this, this.addForm)
        console.log(data)
        if (data.code !== 0) return this.$message.error(data.msg)
        this.$message.success('添加成功')
        this.addPricePop = false
        this.getRepairPrice(this.addForm.equipment_type_id)
      })
    },
    // 重置
    resetForm: function () {
      this.$refs.addFormRef.resetFields()
    },
    addClosed: function () {
      this.$refs.addFormRef.resetFields()
    },
    getProductList: async function () {
      console.log('获取产品的所有内容')
      const res = await maintenanceSet.productList(this)
      if (res.code !== 0) return this.$message.error('获取产品列表信息失败')
      console.log(res)
      this.productList = res.result
      this.productContent.push(this.productList[0])
      console.log('获取选中产品的id的值')
      console.log(this.productContent)
      console.log(this.productContent[0].id)
      this.getRepairPrice(this.productContent[0].id)
      this.getAppearanceList(this.productContent[0].id)
      this.getConclusionList(this.productContent[0].id)
      this.getFaultList(this.productContent[0].id)
      this.getHandleList(this.productContent[0].id)
      this.addForm.equipment_type_id = this.productContent[0].id
      this.addProductForm1.equipment_type_id = this.productContent[0].id
      this.editProductForm1.equipment_type_id = this.productContent[0].id
      this.addProductForm2.equipment_type_id = this.productContent[0].id
      this.editProductForm2.equipment_type_id = this.productContent[0].id
      this.addProductForm3.equipment_type_id = this.productContent[0].id
      this.editProductForm3.equipment_type_id = this.productContent[0].id
      this.addProductForm4.equipment_type_id = this.productContent[0].id
      this.editProductForm4.equipment_type_id = this.productContent[0].id
    },
    // 获取收费标准列表
    getRepairPrice: async function (id) {
      const res = await appearance.repairPrice(this, id)
      console.log(res)
      if (res.code !== 0) return this.$message.error('获取收费标准信息失败')
      this.repairPrice = res.result.data
      this.queryInfo.total = res.result.totalCount
      this.queryInfo.pageNo = res.result.pageNo
      this.queryInfo.pageSize = res.result.pageSize
    },
    // 获取外观显示列表
    getAppearanceList: async function (id) {
      const res = await appearance.productList(this, id)
      if (res.code !== 0) return this.$message.error('获取外观列表信息失败')
      console.log(res)
      this.appearanceList = res.result
    },
    // 获取检修结论列表
    getConclusionList: async function (id) {
      const res = await conclusion.productList(this, id)
      if (res.code !== 0) return this.$message.error('获取检修结论列表信息失败')
      console.log(res)
      this.conclusionList = res.result
    },
    // 获取故障描述列表
    getFaultList: async function (id) {
      const res = await fault.productList(this, id)
      if (res.code !== 0) return this.$message.error('获取故障描述列表信息失败')
      console.log(res)
      this.faultList = res.result
    },
    // 获取处理意见列表
    getHandleList: async function (id) {
      const res = await handle.productList(this, id)
      if (res.code !== 0) return this.$message.error('获取处理意见列表信息失败')
      console.log(res)
      this.handleList = res.result
    },
    // 点击的时候切换内容
    chooseClick (index) {
      this.productContent = []
      this.repairPrice = []
      this.isActive = index
      this.productContent.push(this.productList[index])
      this.getRepairPrice(this.productContent[0].id)
      this.getAppearanceList(this.productContent[0].id)
      this.getConclusionList(this.productContent[0].id)
      this.getFaultList(this.productContent[0].id)
      this.getHandleList(this.productContent[0].id)
      this.addForm.equipment_type_id = this.productContent[0].id
      this.addProductForm1.equipment_type_id = this.productContent[0].id
      this.editProductForm1.equipment_type_id = this.productContent[0].id
      this.addProductForm2.equipment_type_id = this.productContent[0].id
      this.editProductForm2.equipment_type_id = this.productContent[0].id
      this.addProductForm3.equipment_type_id = this.productContent[0].id
      this.editProductForm3.equipment_type_id = this.productContent[0].id
      this.addProductForm4.equipment_type_id = this.productContent[0].id
      this.editProductForm4.equipment_type_id = this.productContent[0].id
    },
    CompanySet: function () {
      this.$router.push('logisticsCompanySetting')
    },
    maintenceSet: function () {
      this.$router.push('maintenancePointSetting')
    },
    // 点击按钮显示和隐藏
    collapseBtn: function () {
      if (this.productListcss === 'display:block') {
        this.productListcss = 'display:none'
        return
      }
      this.productListcss = 'display:block'
    },
    // 面板切换
    handleClick (tab, event) {
      console.log(tab, event)
      this.getRepairPrice(this.productContent[this.isActive].id)
    },
    PageChange1: function () {
      this.getAppearanceList(this.productContent[0].id)
    },
    PageChange2: function () {
      this.getConclusionList(this.productContent[0].id)
    },
    PageChange3: function () {
      this.getFaultList(this.productContent[0].id)
    },
    PageChange4: function () {
      this.getHandleList(this.productContent[0].id)
    }
  }
}
</script>

<style scoped>
  .list{
    padding: -20px !important;
    font-size: 14px;
    width: 100%;
    height: 40px;
    line-height: 40px;
    box-sizing: border-box;
    padding-left: 20px;
    text-align: left;
    display: block;
  }
  .list a{
    text-decoration: none;
    color: black;
  }
  .proDiv{
    width: 90%;
    /*height: 400px;*/
    float: left;
    margin-left: 25px;
    margin-top: 15px;
    border: 2px solid rgb(228, 231, 237);
    padding: 10px;
  }
  .proDiv .el-table{
    /*height: 300px;*/
  }
  .productList{
    padding: -20px !important;
    font-size: 14px;
    width: 100%;
    height: 40px;
    line-height: 40px;
    box-sizing: border-box;
    padding-left: 20px;
    text-align: left;
    display: block;
  }
  .active{
    background-color: #409EFF;
    color: white;
  }
  .addPop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.2);
    z-index: 990;
  }
  .popModel{
    width: 40%;
    position: relative;
    margin: 20% auto 50px;
    background: #FFF;
    border-radius: 2px;
    box-sizing: border-box;
  }
</style>
