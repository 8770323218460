<template>
    <div>
      <el-row style="margin: 0 10px">
        <el-col :span="2" style="font-size: 18px">仓库项目</el-col>
        <el-col :span="5" style="float: right;">
          <!--面包屑导航-->
          <el-breadcrumb separator="/">
            <el-breadcrumb-item>仓库管理</el-breadcrumb-item>
            <el-breadcrumb-item><a>仓库项目</a></el-breadcrumb-item>
          </el-breadcrumb>
        </el-col>
      </el-row>
      <el-card>
        <el-row>
          <p style="font-size: 20px">仓库项目设置</p>
        </el-row>
        <el-tabs v-model="activeName" @tab-click="handleClick">
<!--          仓库名称-->
          <el-tab-pane label="仓库名称" name="first">
            <el-row :gutter="20" style="margin-top: 20px">
              <el-col :span="10">
                仓库名称
              </el-col>
              <el-col :span="5">
                <!--增加的按钮-->
                <addBtn
                  @gainMsgs="PageChange"
                  :addForm="addProductForm"
                  :addRules="addProductRules"
                  :addApi="path"
                  :addProductFormLists="addProductFormList"
                  :addName="addProductName"
                ></addBtn>
              </el-col>
            </el-row>
            <el-table
              border
              :data="storeData"
              style="width: 100%"
              height="500px"
            >
              <el-table-column type="index" label="序号" width="80"></el-table-column>
              <el-table-column prop="title" label="仓库名称"></el-table-column>
              <el-table-column prop="desc" label="仓库描述"></el-table-column>
              <el-table-column prop="mobile" label="联系电话"></el-table-column>
              <el-table-column prop="str" label="仓管员"></el-table-column>
              <el-table-column prop="name" label="操作">
                <template #default="scope">
                  <el-button type="warning" size="mini" style="float: left;margin-right: 20px" @click="WarehouseKeeperDialog(scope.row.id)">仓管员</el-button>
                  <!--编辑的按钮-->
                  <editBtn
                    :editId="scope.row.id"
                    :editForm="editProductForm"
                    :addProductFormList="addProductFormList"
                    :editRules="editProductFormRules"
                    @gainMsg="PageChange"
                    :editApi="path"
                    :editProductFormLists="editProductFormList"
                    :editName="addProductName"
                    style="float: left;margin-right: 20px"
                  ></editBtn>
                  <!--删除的按钮-->
                  <deleteBtn :deleteId="scope.row.id" @gainMsg="PageChange" :deleteApi="path" style="width: 50px;float: left" type="1"></deleteBtn>
                </template>
              </el-table-column>
            </el-table>
            <page :queryInfos="queryInfo" :queryUrl="path" @pageChange="queryChange"></page>
          </el-tab-pane>
<!--          出库类型-->
          <el-tab-pane label="出库类型" name="second">
            <el-row :gutter="20" style="margin-top: 20px">
              <el-col :span="10">
                出库类型
              </el-col>
              <el-col :span="5">
                <!--增加的按钮-->
                <addBtn
                  @gainMsgs="PageChange1"
                  :addForm="addProductForm1"
                  :addRules="addProductRules1"
                  :addApi="path1"
                  :addProductFormLists="addProductFormList1"
                  :addName="addProductName1"
                ></addBtn>
              </el-col>
            </el-row>
            <el-table
              border
              :data="storeOut"
              style="width: 100%" height="500px">
              <el-table-column type="index" label="序号" width="80"></el-table-column>
              <el-table-column prop="title" label="出库名称"></el-table-column>
              <el-table-column prop="desc" label="出库描述"></el-table-column>
              <el-table-column label="操作" width="180px">
                <template #default="scope">
                  <!--编辑的按钮-->
                  <editBtn
                    :editId="scope.row.id"
                    :editForm="editProductForm1"
                    :addProductFormList="addProductFormList1"
                    :editRules="editProductFormRules1"
                    @gainMsg="PageChange1"
                    :editApi="path1"
                    :editProductFormLists="editProductFormList1"
                    :editName="addProductName1"
                    style="float: left;margin-right: 20px"
                  ></editBtn>
                  <!--删除的按钮-->
                  <deleteBtn :deleteId="scope.row.id" @gainMsg="PageChange1" :deleteApi="path" style="width: 50px;float: left" type="2"></deleteBtn>
                </template>
              </el-table-column>
            </el-table>
            <page :queryInfos="queryInfo" :queryUrl="path1" @pageChange="queryChange1"></page>
          </el-tab-pane>
<!--          入库类型-->
          <el-tab-pane label="入库类型" name="third">
            <el-row :gutter="20" style="margin-top: 20px">
              <el-col :span="10">
                入库类型
              </el-col>
              <el-col :span="5">
                <!--增加的按钮-->
                <addBtn
                  @gainMsgs="PageChange2"
                  :addForm="addProductForm2"
                  :addRules="addProductRules2"
                  :addApi="path2"
                  :addProductFormLists="addProductFormList2"
                  :addName="addProductName2"
                ></addBtn>
              </el-col>
            </el-row>
            <el-table
              border
              :data="storeEnter"
              style="width: 100%" height="500px">
              <el-table-column type="index" label="序号" width="80"></el-table-column>
              <el-table-column prop="title" label="入库名称"></el-table-column>
              <el-table-column prop="desc" label="入库描述"></el-table-column>
              <el-table-column label="操作" width="180px">
                <template #default="scope">
                  <!--编辑的按钮-->
                  <editBtn
                    :editId="scope.row.id"
                    :editForm="editProductForm2"
                    :addProductFormList="addProductFormList2"
                    :editRules="editProductFormRules2"
                    @gainMsg="PageChange2"
                    :editApi="path2"
                    :editProductFormLists="editProductFormList2"
                    :editName="addProductName2"
                    style="float: left;margin-right: 20px"
                  ></editBtn>
                  <!--删除的按钮-->
                  <deleteBtn :deleteId="scope.row.id" @gainMsg="PageChange2" :deleteApi="path" style="width: 50px;float: left" type="3"></deleteBtn>
                </template>
              </el-table-column>
            </el-table>
            <page :queryInfos="queryInfo" :queryUrl="path2" @pageChange="queryChange2"></page>
          </el-tab-pane>
        </el-tabs>
      </el-card>
      <!--  管理员-->
      <el-dialog
        title="选择管理员"
        :visible.sync="isShow"
        width="50%"
        @close="showClosed">
<!--        <div class="center">-->
<!--          <div style="width: 20%;margin: 0 5%">-->
<!--            <div class="showModel">-->
<!--              <span>谢总</span>-->
<!--              <span>222@qq.com</span>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
        <el-form v-model="getForm" ref="getProductFormRef">
          <el-form-item>
            <el-checkbox-group v-model="getForm">
              <el-checkbox v-for="(item, index) in userData" :key="index"  :label="item.id">{{item.name}}({{item.mobile}})</el-checkbox>
<!--              <el-checkbox label="2">地推活动</el-checkbox>-->
<!--              <el-checkbox label="3">线下主题活动</el-checkbox>-->
<!--              <el-checkbox label="4">单纯品牌曝光</el-checkbox>-->
            </el-checkbox-group>
          </el-form-item>
        </el-form>
        <div class="showBtns">
          <el-button @click="cleanBtn">取消</el-button>
          <el-button type="primary" @click="selectData">提交</el-button>
        </div>
      </el-dialog>
    </div>
</template>

<script>
import * as store from '../../interface/storeHouse/ProjectSetting'
import * as storeOut from '../../interface/storeHouse/ProjectSettingOut'
import * as storeEnter from '../../interface/storeHouse/ProjectSettingEnter'
import Pagination from '../common/Pagination'
import EditBtn from '../common/EditBtn'
import DeleteBtn from '../common/DeleteBtn'
import AddBtn from '../common/AddBtn'

export default {
  name: 'ProjectSetting',
  components: {
    page: Pagination,
    editBtn: EditBtn,
    deleteBtn: DeleteBtn,
    addBtn: AddBtn
  },
  data () {
    return {
      warehouse_id: '',
      userData: [],
      getForm: [],
      isShow: false,
      activeName: 'first',
      storeData: [],
      storeOut: [],
      storeEnter: [],
      // 仓库的信息内容
      path: 'storeHouse/ProjectSetting',
      queryInfo: {
        query: '',
        pageNo: 1,
        pageSize: 2,
        total: 0
      },
      addProductName: '仓库',
      addProductForm: {
        title: '',
        desc: '',
        mobile: ''
      },
      addProductFormList: [
        {
          title: '仓库名称',
          prop: 'title'
        },
        {
          title: '仓库描述',
          prop: 'desc'
        },
        {
          title: '联系电话',
          prop: 'mobile'
        }
      ],
      addProductRules: {
        title: [
          { required: true, message: '请输入仓库名称', trigger: 'blur' },
          { min: 2, max: 10, message: '长度在 2 到 10 个字符', trigger: 'blur' }
        ],
        desc: [
          { required: true, message: '请输入仓库描述', trigger: 'blur' },
          { min: 2, message: '长度至少2个字符', trigger: 'blur' }
        ],
        mobile: [
          { required: true, message: '请输入联系电话', trigger: 'blur' },
          { min: 11, message: '正确电话号码', trigger: 'blur' }
        ]
      },
      editDialogVisible: false,
      editProductForm: {
        id: '',
        title: '',
        desc: '',
        mobile: ''
      },
      editProductFormList: [
        {
          title: '仓库名称',
          prop: 'title'
        },
        {
          title: '仓库描述',
          prop: 'desc'
        },
        {
          title: '联系电话',
          prop: 'mobile'
        }
      ],
      editProductFormRules: {
        title: [
          { required: true, message: '请输入仓库名称', trigger: 'blur' },
          { min: 2, max: 10, message: '长度在 2 到 10 个字符', trigger: 'blur' }
        ],
        desc: [
          { required: true, message: '请输入仓库描述', trigger: 'blur' },
          { min: 2, message: '长度至少2个字符', trigger: 'blur' }
        ],
        mobile: [
          { required: true, message: '请输入联系电话', trigger: 'blur' },
          { min: 11, message: '正确电话号码', trigger: 'blur' }
        ]
      },
      // 出库信息的内容
      path1: 'storeHouse/ProjectSettingOut',
      addProductName1: '出库',
      addProductForm1: {
        title: '',
        desc: ''
      },
      addProductFormList1: [
        {
          title: '出库名称',
          prop: 'title'
        },
        {
          title: '出库描述',
          prop: 'desc'
        }
      ],
      addProductRules1: {
        title: [
          { required: true, message: '请输入出库名称', trigger: 'blur' },
          { min: 2, max: 10, message: '长度在 2 到 10 个字符', trigger: 'blur' }
        ],
        desc: [
          { required: true, message: '请输入出库描述', trigger: 'blur' },
          { min: 2, message: '长度至少2个字符', trigger: 'blur' }
        ]
      },
      editProductForm1: {
        id: '',
        title: '',
        desc: ''
      },
      editProductFormList1: [
        {
          title: '出库名称',
          prop: 'title'
        },
        {
          title: '出库描述',
          prop: 'desc'
        }
      ],
      editProductFormRules1: {
        title: [
          { required: true, message: '请输入出库名称', trigger: 'blur' },
          { min: 2, max: 10, message: '长度在 2 到 10 个字符', trigger: 'blur' }
        ],
        desc: [
          { required: true, message: '请输入出库描述', trigger: 'blur' },
          { min: 2, message: '长度至少2个字符', trigger: 'blur' }
        ]
      },
      // 入库信息的内容
      path2: 'storeHouse/ProjectSettingEnter',
      addProductName2: '入库',
      addProductForm2: {
        title: '',
        desc: ''
      },
      addProductFormList2: [
        {
          title: '入库名称',
          prop: 'title'
        },
        {
          title: '入库描述',
          prop: 'desc'
        }
      ],
      addProductRules2: {
        title: [
          { required: true, message: '请输入入库名称', trigger: 'blur' },
          { min: 2, max: 10, message: '长度在 2 到 10 个字符', trigger: 'blur' }
        ],
        desc: [
          { required: true, message: '请输入入库描述', trigger: 'blur' },
          { min: 2, message: '长度至少2个字符', trigger: 'blur' }
        ]
      },
      editDialogVisible2: false,
      editProductForm2: {
        id: '',
        title: '',
        desc: ''
      },
      editProductFormList2: [
        {
          title: '入库名称',
          prop: 'title'
        },
        {
          title: '入库描述',
          prop: 'desc'
        }
      ],
      editProductFormRules2: {
        title: [
          { required: true, message: '请输入入库名称', trigger: 'blur' },
          { min: 2, max: 10, message: '长度在 2 到 10 个字符', trigger: 'blur' }
        ],
        desc: [
          { required: true, message: '请输入入库描述', trigger: 'blur' },
          { min: 2, message: '长度至少2个字符', trigger: 'blur' }
        ]
      }
    }
  },
  created () {
    this.getStoreList()
  },
  methods: {
    // 获取全部管理员
    getAllUsers: async function (id) {
      const data = await store.getAllUser(this)
      // console.log(data)
      if (data.code !== 0) return this.$message.error('获取全部管理员失败')
      this.userData = data.result
      var that = this
      // 获取已选择管理员
      const data1 = await store.getWarehouseUser(this, { id: id })
      if (data1.code !== 0) return that.$message.error('获取已选择管理员失败')
      data1.result.forEach(function (item, index) {
        that.getForm.push(item.user_id)
      })

      // this.getForm
    },
    // 选择提交
    selectData: async function () {
      console.log(this.getForm)
      const data = await store.saveWarehouseUser(this, { warehouse_id: this.warehouse_id, user_ids: this.getForm })
      if (data.code !== 0) return this.$message.error(data.msg)
      this.$message.success('设置成功')
      this.isShow = false
      this.getForm = []
      this.getStoreList()
    },
    // 取消
    cleanBtn: function () {
      this.isShow = false
      this.getForm = []
    },
    // 关闭弹窗
    showClosed: function () {},
    // 获取仓库信息的列表
    getStoreList: async function () {
      const data = await store.productList(this)
      // console.log(data)
      var storeData = data.result.data
      for (var j = 0; j < storeData.length; j++) {
        var str = ''
        for (var i = 0; i < storeData[j].user_list.length; i++) {
          if (storeData[j].user_list.length > 0) {
            if (i === storeData[j].user_list.length - 1) {
              str += storeData[j].user_list[i]
            } else {
              str += storeData[j].user_list[i] + ','
            }
          }
        }
        // console.log(str)
        storeData[j].str = str
      }
      this.storeData = storeData
      this.queryInfo.total = data.result.totalCount
      this.queryInfo.pageNo = data.result.pageNo
      this.queryInfo.pageSize = data.result.pageSize
    },
    // 获取仓库出库信息的列表
    getStoreOutList: async function () {
      const data = await storeOut.productList(this)
      console.log(data)
      this.storeOut = data.result.data
      this.queryInfo.total = data.result.totalCount
      this.queryInfo.pageNo = data.result.pageNo
      this.queryInfo.pageSize = data.result.pageSize
    },
    // 获取仓库入库信息的列表
    getStoreEnterList: async function () {
      const data = await storeEnter.productList(this)
      console.log(data)
      this.storeEnter = data.result.data
      this.queryInfo.total = data.result.totalCount
      this.queryInfo.pageNo = data.result.pageNo
      this.queryInfo.pageSize = data.result.pageSize
    },
    // 刷新页面
    PageChange: function () {
      this.getStoreList()
    },
    queryChange: function (e) {
      this.storeData = e.productList
      this.queryInfo = e.queryInfo
    },
    queryChange1: function (e) {
      this.storeOut = e.productList
      this.queryInfo = e.queryInfo
    },
    queryChange2: function (e) {
      this.storeEnter = e.productList
      this.queryInfo = e.queryInfo
    },
    PageChange1: function () {
      console.log('刷新页面信息')
      this.getStoreOutList()
    },
    PageChange2: function () {
      this.getStoreEnterList()
    },
    WarehouseKeeperDialog: async function (id) {
      console.log(id)
      this.isShow = true
      this.getAllUsers(id)
      this.warehouse_id = id
      // 获取所有仓管员的信息
      // const data = await store.storeManager(this, id)
      // console.log(data)
    },
    handleClick (tab, event) {
      console.log(tab, event)
      console.log(tab.$options)
      if (tab.$options.propsData.label === '出库类型') {
        this.getStoreOutList()
      } else if (tab.$options.propsData.label === '入库类型') {
        this.getStoreEnterList()
      } else if (tab.$options.propsData.label === '仓库名称') {
        this.getStoreList()
      }
    }
  }
}
</script>

<style scoped>
  .center{
    width: 100%;
    padding: 30px;
    display: flex;
    flex-wrap: wrap;
    border-top: 2px solid #efefef;
    border-bottom: 2px solid #efefef;
  }
  .showModel, .showModels{
    display: flex;
    flex-direction: column;
    padding: 10px;
    border-radius: 10px;
  }
  .showBtns{
    padding: 20px 0;
    display: flex;
    justify-content: flex-end;
  }
  .showModels{
    background-color: #E6A23C;
    color: #ffffff;
  }
</style>
