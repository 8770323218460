<template>
    <div>
      <el-row style="margin: 0 10px">
        <el-col :span="2" style="font-size: 18px">绑定变更</el-col>
        <el-col :span="5" style="float: right;">
          <!--面包屑导航-->
          <el-breadcrumb separator="/">
            <el-breadcrumb-item>生产管理</el-breadcrumb-item>
            <el-breadcrumb-item><a>绑定变更</a></el-breadcrumb-item>
          </el-breadcrumb>
        </el-col>
      </el-row>
      <el-card>
        <el-row style="margin-top: -15px">
          <p>机身号绑定</p>
          <el-form ref="form" :model="modularform" label-width="100px">
            <el-form-item label="4G模块ID号">
              <el-input v-model="modularform.imei" prop="imei" @input="onblurEvent && getModularList($event,'imei')" style="width: 400px;margin-right: 10px"></el-input>
              <el-button type="success" @click="editBtn('imei')">修改</el-button>
            </el-form-item>
            <el-form-item label="手机号">
              <el-input v-model="modularform.ccid" prop="ccid" @input="onblurEvent && getModularList($event,'ccid')" style="width: 400px;margin-right: 10px"></el-input>
              <el-button type="success" @click="editBtn('ccid')">修改</el-button>
            </el-form-item>
            <el-form-item label="机身号">
              <el-input v-model="modularform.equipment_no" prop="equipment_no" @input="onblurEvent && getModularList($event,'equipment_no')" style="width: 400px;margin-right: 10px"></el-input>
<!--              <el-button type="success" @click="editBtn('equipment_no')">修改</el-button>-->
            </el-form-item>
          </el-form>
        </el-row>
      </el-card>
    </div>
</template>

<script>
import * as modular from '../../interface/processManage/ChangeEquipmentModular'

export default {
  name: 'ChangeEquipmentModular',
  data: function () {
    return {
      modularform: {
        imei: '',
        ccid: '',
        equipment_no: ''
      },
      onblurEvent: true,
      modularUpdateform: {}
    }
  },
  created () {
    this.getModularList()
  },
  methods: {
    getModularList: async function (e, ele) {
      console.log('input的事件')
      console.log(e)
      const data = await modular.modularList(this, e)
      if (data.result === null) {
        console.log('没有获取到设备信息')
        if (ele === 'imei') {
          this.modularform.ccid = ''
          this.modularform.equipment_no = ''
        } else if (ele === 'ccid') {
          this.modularform.imei = ''
          this.modularform.equipment_no = ''
        } else if (ele === 'equipment_no') {
          this.modularform.ccid = ''
          this.modularform.imei = ''
        }
        return
      }
      console.log(data)
      this.modularform = data.result
      this.modularUpdateform.imei = data.result.imei
      this.modularUpdateform.ccid = data.result.ccid
      this.modularUpdateform.equipment_no = data.result.equipment_no
      this.onblurEvent = false
      this.$message.info('请进行修改提交的操作')
    },
    // 点击修改按钮
    editBtn: async function (ele) {
      if (!this.onblurEvent) {
        console.log('当前的信息')
        console.log(this.modularform)
        const result = await this.$confirm('是否确定修改?', '删除提示', {
          confirmButtonText: '确定修改',
          cancelButtonText: '取消',
          type: 'warning'
        }).catch(err => err) // 返回错误信息
        if (result !== 'confirm') return this.$message.info('取消修改')
        this.modularUpdateform.imei_old = this.modularform.imei
        this.modularUpdateform.equipment_no_old = this.modularform.equipment_no
        this.modularUpdateform.ccid_old = this.modularform.ccid
        this.modularUpdateform.equipment_type_id = this.modularform.type_id
        this.modularUpdateform.equipment_id = this.modularform.id
        const data = await modular.modularAdd(this, this.modularUpdateform)
        console.log(data)
        this.onblurEvent = true
        if (data.code !== 0) return this.$message.error('修改失败')
        this.$message.success('修改成功')
      } else {
        this.$message.error('请选中正确的元素信息再进行修改')
      }
    }
  }
}
</script>

<style scoped>

</style>
