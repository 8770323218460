// api 地址
const api = {
  skuCountList: 'admin/WarehouseEquipment/skuCountList',
  detailList: 'admin/WarehouseEquipment/list?id=',
  detailList1: 'admin/WarehouseEquipment/list'
}
// 获取产品列表
export async function skuCountList (ele) {
  var { data } = await ele.$http.get(api.skuCountList)
  return data
}

// 添加机身号
export async function detailList (ele, param) {
  var { data } = await ele.$http.get(api.detailList + param)
  return data
}

// 获取根据页面显示的条数(页数)来查询产品列表
export async function pageChange (ele, param) {
  for (var i in param) {
    var { data } = await ele.$http.get(api.detailList1 + '?' + i + '=' + param[i])
  }
  return data
}
export async function pageChanges (ele, param, pamams) {
  var p = []
  for (var i in param) {
    p.push(i + '=' + param[i])
  }
  var { data } = await ele.$http.get(api.detailList1 + '?' + p[0] + '&' + p[1] + '&' + pamams)
  return data
}
