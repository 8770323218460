<template>
  <div>
    <el-row style="margin: 0 10px">
      <el-col :span="2" style="font-size: 18px">设备列表</el-col>
      <el-col :span="5" style="float: right;">
        <!--面包屑导航-->
        <el-breadcrumb separator="/">
          <el-breadcrumb-item>设备管理</el-breadcrumb-item>
          <el-breadcrumb-item><a>设备列表</a></el-breadcrumb-item>
        </el-breadcrumb>
      </el-col>
    </el-row>
    <el-card>
      <el-form :inline="true" :model="form" class="demo-form-inline">
        <el-form-item>
          <el-input v-model="form.equipment_no" placeholder="机柜编号"></el-input>
        </el-form-item>
        <el-form-item>
          <el-input v-model="form.battery_id" placeholder="电池ID"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="getList">查询</el-button>
        </el-form-item>
      </el-form>

      <el-table border :data="productList" height="670px">
        <el-table-column label="机柜编号" prop="equipment_no"></el-table-column>
        <el-table-column label="电池ID" prop="battery_id"></el-table-column>
        <el-table-column label="电量" prop="power"></el-table-column>
        <el-table-column label="槽位" prop="slot"></el-table-column>
        <el-table-column label="是否故障" prop="is_damage">
          <template #default="scope">
            <span>{{scope.row.is_damage == 0 ? '是' : '否'}}</span>
          </template>
        </el-table-column>
        <el-table-column label="是否被借出" prop="key">
          <template #default="scope">
            <span>{{scope.row.key == 0 ? '是' : '否'}}</span>
          </template>
        </el-table-column>
        <el-table-column label="创建时间" prop="create_time"></el-table-column>
        <el-table-column label="更新时间" prop="update_time"></el-table-column>
<!--        <el-table-column label="操作" width="180px">-->
<!--          <template #default="scope">-->
<!--            <el-button size="mini" type="danger" @click="deleteUser(scope.row.id)">删除</el-button>-->
<!--          </template>-->
<!--        </el-table-column>-->
      </el-table>
      <page :queryInfos="queryInfo" @pageChange="PageChange" queryUrl="deviceManage/BatteryList" :pamams="'equipment_no=' + (form.equipment_no || '') + '&battery_id=' + (form.battery_id || '')"></page>
    </el-card>
  </div>
</template>

<script>
import Pagination from '../common/Pagination'
import * as getBattery from '../../interface/deviceManage/BatteryList'
export default {
  name: 'BatteryList',
  components: {
    page: Pagination
  },
  data: function () {
    return {
      queryInfo: {
        pageNo: 1,
        pageSize: 2,
        total: 0
      },
      productList: [],
      form: {}
    }
  },
  created () {
    this.getList()
  },
  methods: {
    // 获取电池列表
    getList: async function () {
      var p = '?equipment_no=' + (this.form.equipment_no || '') + '&battery_id=' + (this.form.battery_id || '')
      const data = await getBattery.batteryList(this, p)
      if (data.code !== 0) return this.$message.error('获取电池列表失败')
      this.productList = data.result.data
      this.queryInfo.pageNo = data.result.pageNo
      this.queryInfo.pageSize = data.result.pageSize
      this.queryInfo.total = data.result.totalCount
    },
    PageChange: function (e) {
      this.productList = e.productList
      this.queryInfo = e.queryInfo
    }
  }
}
</script>

<style scoped>
  .el-pagination{
    margin-top: 10px;
  }
</style>
