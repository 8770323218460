// 地址
const api = {
  equipmentFileList: 'admin/equipmentFile/list',
  equipmentFileAdd: 'admin/equipmentFile/add',
  release: 'admin/equipmentFile/release?id=',
  equipmentFileDel: 'admin/equipmentFile/del?id=',
  upgrade: 'admin/equipment/upgrade',
  upgradeApp: 'admin/equipment/upgradeApp',
  change: 'admin/Equipment/changeIpAddress',
  change1: 'admin/Equipment/changeDomainAddress',
  change2: 'admin/Equipment/saveIpAddress'
}
export async function change (ele, params) {
  const { data } = await ele.$http.post(api.change, params)
  return data
}
export async function change1 (ele, params) {
  const { data } = await ele.$http.post(api.change1, params)
  return data
}
export async function change2 (ele, params) {
  const { data } = await ele.$http.post(api.change2, params)
  return data
}
// 升级固件
export async function upgrade (ele, params) {
  const { data } = await ele.$http.get(api.upgrade + params)
  return data
}
// 升级版本
export async function upgradeApp (ele, params) {
  const { data } = await ele.$http.get(api.upgradeApp + params)
  return data
}
// 获取设备文件列表
export async function equipmentFileList (ele, params) {
  const { data } = await ele.$http.get(api.equipmentFileList + params)
  return data
}
// 上传文件
export async function equipmentFileAdd (ele, params) {
  const { data } = await ele.$http.post(api.equipmentFileAdd, params)
  return data
}
// 发布
export async function release (ele, params) {
  const { data } = await ele.$http.put(api.release + params)
  return data
}
// 删除
export async function equipmentFileDel (ele, params) {
  const { data } = await ele.$http.delete(api.equipmentFileDel + params)
  return data
}
export async function pageChanges (ele, param) {
  var p = []
  for (var i in param) {
    p.push(i + '=' + param[i])
  }
  var { data } = await ele.$http.get(api.equipmentFileList + '?' + p[0] + '&' + p[1])
  return data
}
