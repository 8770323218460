<template>
    <div>
      <el-row style="margin: 0 10px">
        <el-col :span="5" style="font-size: 18px">{{$t('cp.cplb')}}</el-col>
        <el-col :span="9" style="float: right;">
          <!--面包屑导航-->
          <el-breadcrumb separator="/">
            <el-breadcrumb-item>{{$t('role.xtgl')}}</el-breadcrumb-item>
            <el-breadcrumb-item><a>{{$t('cp.cplb')}}</a></el-breadcrumb-item>
          </el-breadcrumb>
        </el-col>
      </el-row>
      <el-card>
        <el-row :gutter="20">
          <el-col :span="7">
            <el-input :placeholder="$t('cp.cpbt')" v-model="queryInfo.query" clearable @clear="getProductList">
<!--              <template #append>-->
<!--                <el-button icon="el-icon-search" @click="getProductList"></el-button>-->
<!--              </template>-->
            </el-input>
          </el-col>
          <el-col :span="4">
            <el-button type="primary" @click="getProductList(queryInfo.query)">{{$t('home.xc')}}</el-button>
          </el-col>
          <el-col :span="4">
            <!--            增加的按钮-->
            <addBtn
              :addForm="addProductForm"
              :addRules="addProductRules"
              :addApi="path"
              :addProductFormLists="addProductFormList"
              :addName="addProductName"
              @gainMsgs="PageChanges"
            ></addBtn>
          </el-col>
        </el-row>
        <tableList :addApis="path" :addProductNames="addProductName" :allParam="allParams" :productLists="productList" @queryInfo="queryInfos"></tableList>
        <page :queryInfos="queryInfo" :queryUrl="path" :type="true" @pageChange="PageChange" :pamams="'title=' + (queryInfo.query || '')"></page>
      </el-card>
    </div>
</template>

<script>
import * as product from '../../interface/product/ProductList'
import Pagination from '../common/Pagination'
import AddBtn from '../common/AddBtn'
import TableList from '../common/TableList'
export default {
  name: 'ProductList',
  components: {
    page: Pagination,
    tableList: TableList,
    addBtn: AddBtn
  },
  data: function () {
    return {
      addProductName: this.$t('cp.cp'),
      queryInfo: {
        query: '',
        pageNo: 1,
        pageSize: 2,
        total: 0
      },
      path: 'product/ProductList',
      productList: [],
      allParams: [
        {
          title: this.$t('cp.cpbt'),
          prop: 'title',
          required: true,
          min: 3,
          max: 10
        },
        {
          title: this.$t('cp.cpms'),
          prop: 'desc',
          required: true,
          min: 3,
          max: 100
        },
        {
          title: this.$t('cp.scrq'),
          prop: 'create_time',
          required: false
        },
        {
          title: this.$t('cp.jg'),
          prop: 'price',
          required: true
        }
      ],
      addProductForm: {},
      addProductFormList: [],
      addProductRules: {},
      dialogVisible: false
    }
  },
  created () {
    this.getProductList()
    console.log('获取产品信息成功')
    var params = this.allParams
    for (var i = 0; i < params.length; i++) {
      if (params[i].required) {
        this.addProductForm[params[i].prop] = ''
        var obj = {
          title: params[i].title,
          prop: params[i].prop
        }
        this.addProductFormList.push(obj)
        if (params[i].title !== 'ID') {
          // var n = params[i].min
          // var x = params[i].max
          // this.addProductRules[params[i].prop] = [
          //   { required: true, message: `请输入${params[i].title}`, trigger: 'blur' },
          //   { min: n, max: x, message: `长度在 ${n} 到 ${x} 个字符`, trigger: 'blur' }
          // ]
        }
      }
    }
    console.log('addProductForm的值')
    console.log(this.addProductRules)
  },
  methods: {
    PageChanges () {
      this.getProductList()
      this.$router.go(0)
    },
    queryInfos: function (e) {
      console.log(e)
      // this.queryInfo = e.queryInfo
      this.queryInfo.total = e.queryInfo.total
      this.queryInfo.pageNo = e.queryInfo.pageNo
      this.queryInfo.pageSize = e.queryInfo.pageSize
      this.productList = e.productList
      console.log(this.queryInfo)
    },
    PageChange (e) {
      console.log(e)
      this.productList = e.productList
      this.queryInfo = e.queryInfo
      console.log(this.productList)
    },
    getProductList: async function (query) {
      var p = ''
      if (query) p = '?title=' + (query || '')
      const data = await product.productList(this, p)
      this.productList = data.result.data
      console.log(this.productList)
      this.queryInfo.total = data.result.totalCount
      this.queryInfo.pageNo = data.result.pageNo
      this.queryInfo.pageSize = data.result.pageSize
    }
  }
}
</script>

<style scoped>
.el-pagination{
  margin-top: 10px;
}
</style>
