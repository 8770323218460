<template>
  <div>
    <!--面包屑-->
    <el-row style="margin: 0 10px">
      <el-col :span="2" style="font-size: 18px">出库</el-col>
      <el-col :span="5" style="float: right;">
        <!--面包屑导航-->
        <el-breadcrumb separator="/">
          <el-breadcrumb-item>仓库管理</el-breadcrumb-item>
          <el-breadcrumb-item><a>出库</a></el-breadcrumb-item>
        </el-breadcrumb>
      </el-col>
    </el-row>
    <el-card>
      <el-row>
        <el-col>
          <el-form :inline="true"  class="login_form" :model="preEntryForm" ref="preEntrRef">
            <el-form-item prop="warehouse_no">
              <el-input placeholder="出库单号" type="text" v-model="preEntryForm.warehouse_no"></el-input>
            </el-form-item>
<!--            <el-form-item prop="name">-->
<!--              <el-select v-model="preEntryForm.equipment_type_id" placeholder="请选择产品">-->
<!--                <el-option label="全部" value=""></el-option>-->
<!--                <el-option v-for="(item, index) in getAllEqData" :key="index" :label="item.title" :value="item.id"></el-option>-->
<!--              </el-select>-->
<!--            </el-form-item>-->
            <el-form-item prop="warehouse_out_type_name">
              <el-select v-model="preEntryForm.warehouse_out_type_id" placeholder="出库类型">
                <el-option label="全部" value=""></el-option>
                <el-option v-for="(item, index) in storeHouseType" :key="index" :label="item.title" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
<!--            <el-form-item prop="mobile">-->
<!--              <el-input placeholder="机身编号" type="text" v-model="preEntryForm.machineID"></el-input>-->
<!--            </el-form-item>-->
            <el-form-item :span="2">
              <el-button type="primary" @click="preEn">查询</el-button>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col>
          <el-button type="primary" @click="addPage()">添加出库</el-button>
        </el-col>
      </el-row>

      <el-table border :data="exwareHousingList" height="600px">
        <el-table-column label="序号" type="index" width="80px"></el-table-column>
        <el-table-column label="出库单号" prop="warehouse_no" width="200px"></el-table-column>
        <el-table-column label="产品名称" prop="equipmentList">
          <template #default="scope">
            <ul v-for="(item,index) in scope.row.equipmentList" :key="index" style="padding: 0;list-style: none">
              <li>{{item.name}} : {{item.count}}</li>
            </ul>
            <el-table-column label="所有产品">
              <el-table-column label="产品名称" prop="equipmentList">
                <template #default="scope">
                  <div v-for="(item,index) in scope.row.equipmentList" :key="index">
                    {{item.name}}
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="出库数量" prop="equipmentList">
                <template #default="scope">
                  <div v-for="(item,index) in scope.row.equipmentList" :key="index">{{item.count}}</div>
                </template>
              </el-table-column>
            </el-table-column>
          </template>
        </el-table-column>
        <el-table-column label="出库总数量" prop="num"></el-table-column>
        <el-table-column label="出库类型" prop="warehouse_out_type_name"></el-table-column>
        <el-table-column label="出库时间" prop="create_time"></el-table-column>
        <el-table-column label="客户" prop="custom_name"></el-table-column>
        <el-table-column label="出库仓库" prop="warehouse_name"></el-table-column>
        <el-table-column label="操作员" prop="admin_user_name"></el-table-column>
      </el-table>
      <page :queryInfos="queryInfo" :queryUrl="path" @pageChange="queryChange" :pamams="'warehouse_no=' + (preEntryForm.warehouse_no || '') + '&warehouse_out_type_id=' + (preEntryForm.warehouse_out_type_id || '')"></page>
    </el-card>
  </div>
</template>

<script>
import * as exStore from '../../interface/storeHouse/ExWarehouse'
import Pagination from '../common/Pagination'
import * as getReceiptRegister from '../../interface/AfterSalesManage/ReceiptRegister'

export default {
  name: 'ExWareHouse',
  components: {
    page: Pagination
  },
  data: function () {
    return {
      preEntryForm: {
        imei: '',
        mobile: ''
      },
      queryInfo: {
        query: '',
        pageNo: 1,
        pageSize: 2,
        total: 0
      },
      wareHousingList: [],
      editWareForm: {
        id: '',
        title: '',
        desc: ''
      },
      path: 'storeHouse/ExWarehouse',
      editWareFormRules: {
        title: [
          { required: true, message: '请输入产品标题', trigger: 'blur' },
          { min: 3, max: 10, message: '长度在 3 到 10 个字符', trigger: 'blur' }
        ],
        desc: [
          { required: true, message: '请输入产品描述', trigger: 'blur' },
          { min: 3, message: '长度至少3个字符', trigger: 'blur' }
        ]
      },
      exwareHousingList: [],
      getAllEqData: [],
      storeHouseType: []
    }
  },
  created () {
    this.exWareHouseList()
    this.getAll()
    this.getStoreType()
  },
  methods: {
    // 获取出库类型
    getStoreType: async function () {
      const data = await exStore.exWareHousingAllType(this)
      console.log('出库类型')
      // console.log(data)
      this.storeHouseType = data.result
      console.log(this.storeHouseType)
    },
    // 获取全部产品
    getAll: async function () {
      const data = await getReceiptRegister.equipmentTypeAll(this)
      if (data.code !== 0) return this.$message.error('获取全部产品列表失败')
      this.getAllEqData = data.result
    },
    // 获取出库列表
    exWareHouseList: async function (p) {
      var p1 = ''
      if (p) p1 = p
      const data = await exStore.exWareHousingList(this, p1)
      console.log(data)
      this.exwareHousingList = data.result.data
      this.queryInfo.total = data.result.totalCount
      this.queryInfo.pageNo = data.result.pageNo
      this.queryInfo.pageSize = data.result.pageSize
    },
    queryChange: function (e) {
      this.exwareHousingList = e.productList
    },
    PageChange: function () {
    },
    preEn: function () {
      var p = '?warehouse_no=' + (this.preEntryForm.warehouse_no || '') + '&warehouse_out_type_id=' + (this.preEntryForm.warehouse_out_type_id || '')
      this.exWareHouseList(p)
    },
    showEditDialog: function () {

    },
    removeProductById: function () {

    },
    addPage: function () {
      this.$router.push('/addExWarehouse')
    }
  }
}
</script>

<style scoped>

</style>
