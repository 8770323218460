<template>
    <div>
      <el-row style="margin: 0 10px">
        <el-col :span="6" style="font-size: 18px">{{$t('admin.glylb')}}</el-col>
        <el-col :span="9" style="float: right;">
          <!--面包屑导航-->
          <el-breadcrumb separator="/">
            <el-breadcrumb-item>{{$t('role.xtgl')}}</el-breadcrumb-item>
            <el-breadcrumb-item><a>{{$t('admin.glylb')}}</a></el-breadcrumb-item>
          </el-breadcrumb>
        </el-col>
      </el-row>
      <el-card>
        <el-row :gutter="20">
          <el-col :span="7">
            <el-input :placeholder="$t('admin.sjh')" v-model="mobile">
<!--              <template #append>-->
<!--                <el-button icon="el-icon-search" @click="getProductList"></el-button>-->
<!--              </template>-->
            </el-input>
          </el-col>
          <el-col :span="4">
            <el-button type="primary" @click="getProductList">{{$t('home.xc')}}</el-button>
          </el-col>
          <el-col :span="4">
            <!--            增加的按钮-->
            <addBtn
              @gainMsgs="PageChange"
              :addForm="addProductForm"
              :addRules="addProductRules"
              :addApi="path"
              :addProductFormLists="addProductFormList"
              :addName="addProductName"
            ></addBtn>
          </el-col>
        </el-row>
        <el-table border :data="productList" height="670px">
          <el-table-column :label="$t('home.xh')" type="index" width="80px"></el-table-column>
          <el-table-column :label="$t('admin.yhdlmc')" prop="username"></el-table-column>
          <el-table-column :label="$t('group.mc')" prop="name"></el-table-column>
          <el-table-column :label="$t('admin.gskh')" prop="item_name"></el-table-column>
          <el-table-column :label="$t('admin.sjh')" prop="mobile"></el-table-column>
          <el-table-column :label="$t('admin.yx')" prop="email"></el-table-column>
          <el-table-column :label="$t('devDetail.zt')" prop="statused"></el-table-column>
          <el-table-column :label="$t('home.cz')" width="120px">
            <template #default="scope">
              <!--              编辑的按钮-->
              <editBtn
                :editId="scope.row.id"
                :editForm="editProductForm"
                :addProductFormList="addProductFormList"
                :editRules="editProductFormRules"
                @gainMsg="PageChange"
                :editApi="path"
                :editProductFormLists="editProductFormList"
                :editName="addProductName"
              ></editBtn>
              <!--              删除的按钮-->
              <deleteBtn :deleteId="scope.row.id" @gainMsg="PageChange" :deleteApi="path"></deleteBtn>
            </template>
          </el-table-column>
        </el-table>
        <page :queryInfos="queryInfo" @pageChange="PageChanges" queryUrl="systemManage/AdminManage" :pamams="'mobile=' + (mobile || '') "></page>
      </el-card>
    </div>
</template>

<script>
import Pagination from '../common/Pagination'
import EditBtn from '../common/EditBtn'
import DeleteBtn from '../common/DeleteBtn'
import AddBtn from '../common/AddBtn'
import * as admin from '../../interface/systemManage/AdminManage'

export default {
  name: 'AdminManage',
  components: {
    page: Pagination,
    editBtn: EditBtn,
    deleteBtn: DeleteBtn,
    addBtn: AddBtn
  },
  data: function () {
    var checkEmail = (rule, value, cb) => {
      // 验证邮箱的正则表达式
      const regEmail = /^\w+@\w+(\.\w+)+$/ // xx@xx.com.cn
      if (regEmail.test(value)) {
        return cb() // 正常通过,调用回调函数
      }
      // 校验不通过,则回调错误
      cb(new Error(this.$t('admin.qsrhfyx')))
    }
    var checkMobile = (rule, value, cb) => {
      const regMobile = /^1[3-9]\d{9}$/
      if (regMobile.test(value)) {
        return cb()
      }
      // 校验不通过,则回调错误
      cb(new Error(this.$t('admin.qsrhfsjhm')))
    }
    return {
      addProductName: this.$t('admin.gly'),
      // queryInfo: '',
      // queryInfo: {
      //   query: '', // 获取信息的关键字
      //   pagenum: 1, // 页码
      //   pagesize: 2// 条数    这个取值来源于上面的:page-sizes
      // },
      queryInfo: {
        query: '',
        pageNo: 1,
        pageSize: 2,
        total: 0
      },
      path: 'systemManage/AdminManage',
      total: 0,
      productList: [],
      dialogVisible: false,
      addProductForm: {
        username: '',
        password: '',
        name: '',
        mobile: '',
        email: '',
        status: 0,
        item_id: '',
        role_id: ''
      },
      addProductFormList: [
        {
          title: this.$t('admin.dlmc'),
          prop: 'username'
        },
        {
          title: this.$t('admin.mm'),
          prop: 'password'
        },
        {
          title: this.$t('group.mc'),
          prop: 'name'
        },
        {
          title: this.$t('admin.sjh'),
          prop: 'mobile'
        },
        {
          title: this.$t('admin.yx'),
          prop: 'email'
        },
        {
          title: this.$t('devDetail.zt'),
          prop: 'status'
        },
        {
          title: this.$t('home.khmc'),
          prop: 'item_id'
        },
        {
          title: this.$t('role.js'),
          prop: 'role_id'
        }
      ],
      addProductRules: {
        username: [
          { required: true, message: this.$t('admin.qsrdlmc'), trigger: 'blur' },
          { min: 2, max: 10, message: this.$t('power.cdzf'), trigger: 'blur' }
        ],
        name: [
          { required: true, message: this.$t('group.qsrmc'), trigger: 'blur' },
          { min: 2, message: this.$t('power.cdzfs'), trigger: 'blur' }
        ],
        password: [
          { required: true, message: this.$t('admin.qsrmm'), trigger: 'blur' },
          { min: 2, message: this.$t('power.cdzfs'), trigger: 'blur' }
        ],
        email: [
          { required: true, message: this.$t('admin.qsryx'), trigger: 'blur' },
          { validator: checkEmail, message: this.$t('admin.qsrhfyx'), trigger: 'blur' }
        ],
        mobile: [
          { required: true, message: this.$t('admin.qsrsjh'), trigger: 'blur' },
          { validator: checkMobile, message: this.$t('admin.qsrhfsjhm'), trigger: 'blur' }
        ]
      },
      editDialogVisible: false,
      editProductForm: {
        username: '',
        password: '',
        name: '',
        mobile: '',
        email: '',
        status: '',
        item_id: '',
        role_id: ''
      },
      editProductFormList: [
        {
          title: this.$t('admin.dlmc'),
          prop: 'username'
        },
        {
          title: this.$t('admin.mm'),
          prop: 'password'
        },
        {
          title: this.$t('group.mc'),
          prop: 'name'
        },
        {
          title: this.$t('admin.sjh'),
          prop: 'mobile'
        },
        {
          title: this.$t('admin.yx'),
          prop: 'email'
        },
        {
          title: this.$t('devDetail.zt'),
          prop: 'status'
        },
        {
          title: this.$t('home.khmc'),
          prop: 'item_id'
        },
        {
          title: this.$t('role.js'),
          prop: 'role_id'
        }
      ],
      editProductFormRules: {
        username: [
          { required: true, message: this.$t('admin.qsrdlmc'), trigger: 'blur' },
          { min: 2, max: 10, message: this.$t('power.cdzf'), trigger: 'blur' }
        ],
        name: [
          { required: true, message: this.$t('group.qsrmc'), trigger: 'blur' },
          { min: 2, message: this.$t('power.cdzfs'), trigger: 'blur' }
        ],
        // password: [
        //   { required: true, message: this.$t('admin.qsrmm'), trigger: 'blur' },
        //   { min: 2, message: this.$t('power.cdzfs'), trigger: 'blur' }
        // ],
        email: [
          { required: true, message: this.$t('admin.qsryx'), trigger: 'blur' },
          { validator: checkEmail, message: this.$t('admin.qsrhfyx'), trigger: 'blur' }
        ],
        mobile: [
          { required: true, message: this.$t('admin.qsrsjh'), trigger: 'blur' },
          { validator: checkMobile, message: this.$t('admin.qsrhfsjhm'), trigger: 'blur' }
        ]
      },
      mobile: ''
    }
  },
  created () {
    this.getProductList()
  },
  methods: {
    PageChanges (e) {
      // this.getProductList()
      this.productList = e.productList
      this.queryInfo = e.queryInfo
    },
    PageChange () {
      this.getProductList()
    },
    getProductList: async function () {
      var p = ''
      if (this.mobile) p = '?mobile=' + this.mobile
      const data = await admin.adminList(this, p)
      // console.log(data)
      this.productList = data.result.data
      var that = this
      if (that.productList.length > 0) {
        that.productList.forEach(function (item, index) {
          that.productList[index].statused = item.status === 0 ? that.$t('admin.zc') : that.$t('admin.jy')
        })
      }
      this.total = data.result.total
      this.queryInfo.total = data.result.totalCount
      this.queryInfo.pageNo = data.result.pageNo
      this.queryInfo.pageSize = data.result.pageSize
    }
  }
}
</script>

<style scoped>

</style>
