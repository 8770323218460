<template>
  <div>
    <el-row style="margin: 0 10px">
      <el-col :span="2" style="font-size: 18px">入库详细信息</el-col>
      <el-col :span="5" style="float: right;">
        <!--面包屑导航-->
        <el-breadcrumb separator="/">
          <el-breadcrumb-item>仓库管理</el-breadcrumb-item>
          <el-breadcrumb-item><a>入库</a></el-breadcrumb-item>
          <el-breadcrumb-item><a>入库详细信息</a></el-breadcrumb-item>
        </el-breadcrumb>
      </el-col>
    </el-row>
    <el-card style="margin-top: 10px">
      <el-form ref="form" :model="form" style="padding: 0 0 0 200px">
        <el-row style="margin-bottom: 20px">
          <el-col :span="2"><el-button type="success" @click="returnMsg()">返回</el-button></el-col>
          <el-col :span="3"><el-button type="primary" v-print="printObj">打印入库表</el-button></el-col>
          <el-col :span="3"><el-button type="primary" v-print="printList">打印机身码列表</el-button></el-col>
        </el-row>
        <table
          id="printMe"
          class="table table-bordered col-sm-4"
          style="text-align:center;width: 85%;"
        >
          <thead class="lead">
          <td colspan="14" style="background-color: #fff;border: 1px solid rgb(22, 22, 22);height: 70px;line-height:70px;font-size: 20px">产品入库单</td>
          </thead>
          <tbody>
          <tr style="height: 45px">
            <td colspan="2" width="14%" style="line-height:33px">入库数量：</td>
            <td colspan="3" width="19%">
              <div>{{storeHouseList.num}}</div>
            </td>
            <td colspan="2" width="14%" style="line-height:33px">入库仓库：</td>
            <td colspan="3" width="19%" style="word-wrap:break-word;">
              <div>{{storeHouseList.warehouse_name}}</div>
            </td>
            <td colspan="2" width="14%" style="line-height:33px">产品名称：</td>
            <td collapse="2" width="19%">
              <div>{{storeHouseList.equipment_type_name}}</div>
            </td>
          </tr>
          <tr style="height: 45px">
            <td colspan="2" width="14%" style="word-wrap:break-word;line-height:33px">入库类型:</td>
            <td colspan="3" width="19%">
              <div>{{storeHouseList.warehouse_in_type_name}}</div>
            </td>
            <td colspan="2" width="14%" style="line-height:33px">单位：</td>
            <td colspan="3" width="19%">
              <div>{{storeHouseList.contact_unit}}</div>
            </td>
            <td colspan="2" width="14%" style="line-height:33px">入库单号：</td>
            <td colspan="2" width="19%">
              <div>{{storeHouseList.warehouse_no}}</div>
            </td>
          </tr>
          <tr style="height: 200px;text-align: left;">
            <td colspan="14" contentEditable="true" style="vertical-align: top; outline: none;padding: 20px">备注：</td>
          </tr>
<!--          <tr style="height: 45px">-->
<!--            <td colspan="2" width="14%" style="line-height:33px">制表：</td>-->
<!--            <td colspan="3" width="19%" style="line-height:33px"><div>{{}}</div></td>-->
<!--            <td colspan="2" width="14%" style="line-height:33px">仓管：</td>-->
<!--            <td colspan="3" width="19%" style="word-wrap:break-word;line-height:33px">-->
<!--              <div>{{}}</div>-->
<!--            </td>-->
<!--            <td colspan="2" width="14%" style="line-height:33px">审核：</td>-->
<!--            <td colspan="2" width="19%" style="line-height:33px"><div>{{}}</div></td>-->
<!--          </tr>-->
          </tbody>
        </table>
        <table id="printMe1" class="table table-bordered col-sm-4" style="text-align:center;width: 85%;margin-top: 20px">
          <thead class="lead">
          <td colspan="14" style="background-color: #fff;border: 1px solid rgb(22, 22, 22);height: 70px;line-height:70px;font-size: 20px">机身码列表</td>
          </thead>
          <tbody>
            <tr style="height: 45px">
              <td colspan="2" width="14%" style="line-height:33px">入库单号：</td>
              <td colspan="3" width="19%"><div>{{storeHouseList.warehouse_no}}</div></td>
              <td colspan="2" width="14%" style="line-height:33px">入库仓库：</td>
              <td colspan="3" width="19%" style="word-wrap:break-word;">
                <div>{{storeHouseList.warehouse_name}}</div>
              </td>
              <td colspan="2" width="14%" style="line-height:33px">产品名称：</td>
              <td collapse="2" width="19%">
                <div>{{storeHouseList.equipment_type_name}}</div>
              </td>
            </tr>
            <tr style="height: 45px">
              <td colspan="2" width="14%" style="line-height:33px">入库类型：</td>
              <td colspan="3" width="19%"><div>{{storeHouseList.warehouse_in_type_name}}</div></td>
              <td colspan="2" width="14%" style="line-height:33px">入库数量：</td>
              <td colspan="3" width="19%"><div>{{storeHouseList.num}}</div></td>
              <td colspan="2" width="14%" style="line-height:33px">入库时间：</td>
              <td colspan="3" width="19%">
                <div>{{storeHouseList.create_time}}</div>
              </td>
            </tr>
          <tr id="tabAudit" style="height: 500px;">
            <td colspan="5" style="vertical-align: top;">
              <p style="font-size: 20px;margin-top: 20px">{{storeHouseList.equipment_type_name}}</p>
              <ul class="listDetail">
                <li v-for="item in storeHouseList.equipmentList" :key="item.id">{{item.equipment_no}}</li>
              </ul>
            </td>
            <td colspan="5"></td>
            <td colspan="5"></td>
          </tr>
          </tbody>
        </table>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import * as store from '../../interface/storeHouse/WareHousing'
export default {
  name: 'showWareHousing',
  data: function () {
    return {
      form: {
        warehouse_no: '',
        warehouse_id: '',
        contact_unit: '',
        unit: '台',
        equipment_type_id: '',
        num: '',
        warehouse_in_type_id: '',
        equipment_nos: []
      },
      productName: '',
      equipment_no: '',
      printObj: {
        id: 'printMe',
        popTitle: '产品入库单',
        extraCss: 'https://www.baidu.com,https://www.baidu.com',
        extraHead: '<meta http-equiv="Content-Language"content="zh-cn"/>',
        beforeOpenCallback (vue) {
          this.print = false
          console.log('打开之前')
        },
        openCallback (vue) {
          console.log('执行了打印')
        },
        closeCallback (vue) {
          console.log('关闭了打印工具')
        }
      },
      printList: {
        id: 'printMe1',
        popTitle: '机身码列表',
        extraCss: 'https://www.baidu.com,https://www.baidu.com',
        extraHead: '<meta http-equiv="Content-Language"content="zh-cn"/>',
        beforeOpenCallback (vue) {
          console.log('打开之前')
        },
        openCallback (vue) {
          console.log('执行了打印')
        },
        closeCallback (vue) {
          console.log('关闭了打印工具')
        }
      },
      storeHouseList: [],
      storeHouseType: [],
      productList: [],
      print: true
    }
  },
  created () {
    this.getStoreHouse()
  },
  methods: {
    // 获取入库仓库
    getStoreHouse: async function () {
      const id = this.$route.query.ids
      console.log(id)
      const data = await store.wareHouseDetail(this, id)
      console.log(data)
      this.storeHouseList = data.result
    },
    // 获取出入库单号
    getWareHouseNo: async function () {
      const data = await store.wareHouseNo(this)
      console.log(data)
      this.form.warehouse_no = data.result.warehouse_no
    },
    returnMsg: function () {
      this.$router.go(-1)
    },
    currentSel: function (e) {
      for (var i = 0; i < this.productList.length; i++) {
        if (this.productList[i].id === e) {
          this.productName = this.productList[i].title
        }
      }
    }
  }
}
</script>

<style scoped>
  table {
    border-collapse: collapse;
  }

  thead {
    background-color: #fff;
  }

  .table-bordered>tbody>tr>td,
  .table-bordered>tbody>tr>th,
  .table-bordered>tfoot>tr>td,
  .table-bordered>tfoot>tr>th,
  .table-bordered>thead>tr>td,
  .table-bordered>thead>tr>th {
    border: 1px solid rgb(22, 22, 22);
  }

  div {
    margin: 0 auto;
    overflow: hidden;
    font-size: 12px;
    display: block;
  }

  p {
    margin: 15px 30px;
    position: relative;
    overflow: hidden;
    padding: 0;
  }

  .info {
    width: 80%;
    height: 100%;
    background-color: #fff;
  }
  .el-input, .el-select {
    width: 80%;
    margin: 10px auto;
  }
  .listDetail{
    list-style: none;
    text-align: center;
    padding: 0 20px;
  }
  .listDetail li {
    border: 1px solid #eee;
    border-top: none;
    height: 30px;
    line-height: 30px;
  }
  .listDetail li:nth-child(1) {
    border-top: 1px solid #eee;
  }
</style>
