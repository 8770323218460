<template>
    <div>
      <el-row style="margin: 0 10px">
        <el-col :span="2" style="font-size: 18px">老化故障列表</el-col>
        <el-col :span="5" style="float: right;">
          <!--面包屑导航-->
          <el-breadcrumb separator="/">
            <el-breadcrumb-item>生产管理</el-breadcrumb-item>
            <el-breadcrumb-item><a>老化故障列表</a></el-breadcrumb-item>
          </el-breadcrumb>
        </el-col>
      </el-row>
      <el-card>
        <el-row :gutter="20">
          <el-col :span="7">
            <el-input placeholder="机身编号" v-model="queryInfo.query" clearable @clear="getProductList">
<!--              <template #append>-->
<!--                <el-button icon="el-icon-search" @click="getProductList"></el-button>-->
<!--              </template>-->
            </el-input>
          </el-col>
          <el-col :span="4">
            <el-button type="primary" @click="getProductList">查询</el-button>
            <el-button type="primary" @click="goMsg">返回</el-button>
          </el-col>
        </el-row>
        <el-table border :data="productList" height="670px">
          <el-table-column label="序号" type="index" width="80px"></el-table-column>
          <el-table-column label="产品名称" prop="title"></el-table-column>
          <el-table-column label="机身编号" prop="desc"></el-table-column>
          <el-table-column label="故障代码" prop="create_time"></el-table-column>
          <el-table-column label="报警时间" prop="create_time"></el-table-column>
        </el-table>
      </el-card>
    </div>
</template>

<script>
export default {
  name: 'AgingFaultList',
  data: function () {
    return {
      queryInfo: '',
      productList: []
    }
  },
  methods: {
    getProductList: function () {

    },
    goMsg: function () {
      this.$router.go(-1)
    }
  }
}
</script>

<style scoped>

</style>
