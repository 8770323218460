<template>
  <div>
    <el-row style="margin: 0 10px">
      <el-col :span="6" style="font-size: 18px">{{$t('video.spgl')}}</el-col>
      <el-col :span="9" style="float: right;">
        <!--面包屑导航-->
        <el-breadcrumb separator="/">
          <el-breadcrumb-item>{{$t('video.spgl')}}</el-breadcrumb-item>
        </el-breadcrumb>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="6" style="padding-right: 20px">
        <el-card style="padding: 0 !important;">
          <div slot="header" class="clearfix">
            <span>{{$t('home.fzgl')}}</span>
            <el-button style="float: right; padding: 3px 0" type="text" @click="collapseBtn"><i class="el-icon-s-operation" style="font-size: 18px"></i></el-button>
          </div>
          <div v-for="(item,index) in GroupAll" :key="index" @click="chooseClick(index)" :class="{active:index==isActive}" :style="productListcss" class="productList">
            <i class="el-icon-s-grid" style="padding-right: 10px"></i>{{item.name}}
          </div>
        </el-card>
      </el-col>
      <el-col :span="18" style="float: right">
        <el-card style="padding: 20px">
          <div>{{name}}</div>
          <el-form :inline="true" :model="form" class="demo-form-inline" style="margin-top: 20px">
            <el-form-item :label="$t('group.mc')">
              <el-input v-model="form.name" clearable :placeholder="$t('group.mc')"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="getList">{{$t('home.xc')}}</el-button>
            </el-form-item>
            <el-form-item>
              <upload-img isVideo="1" @getList="getList" :imgLists="imgList" :lastIndex="lastIndex"></upload-img>
            </el-form-item>
          </el-form>
          <el-table
            ref="filterTable"
            :data="imgList"
            border
            style="width: 100%">
            <el-table-column prop="path" :label="$t('video.sp')">
              <template width="100px" slot-scope="scope">
<!--                <el-image-->
<!--                  style="height: 100px"-->
<!--                  :src="url + scope.row.path"-->
<!--                  :preview-src-list="[url + scope.row.path]">-->
<!--                </el-image>-->
                <video controls muted style="width: auto;height: auto;max-height: 100px;max-width: 100px" :src="url + scope.row.path"></video>
              </template>
            </el-table-column>
            <el-table-column prop="name" :label="$t('group.mc')"></el-table-column>
            <el-table-column prop="size" :label="$t('video.dx')"></el-table-column>
            <el-table-column prop="create_time" :label="$t('video.scsj')"></el-table-column>
            <el-table-column :label="$t('home.cz')" width="255px">
              <template #default="scope">
                <el-button type="danger" size="mini" @click="delFile(scope.row.id)">{{$t('home.sc')}}</el-button>
              </template>
            </el-table-column>
          </el-table>
          <pagination :queryInfos="queryInfo" @pageChange="PageChange" queryUrl="Img/ImgManage" :pamams="'type=' + this.form.type + '&group_id=' + this.form.group_id + '&name=' + (this.form.name || '')"></pagination>
        </el-card>
      </el-col>
    </el-row>
<!--    <div class="videoPop" v-if="showPop">-->
<!--      <el-dialog-->
<!--        title="预览"-->
<!--        :visible.sync="showPop"-->
<!--        width="50%"-->
<!--        @close="showClosed"-->
<!--      >-->
<!--        <video controls  style="width: auto;height: auto;" :src="urlPath"></video>-->
<!--      </el-dialog>-->
<!--    </div>-->
  </div>
</template>
<script>
import Pagination from '../../common/Pagination'
import UploadImg from '../../common/UploadImg'
import * as getImg from '../../../interface/Img/ImgManage'

export default {
  name: 'Video',
  components: { Pagination, UploadImg },
  data: function () {
    return {
      isActive: -1,
      url: this.url,
      productListcss: 'display:block',
      GroupAll: [],
      ImgData: [],
      form: {
        type: 2,
        group_id: 0
      },
      imgList: [],
      queryInfo: {
        query: '',
        pageNo: 1,
        pageSize: 2,
        total: 0
      },
      name: this.$t('home.qbfz'),
      showPop: false,
      urlPath: '',
      lastIndex: 0
    }
  },
  created () {
    this.getGroupAll()
    // this.getList()
  },
  methods: {
    // 视频
    videoShow: function (url) {
      this.showPop = true
      this.urlPath = url
    },
    showClosed: function () {
      this.showPop = false
    },
    // 删除图片
    delFile: function (id) {
      var that = this
      that.$confirm(this.$t('home.sfqrsc'), this.$t('home.ts'), {
        confirmButtonText: this.$t('home.qd'),
        cancelButtonText: this.$t('home.qx'),
        type: 'warning'
      }).then(async (res) => {
        console.log(res)
        const data = await getImg.delFileManagement(this, id)
        if (data.code !== 0) return this.$message.error(data.msg)
        this.$message.success(this.$t('home.sccg'))
        that.getList()
      }).catch(() => {
        that.$message({
          type: 'info',
          message: this.$t('home.yqxsc')
        })
      })
    },
    // 点击按钮实现产品列表的显示和隐藏
    collapseBtn: function () {
      if (this.productListcss === 'display:block') {
        this.productListcss = 'display:none'
        return
      }
      this.productListcss = 'display:block'
    },
    // 获取全部分组
    getGroupAll: async function () {
      const data = await getImg.fileGroupAll(this)
      if (data.code !== 0) return this.$message.error(data.msg)
      this.GroupAll = data.result
      this.chooseClick(0)
    },
    // 选择
    chooseClick: function (index) {
      this.lastIndex = index
      console.log(index)
      this.isActive = index
      this.form.group_id = this.GroupAll[index].id
      this.name = this.GroupAll[index].name
      this.getList()
    },
    // 获取文件列表
    getList: async function () {
      var p = '?type=' + this.form.type + '&group_id=' + this.form.group_id + '&name=' + (this.form.name || '')
      const data = await getImg.FileManagement(this, p)
      if (data.code !== 0) return this.$message.error(data.msg)
      this.imgList = data.result.data
      console.log(this.imgList)
      this.queryInfo.total = data.result.totalCount
      this.queryInfo.pageNo = data.result.pageNo
      this.queryInfo.pageSize = data.result.pageSize
    },
    PageChange: function (e) {
      this.imgList = e.productList
      this.queryInfo = e.queryInfo
    }
  }
}
</script>
<style scoped>
  .productList{
    padding: -20px !important;
    font-size: 14px;
    width: 100%;
    height: 40px;
    line-height: 40px;
    box-sizing: border-box;
    padding-left: 20px;
    text-align: left;
    display: block;
  }
  .active{
    background-color: #409EFF;
    color: white;
  }
  .el-image img{
    width: auto !important;
    height:auto !important;
    max-width:100px;
    max-height:100px;
    border:none;
  }
  .videoPop{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-color: rgba(0,0,0,0.2);
    display: flex;
    align-items: center;
    justify-content: center;
  }
</style>
