<template>
    <div>
      <el-row style="margin: 0 10px">
        <el-col :span="2" style="font-size: 18px">入库列表</el-col>
        <el-col :span="5" style="float: right;">
          <!--面包屑导航-->
          <el-breadcrumb separator="/">
            <el-breadcrumb-item>仓库管理</el-breadcrumb-item>
            <el-breadcrumb-item><a>入库</a></el-breadcrumb-item>
            <el-breadcrumb-item><a>入库列表</a></el-breadcrumb-item>
          </el-breadcrumb>
        </el-col>
      </el-row>
      <el-card style="margin-top: 10px">
        <el-form ref="form" :model="form" style="padding: 0 0 0 200px">
          <el-row style="margin-bottom: 20px">
            <el-col :span="2"><el-button type="success" @click="returnMsg()">返回</el-button></el-col>
            <el-col :span="2"><el-button type="primary" @click="addWareHousing()">提交</el-button></el-col>
          </el-row>
          <table
            id="printMe"
            class="table table-bordered col-sm-4"
            style="text-align:center;width: 85%;"
          >
            <thead class="lead">
            <td colspan="14" style="background-color: #fff;border: 1px solid rgb(22, 22, 22);height: 70px;line-height:70px;font-size: 20px">产品入库单</td>
            </thead>
            <tbody>
            <tr style="height: 45px">
              <td colspan="2" width="14%" style="line-height:33px">入库数量：</td>
              <td colspan="3" width="19%">
                <el-input class="form-control" v-model="form.num" v-if="print" disabled></el-input>
                <div v-else>{{form.num}}</div>
              </td>
              <td colspan="2" width="14%" style="line-height:33px">入库仓库：</td>
              <td colspan="3" width="19%" style="word-wrap:break-word;">
                <el-select class="form-control form-control-warning" v-model="form.warehouse_id">
                  <el-option v-for="item in storeHouseList" :key="item.id" :label="item.title" :value="item.id"></el-option>
                </el-select>
              </td>
              <td colspan="2" width="14%" style="line-height:33px">产品名称：</td>
              <td collapse="2" width="19%">
                <el-select class="form-control form-control-warning" v-model="form.equipment_type_id" @change="currentSel">
                  <el-option v-for="item in productList" :key="item.id" :label="item.title" :value="item.id"></el-option>
                </el-select>
              </td>
            </tr>
            <tr style="height: 45px">
              <td colspan="2" width="14%" style="word-wrap:break-word;line-height:33px">入库类型:</td>
              <td colspan="3" width="19%">
                <el-select class="form-control form-control-warning" v-model="form.warehouse_in_type_id">
                  <el-option v-for="item in storeHouseType" :key="item.id" :label="item.title" :value="item.id"></el-option>
                </el-select>
              </td>
              <td colspan="2" width="14%" style="line-height:33px">单位：</td>
              <td colspan="3" width="19%">
                <el-input class="form-control" v-model="form.contact_unit"></el-input>
              </td>
              <td colspan="2" width="14%" style="line-height:33px">入库单号：</td>
              <td colspan="2" width="19%">
                <el-input class="form-control" v-model="form.warehouse_no"></el-input>
              </td>
            </tr>
            <tr style="height: 200px;text-align: left;">
              <td colspan="14" contentEditable="true" style="vertical-align: top; outline: none;padding: 20px">备注：</td>
            </tr>
          </tbody>
          </table>
          <table id="printMe1" class="table table-bordered col-sm-4" style="text-align:center;width: 85%;margin-top: 20px">
            <thead class="lead">
              <td colspan="14" style="background-color: #fff;border: 1px solid rgb(22, 22, 22);height: 70px;line-height:70px;font-size: 20px">机身码列表</td>
            </thead>
            <tbody>
              <tr id="tabAudit" style="height: 500px;">
                <td colspan="4" style="vertical-align: top;">
                  <p style="font-size: 20px;margin-top: 20px">{{productName}}</p>
                  <el-input v-model="equipment_no" :value="equipment_no" @keyup.enter.native="onSubmit"></el-input>
                  <el-card class="box-card" v-if="form.equipment_nos.length > 0">
                    <div v-for="(item,index) in form.equipment_nos" :key="index" class="text item showText">
                      {{item}}
                    </div>
                  </el-card>
                </td>
              </tr>
            </tbody>
          </table>
        </el-form>
      </el-card>
    </div>
</template>

<script>
import * as store from '../../interface/storeHouse/WareHousing'
export default {
  name: 'AddWareHousing',
  data: function () {
    return {
      form: {
        warehouse_no: '',
        warehouse_id: '',
        contact_unit: '',
        unit: '台',
        equipment_type_id: '',
        num: '',
        warehouse_in_type_id: '',
        equipment_nos: []
      },
      productName: '',
      equipment_no: '',
      storeHouseList: [],
      storeHouseType: [],
      productList: [],
      print: true
    }
  },
  created () {
    this.getStoreHouse()
    this.getStoreType()
    this.getProductList()
    this.getWareHouseNo()
  },
  methods: {
    // 获取入库仓库
    getStoreHouse: async function () {
      const data = await store.wareHousingAllList(this)
      console.log(data)
      this.storeHouseList = data.result
    },
    // 获取入库类型
    getStoreType: async function () {
      const data = await store.wareHousingAllType(this)
      console.log(data)
      this.storeHouseType = data.result
    },
    // 获取产品名称
    getProductList: async function () {
      const data = await store.productList(this)
      console.log(data)
      this.productList = data.result
    },
    addWareHousing: async function () {
      console.log('仓库信息')
      console.log(this.form)
      // this.form.equipment_nos.push(this.equipment_no)
      const data = await store.wareHousingAdd(this, this.form)
      if (data.code !== 0) return this.$message.error('添加入库信息失败')
      this.$message.success('添加入库信息成功')
      this.$router.push('wareHousing')
    },
    // 获取出入库单号
    getWareHouseNo: async function () {
      const data = await store.wareHouseNo(this)
      console.log(data)
      this.form.warehouse_no = data.result.warehouse_no
    },
    returnMsg: function () {
      this.$router.go(-1)
    },
    currentSel: function (e) {
      for (var i = 0; i < this.productList.length; i++) {
        if (this.productList[i].id === e) {
          this.productName = this.productList[i].title
        }
      }
    },
    onSubmit: function (e) {
      console.log(e)
      this.form.equipment_nos.push(this.equipment_no)
      this.form.num = this.form.equipment_nos.length
      this.equipment_no = ''
    }
  }
}
</script>

<style scoped>
  table {
    border-collapse: collapse;
  }

  thead {
    background-color: #fff;
  }

  .table-bordered>tbody>tr>td,
  .table-bordered>tbody>tr>th,
  .table-bordered>tfoot>tr>td,
  .table-bordered>tfoot>tr>th,
  .table-bordered>thead>tr>td,
  .table-bordered>thead>tr>th {
    border: 1px solid rgb(22, 22, 22);
  }

  div {
    margin: 0 auto;
    overflow: hidden;
    font-size: 12px;
    display: block;
  }

  p {
    margin: 15px 30px;
    position: relative;
    overflow: hidden;
    padding: 0;
  }

  .info {
    width: 80%;
    height: 100%;
    background-color: #fff;
  }
  .el-input, .el-select {
    width: 80%;
    margin: 10px auto;
  }
  .el-input{
    border: none;
  }
  .showText{
    padding: 10px 0;
    font-size: 12px;
  }
  .el-card.is-always-shadow, .el-card.is-hover-shadow:focus, .el-card.is-hover-shadow:hover {
     box-shadow: none;
  }
</style>
