const api = {
  getList: 'admin/Package/list',
  addList: 'admin/Package/add',
  getListInfo: 'admin/Package/info?id=',
  updateList: 'admin/Package/edit',
  deleteList: 'admin/Package/del?id='
}
// 获取套餐列表
export async function getLists (ele, param) {
  var { data } = await ele.$http.get(api.getList + param)
  return data
}
// 新增套餐
export async function addLists (ele, param) {
  var { data } = await ele.$http.post(api.addList, param)
  return data
}
// 获取套餐详情
export async function getListsInfo (ele, param) {
  var { data } = await ele.$http.get(api.getListInfo + param)
  return data
}
// 编辑套餐
export async function updateLists (ele, param) {
  var { data } = await ele.$http.put(api.updateList, param)
  return data
}
// 删除
export async function deleteLists (ele, param) {
  var { data } = await ele.$http.delete(api.deleteList + param)
  return data
}
export async function pageChange (ele, param) {
  for (var i in param) {
    var { data } = await ele.$http.get(api.getList + '?' + i + '=' + param[i])
  }
  return data
}
export async function pageChanges (ele, param, pamams) {
  var p = []
  for (var i in param) {
    p.push(i + '=' + param[i])
  }
  var { data } = await ele.$http.get(api.getList + '?' + p[0] + '&' + p[1] + '&' + pamams)
  return data
}
