<template>
    <div>
      <el-row style="margin: 0 10px">
        <el-col :span="2" style="font-size: 18px">明细列表</el-col>
        <el-col :span="5" style="float: right;">
          <!--面包屑导航-->
          <el-breadcrumb separator="/">
            <el-breadcrumb-item>生产管理</el-breadcrumb-item>
            <el-breadcrumb-item><a>库存盘点</a></el-breadcrumb-item>
            <el-breadcrumb-item><a>明细列表</a></el-breadcrumb-item>
          </el-breadcrumb>
        </el-col>
      </el-row>
      <el-card>
        <el-row><p>{{title}}出入库列表</p></el-row>
        <el-row style="padding-top: 20px;margin-left: 5px">
          <el-form :inline="true"  class="login_form" :model="form" ref="preEntrRef">
            <el-form-item prop="mobile">
              <el-date-picker
                v-model="form.date"
                type="datetimerange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy-MM-dd HH:mm:ss">
              </el-date-picker>
            </el-form-item>
            <el-form-item prop="mobile">
              <el-select v-model="form.type" placeholder="请选择类型">
                <el-option label="全部" value=""></el-option>
                <el-option label="入库" value="0"></el-option>
                <el-option label="出库" value="1"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="preEn">查询</el-button>
            </el-form-item>
            <el-form-item>
              <el-button type="success" @click="returnMsg">返回</el-button>
            </el-form-item>
          </el-form>
        </el-row>
        <el-table
          ref="filterTable"
          :data="detailLists"
          height="550px"
          border
          style="width: 100%">
          <el-table-column type="index" label="序号" width="55px"></el-table-column>
          <el-table-column prop="createTime" label="日期"></el-table-column>
          <el-table-column prop="equipment_type_name" label="产品名称"></el-table-column>
<!--          <el-table-column prop="mobile" label="单位"></el-table-column>-->
<!--          <el-table-column prop="updated_at" label="入库数量"></el-table-column>-->
<!--          <el-table-column prop="updated_at" label="出库数量"></el-table-column>-->
<!--          <el-table-column prop="updated_at" label="库存数量"></el-table-column>-->
          <el-table-column prop="equipment_type_name" label="出入库类型"></el-table-column>
          <el-table-column prop="equipment_no" label="出入库单号"></el-table-column>
          <el-table-column prop="warehouse_name" label="仓库"></el-table-column>
        </el-table>
        <page :queryInfos="queryInfo" :queryUrl="path" @pageChange="queryChange2" :pamams="'id=' + id + '&type=' + (form.type || '') + '&create_time=' + (form.date || '')"></page>
      </el-card>
    </div>
</template>

<script>
import * as skuCount from '../../interface/storeHouse/InventoryCount'
import Pagination from '../common/Pagination'

export default {
  name: 'DetailedList',
  components: {
    page: Pagination
  },
  data: function () {
    return {
      form: {},
      detailLists: [],
      path: 'storeHouse/InventoryCount',
      queryInfo: {
        query: '',
        pageNo: 1,
        pageSize: 2,
        total: 0
      },
      title: '',
      id: ''
    }
  },
  created () {
    this.getDetailList()
  },
  methods: {
    getDetailList: async function (p) {
      const id = this.$route.query.detailId
      this.id = this.$route.query.detailId
      this.title = this.$route.query.title
      var p1 = id
      if (p) p1 = id + p
      // console.log(id)
      const data = await skuCount.detailList(this, p1)
      if (data.code !== 0) return this.$message.error('获取产品列表失败')
      // console.log(data)
      this.detailLists = data.result.data
      this.queryInfo.total = data.result.totalCount
      this.queryInfo.pageNo = data.result.pageNo
      this.queryInfo.pageSize = data.result.pageSize
    },
    returnMsg: function () {
      this.$router.go(-1)
    },
    queryChange2: function (e) {
      this.detailLists = e.productList
    },
    preEn: function () {
      var p = '&type=' + (this.form.type || '') + '&create_time=' + (this.form.date || '')
      this.getDetailList(p)
    }
  }
}
</script>

<style scoped>

</style>
