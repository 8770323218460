// api 地址
const api = {
  productList: 'admin/equipmentType/getAll',
  getEquipmentBind: 'admin/equipmentServer/add',
  modularList: 'admin/modular/list?keyword=',
  getEquipmentServer: 'admin/equipmentServer/list?equipment_type_id=',
  modularLists: 'admin/modular/list',
  modularListsed: 'admin/modular/info?keyword='
}
// 获取产品列表
export async function productList (ele) {
  var { data } = await ele.$http.get(api.productList)
  return data
}

// 添加机身号
export async function getEquipmentBind (ele, param) {
  var { data } = await ele.$http.post(api.getEquipmentBind, param)
  return data
}

export async function modularList (ele, param) {
  if (param !== '') {
    var { data } = await ele.$http.get(api.modularList + param)
    return data
  } else {
    var { data1 } = await ele.$http.get(api.modularList)
    return data1
  }
}
export async function modularLists (ele, param) {
  if (param !== '') {
    var { data } = await ele.$http.get(api.modularListsed + param)
    return data
  } else {
    var { data1 } = await ele.$http.get(api.modularListsed)
    return data1
  }
}
// 获取预选列表
export async function getEquipmentServer (ele, param) {
  var { data } = await ele.$http.get(api.getEquipmentServer + param)
  return data
}
export async function pageChanges (ele, param) {
  var p = []
  for (var i in param) {
    p.push(i + '=' + param[i])
  }
  var { data } = await ele.$http.get(api.modularLists + '?' + p[0] + '&' + p[1])
  return data
}
