<template>
  <div>
    <!--面包屑-->
    <el-row style="margin: 0 10px">
      <el-col :span="2" style="font-size: 18px">库存盘点</el-col>
      <el-col :span="5" style="float: right">
        <!--面包屑导航-->
        <el-breadcrumb separator="/">
          <el-breadcrumb-item>生产管理</el-breadcrumb-item>
          <el-breadcrumb-item><a>库存盘点</a></el-breadcrumb-item>
        </el-breadcrumb>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="11" style="margin-right: 25px;float: left" v-for="item in skuCountList" :key="item.id">
        <el-card style="margin-right: 15px;height: 300px;overflow-y: auto;margin-top: 20px;width: 90%" >
          <el-row>
            <el-col :span="11" style="line-height: 40px;font-size: 18px;font-weight: 600">{{item.title}}仓库统计</el-col>
            <el-col :span="11"><el-button type="primary" style="float: right" @click="detailedList(item.id, item.title)">明细列表</el-button></el-col>
          </el-row>
          <el-row class="wareRow row1" style="margin-top: 20px">
            <el-col :span="6" class="wareCol">仓库</el-col>
            <el-col :span="6" class="wareCol">入库</el-col>
            <el-col :span="6" class="wareCol">出库</el-col>
            <el-col :span="6" class="wareCol">库存</el-col>
          </el-row>
          <el-row class="wareRow" v-for="(subItem,index) in item.value" :key="index">
            <el-col :span="6" class="wareCol">{{subItem.title}}</el-col>
            <el-col :span="6" class="wareCol">{{subItem.house_in_count}}</el-col>
            <el-col :span="6" class="wareCol">{{subItem.house_out_count}}</el-col>
            <el-col :span="6" class="wareCol">{{subItem.house_count}}</el-col>
          </el-row>
          <el-row class="wareRow">
            <el-col :span="6" class="wareCol">合计</el-col>
            <el-col :span="6" class="wareCol">{{house_in(item.id)}}</el-col>
            <el-col :span="6" class="wareCol">{{house_out(item.id)}}</el-col>
            <el-col :span="6" class="wareCol">{{house(item.id)}}</el-col>
          </el-row>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import * as skuCount from '../../interface/storeHouse/InventoryCount'
export default {
  name: 'InventoryCount',
  data () {
    return {
      skuCountList: []
    }
  },
  created () {
    this.getSkuCountList()
  },
  methods: {
    // 获取产品列表
    getSkuCountList: async function () {
      const data = await skuCount.skuCountList(this)
      if (data.code !== 0) return this.$message.error('获取库存盘点信息列表失败')
      console.log(data)
      this.skuCountList = data.result
    },
    house_in: function (id) {
      var list = this.skuCountList
      for (var i = 0; i < list.length; i++) {
        if (list[i].id === id) {
          var sumCount = 0
          for (const listKey in list[i].value) {
            sumCount = sumCount + parseInt(list[i].value[listKey].house_in_count)
          }
          return sumCount
        }
      }
    },
    house_out: function (id) {
      var list = this.skuCountList
      for (var i = 0; i < list.length; i++) {
        if (list[i].id === id) {
          var sumCount = 0
          for (const listKey in list[i].value) {
            sumCount = sumCount + parseInt(list[i].value[listKey].house_out_count)
          }
          return sumCount
        }
      }
    },
    house: function (id) {
      var list = this.skuCountList
      for (var i = 0; i < list.length; i++) {
        if (list[i].id === id) {
          var sumCount = 0
          for (const listKey in list[i].value) {
            sumCount = sumCount + parseInt(list[i].value[listKey].house_count)
          }
          return sumCount
        }
      }
    },
    detailedList: function (id, title) {
      this.$router.push({ path: 'detailedList', query: { detailId: id, title: title } })
    }
  }
}
</script>

<style scoped>
  .clearfix:before,
  .clearfix:after {
    display: table;
    content: "";
  }
  .clearfix:after {
    clear: both
  }
  .productList{
    padding: -20px !important;
    font-size: 14px;
    width: 100%;
    height: 40px;
    line-height: 40px;
    box-sizing: border-box;
    padding-left: 20px;
    text-align: left;
    display: block;
  }
  .active{
    background-color: #409EFF;
    color: white;
  }
  .el-card{
    border: none;
  }
  .wareCol{
    border-bottom: 1px solid #eee;
    border-right: 1px solid #eee;
    height: 40px;
    line-height: 40px;
    text-align: center;
  }
  .row1{
    border-top: 1px solid #eee;
  }
  .wareRow .wareCol:nth-child(1){
    border-left: 1px solid #eee;
  }
  .el-card:nth-child(2n){
    float: left;
  }
  .el-card:nth-child(2n+1){
    float: right;
  }
</style>
