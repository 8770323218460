// api 地址
const api = {
  falseList: 'admin/EquipmentFailure/list',
  equipmentTypeList: 'admin/equipmentType/getAll',
  EquipmentList: 'admin/equipment/list?equipment_type_id='
}
// 获取故障列表
export async function falseList (ele, param) {
  var { data } = await ele.$http.get(api.falseList + param)
  return data
}

// 获取产品列表
export async function equipmentTypeList (ele) {
  var { data } = await ele.$http.get(api.equipmentTypeList)
  return data
}

// 根据设备类型查询设备列表
export async function EquipmentList (ele, param) {
  var { data } = await ele.$http.get(api.EquipmentList + param)
  return data
}
export async function pageChange (ele, param) {
  for (var i in param) {
    var { data } = await ele.$http.get(api.falseList + '?' + i + '=' + param[i])
  }
  return data
}
export async function pageChanges (ele, param, pamams) {
  var p = []
  for (var i in param) {
    p.push(i + '=' + param[i])
  }
  var { data } = await ele.$http.get(api.falseList + '?' + p[0] + '&' + p[1] + '&' + pamams)
  return data
}
