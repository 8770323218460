<template>
    <div>
      <el-row style="margin: 0 10px">
        <el-col :span="2" style="font-size: 18px">故障列表</el-col>
        <el-col :span="5" style="float: right;">
          <!--面包屑导航-->
          <el-breadcrumb separator="/">
            <el-breadcrumb-item>设备管理</el-breadcrumb-item>
            <el-breadcrumb-item><a>故障列表</a></el-breadcrumb-item>
          </el-breadcrumb>
        </el-col>
      </el-row>
      <el-card>
        <el-row :gutter="20">
          <el-col :span="7">
            <el-input placeholder="机身编号" v-model="getTitle" clearable>
<!--              <template #append>-->
<!--                <el-button icon="el-icon-search" @click="searchList"></el-button>-->
<!--              </template>-->
            </el-input>
          </el-col>
          <el-col :span="7">
            <el-button type="primary" @click="searchList">查询</el-button>
          </el-col>
        </el-row>
        <el-table border :data="faultList" height="670px">
          <el-table-column label="序号" type="index" width="80px"></el-table-column>
          <el-table-column label="产品名称" prop="equipment_name"></el-table-column>
          <el-table-column label="机身编号" prop="equipment_no"></el-table-column>
          <el-table-column label="故障代码" prop="code"></el-table-column>
          <el-table-column label="报警时间" prop="create_time"></el-table-column>
        </el-table>
        <page :queryInfos="queryInfo" @pageChange="PageChange" queryUrl="deviceManage/DeviceList" :pamams="'equipment_no=' + getTitle"></page>
      </el-card>
    </div>
</template>

<script>
import * as device from '../../interface/deviceManage/DeviceList'
import Pagination from '../common/Pagination'

export default {
  name: 'FaultList',
  components: {
    page: Pagination
  },
  created () {
    this.getFaultList()
  },
  data: function () {
    return {
      faultList: [],
      getTitle: '',
      queryInfo: {
        query: '',
        pageNo: 1,
        pageSize: 2,
        total: 0
      }
    }
  },
  methods: {
    getFaultList: async function (p) {
      var p1 = ''
      if (p) p1 = '?equipment_no=' + p
      const data = await device.falseList(this, p1)
      if (data.code !== 0) return this.$message.error('获取异常设备列表失败')
      // console.log(data)
      this.faultList = data.result.data
      this.queryInfo.total = data.result.totalCount
      this.queryInfo.pageNo = data.result.pageNo
      this.queryInfo.pageSize = data.result.pageSize
    },
    searchList: function () {
      this.getFaultList(this.getTitle)
    },
    PageChange: function (e) {
      this.faultList = e.productList
      this.queryInfo = e.queryInfo
    }
  }
}
</script>

<style scoped>

</style>
