<template>
  <el-table border :data="productLists || productList" height="600px">
    <el-table-column :label="$t('home.xh')" type="index" width="80px"></el-table-column>
    <el-table-column
      v-for="item in this.allParams"
      :key="item.index"
      :label="item.title"
      :prop="item.prop"
    >
    </el-table-column >
    <el-table-column :label="$t('home.cz')" width="120px">
      <template #default="scope">
        <!--              编辑的按钮-->
        <editBtn
          :editId="scope.row.id"
          :editForm="editProductForm"
          :editRules="editProductFormRules"
          @gainMsg="PageChange"
          :editApi="addApi"
          :editProductFormLists="editProductFormList"
          :editName="addProductName"
        ></editBtn>
        <!--              删除的按钮-->
        <deleteBtn :deleteId="scope.row.id" @gainMsg="PageChange" :deleteApi="addApi"></deleteBtn>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import EditBtn from '../common/EditBtn'
import DeleteBtn from '../common/DeleteBtn'
export default {
  props: ['allParam', 'addProductNames', 'addApis', 'productLists'],
  components: {
    editBtn: EditBtn,
    deleteBtn: DeleteBtn
  },
  created () {
    this.getProductList()
    console.log('参数的值')
    console.log(this.productList)
    var params = this.allParam
    for (var i = 0; i < params.length; i++) {
      if (params[i].required) {
        this.editProductForm[params[i].prop] = ''
        var obj = {
          title: params[i].title,
          prop: params[i].prop
        }
        this.editProductFormList.push(obj)
        if (params[i].title !== 'ID') {
          var n = params[i].min
          var x = params[i].max
          this.editProductFormRules[params[i].prop] = [
            { required: true, message: `请输入${params[i].title}`, trigger: 'blur' },
            { min: n, max: x, message: `长度在 ${n} 到 ${x} 个字符`, trigger: 'blur' }
          ]
        }
      }
    }
    this.editProductForm.id = ''
    console.log('editProductForm的值')
    console.log(this.editProductFormRules)
  },
  data: function () {
    return {
      queryInfo: '',
      productList: [],
      allParams: this.allParam,
      editDialogVisible: false,
      addProductName: this.addProductNames,
      addApi: this.addApis,
      editProductForm: {},
      editProductFormList: [],
      editProductFormRules: {}
    }
  },
  methods: {
    PageChange: function () {
      this.getProductList()
    },
    getProductList: async function () {
      const resAll = await import('../../interface/' + this.addApi)
      const data = await resAll.productList(this, '')
      console.log(data)
      this.productList = data.result.data
      // this.total = data.result.total
      var queryInfo = {
        total: data.result.totalCount,
        pageNo: data.result.pageNo,
        pageSize: data.result.pageSize
      }
      this.$emit('queryInfo', { queryInfo: queryInfo, productList: data.result.data })
    }
  }
}
</script>

<style scoped>

</style>
