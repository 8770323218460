<template>
  <div>
    <el-row>
      <el-col :span="5" style="font-size: 18px">{{$t('role.jsgl')}}</el-col>
      <el-col :span="5" style="float: right;">
        <!--面包屑导航-->
        <el-breadcrumb separator="/">
          <el-breadcrumb-item>{{$t('role.xtgl')}}</el-breadcrumb-item>
          <el-breadcrumb-item><a>{{$t('role.jsgl')}}</a></el-breadcrumb-item>
        </el-breadcrumb>
      </el-col>
    </el-row>

    <!--卡片视图-->
    <el-card>
<!--      <el-button type="primary">添加角色</el-button>-->
      <addBtn
        @gainMsgs="PageChange"
        :addForm="addProductForm"
        :addRules="addProductRules"
        :addApi="path"
        :addProductFormLists="addProductFormList"
        :addName="addRoleName"
      ></addBtn>
      <el-table border stripe :data="roleList">
        <el-table-column type="index" :label="$t('home.xh')" width="80px"></el-table-column>
        <el-table-column :label="$t('role.jsmc')" prop="name"></el-table-column>
        <el-table-column :label="$t('role.jsms')" prop="desc"></el-table-column>
        <el-table-column :label="$t('home.cz')">
          <template #default="scope">
            <!--编辑的按钮-->
            <editBtn
              :editId="scope.row.id"
              :editForm="editProductForm"
              :addProductFormList="addProductFormList"
              :editRules="editProductFormRules"
              @gainMsg="PageChange"
              :editApi="path"
              :editProductFormLists="editProductFormList"
              :editName="addRoleName"
            ></editBtn>
            <!--删除的按钮-->
            <deleteBtn :deleteId="scope.row.id" @gainMsg="PageChange" :deleteApi="path"></deleteBtn>
            <!--分配权限-->
            <div style="margin-left: 10px;display: inline-block">
              <el-button size="mini" class="el-icon-setting" type="warning" @click="showSetRightDialog(scope.row)">
                {{$t('role.fpqx')}}</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <page :queryInfos="queryInfo" @pageChange="PageChanges" queryUrl="systemManage/RoleManage"></page>
    </el-card>

    <!--分配权限的对话框-->
    <el-dialog
      :title="$t('role.fpqx')"
      :visible.sync="setRightDialogVisble"
      width="50%"
      @close="setRightDialogClose"
    >
      <el-tree
        :data="rightsList"
        show-checkbox
        node-key="id"
        default-expand-all
        :default-checked-keys="defKeys"
        :props="treeProps"
        ref="treeRef"
      >
      </el-tree>

      <template #footer>
        <span class="dialog-footer">
          <el-button @click="setRightDialogVisble = false">{{$t('home.qx')}}</el-button>
          <el-button type="primary" @click="setRights">{{$t('home.qd')}}</el-button>
        </span>
      </template>
    </el-dialog>

  </div>

</template>

<script>
import * as role from '../../interface/systemManage/RoleManage'
import AddBtn from '../common/AddBtn'
import EditBtn from '../common/EditBtn'
import DeleteBtn from '../common/DeleteBtn'
import Pagination from '../common/Pagination'
import * as power from '../../interface/systemManage/PowerManage'
export default {
  name: 'RoleManage',
  components: {
    editBtn: EditBtn,
    deleteBtn: DeleteBtn,
    addBtn: AddBtn,
    page: Pagination
  },
  data () {
    return {
      path: 'systemManage/RoleManage',
      addProductForm: {
        name: '',
        desc: ''
      },
      addProductRules: {
        name: [
          { required: true, message: this.$t('role.qsrjsmc'), trigger: 'blur' },
          { min: 3, max: 10, message: this.$t('role.cdzf'), trigger: 'blur' }
        ],
        desc: [
          { required: true, message: this.$t('role.qsrjsms'), trigger: 'blur' },
          { min: 3, message: this.$t('role.cdzfm'), trigger: 'blur' }
        ]
      },
      addProductFormList: [
        {
          title: this.$t('role.jsmc'),
          prop: 'name'
        },
        {
          title: this.$t('role.jsms'),
          prop: 'desc'
        }
      ],
      editProductForm: {
        name: '',
        desc: ''
      },
      editProductFormRules: {
        name: [
          { required: true, message: this.$t('role.qsrjsmc'), trigger: 'blur' },
          { min: 3, max: 10, message: this.$t('role.cdzf'), trigger: 'blur' }
        ],
        desc: [
          { required: true, message: this.$t('role.qsrjsms'), trigger: 'blur' },
          { min: 3, message: this.$t('role.cdzfm'), trigger: 'blur' }
        ]
      },
      editProductFormList: [
        {
          title: this.$t('role.jsmc'),
          prop: 'name'
        },
        {
          title: this.$t('role.jsms'),
          prop: 'desc'
        }
      ],
      addRoleName: this.$t('role.js'),
      roleList: [],
      setRightDialogVisble: false, // 显示设置权限对话框
      rightsList: [], // 权限列表
      defKeys: [], // 默认选中的节点的id值
      treeProps: { // 树状控件属性绑定的对象
        label: 'name', // 通过label设置树状节点文本展示的值
        children: 'children' // 展示子节点的信息
      },
      roleId: '', // 当前正在操作的角色id
      queryInfo: {
        query: '',
        pageNo: 1,
        pageSize: 2,
        total: 0
      }
    }
  },
  created () {
    this.getRoleList()
  },
  methods: {
    getRoleList: async function () {
      const data = await role.roleList(this)
      this.roleList = data.result.data
      console.log(this.roleList)
      this.queryInfo.total = data.result.totalCount
      this.queryInfo.pageNo = data.result.pageNo
      this.queryInfo.pageSize = data.result.pageSize
    },
    PageChange () {
      this.getRoleList()
    },
    // 下一页
    PageChanges: function (e) {
      this.roleList = e.productList
      this.queryInfo = e.queryInfo
    },
    async showSetRightDialog (role) {
      // 记录当前正在操作的角色id
      this.roleId = role.id
      // 显示对话框
      this.setRightDialogVisble = true
      if (this.$i18n.locale === 'en-US') {
        this.treeProps = { // 树状控件属性绑定的对象
          label: 'name_english', // 通过label设置树状节点文本展示的值
          children: 'children' // 展示子节点的信息
        }
      } else {
        this.treeProps = { // 树状控件属性绑定的对象
          label: 'name', // 通过label设置树状节点文本展示的值
          children: 'children' // 展示子节点的信息
        }
      }
      // 获取默认选中的节点
      const roleData = await power.getRoleId(this, this.roleId)
      if (roleData.code !== 0) return this.$message.error(this.$t(role.hqjssb))
      console.log('获取当前权限的信息')
      console.log(roleData)
      this.defKeys = roleData.result.data
      // 获取所有权限的列表
      const data = await power.powerList(this)
      if (data.code !== 0) return this.$message.error(this.$t('role.hqqxsb'))
      this.rightsList = data.result
      // 将当前角色拥有的权限,加入到defKeys中
      this.getLeafKey(data, this.defKeys)
      console.log(this.defKeys)
    },
    // 获取所有选中权限的id,加入到defKeys数组中
    getLeafKey (node, arr) {
      console.log(node) // 角色.它是一个对象. 它拥有的权限是id
      if (!node.children) { // 当遍历到第三层权限时,它不再有children,就结束遍历
        return arr.push(node.id) // 记录当前角色所拥有的权限id. 只需记录三级权限即可
      }
      // 不断的遍历children
      node.children.forEach(item => this.getLeafKey(item, arr))
    },
    setRightDialogClose () {
      // 当对话框关闭时,将默认选中权限数组清空
      this.defKeys = []
    },
    async setRights () {
      const keys = [
        ...this.$refs.treeRef.getCheckedKeys(),
        ...this.$refs.treeRef.getHalfCheckedKeys()
      ]
      const idStr = keys
      var power = {
        role_id: this.roleId,
        node_ids: idStr
      }
      // 发起更新请求
      const res = await role.editPowerManage(this, power)
      // const { data: res } = await this.$http.post(`roles/${this.roleId}/rights`, { rids: idStr })
      console.log(res)
      if (res.code !== 0) return this.$message.error(this.$t('role.fpqxsb'))
      this.$message.success(this.$t('role.fpqxcg'))
      // 重新请求角色数据
      this.getRoleList()
      // 关闭对话框
      this.setRightDialogVisble = false
    }
  }
}
</script>

<style scoped>
  .el-tag {
    margin: 7px;
  }

  .bdtop {
    border-top: 1px solid #eee;
  }

  .bdbottom {
    border-bottom: 1px solid #eee;
  }

  .vcenter {
    display: flex; /*弹性盒子*/
    align-items: center;
  }
</style>
