// api 地址
const api = {
  exWareHousingList: 'admin/warehouseOut/list',
  exWareHousingAdd: 'admin/warehouseOut/add',
  wareHousingAllList: 'admin/warehouse/getAll',
  exWareHousingAllType: 'admin/warehouseOutType/getAll',
  productList: 'admin/equipmentType/getAll',
  wareHouseNo: 'admin/Tool/getOrderNoAndTime',
  equipmentInfo: 'admin/equipment/info',
  getWarehouseNos: 'admin/Tool/getOrderNoAndTime'
}

// 获取入库列表
export async function exWareHousingList (ele, param) {
  var { data } = await ele.$http.get(api.exWareHousingList + param)
  return data
}

// 添加产品
export async function exWareHousingAdd (ele, param) {
  var { data } = await ele.$http.post(api.exWareHousingAdd, param)
  return data
}

// 获取所有出库列表
export async function wareHousingAllList (ele) {
  var { data } = await ele.$http.get(api.wareHousingAllList)
  return data
}

// 获取所有出库类型
export async function exWareHousingAllType (ele) {
  var { data } = await ele.$http.get(api.exWareHousingAllType)
  return data
}

// 获取所有产品类型
export async function productList (ele) {
  var { data } = await ele.$http.get(api.productList)
  return data
}

// 获取所有出入库单号
export async function wareHouseNo (ele) {
  var { data } = await ele.$http.get(api.wareHouseNo)
  return data
}

// 获取所有设备的信息
export async function equipmentInfo (ele) {
  var { data } = await ele.$http.get(api.equipmentInfo)
  return data
}
// 获取出入库单号
export async function getWarehouseNo (ele) {
  var { data } = await ele.$http.get(api.getWarehouseNos)
  return data
}
export async function pageChanges (ele, param, pamams) {
  var p = []
  for (var i in param) {
    p.push(i + '=' + param[i])
  }
  var { data } = await ele.$http.get(api.exWareHousingList + '?' + p[0] + '&' + p[1] + '&' + pamams)
  return data
}
