const api = {
  MerchantList: 'admin/Merchant/list',
  addProduct: 'admin/Merchant/add',
  delProduct: 'admin/Merchant/del?id=',
  editProductDialog: 'admin/Merchant/info?id=',
  editProduct: 'admin/Merchant/edit'
}
// 获取商户列表
export async function MerchantList (ele) {
  const { data } = await ele.$http.get(api.MerchantList)
  return data
}
export async function pageChanges (ele, param) {
  var p = []
  for (var i in param) {
    p.push(i + '=' + param[i])
  }
  var { data } = await ele.$http.get(api.MerchantList + '?' + p[0] + '&' + p[1])
  return data
}
// 添加
export async function addProduct (ele, param) {
  const { data } = await ele.$http.post(api.addProduct, param)
  return data
}
// 删除
export async function delProduct (ele, param) {
  const { data } = await ele.$http.delete(api.delProduct + param)
  return data
}
// 编辑详情
export async function editProductDialog (ele, param) {
  const { data } = await ele.$http.get(api.editProductDialog + param)
  return data
}
// 编辑
export async function editProduct (ele, id, param) {
  const { data } = await ele.$http.put(api.editProduct + '?id=' + id, param)
  return data
}
