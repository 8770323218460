<template>
  <div style="display: inline-block;margin-right: 10px">
    <el-button type="primary" @click="uploadImg">{{isVideo == 1 ? $t('img.scsp') : $t('img.sctp')}}</el-button>
    <el-dialog :title="$t('img.xzwj')" :visible.sync="dialogVisible" width="70%" @close="addProductDialogClosed">
      <form action=" " method="post" enctype="multipart/form-data">
        <div :class="showSelect ? 'btnShows' : 'btnShow'">
          <div v-if="showSelect">
            <el-button type="primary"  @click="sureImg">{{$t('img.qr')}}</el-button>{{$t('img.djtp')}}
          </div>
          <div style="display:flex;justify-content: flex-end;align-items: center" v-if="!showSelect">
            <el-checkbox-group v-model="isAll" size="mini" @change="selectAll">
              <el-checkbox value="1" :label="$t('img.qx')"></el-checkbox>
            </el-checkbox-group>
            <el-button type="danger" size="mini" style="margin: 0 20px" @click="deleteImg">{{$t('home.sc')}}</el-button>
            <!--          <el-button type="primary" size="mini" style="width: 80px;height: 30px;position: relative">-->
            <!--            上传图片-->
            <!--            <input style="opacity: 0;position: absolute;top: 0;left: 0" type="file" name="imgs" @change="addImg" multiple />-->
            <!--          </el-button>-->
            <!--          <img :src="imgUrl" alt="">-->
            <!--        <div class="el-button el-button&#45;&#45;primary el-button&#45;&#45;mini" style="width: 100px;height:30px">上传图片-->
            <!--          <input style="opacity: 0;" type="file" @change="addImg" multiple />-->
            <!--        </div>-->
            <el-upload
              :headers="headerMsg"
              :data="form"
              class="upload-file"
              :action="url + '/admin/FileManagement/upload'"
              ref="upload"
              name="file"
              :on-success="successList"
              :on-error="failList"
              :on-progress="uploadVideoProcess"
              :show-file-list="false"
              :file-list="fileList"
              :accept="isVideo == 1 ? '.mp4, .MP4' : '.jpg, .jpge'"
              :before-upload="beforeUpload">
              <el-button type="primary" size="mini">{{isVideo == 1 ? $t('img.scsp') : $t('img.sctp')}}</el-button>
            </el-upload>
            <div v-if="isVideo == 1" slot="tip" class="el-upload__tip">{{$t('img.znscwj')}}</div>
            <div v-else slot="tip" class="el-upload__tip">{{$t('img.znsctwj')}}</div>
          </div>
        </div>
        <div style="padding-top: 20px">
          <el-row>
            <el-col :span="4">
              <div class="menuList">
                <div slot="header" class="clearfix" style="padding: 10px 20px;display: flex;align-items: center;justify-content: space-between">
                  <span>{{$t('home.fzgl')}}</span>
                  <el-button style="float: right; padding: 3px 0" type="text" @click="collapseBtn"><i class="el-icon-s-operation" style="font-size: 18px"></i></el-button>
                </div>
                <div v-for="(item,index) in GroupAll" :key="index" @click="chooseClick(index)" :class="{active:index==isActive}" :style="productListcss" class="productList">
                  <i class="el-icon-s-grid" style="padding-right: 10px"></i>{{item.name}}
                </div>
<!--                <el-menu style="min-height: 500px;"  :default-active="defaultID">-->
<!--&lt;!&ndash;                  <el-menu-item @click="handleOpen(0, 0)" index="0">&ndash;&gt;-->
<!--&lt;!&ndash;                    <i class="el-icon-menu"></i>&ndash;&gt;-->
<!--&lt;!&ndash;                    <span slot="title">全部分组</span>&ndash;&gt;-->
<!--&lt;!&ndash;                  </el-menu-item>&ndash;&gt;-->
<!--                  <el-menu-item v-for="(item, index) in GroupAll" :key="index" @click="handleOpen(index,item.id)">-->
<!--                    <i class="el-icon-menu"></i>-->
<!--                    <span slot="title">{{item.name}}</span>-->
<!--                  </el-menu-item>-->
<!--                </el-menu>-->
              </div>
            </el-col>
            <el-col :span="20">
              <div style="position: relative">
                <div class="showImg" style="min-height: 600px;">
                  <div class="imgModel" v-if="isVideo == 1 && videoFlag == true">
                    <el-progress :width="50" type="circle" :percentage="videoUploadPercent"></el-progress>
                  </div>
                  <div class="imgModel" v-for="(item, index) in imgList" :key="index" @click="select(index)">
                    <div class="deleteShow">
<!--                      <el-checkbox v-if="item.checked"></el-checkbox>-->
<!--                      <el-checkbox v-else checked></el-checkbox>-->
                      <el-checkbox v-if="item.checked" checked></el-checkbox>
                    </div>
                    <video muted controls v-if="isVideo == 1" :src="url + item.path" style="width: auto;height: auto;max-width: 200px;max-height: 250px"></video>
                    <img v-else :src="url + item.path" style="width: auto;height: auto;max-width: 200px;max-height: 250px">
                  </div>
                </div>
                <div class="pageShow">
                  <pagination :queryInfos="queryInfo" @pageChange="PageChange" queryUrl="Img/ImgManage" :pamams="'type=' + this.form.type + '&group_id=' + this.form.group_id"></pagination>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
        <div class="showBtnSure">
<!--          <el-button type="primary" @click="sureImg">确认</el-button>-->
        </div>
      </form>
    </el-dialog>
  </div>
</template>
<script>
import * as getImg from '../../interface/Img/ImgManage'
import Pagination from './Pagination'
export default {
  components: { Pagination },
  props: ['imgLists', 'type', 'isVideo', 'lastIndex'],
  data: function () {
    return {
      showSelect: false,
      url: this.url,
      dialogVisible: false,
      fileList: [],
      GroupAll: [],
      form: {
        type: this.isVideo === '1' ? 2 : 1,
        group_id: 0
      },
      imgs: '',
      imgUrl: '',
      headerMsg: { Authorization: window.sessionStorage.getItem('token') },
      formData: [],
      imgList: [],
      imgId: [],
      fileName: [],
      isAll: false,
      queryInfo: {
        query: '',
        pageNo: 1,
        pageSize: 2,
        total: 0
      },
      defaultID: '0',
      isActive: -1,
      productListcss: 'display:block',
      videoFlag: false,
      videoUploadPercent: 0
    }
  },
  created () {
    this.getGroupAll()
    this.selectImgs()
    // this.getList()
  },
  methods: {
    selectImgs: function () {
      this.imgId = []
      if (this.type === 'selectImg') {
        this.showSelect = true
      } else {
        this.showSelect = false
      }
    },
    beforeUpload: function (file) {
      const fileSuffix = file.name.substring(file.name.lastIndexOf('.') + 1)
      var flags = true
      if (this.isVideo === '1') {
        const whiteList = ['mp4', 'MP4']
        if (whiteList.indexOf(fileSuffix) === -1) {
          flags = false
          this.$message.error(this.$t('img.wjscgs'))
        }
      } else {
        const whiteList = ['jpg', 'jpge']
        if (whiteList.indexOf(fileSuffix) === -1) {
          flags = false
          this.$message.error(this.$t('img.wjsctgs'))
        }
      }
      const isM = file.size / 1024 / 1024 < 100
      if (!isM) {
        flags = false
        this.$message.error(this.$t('img.scwjdx'))
      }
      if (!flags) {
        if (!flags) return Promise.reject(flags)
      }
    },
    uploadVideoProcess: function (event, file, fileList) {
      // console.log('percentage', file)
      this.videoFlag = true
      this.videoUploadPercent = parseInt(file.percentage.toFixed(0))
    },
    // 点击按钮实现产品列表的显示和隐藏
    collapseBtn: function () {
      if (this.productListcss === 'display:block') {
        this.productListcss = 'display:none'
        return
      }
      this.productListcss = 'display:block'
    },
    PageChange: function (e) {
      // console.log(e)
      var imgList = e.productList
      for (var i = 0; i < imgList.length; i++) {
        imgList[i].checked = false
      }
      this.imgList = imgList
      this.queryInfo = e.queryInfo
    },
    // 删除
    deleteImg: async function () {
      console.log(this.imgId)
      for (var i = 0; i < this.imgId.length; i++) {
        this.delFile(this.imgId[i])
        // this.getList()
      }
      this.$message.success(this.$t('home.sccg'))
    },
    // 删除图片
    delFile: async function (id) {
      const data = await getImg.delFileManagement(this, id)
      if (data.code !== 0) return this.$message.error(data.msg)
      this.getList()
    },
    // 全选
    selectAll: function () {
      console.log(this.isAll)
      var imgList = this.imgList
      var imgId = []
      var fileName = []
      if (this.isAll) {
        for (var i = 0; i < imgList.length; i++) {
          imgList[i].checked = true
          imgId.push(imgList[i].id)
          fileName.push(imgList[i].nale)
        }
        this.imgList = imgList
        this.imgId = imgId
        this.fileName = fileName
      } else {
        for (var j = 0; j < imgList.length; j++) {
          imgList[j].checked = false
        }
        this.imgList = imgList
        this.imgId = []
        this.fileName = []
      }
    },
    isAllSelect: function () {
      var imgList = this.imgList
      for (var i = 0; i < imgList.length; i++) {
        if (!imgList[i].checked) {
          return false
        }
      }
      return true
    },
    delIndex: function (id) {
      for (var i = 0; i < this.imgId.length; i++) {
        if (this.imgId[i] === id) return i
      }
    },
    // 选择
    select: function (index) {
      this.$set(this.imgList[index], 'checked', !this.imgList[index].checked)
      // console.log(this.imgList)
      if (this.imgList[index].checked) {
        this.imgId.push(this.imgList[index].id)
        this.fileName.push(this.imgList[index].name)
      } else {
        this.imgId.splice(this.delIndex(this.imgList[index].id), 1)
        this.fileName.splice(this.delIndex(this.imgList[index].id), 1)
      }
      // console.log(this.imgId)
      this.isAll = this.isAllSelect()
      // var obj = document.getElementById('printMe')
      // obj.style.display = 'inline-table'
    },
    // 获取文件列表
    getList: async function () {
      var p = '?type=' + this.form.type + '&group_id=' + this.form.group_id
      const data = await getImg.FileManagement(this, p)
      if (data.code !== 0) return this.$message.error(data.msg)
      var imgList = data.result.data
      for (var i = 0; i < imgList.length; i++) {
        imgList[i].checked = false
      }
      this.imgList = imgList
      this.queryInfo.total = data.result.totalCount
      this.queryInfo.pageNo = data.result.pageNo
      this.queryInfo.pageSize = data.result.pageSize
    },
    successList: function (response, file, fileList) {
      this.videoFlag = false
      this.videoUploadPercent = 0
      console.log('response', response)
      console.log('file', file)
      console.log('fileList', fileList)
      if (response.code !== 0) return this.$message.error(response.msg)
      this.$message.success(this.$t('devDetail.sccg'))
      this.imgUrl = this.url + response.result.data.path
      this.getList()
    },
    failList: function (err, file, fileList) {
      this.videoFlag = false
      this.videoUploadPercent = 0
      console.log('err', err)
      console.log('file', file)
      console.log('fileList', fileList)
      // let myError = err.toString()
      // myError = myError.replace('Error: ', '')
      // myError = JSON.parse(myError)
      // console.log(myError)
      this.$message.error(this.$t('img.scsb'))
      this.getList()
    },
    // 获取全部分组
    getGroupAll: async function () {
      const data = await getImg.fileGroupAll(this)
      if (data.code !== 0) return this.$message.error(data.msg)
      this.GroupAll = data.result
      this.chooseClick(0)
      // this.getList()
    },
    // 选择
    chooseClick: function (index) {
      console.log(index)
      this.isActive = index
      this.form.group_id = this.GroupAll[index].id
      // this.name = this.GroupAll[index].name
      this.getList()
    },
    // 左边菜单
    handleOpen (index, id) {
      console.log(id)
      // this.form.group_id = id
      this.form.group_id = this.GroupAll[index].id
      this.getList()
      this.defaultID = index + ''
    },
    uploadImg: function () {
      this.dialogVisible = true
      console.log(this.group_id)
      if (this.imgLists) this.imgList = this.imgLists
      if (this.lastIndex || this.lastIndex === 0) this.chooseClick(this.lastIndex)
    },
    addProductDialogClosed: function () {
      this.dialogVisible = false
      // this.imgId = []
      this.$emit('getList')
    },
    sureImg: function () {
      if (this.type === 'selectImg') {
        console.log(this.imgId)
        if (this.imgId.length > 1) return this.$message.error(this.$t('img.dqxzsl') + this.imgId.length + this.$t('img.qxzsl'))
        var arr = {
          file_name: this.fileName[0],
          file_id: this.imgId[0]
        }
        this.$emit('getList', arr)
      } else {
        this.$emit('getList')
      }
      this.dialogVisible = false
    }
  }
}
</script>
<style>
  .productList{
    padding: -20px !important;
    font-size: 14px;
    width: 100%;
    height: 40px;
    line-height: 40px;
    box-sizing: border-box;
    padding-left: 20px;
    text-align: left;
    display: block;
  }
  .active{
    background-color: #409EFF;
    color: white;
  }
  .btnShow{
    border-top: 1px solid #eeeeee;
    border-bottom: 1px solid #eeeeee;
    padding: 20px 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .btnShows{
    border-top: 1px solid #eeeeee;
    border-bottom: 1px solid #eeeeee;
    padding: 20px 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .menuList{
    border: 1px solid #eeeeee;
  }
  .showImg{
    display: flex;
    flex-wrap: wrap;
  }
  .imgModel{
    width: 200px;
    height: 250px;
    border: 1px solid #eeeeee;
    margin-left: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }
  .deleteShow{
    position: absolute;
    top: -4px;
    right: 0;
    z-index: 999;
  }
  .showBtnSure{
    margin-top: 80px;
    border-top: 1px solid #eeeeee;
    padding: 20px 0;
    display: flex;
    justify-content: center;
  }
  .pageShow{
    width: 100%;
    position: absolute;
    bottom: -50px;
    margin: 0 auto;
  }
  .el-pagination {
    display: flex;
    justify-content: center;
  }
</style>
