<template>
  <div>
    <el-row style="margin: 0 10px">
      <el-col :span="2" style="font-size: 18px">门店管理</el-col>
      <el-col :span="5" style="float: right;">
        <!--面包屑导航-->
        <el-breadcrumb separator="/">
          <el-breadcrumb-item>商户管理</el-breadcrumb-item>
          <el-breadcrumb-item><a>门店管理</a></el-breadcrumb-item>
        </el-breadcrumb>
      </el-col>
    </el-row>
    <el-card>
      <el-form :inline="true" :model="queryInfo" class="demo-form-inline">
        <el-form-item label="门店名称">
          <el-input v-model="queryInfo.name" placeholder="门店名称"></el-input>
        </el-form-item>
        <el-form-item label="状态">
          <el-select v-model="queryInfo.status" placeholder="状态">
            <el-option label="正常" :value="0"></el-option>
            <el-option label="禁用" :value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="getList('?status=' + queryInfo.status + '&name=' + queryInfo.name)">查询</el-button>
        </el-form-item>
      </el-form>
      <el-button type="primary" @click="addList">添加门店</el-button>
      <el-table
        ref="filterTable"
        :data="shopListData"
        border
        style="width: 100%">
        <el-table-column prop="id" label="编号" width="80px"></el-table-column>
        <el-table-column prop="name" label="门店名称"></el-table-column>
<!--        <el-table-column prop="tel" label="联系电话"></el-table-column>-->
<!--        <el-table-column prop="contacts" label="联系人"></el-table-column>-->
<!--        <el-table-column prop="addressDetail" label="地址" width="290px"></el-table-column>-->
<!--        <el-table-column prop="lngAndLat" label="经纬度"></el-table-column>-->
        <el-table-column prop="merchant_name" label="所属商户"></el-table-column>
        <el-table-column prop="agent_name" label="所属经销商"></el-table-column>
        <el-table-column prop="bonus" label="分成"></el-table-column>
        <el-table-column prop="bonus_show" label="显示分成"></el-table-column>
        <el-table-column prop="blanceed" label="佣金">
          <template #default="scope">
            <p style="color: #67c23a;font-weight: bold">{{scope.row.blanceed}}</p>
          </template>
        </el-table-column>
        <el-table-column prop="statused" label="状态"></el-table-column>
        <el-table-column label="操作" width="250px">
          <template #default="scope">
            <el-button type="primary" size="mini" @click="editList(scope.row.id)" style="margin-top: 10px;margin-left: 15px">编辑</el-button>
            <el-button type="danger" size="mini" @click="deleteCase(scope.row.id)" style="margin-top: 10px">删除</el-button>
<!--            <el-button type="primary" size="mini" style="margin-top: 10px">设置分成</el-button>-->
            <el-button type="primary" size="mini" style="margin-top: 10px" @click="goOrder">查看订单</el-button>
          </template>
        </el-table-column>
      </el-table>
      <page :queryInfos="queryInfo" @pageChange="PageChanges" query-url="agentManage/ShopList" :pamams="'status=' + queryInfo.status + '&name=' + queryInfo.name"></page>
    </el-card>
<!--    添加门店-->
    <div class="addPop" v-if="addShopPop">
      <el-dialog
        :title="addType == 'update' ? '编辑门店' : '添加门店'"
        :visible.sync="addShopPop"
        width="50%"
        @close="addClosed"
      >
        <el-form label-width="115px" :model="addForm" :rules="addFormRules" ref="addFormRef">
          <el-form-item label="门店名称" prop="name">
            <el-input v-model="addForm.name"></el-input>
          </el-form-item>
          <el-form-item label="代理登陆账号" prop="mobile">
            <el-input v-model="addForm.mobile"></el-input>
          </el-form-item>
          <el-form-item label="代理登陆密码" prop="password">
            <el-input v-model="addForm.password"></el-input>
          </el-form-item>
          <el-form-item label="状态" prop="status">
            <el-radio-group v-model="addForm.status">
              <el-radio :label="0">正常</el-radio>
              <el-radio :label="1">禁用</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="所属商户">
            <el-select v-model="addForm.merchant_id" placeholder="请选择商户">
              <el-option v-for="(item, index) in getMerchantAll" :key="index" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="所选套餐">
            <el-select v-model="addForm.package_id" placeholder="请选择套餐">
              <el-option v-for="(item, index) in packageAll" :key="index" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="经度" prop="lng">
            <el-input v-model="addForm.lng"></el-input>
          </el-form-item>
          <el-form-item label="纬度" prop="lat">
            <el-input v-model="addForm.lat"></el-input>
          </el-form-item>
          <el-form-item label="联系人" prop="contacts">
            <el-input v-model="addForm.contacts"></el-input>
          </el-form-item>
          <el-form-item label="联系电话" prop="tel">
            <el-input v-model="addForm.tel" type="number"></el-input>
          </el-form-item>
          <el-form-item label="联系人地址" class="inputAddress" prop="pcda">
            <el-cascader size="large" :placeholder="titleShow" :options="options" v-model="selectedOptions" @change="handleChange"></el-cascader>
            <el-input v-model="addForm.address" placeholder="请输入详细地址"></el-input>
          </el-form-item>
          <getPop :agent_id="dataName" @getId="getId"></getPop>
          <el-form-item label="分成" prop="bonus">
            <el-input v-model="addForm.bonus"></el-input>
            <span style="margin-left: 10px">单位%</span>
          </el-form-item>
          <el-form-item label="显示分成" prop="bonus_show">
            <el-input v-model="addForm.bonus_show"></el-input>
            <span style="margin-left: 10px">单位%</span>
          </el-form-item>
<!--          <el-form-item label="登陆账号" prop="login_name">-->
<!--            <el-input v-model="addForm.login_name"></el-input>-->
<!--          </el-form-item>-->
<!--          <el-form-item label="登陆密码" prop="login_password">-->
<!--            <el-input v-model="addForm.login_password"></el-input>-->
<!--          </el-form-item>-->
          <el-form-item>
            <el-button v-if="addType == 'update'" :type="isBtn ? 'primary' : ''" @click="onSubmitEdit('addForm')">编辑</el-button>
            <el-button v-else :type="isBtn ? 'primary' : ''" @click="onSubmit('addForm')">添加</el-button>
            <el-button @click="resetForm('addForm')">重置</el-button>
          </el-form-item>
        </el-form>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import Pagination from '../common/Pagination'
import GetPop from '../common/getPop'
import * as ShopList from '../../interface/agentManage/ShopList'
import { regionData, CodeToText } from 'element-china-area-data'
import * as agentList from '../../interface/agentManage/mercantileAgent'

export default {
  name: 'Shop',
  components: {
    page: Pagination,
    getPop: GetPop
  },
  data: function () {
    return {
      titleShow: '请选择地址',
      form: {
        status: ''
      },
      queryInfo: {
        name: '',
        status: '',
        query: '',
        query1: '',
        pageNo: 1,
        pageSize: 2,
        total: 0
      },
      shopListData: [],
      addShopPop: false,
      addForm: {},
      options: regionData,
      selectedOptions: [],
      addType: '',
      addFormRules: {
        name: [
          { required: true, message: '请输入门店名称', trigger: 'blur' },
          { min: 2, message: '长度为至少2个字符', trigger: 'blur' }
        ],
        status: [
          { required: true, message: '请选择状态', trigger: 'blur' }
        ],
        contacts: [
          { required: true, message: '请输入联系人', trigger: 'blur' }
        ],
        tel: [
          { required: true, message: '请输入联系电话', trigger: 'blur' }
        ],
        agent_id: [
          { required: true, message: '请选择所属经销商', trigger: 'blur' }
        ],
        bonus: [
          { required: true, message: '请输入分成', trigger: 'blur' }
        ],
        bonus_show: [
          { required: true, message: '请输入显示分成', trigger: 'blur' }
        ],
        login_name: [
          { required: true, message: '请输入登陆账号', trigger: 'blur' },
          { min: 2, message: '长度为至少2个字符', trigger: 'blur' }
        ],
        login_password: [
          { required: true, message: '请输入登陆密码', trigger: 'blur' },
          { min: 6, message: '登陆密码长度不能少于6', trigger: 'blur' }

        ]
      },
      dataName: '',
      dataId: '',
      getMerchantAll: [],
      packageAll: [],
      isBtn: true
    }
  },
  created () {
    this.getList()
    this.getAllMerchant()
    this.getPackageAll()
  },
  methods: {
    // 获取全部套餐
    getPackageAll: async function () {
      const data = await ShopList.getAllPackage(this)
      if (data.code !== 0) return this.$message.error('获取全部套餐失败')
      this.packageAll = data.result
    },
    // 获取全部商户
    getAllMerchant: async function () {
      const data = await agentList.getAllMerchant(this)
      if (data.code !== 0) return this.$message.error('获取全部商户')
      this.getMerchantAll = data.result
    },
    // 查看订单
    goOrder: function () {
      this.$router.push('SalesOrder')
    },
    getId: function (res) {
      console.log(res)
      this.dataName = res.dataName
      this.addForm.agent_id = res.dataId
    },
    PageChange () {
      this.getList()
    },
    PageChanges: function (e) {
      this.shopListData = e.productList
      this.queryInfo = e.queryInfo
    },
    // 获取门店数据
    getList: async function (e) {
      var p = ''
      if (e) p = e
      const data = await ShopList.getShopList(this, p)
      console.log(data)
      this.shopListData = data.result.data
      var that = this
      that.shopListData.forEach(function (item, index, array) {
        that.shopListData[index].addressDetail = item.province + item.city + item.district + item.address
        that.shopListData[index].lngAndLat = '(' + item.lng + ',' + item.lat + ')'
        that.shopListData[index].bonus = item.bonus + '%'
        that.shopListData[index].bonus_show = item.bonus_show + '%'
        that.shopListData[index].blanceed = '￥' + item.blance
        that.shopListData[index].statused = item.status === 0 ? '正常' : '禁用'
      })
      that.queryInfo.total = data.result.totalCount
      that.queryInfo.pageNo = data.result.pageNo
      that.queryInfo.pageSize = data.result.pageSize
    },
    // 地址
    handleChange: function (value) {
      console.log(value)
      this.addForm.province = CodeToText[value[0]]
      this.addForm.city = CodeToText[value[1]]
      this.addForm.district = CodeToText[value[2]]
    },
    addList: function () {
      this.addShopPop = true
      this.isBtn = true
      this.addType = 'add'
      this.titleShow = '请选择'
      this.dataName = ''
      this.addForm = {}
    },
    // 编辑门店弹窗
    editList: async function (e) {
      this.addShopPop = true
      this.isBtn = true
      this.addType = 'update'
      const data = await ShopList.getShopInfo(this, e)
      this.addForm = data.result
      this.titleShow = data.result.province + '/' + data.result.city + '/' + data.result.district + '/' + data.result.address
      this.dataName = data.result.agent_name
      delete this.addForm.create_time
      delete this.addForm.update_time
      delete this.addForm.password
      console.log(data)
    },
    // 删除门店
    deleteCase: function (e) {
      this.$confirm('是否确认删除', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const res = await ShopList.deleteShopList(this, e)
        if (res.code !== 0) return this.$message.error('删除失败')
        this.$message.success('删除成功')
        this.getList()
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    // 关闭弹窗
    addClosed: function () {
      this.$refs.addFormRef.resetFields()
    },
    // 添加门店
    onSubmit: function (addForm) {
      if (!this.isBtn) return false
      this.isBtn = false
      if (this.addForm.province === '' || this.addForm.city === '' || this.addForm.district === '' || this.addForm.address === '') {
        this.isBtn = true
        return this.$message.error('请填写完整地址')
      }
      this.$refs.addFormRef.validate(async valid => {
        if (!valid) {
          this.isBtn = true
          return this.$message.error('请填写完整信息')
        }
        const data = await ShopList.addShopList(this, this.addForm)
        console.log(data)
        if (data.code !== 0) {
          this.isBtn = true
          return this.$message.error('添加失败')
        }
        this.$message.success('添加成功')
        this.addShopPop = false
        this.isBtn = true
        this.getList()
      })
    },
    // 编辑门店
    onSubmitEdit: function () {
      if (!this.isBtn) return false
      this.isBtn = false
      if (this.addForm.province === '' || this.addForm.city === '' || this.addForm.district === '' || this.addForm.address === '') {
        this.isBtn = true
        return this.$message.error('请填写完整地址')
      }
      this.$refs.addFormRef.validate(async valid => {
        if (!valid) {
          this.isBtn = true
          return this.$message.error('请填写完整信息')
        }
        const data = await ShopList.updateShopList(this, this.addForm)
        console.log(data)
        if (data.code !== 0) {
          this.isBtn = true
          return this.$message.error('编辑失败')
        }
        this.$message.success('编辑成功')
        this.addShopPop = false
        this.isBtn = true
        this.getList()
      })
    }
  }
}
</script>

<style scoped>
  .el-tag + .el-tag {
    margin-left: 10px;
  }
  .button-new-tag {
    margin-left: 10px;
    height: 32px;
    line-height: 30px;
    padding-top: 0;
    padding-bottom: 0;
  }
  .input-new-tag {
    width: 90px;
    margin-left: 10px;
    vertical-align: bottom;
  }
  .el-cascader {
    display: inline;
    margin-bottom: 10px;
  }
  .el-input, .el-select, .el-cascader  {
    width: 70% !important;
    max-width: 620px;
    float: left;
  }
</style>
