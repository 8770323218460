<template>
  <div>
    <el-row style="margin: 0 10px">
      <el-col :span="2" style="font-size: 18px">业务员</el-col>
      <el-col :span="5" style="float: right;">
        <!--面包屑导航-->
        <el-breadcrumb separator="/">
          <el-breadcrumb-item>商户管理</el-breadcrumb-item>
          <el-breadcrumb-item><a>业务员</a></el-breadcrumb-item>
        </el-breadcrumb>
      </el-col>
    </el-row>
    <el-card>
      <el-form :inline="true" :model="data" class="demo-form-inline">
        <el-form-item label="业务员名称">
          <el-input v-model="data.name" placeholder="业务员名称"></el-input>
        </el-form-item>
        <el-form-item label="手机号">
          <el-input v-model="data.phone" placeholder="手机号"></el-input>
        </el-form-item>
        <el-form-item label="状态">
          <el-select v-model="data.region" placeholder="状态">
            <el-option label="正常" value="0"></el-option>
            <el-option label="禁用" value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="getList">查询</el-button>
        </el-form-item>
      </el-form>
      <addPop :isBtns="true" addType="salesman" @getLists="getList"></addPop>
      <el-table
        ref="filterTable"
        :data="agentListData"
        border
        style="width: 100%">
        <el-table-column prop="id" label="编号" width="80px"></el-table-column>
        <el-table-column prop="name" label="名称"></el-table-column>
        <el-table-column prop="number" label="联系电话"></el-table-column>
        <el-table-column prop="consignee" label="联系人"></el-table-column>
        <el-table-column prop="addressDetail" label="地址" width="290px"></el-table-column>
        <el-table-column prop="bonused" label="佣金比例"></el-table-column>
        <el-table-column prop="blanceed" label="佣金">
          <template #default="scope">
            <p style="color: #67c23a;font-weight: bold">{{scope.row.blanceed}}</p>
          </template>
        </el-table-column>
        <el-table-column prop="statused" label="状态"></el-table-column>
        <el-table-column prop="typeed" label="类型"></el-table-column>
        <el-table-column prop="parentName" label="上级" width="270px"></el-table-column>
        <el-table-column label="操作" width="175px">
          <template #default="scope">
            <addPop :isBtns="true" addType="update" isUpdate="salesman" :Id="scope.row.id" @getLists="getList"></addPop>
            <!--            <el-button type="primary" size="mini" @click="showEquipment(scope.row.id)" style="margin-top: 10px">所属设备</el-button>-->
            <!--            <el-button type="success" size="mini" @click="statistics(scope.row.id)" style="margin-top: 10px">统计</el-button>-->
            <el-button type="danger" size="mini" @click="deleteCase(scope.row.id)" style="margin-top: 10px">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <Pagination :queryInfos="queryInfo" @pageChange="PageChanges" query-url="agentManage/salesman" :pamams="'phone=' + queryInfo.phone + '&name=' + queryInfo.name + '&status=' + queryInfo.region"></Pagination>
    </el-card>
  </div>
</template>

<script>
import * as agentList from '../../interface/agentManage/mercantileAgent'
import AddPop from '../common/AddPop'
import Pagination from '../common/Pagination'
export default {
  name: 'Salesman',
  components: {
    Pagination,
    addPop: AddPop
  },
  data: function () {
    return {
      agentListData: [],
      form: {},
      name: [],
      queryInfo: {
        region: '',
        pageNo: 1,
        pageSize: 2,
        total: 0
      },
      data: {}
    }
  },
  created () {
    this.getList()
  },
  methods: {
    PageChanges: function (e) {
      var agentListData = e.productList
      for (var i = 0; i < agentListData.length; i++) {
        agentListData[i].addressDetail = agentListData[i].province + agentListData[i].city + agentListData[i].district + agentListData[i].address
        agentListData[i].bonused = agentListData[i].bonus + '%'
        agentListData[i].blanceed = '￥' + agentListData[i].blance
        agentListData[i].statused = agentListData[i].status === 0 ? '正常' : '禁用'
        agentListData[i].typeed = agentListData[i].type === 0 ? '个人' : '企业'
      }
      this.agentListData = agentListData
      this.queryInfo = e.queryInfo
    },
    // 查询业务员
    getList: async function (e) {
      var p = '&name=' + (this.data.name || '') + '&number=' + (this.data.phone || '') + '&status=' + (this.data.region || '')
      const res = await agentList.getSalesman(this, p)
      if (res.code !== 0) return this.$message.error('获取业务员信息失败')
      var agentListData = res.result.data
      for (var i = 0; i < res.result.data.length; i++) {
        agentListData[i].addressDetail = res.result.data[i].province + res.result.data[i].city + res.result.data[i].district + res.result.data[i].address
        agentListData[i].bonused = res.result.data[i].bonus + '%'
        agentListData[i].blanceed = '￥' + agentListData[i].blance
        agentListData[i].statused = res.result.data[i].status === 0 ? '正常' : '禁用'
        agentListData[i].typeed = res.result.data[i].type === 0 ? '个人' : '企业'
      }
      this.agentListData = agentListData
      console.log(this.agentListData)
    },
    // 设备
    showEquipment: function () {

    },
    // 统计
    statistics: function () {

    },
    // 删除
    deleteCase: function (e) {
      console.log(e)
      this.$confirm('是否确认删除', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const res = await agentList.deleteAgentList(this, e)
        if (res.code !== 0) return this.$message.error('删除失败')
        this.$message.success('删除成功')
        this.getList()
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    // 返回
    returnMsg: function () {
      this.$router.go(-1)
    }
  }
}
</script>

<style scoped>
  .el-tag + .el-tag {
    margin-left: 10px;
  }
  .button-new-tag {
    margin-left: 10px;
    height: 32px;
    line-height: 30px;
    padding-top: 0;
    padding-bottom: 0;
  }
  .input-new-tag {
    width: 90px;
    margin-left: 10px;
    vertical-align: bottom;
  }
</style>
