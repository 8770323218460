<template>
  <div class="login_container">
    <!--登录左侧的盒子-->
    <div class="loginLeft">
      <!--      <img src="../assets/login-bg4.svg" alt="">-->
    </div>
    <!--登录右侧的盒子-->
    <div class="loginRight">
      <div class="login_box">
        <!--头像-->
        <!--        <div class="avatar_box">-->
        <!--          <img src="../assets/logo.png" alt="">-->
        <!--        </div>-->
        <div class="title">{{$t('login.htglxt')}}</div>
        <el-form class="login_form" :model="loginForm" :rules="loginFormRules" ref="loginFormRef">
          <el-form-item prop="username">
            <el-input v-model="loginForm.username" type="text" :placeholder="$t('login.qsryhm')" style="width: 100% !important;">
              <i slot="suffix" class="el-icon-user-solid" style="padding: 0 15px;font-size: 18px"></i>
            </el-input>
          </el-form-item>
          <el-form-item prop="password">
            <el-input v-model="loginForm.password" style="width: 100% !important;" type="password" :placeholder="$t('admin.qsrmm')" @keyup.enter.native="login">
              <i slot="suffix" class="el-icon-s-goods" style="padding: 0 15px;font-size: 18px"></i>
            </el-input>
          </el-form-item>
          <el-form-item class="btns">
            <el-button type="primary" @click="login" style="margin-right: 30px">{{$t('login.dl')}}</el-button>
            <el-button type="info" @click="resetLoginForm">{{$t('login.cz')}}</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import * as login from '../interface/Login'

export default {
  name: 'Login',
  data: function () {
    return {
      loginForm: {
        username: '',
        password: ''
      },
      /* 表单数据的前端验证规则 */
      loginFormRules: {
        username: [
          {
            required: true,
            message: this.$t('login.qsryhm'),
            trigger: 'blur'
          },
          {
            min: 3,
            max: 10,
            message: this.$t('role.cdzf'),
            trigger: 'blur'
          }
        ],
        password: [
          {
            required: true,
            message: this.$t('admin.qsrmm'),
            trigger: 'blur'
          },
          {
            min: 6,
            max: 15,
            message: this.$t('login.zfcd'),
            trigger: 'blur'
          }
        ]
      }
    }
  },
  methods: {
    // 重置表单
    resetLoginForm: function () {
      // this  当前组件对象.
      // $refs 当前组件对象的所有引用. 取这个表单来操作
      // 重置表单
      this.$refs.loginFormRef.resetFields()
    },
    login: function () {
      // 点击登录按钮,也需要进行表单验证
      this.$refs.loginFormRef.validate(async valid => {
        // valid  这个参数代表验证的结果. 通过验证则返回true,不通过则返回false
        // console.log(valid)
        // 验证不通过,则直接结束本函数
        if (!valid) { // 完全等同于valid === false
          return false
        }
        // 通过表单验证,发送登录请求. 解构对象,获取返回值(响应)里面的data数据
        console.log('登录')
        console.log(login)
        const data = await login.loginCheck(this, this.loginForm)
        // const { data } = await this.$http.post('admin/login', this.loginForm)
        console.log(data)
        // 登录失败的提示
        if (data.code !== 0) {
          // 使用提示框组件. 并且直接结束函数
          return this.$message.error('Login failed')
        }
        // 不进if 代表登录成功
        this.$message.success('Login successful')

        // 登录成功,需要保存token,后续所有请求都要带上. 这个token代表你登录成功
        // 使用sessionStorage就意味着,当浏览器关闭,就会清空
        window.sessionStorage.setItem('token', data.result.token)
        // 登录成功,跳转到/home
        this.setName()
        // this.$router.push('/home')
      })
    },
    setName: async function () {
      const data = await login.getAdminUserInfo(this)
      if (data.code !== 0) return this.$message.error(data.msg)
      console.log(data)
      window.sessionStorage.setItem('name', data.result.name)
      window.sessionStorage.setItem('item_id', data.result.item_id)
      this.$router.push('/home')
    }
  }
}
</script>

<style lang="less" scoped>
  .el-input--prefix .el-input__inner{
    padding-left: 20px !important;
  }
  /*scoped 让这里的样式只会在本组件生效*/
  .login_container {
    background-color: #eaedf1;
    height: 100%;
    background-image: url('../assets/login.jpg');
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
  /*登录左侧*/
  .loginLeft{
    width: 50%;
    height: 100%;
    float: left;
    overflow: hidden;
    img{
      width: 100%;
      overflow: hidden;
    }
  }
  .loginRight{
    width: 50%;
    height: 100%;
    /*background-color: #eaedf1;*/
    float: left;
    position: relative;
  }
  .title{
    font-size: 25px;
    text-align: center;
    height: 130px;
    line-height: 130px;
  }
  /*登录右侧*/
  .login_box {
    width: 450px;
    height: 300px;
    background: white;
    border-radius: 3px;
    /*在父盒子中移动50%*/
    position: absolute;
    left: 60%;
    top: 50%;
    /*向左上角平移自己宽高的一半*/
    transform: translate(-50%, -50%);
    border: 10px solid #eeeeee;
  }

  .login_form {
    position: absolute;
    padding: 0 40px;
    width: 100%;
    box-sizing: border-box;
    bottom: 0;
  }

  .btns {
    display: flex;
    justify-content: flex-end;
    margin-top: 30px;
  }
</style>
