<template>
  <div>
    <el-row style="margin: 0 10px">
      <el-col :span="2" style="font-size: 18px">统计列表</el-col>
      <el-col :span="5" style="float: right;">
        <!--面包屑导航-->
        <el-breadcrumb separator="/">
          <el-breadcrumb-item>生产管理</el-breadcrumb-item>
          <el-breadcrumb-item><a>统计列表</a></el-breadcrumb-item>
        </el-breadcrumb>
      </el-col>
    </el-row>
    <el-card>
      <el-row style="margin-top: -15px">
        <p>统计列表</p>
        <el-button type="primary" @click="bindList">绑定变更列表</el-button>
        <el-button type="primary" @click="moduleList">模块列表</el-button>
        <el-button type="primary" @click="finishList">成品列表</el-button>
      </el-row>
    </el-card>
  </div>
</template>

<script>
export default {
  name: 'CountList',
  data: function () {
    return {
      form: []
    }
  },
  methods: {
    bindList: function () {
      this.$router.push('bindList')
    },
    moduleList: function () {
      this.$router.push('moduleList')
    },
    finishList: function () {
      this.$router.push('finishList')
    }
  }
}
</script>

<style scoped>

</style>
