// api 地址
const api = {
  wareHousingList: 'admin/warehouseIn/list',
  wareHousingAdd: 'admin/warehouseIn/add',
  wareHousingAllList: 'admin/warehouse/getAll',
  wareHousingAllType: 'admin/warehouseInType/getAll',
  productList: 'admin/equipmentType/getAll',
  wareHouseNo: 'admin/warehouseIn/getWarehouseNo',
  wareHouseDetail: 'admin/warehouseIn/info?id='
}
// 获取入库列表
export async function wareHousingList (ele, param) {
  var { data } = await ele.$http.get(api.wareHousingList + param)
  return data
}

// 获取根据页面显示的条数(页数)来查询产品列表
export async function pageChange (ele, param) {
  for (var i in param) {
    var { data } = await ele.$http.get(api.wareHousingList + '?' + i + '=' + param[i])
  }
  return data
}

// 添加产品
export async function wareHousingAdd (ele, param) {
  var { data } = await ele.$http.post(api.wareHousingAdd, param)
  return data
}

// 获取所有入库列表
export async function wareHousingAllList (ele) {
  var { data } = await ele.$http.get(api.wareHousingAllList)
  return data
}

// 获取所有入库类型
export async function wareHousingAllType (ele) {
  var { data } = await ele.$http.get(api.wareHousingAllType)
  return data
}

// 获取所有产品类型
export async function productList (ele) {
  var { data } = await ele.$http.get(api.productList)
  return data
}

// 获取所有出入库单号
export async function wareHouseNo (ele) {
  var { data } = await ele.$http.get(api.wareHouseNo)
  return data
}

// 获取入库的详情
export async function wareHouseDetail (ele, id) {
  var { data } = await ele.$http.get(api.wareHouseDetail + id)
  return data
}
export async function pageChanges (ele, param, pamams) {
  var p = []
  for (var i in param) {
    p.push(i + '=' + param[i])
  }
  var { data } = await ele.$http.get(api.wareHousingList + '?' + p[0] + '&' + p[1] + '&' + pamams)
  return data
}
