// 地址
const api = {
  ItemList: 'admin/Item/list',
  addProduct: 'admin/Item/add',
  editProductDialog: 'admin/Item/info?id=',
  editProduct: 'admin/Item/edit',
  delItem: 'admin/Item/del?id='
}
// 获取列表
export async function ItemList (ele, params) {
  const { data } = await ele.$http.get(api.ItemList + params)
  return data
}
export async function pageChanges (ele, param, pamams) {
  var p = []
  for (var i in param) {
    p.push(i + '=' + param[i])
  }
  var { data } = await ele.$http.get(api.ItemList + '?' + p[0] + '&' + p[1] + '&' + pamams)
  return data
}
// 添加
export async function addProduct (ele, params) {
  const { data } = await ele.$http.post(api.addProduct, params)
  return data
}
// 获取详情
export async function editProductDialog (ele, params) {
  const { data } = await ele.$http.get(api.editProductDialog + params)
  return data
}
// 编辑
export async function editProduct (ele, id, params) {
  const { data } = await ele.$http.put(api.editProduct + '?id=' + id, params)
  return data
}
// 删除
export async function delItem (ele, id) {
  const { data } = await ele.$http.delete(api.delItem + id)
  return data
}
