<template>
  <div>
    <el-row style="margin: 0 10px">
      <el-col :span="2" style="font-size: 18px">套餐管理</el-col>
      <el-col :span="5" style="float: right;">
        <!--面包屑导航-->
        <el-breadcrumb separator="/">
          <el-breadcrumb-item>商户管理</el-breadcrumb-item>
          <el-breadcrumb-item><a>套餐管理</a></el-breadcrumb-item>
        </el-breadcrumb>
      </el-col>
    </el-row>
    <el-card>
      <el-form :inline="true" :model="form" class="demo-form-inline">
        <el-form-item label="套餐名称">
          <el-input v-model="form.name" placeholder="请输入套餐名称"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="getList('?name=' + form.name)">查询</el-button>
        </el-form-item>
      </el-form>
      <el-button type="primary" @click="addShopPop=true">添加套餐</el-button>
      <el-table
        ref="filterTable"
        :data="tcListData"
        border
        style="width: 100%">
        <el-table-column prop="id" label="套餐id"></el-table-column>
        <el-table-column prop="name" label="套餐名称"></el-table-column>
        <el-table-column prop="update_time" label="创建时间"></el-table-column>
        <el-table-column label="操作">
          <template #default="scope">
            <el-button type="primary" size="mini" @click="editList(scope.row.id)" style="margin-top: 10px">编辑</el-button>
            <el-button type="danger" size="mini" @click="deleteCase(scope.row.id)" style="margin-top: 10px">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <page :queryInfos="queryInfo" @pageChange="PageChange" queryUrl="agentManage/SetMeal" :pamams="'name=' + form.name"></page>
    </el-card>
    <!--    添加套餐-->
    <div class="addPop" v-if="addShopPop">
      <el-dialog
        :title="isUpdate ? '编辑套餐' : '添加套餐'"
        :visible.sync="addShopPop"
        width="50%"
        @close="resetForm"
      >
        <el-form label-width="135px" :model="addForm" :rules="addFormRules" ref="addFormRef">
<!--          <el-form-item label="设备类型">-->
<!--            <el-select v-model="addForm.type" prop="type" placeholder="设备类型">-->
<!--              <el-option label="机柜" :value="0"></el-option>-->
<!--              <el-option label="密码线" :value="1"></el-option>-->
<!--              <el-option label="蓝牙" :value="2"></el-option>-->
<!--            </el-select>-->
<!--          </el-form-item>-->
          <el-form-item label="后台选择套餐内容" prop="name">
            <el-input v-model="addForm.name" placeholder="*小时*元*元封顶"></el-input>
          </el-form-item>
          <el-form-item label="用户显示套餐内容" prop="show_name">
            <el-input v-model="addForm.show_name" placeholder="*小时*元"></el-input>
          </el-form-item>
          <div>
            <el-form-item label="免费时间（秒）" prop="free_time">
              <div class="showTc">
                <div class="showTcModel">
                  <el-input v-model="addForm.free_time"></el-input>
                </div>
                <el-form-item label="封顶金额（元）" prop="top_price">
                  <el-input v-model="addForm.top_price"></el-input>
                </el-form-item>
              </div>
            </el-form-item>
          </div>
          <div v-for="(item, index) in list" :key="index" class="lines">
            <el-form-item label="套餐价格（元）" prop="key">
              <div class="showTc">
                <div class="showTcModel">
                  <el-input v-model="item.key"></el-input>
                </div>
                <el-form-item label="套餐时间（秒）" prop="value">
                  <el-input v-model="item.value"></el-input>
                </el-form-item>
                <el-button v-if="index != 0" type="danger" @click="deleteMeal(index)" style="margin-left: 20px">删除</el-button>
              </div>
            </el-form-item>
          </div>

          <el-form-item>
            <el-button type="primary" @click="addMeal">增加套餐</el-button>
          </el-form-item>
          <el-form-item>
            <el-button v-if="isUpdate" :type="isBtn ? 'primary' : ''" @click="saveUpdate('addForm')">保存</el-button>
            <el-button v-else :type="isBtn ? 'primary' : ''" @click="onSubmit('addForm')">添加</el-button>
            <el-button @click="resetForm('addForm')">重置</el-button>
          </el-form-item>
        </el-form>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import * as SetMeal from '../../interface/agentManage/SetMeal'
import Pagination from '../common/Pagination'

export default {
  name: 'SetMeal',
  components: {
    page: Pagination
  },
  data: function () {
    return {
      form: {
        name: ''
      },
      queryInfo: {
        query: '',
        pageNo: 1,
        pageSize: 2,
        total: 0
      },
      addShopPop: false,
      tcListData: [],
      addForm: {},
      list: [
        { key: '', value: '' }
      ],
      addFormRules: {
        name: [
          { required: true, message: '请填写后台选择套餐内容', trigger: 'blur' }
        ],
        show_name: [
          { required: true, message: '请填写用户显示套餐内容', trigger: 'blur' }
        ],
        free_time: [
          { required: true, message: '请填写免费时间（秒）', trigger: 'blur' }
        ],
        top_price: [
          { required: true, message: '请填写封顶金额（元）', trigger: 'blur' }
        ]
      },
      isUpdate: false,
      isBtn: true
    }
  },
  created () {
    this.getList()
  },
  methods: {
    // 重置
    resetForm: function () {
      this.$refs.addFormRef.resetFields()
      this.list = [{ key: '', value: '' }]
    },
    PageChange (e) {
      // this.getList()
      this.tcListData = e.productList
      this.queryInfo = e.queryInfo
    },
    // 获取套餐信息
    getList: async function (p) {
      var param = ''
      if (p) param = p
      const data = await SetMeal.getLists(this, param)
      if (data.code !== 0) return this.$message.error('获取套餐信息失败')
      this.tcListData = data.result.data
      this.queryInfo.total = data.result.totalCount
      this.queryInfo.pageNo = data.result.pageNo
      this.queryInfo.pageSize = data.result.pageSize
      // console.log(data)
    },
    // 删除
    deleteCase: function (e) {
      this.$confirm('是否确认删除', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const res = await SetMeal.deleteLists(this, e)
        if (res.code !== 0) return this.$message.error('删除失败')
        this.$message.success('删除成功')
        this.getList()
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    // 编辑
    editList: async function (e) {
      this.addShopPop = true
      this.isBtn = true
      const data = await SetMeal.getListsInfo(this, e)
      console.log(data)
      this.addForm = data.result
      this.list = data.result.prices
      this.isUpdate = true
    },
    // 保存
    saveUpdate: function (addForm) {
      if (!this.isBtn) return false
      this.isBtn = false
      this.addForm.prices = this.list
      delete this.addForm.create_time
      delete this.addForm.update_time
      this.$refs.addFormRef.validate(async valid => {
        if (!valid) {
          this.isBtn = true
          return this.$message.error('请填写完整信息')
        }
        const data = await SetMeal.updateLists(this, this.addForm)
        console.log(data)
        if (data.code !== 0) {
          this.isBtn = true
          return this.$message.error('编辑套餐失败')
        }
        this.$message.success('保存成功')
        this.addShopPop = false
        this.isBtn = false
        this.getList()
      })
    },
    // 增加套餐节点
    addMeal: function () {
      this.list.push({ key: '', value: '' })
    },
    // 删除套餐节点
    deleteMeal: function (i) {
      this.list.splice(i, 1)
    },
    // 增加套餐
    onSubmit: function (addForm) {
      if (!this.isBtn) return false
      this.isBtn = false
      this.addForm.prices = this.list
      this.$refs.addFormRef.validate(async valid => {
        if (!valid) {
          this.isBtn = true
          return this.$message.error('请填写完整信息')
        }
        const data = await SetMeal.addLists(this, this.addForm)
        if (data.code !== 0) {
          this.isBtn = true
          return this.$message.error('添加套餐失败')
        }
        this.$message.success('添加成功')
        this.addShopPop = false
        this.isBtn = false
        this.getList()
        this.resetForm()
      })
    }
  }
}
</script>
<style scoped>
  .showTc{
    display: flex;
    width: 100%;
  }
  .showTcModel{
    width: 30%;
  }
  .lines{
    border-top: 1px solid #EBEEF5;
    padding-top: 20px;
  }
</style>
