<template>
    <div>
      <el-row style="margin: 0 10px">
        <el-col :span="2" style="font-size: 18px">出库列表</el-col>
        <el-col :span="5" style="float: right;">
          <!--面包屑导航-->
          <el-breadcrumb separator="/">
            <el-breadcrumb-item>仓库管理</el-breadcrumb-item>
            <el-breadcrumb-item><a>出库</a></el-breadcrumb-item>
            <el-breadcrumb-item><a>出库列表</a></el-breadcrumb-item>
          </el-breadcrumb>
        </el-col>
      </el-row>
      <el-card style="margin-top: 10px">
        <el-form :model="formAllData" :rules="formRules" ref="formRef" style="padding: 0 0 0 200px">
          <el-row style="margin-bottom: 20px">
            <el-col :span="2"><el-button type="success" @click="returnMsg()">返回</el-button></el-col>
            <el-col :span="2"><el-button type="primary" @click="addExWareHousing">提交</el-button></el-col>
            <el-col :span="3"><el-button type="primary" v-print="printObj">打印入库表</el-button></el-col>
            <el-col :span="3"><el-button type="primary" v-print="printList">打印机身码列表</el-button></el-col>
          </el-row>
          <table
            id="printMe"
            class="table table-bordered col-sm-4"
            style="text-align:center;width: 85%;"
          >
            <caption style="font-size: 22px;margin-bottom: 10px">深圳市中亿医疗设备有限公司</caption>
            <thead class="lead">
            <td colspan="14" style="background-color: #fff;border: 1px solid rgb(22, 22, 22);height: 70px;line-height:70px;font-size: 20px">出库清单</td>
            </thead>
            <tbody>
              <tr style="height: 45px">
                <td width="14%" colspan="2">出库类型：</td>
                <td width="19%" colspan="3">
                  <el-select class="form-control form-control-warning" v-model="formAllData.warehouse_out_type_id">
                    <el-option v-for="(item, index) in storeHouseType" :key="index" :label="item.title" :value="item.id"></el-option>
                  </el-select>
                </td>
                <td width="14%" colspan="2">发送仓库：</td>
                <td width="19%" colspan="3">
                  <el-select class="form-control form-control-warning" v-model="formAllData.warehouse_id">
                    <el-option v-for="(item, index) in storeHouseList" :key="index" :label="item.title" :value="item.id"></el-option>
                  </el-select>
                </td>
                <td width="14%" colspan="2">出库单号</td>
                <td width="19%" colspan="2">
                  <el-input class="form-control" v-model="formAllData.warehouse_no" readonly></el-input>
                </td>
              </tr>
              <tr style="height: 45px">
                <td width="14%" colspan="2">出库日期：</td>
                <td width="21%" colspan="3">
<!--                  <el-date-picker-->
<!--                    type="date"-->
<!--                    v-model="warehouse_time"-->
<!--                    placeholder="选择日期时间"-->
<!--                    align="right"-->
<!--                    style="width: 80%"-->
<!--                    aria-readonly="true"-->
<!--                  >-->
<!--                  </el-date-picker>-->
                  <el-input readonly v-model="showTime"></el-input>
                </td>
                <td width="14%" colspan="2">提货方式：</td>
                <td width="21%" colspan="3"><el-input class="form-control" v-model="formAllData.task_method"></el-input></td>
                <td width="14%" colspan="2">物流单号:</td>
                <td width="21%" colspan="3"><el-input class="form-control" v-model="formAllData.express_no"></el-input></td>
              </tr>
              <tr style="height: 45px">
                <td width="14%" colspan="2">客户名称：</td>
                <td width="21%" colspan="3">
                  <el-form-item prop="custom_name"><el-input class="form-control" v-model="formAllData.custom_name"></el-input></el-form-item>
                </td>
                <td width="14%" colspan="2">联系电话：</td>
                <td width="21%" colspan="3">
                  <el-form-item prop="custom_mobile">
                    <el-input v-model="formAllData.custom_mobile"></el-input>
                  </el-form-item>
                </td>
                <td width="14%" colspan="2">收货人:</td>
                <td width="21%" colspan="3">
                  <el-form-item prop="consignee">
                    <el-input class="form-control" v-model="formAllData.consignee"></el-input>
                  </el-form-item>
                </td>
              </tr>
              <tr style="height: 45px">
                <td width="14%" colspan="2">收货地址:</td>
                <td width="86%" colspan="12">
                  <el-form-item prop="consignee_address">
                    <el-input style="width: 95%" class="form-control" v-model="formAllData.consignee_address"></el-input>
                  </el-form-item>
                </td>
              </tr>
              <tr style="font-size: 20px;text-align: center;color: rgb(31, 78, 121);height: 55px">
                <td colspan="14">
                  <span style="line-height: 60px">商品明细</span>
                  <el-button type="primary" style="float: right;margin-right: 40px;margin-top: 10px" @click="dialogFormVisibleDialog">机身编码录入</el-button>
                </td>
              </tr>
              <tr style="height: 45px">
                <th colspan="1">序号</th>
                <th colspan="2">货物名称</th>
<!--                <th colspan="2">规格型号</th>-->
<!--                <th colspan="1">单位</th>-->
                <th colspan="4">数量</th>
                <th colspan="2">单价</th>
                <th colspan="6">金额</th>
<!--                <th colspan="4">备注</th>-->
              </tr>
              <tr style="height: 45px" v-for="(item,index) in equipmentList" :key="index">
                <td colspan="1">{{index + 1}}</td>
                <td colspan="2">{{item.listData.title}}</td>
<!--                <td colspan="2"></td>-->
<!--                <td colspan="1">台</td>-->
                <td colspan="4">{{item.list.length}}</td>
                <td colspan="2">{{item.listData.price}}</td>
                <td colspan="6">{{item.list.length * parseFloat(item.listData.price)}}</td>
<!--                <td width="21%" colspan="4">-->
<!--                  <el-form-item prop="custom_mobile">-->
<!--                    <el-input v-model="value"></el-input>-->
<!--                  </el-form-item>-->
<!--                </td>-->
              </tr>
              <tr style="height: 45px">
                <td colspan="3">合计总数:</td>
                <td colspan="4">
                  <el-input class="form-control" v-model="formAllData.num"></el-input>
                </td>
                <td colspan="3">合计金额:</td>
                <td colspan="4">
                  <el-input class="form-control" v-model="formAllData.total_price"></el-input>
                </td>
              </tr>
<!--              <tr style="height: 45px">-->
<!--                <td colspan="1">审核:</td>-->
<!--                <td colspan="2"></td>-->
<!--                <td colspan="2">仓库:</td>-->
<!--                <td colspan="2"></td>-->
<!--                <td colspan="2">提货人:</td>-->
<!--                <td colspan="2"></td>-->
<!--                <td colspan="1">日期:</td>-->
<!--                <td colspan="2">2021/8/31 11:26</td>-->
<!--              </tr>-->
<!--              <tr>白：财务 粉：客户 黄：仓库</tr>-->
            </tbody>
          </table>
          <table id="printMe1" class="table table-bordered col-sm-4" style="text-align:center;width: 85%;margin-top: 20px">
            <thead class="lead">
            <td colspan="14" style="background-color: #fff;border: 1px solid rgb(22, 22, 22);height: 70px;line-height:70px;font-size: 20px">机身码列表</td>
            </thead>
            <tbody>
              <tr v-for="(item, index) in equipmentList" :key="index">
                <td colspan="12" width="100%" style="vertical-align: top;padding: 10px 0;">{{item.listData.title}}
                  <ul class="preLists">
                    <li v-for="(item1, index1) in item.list" :key="index1">{{item1}}</li>
                  </ul>
                </td>

              </tr>
            </tbody>
          </table>
        </el-form>
      </el-card>
      <el-dialog title="机身编号" :visible.sync="dialogFormVisible">
        <el-form :inline="true" class="demo-form-inline">
          <el-row>
            <el-col :span="8">
              <el-form-item>
                <el-select v-model="form.equipment_type_id" placeholder="请选择产品(必选)" :disabled="isDisabled">
                  <el-option v-for="(item, index) in getAllEqData" :key="index" :label="item.title" :value="item.id"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="9">
              <el-input style="width: 290px" v-model="form.id" placeholder="输入编号"></el-input>
            </el-col>
            <el-col :span="3">
              <el-form-item style="margin-top: 10px;width: 200px;margin-left: 30px">
                <el-button type="primary" @click="addList">录入</el-button>
              </el-form-item>
            </el-col>
          </el-row>
<!--          <el-form-item>-->
<!--            <el-select v-model="form.equipment_type_id" placeholder="请选择产品(必选)" :disabled="isDisabled">-->
<!--              <el-option v-for="(item, index) in getAllEqData" :key="index" :label="item.title" :value="item.id"></el-option>-->
<!--            </el-select>-->
<!--          </el-form-item>-->
<!--          <el-input style="width: 300px" v-model="form.id" placeholder="输入编号"></el-input>-->
<!--          <el-form-item style="margin-top: 10px;width: 200px">-->
<!--            <el-button type="primary" @click="addList">录入</el-button>-->
<!--          </el-form-item>-->
        </el-form>
        <ul class="preList">
          <li v-for="(item, index) in list" :key="index">{{item}}</li>
        </ul>
        <el-form :model="form">
          <div>已录入：{{list.length}}台</div>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="cleanBtn">取 消</el-button>
          <el-button type="primary" @click="sureClick">确 定</el-button>
        </div>
      </el-dialog>
    </div>
</template>

<script>
import * as store from '../../interface/storeHouse/ExWarehouse'
import * as getReceiptRegister from '../../interface/AfterSalesManage/ReceiptRegister'

export default {
  name: 'AddExWarehouse',
  data: function () {
    var checkMobile = (rule, value, cb) => {
      const regMobile = /^1[3-9]\d{9}$/
      if (regMobile.test(value)) {
        return cb()
      }
      // 校验不通过,则回调错误
      cb(new Error('请输入合法的手机号码'))
    }
    return {
      formAllData: {
        warehouse_no: '',
        warehouse_id: '',
        num: '',
        warehouse_out_type_id: '',
        custom_name: '',
        custom_mobile: '',
        consignee: '',
        consignee_address: '',
        total_price: '',
        equipment_nos: []
      },
      warehouse_time: '',
      warehouse_no1: '',
      equipment_no: '',
      printObj: {
        id: 'printMe',
        popTitle: '产品入库单',
        extraCss: 'https://www.baidu.com,https://www.baidu.com',
        extraHead: '<meta http-equiv="Content-Language"content="zh-cn"/>',
        // url: 'http://localhost:8080/',
        // preview: true,
        // previewTitle: '产品入库单',
        // previewBeforeOpenCallback (vue) {
        //   console.log('正在加载预览窗口')
        // },
        // previewOpenCallback (vue) {
        //   console.log('已经加载完预览窗口')
        // },
        beforeOpenCallback (vue) {
          console.log('打开之前')
        },
        openCallback (vue) {
          console.log('执行了打印')
        },
        closeCallback (vue) {
          console.log('关闭了打印工具')
        }
      },
      printList: {
        id: 'printMe1',
        popTitle: '机身码列表',
        extraCss: 'https://www.baidu.com,https://www.baidu.com',
        extraHead: '<meta http-equiv="Content-Language"content="zh-cn"/>',
        beforeOpenCallback (vue) {
          console.log('打开之前')
        },
        openCallback (vue) {
          console.log('执行了打印')
        },
        closeCallback (vue) {
          console.log('关闭了打印工具')
        }
      },
      storeHouseList: [],
      storeHouseType: [],
      productList: [],
      dialogFormVisible: false,
      formRules: {
        warehouse_no: [
          { required: true, message: '请输入出库单号', trigger: 'blur' }
        ],
        warehouse_id: [
          { required: true, message: '请选择发货仓库', trigger: 'blur' }
        ],
        num: [
          { required: true, message: '请输入数量', trigger: 'blur' }
        ],
        warehouse_out_type_id: [
          { required: true, message: '请选择出库类型', trigger: 'blur' }
        ],
        custom_name: [
          { required: true, message: '请输入客户姓名', trigger: 'blur' }
        ],
        custom_mobile: [
          { required: true, message: '请输入手机号码', trigger: 'blur' },
          { validator: checkMobile, message: '手机号码不正确,请重新输入', trigger: 'blur' }
        ],
        consignee: [
          { required: true, message: '请输入收货人', trigger: 'blur' }
        ],
        consignee_address: [
          { required: true, message: '请输入收货地址', trigger: 'blur' },
          { min: 3, message: '至少3个字符', trigger: 'blur' }
        ],
        total_price: [
          { required: true, message: '请输入合计金额', trigger: 'blur' }
        ]
      },
      list: [],
      getAllEqData: [],
      isDisabled: false,
      equipment_type_name: '',
      listData: {},
      equipmentList: [],
      form: {},
      showTime: ''
    }
  },
  created () {
    this.getStoreHouse()
    this.getStoreType()
    this.getProductList()
    // this.getWareHouseNo()
    this.getNo()
    this.getAll()
    this.showTimes()
  },
  methods: {
    // 获取全部产品
    getAll: async function () {
      const data = await getReceiptRegister.equipmentTypeAll(this)
      if (data.code !== 0) return this.$message.error('获取全部产品列表失败')
      this.getAllEqData = data.result
    },
    // 获取出库单号
    getNo: async function () {
      const data = await store.getWarehouseNo(this)
      this.formAllData.warehouse_no = data.result.order_no
    },
    // 获取出库仓库
    getStoreHouse: async function () {
      const data = await store.wareHousingAllList(this)
      console.log(data)
      this.storeHouseList = data.result
    },
    // 获取出库类型
    getStoreType: async function () {
      const data = await store.exWareHousingAllType(this)
      console.log('出库类型')
      // console.log(data)
      this.storeHouseType = data.result
      console.log(this.storeHouseType)
    },
    // 获取产品名称
    getProductList: async function () {
      const data = await store.productList(this)
      console.log(data)
      this.productList = data.result
    },
    // 获取出入库单号
    // getWareHouseNo: async function () {
    //   const data = await store.wareHouseNo(this)
    //   console.log(data)
    //   this.form.warehouse_no = data.result.warehouse_no
    // },
    // 返回的列表
    returnMsg: function () {
      this.$router.go(-1)
    },
    // 添加出库列表
    addExWareHousing: async function () {
      this.$refs.formRef.validate(async valid => {
        if (!valid) return this.$message.error('请填写完整的出库新增信息')
        // this.form.equipment_nos.push(this.equipment_no)
        // this.form.equipment_nos =
        var that = this
        if (that.equipmentList) {
          that.equipmentList.forEach(function (item, index) {
            if (item.list) {
              item.list.forEach(function (item1, index1) {
                that.formAllData.equipment_nos.push(item1)
              })
            }
          })
        }
        console.log(that.formAllData)
        const data = await store.exWareHousingAdd(this, that.formAllData)
        if (data.code !== 0) return that.$message.error(data.msg)
        that.$message.success('新增出库列表成功')
        that.$router.push('exWarehouse')
      })
    },
    // 机身编码录入列表
    dialogFormVisibleDialog: async function () {
      // 获取所有的机身编码录入
      const data = await store.equipmentInfo(this)
      console.log(data)
      this.dialogFormVisible = true
    },
    // 录入
    addList: function () {
      if (!this.isDisabled) {
        if (!this.form.equipment_type_id) return this.$message.error('请输入产品')
        this.isDisabled = true
        var that = this
        this.getAllEqData.forEach(function (item, index, array) {
          if (item.id === that.form.equipment_type_id) that.listData = item
        })
      }
      if (!this.form.id) return this.$message.error('请输入编号')
      this.list.push(this.form.id)
      this.form.id = ''
    },
    // 删除
    deleteList: function (i) {
      this.list.splice(i, 1)
    },
    // 确定
    sureClick: function () {
      this.equipmentList.push({ listData: this.listData, list: this.list })
      console.log(this.equipmentList)
      this.cleanBtn()
      var num = 0
      var price = 0
      for (var i = 0; i < this.equipmentList.length; i++) {
        num += this.equipmentList[i].list.length
        price += this.equipmentList[i].list.length * parseFloat(this.equipmentList[i].listData.price)
      }
      this.formAllData.num = num
      this.formAllData.total_price = price
    },
    // 取消
    cleanBtn: function () {
      this.dialogFormVisible = false
      this.list = []
      this.isDisabled = false
      this.listData = {}
    },
    // 获取时间
    showTimes: function () {
      const nowtime = new Date()
      const year = nowtime.getFullYear()
      const month = this.padaDate(nowtime.getMonth() + 1)
      const date = this.padaDate(nowtime.getDate())
      this.showTime = year + '-' + month + '-' + date
      // console.log(this.showTime)
    },
    padaDate: function (value) {
      return value < 10 ? '0' + value : value
    }
  }
}
</script>

<style scoped>
  table {
    border-collapse: collapse;
  }

  thead {
    background-color: #fff;
  }

  .table-bordered>tbody>tr>td,
  .table-bordered>tbody>tr>th,
  .table-bordered>tfoot>tr>td,
  .table-bordered>tfoot>tr>th,
  .table-bordered>thead>tr>td,
  .table-bordered>thead>tr>th{
    border: 1px solid rgb(22, 22, 22);
  }

  div {
    margin: 0 auto;
    overflow: hidden;
    font-size: 12px;
    display: block;
  }

  p {
    margin: 15px 30px;
    position: relative;
    overflow: hidden;
    padding: 0;
  }

  .info {
    width: 80%;
    height: 100%;
    background-color: #fff;
  }
  .el-input, .el-select {
    width: 80%;
    margin: 10px auto;
  }
  .preList, .preLists{
    padding-left: 20px;
    width: 90%;
    display: flex;
    flex-wrap: wrap;
  }
  .preList li, .preLists li{
    list-style: none;
    border: 1px solid #eee;
    border-top: none;
    height: 30px;
    line-height: 30px;
    padding: 0 15px;
    width: 250px;
  }
  .preLists{
    padding-left: 0;
    width: 600px;
    margin: 20px auto;
  }
  .preList li:nth-child(1), .preList li:nth-child(2), .preLists li:nth-child(1), .preLists li:nth-child(2){
    border-top: 1px solid #eee;
  }
  .el-dialog__body{
    margin-top: 0px;
  }
  .el-input .el-form-item__error{
    position: relative !important;
  }
  /*.showInput{*/
  /*  display: flex;*/
  /*  width: 100%;*/
  /*  padding-left: 20px;*/
  /*}*/
</style>
