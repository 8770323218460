<template>
    <div>
      <el-row style="margin: 0 10px">
        <el-col :span="2" style="font-size: 18px">病例设置</el-col>
        <el-col :span="5" style="float: right;">
          <!--面包屑导航-->
          <el-breadcrumb separator="/">
            <el-breadcrumb-item>会员管理</el-breadcrumb-item>
            <el-breadcrumb-item><a>病例设置</a></el-breadcrumb-item>
          </el-breadcrumb>
        </el-col>
      </el-row>
      <el-card>
        <el-button type="primary" @click="dialogVisible=true">新增</el-button>
        <el-table
          ref="filterTable"
          :data="caseListData"
          border
          style="width: 50%">
          <el-table-column type="index" label="序号" width="100px"></el-table-column>
          <el-table-column prop="case_type_name" label="病例类型"></el-table-column>
          <el-table-column label="操作">
            <template #default="scope">
              <el-button type="primary" size="mini" @click="editInfoDialog(scope.row.id)">修改</el-button>
              <el-button type="danger" size="mini" @click="deleteCase(scope.row.id)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-card>
<!--      新增的对话框-->
      <el-dialog
        title="新增病例设置"
        :visible.sync="dialogVisible"
        width="50%"
        @close="addDialogClosed"
      >
        <el-form label-width="100px" :model="addForm" :rules="addFormRules" ref="addFormRef">
          <el-form-item label="病例类型名称" prop="case_type_name">
            <el-input v-model="addForm.case_type_name"></el-input>
          </el-form-item>
          <el-form-item label="病例问答">
            <template>
              <el-row v-for="(item,index) in CaseRowLists" :key="index" style="margin: 10px 0;">
                <el-col :span="3">
                  <el-select v-model="item.type" placeholder="类型" @change="RadioChange($event,index)">
                    <el-option value="input" label="填空">填空</el-option>
                    <el-option value="radio" label="单选">单选</el-option>
                    <el-option value="checkout" label="多选">多选</el-option>
                  </el-select>
                </el-col>
                <el-col :span="5" style="margin:0 20px"><el-input placeholder="请输入标题" v-model="item.title"></el-input></el-col>
                <el-col :span="2"><el-button type="danger" size="mini" style="margin: 5px 10px" @click="deleteRow(index)">删除当前行</el-button></el-col>
                <el-col :span="12"></el-col>
                <el-col :span="24" style="margin-top: 5px">
                  <el-input v-if="item.inputType===0" placeholder="请输入列表内容" v-model="item.content"></el-input>
                  <span v-else-if="item.inputType===1">
                    <el-tag :key="tag" v-for="tag in item.dynamicTagOdds" closable :disable-transitions="false" @close="handleClose(tag,index,'dynamicTagOdds')">{{tag.length>50?tag.substring(0,49)+'...':tag}}}</el-tag>
                    <el-input placeholder="内容" class="input-new-tag" v-if="item.inputVisible1" v-model="item.inputValue1" ref="saveTagInput1" @keyup.enter.native="handleInputConfirm(index)" @blur="handleInputConfirm(index)"></el-input>
                    <el-button style="margin-top: 5px" v-else class="button-new-tag" size="small" type="info" @click="showInput1(index)">添加单选</el-button>
                  </span>
                  <span v-else-if="item.inputType===2">
                    <el-tag style="margin-top: 5px" :key="tag" v-for="tag in item.dynamicTagEvens" closable :disable-transitions="false" @close="handleClose(tag,index,'dynamicTagEvens')">{{tag.length>50?tag.substring(0,49)+'...':tag}}</el-tag>
                    <el-input style="margin-top: 5px" class="input-new-tag" v-if="item.inputVisible2" v-model="item.inputValue2" ref="saveTagInput2" @keyup.enter.native="handleInputConfirm1(index)" @blur="handleInputConfirm1(index)"></el-input>
                    <el-button style="margin-top: 5px" v-else class="button-new-tag" size="small" type="info" @click="showInput2(index)">添加多选</el-button>
                  </span>
                </el-col>
              </el-row>
            </template>
          </el-form-item>
          <el-button type="primary" size="mini" @click="newCaseRow">新增</el-button>
        </el-form>

        <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="addUser">确 定</el-button>
        </span>
        </template>
      </el-dialog>
<!--      编辑的对话框-->
      <el-dialog
        title="编辑病例设置"
        :visible.sync="editDialogVisible"
        width="50%"
        @close="editDialogClosed"
      >
        <el-form label-width="100px" :model="editForm" :rules="editFormRules" ref="editFormRef">
          <el-form-item label="病例类型名称" prop="case_type_name">
            <el-input v-model="editForm.case_type_name"></el-input>
          </el-form-item>
          <el-form-item label="病例问答">
            <template>
              <el-row v-for="(item,index) in CaseRowLists" :key="index" style="margin: 10px 0;">
                <el-col :span="3">
                  <el-select v-model="item.type" placeholder="类型" @change="RadioChange($event,index)">
                    <el-option value="input" label="填空">填空</el-option>
                    <el-option value="radio" label="单选">单选</el-option>
                    <el-option value="checkout" label="多选">多选</el-option>
                  </el-select>
                </el-col>
                <el-col :span="5" style="margin:0 20px"><el-input placeholder="请输入标题" v-model="item.title"></el-input></el-col>
                <el-col :span="2"><el-button type="danger" size="mini" style="margin: 5px 10px" @click="deleteRow(index)">删除当前行</el-button></el-col>
                <el-col :span="12"></el-col>
                <el-col :span="24" style="margin-top: 5px">
                  <el-input v-if="item.inputType===0" placeholder="请输入列表内容" v-model="item.content"></el-input>
                  <span v-else-if="item.inputType===1">
                    <el-tag :key="tag" v-for="tag in item.dynamicTagOdds" closable :disable-transitions="false" @close="handleClose(tag,index,'dynamicTagOdds')">{{tag.length>50?tag.substring(0,49)+'...':tag}}</el-tag>
                    <el-input placeholder="内容" class="input-new-tag" v-if="item.inputVisible1" v-model="item.inputValue1" ref="saveTagInput1" @keyup.enter.native="handleInputConfirm(index)" @blur="handleInputConfirm(index)"></el-input>
                    <el-button style="margin-top: 5px" v-else class="button-new-tag" size="small" type="info" @click="showInput1(index)">添加单选</el-button>
                  </span>
                  <span v-else-if="item.inputType===2">
                    <el-tag style="margin-top: 5px" :key="tag" v-for="tag in item.dynamicTagEvens" closable :disable-transitions="false" @close="handleClose(tag,index,'dynamicTagEvens')">{{tag.length>50?tag.substring(0,49)+'...':tag}}</el-tag>
                    <el-input style="margin-top: 5px" class="input-new-tag" v-if="item.inputVisible2" v-model="item.inputValue2" ref="saveTagInput2" @keyup.enter.native="handleInputConfirm1(index)" @blur="handleInputConfirm1(index)"></el-input>
                    <el-button style="margin-top: 5px" v-else class="button-new-tag" size="small" type="info" @click="showInput2(index)">添加多选</el-button>
                  </span>
                </el-col>
              </el-row>
            </template>
          </el-form-item>
          <el-button type="primary" size="mini" @click="newCaseRow">新增</el-button>
        </el-form>

        <template #footer>
        <span class="dialog-footer">
          <el-button @click="editDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="editCaseInfo">确定修改</el-button>
        </span>
        </template>
      </el-dialog>
    </div>
</template>

<script>
import * as caseSet from '../../interface/memberManage/CaseSetting'

export default {
  name: 'CaseSetting',
  created () {
    this.getCaseList()
  },
  data: function () {
    return {
      caseListData: [],
      dialogVisible: false,
      addForm: {
        case_type_name: '',
        answer_list: []
      },
      addFormRules: {},
      CaseRowLists: [],
      editDialogVisible: false,
      editFormRules: {},
      editForm: {
        id: '',
        case_type_name: '',
        answer_list: []
      }
    }
  },
  methods: {
    getCaseList: async function () {
      const res = await caseSet.caseList(this)
      if (res.code !== 0) return this.$message.error('获取病例信息失败')
      console.log(res)
      this.caseListData = res.result.data
    },
    addDialogClosed: function () {
      this.$refs.addFormRef.resetFields()
      this.CaseRowLists = []
    },
    editDialogClosed: function () {
      this.$refs.editFormRef.resetFields()
      this.CaseRowLists = []
    },
    addUser: async function () {
      this.addForm.answer_list = []
      var arr = this.CaseRowLists
      for (var i = 0; i < arr.length; i++) {
        if (arr[i].type === 'input') {
          var obj = {
            key: arr[i].title,
            value: arr[i].content,
            type: arr[i].type
          }
          this.addForm.answer_list.push(obj)
        } else if (arr[i].type === 'radio') {
          var obj1 = {
            key: arr[i].title,
            value: arr[i].dynamicTagOdds,
            type: arr[i].type
          }
          this.addForm.answer_list.push(obj1)
        } else if (arr[i].type === 'checkout') {
          var obj2 = {
            key: arr[i].title,
            value: arr[i].dynamicTagEvens,
            type: arr[i].type
          }
          this.addForm.answer_list.push(obj2)
        }
      }
      const data = await caseSet.addCase(this, this.addForm)
      if (data.code !== 0) {
        this.$message.error('添加病例信息失败')
        this.dialogVisible = false
        return
      }
      this.$message.success('添加病例信息成功')
      this.dialogVisible = false
      this.getCaseList()
    },
    handleClose (tag, index, name) {
      if (name === 'dynamicTagOdds') {
        this.CaseRowLists[index].dynamicTagOdds.splice(this.CaseRowLists[index].dynamicTagOdds.indexOf(tag), 1)
      } else {
        this.CaseRowLists[index].dynamicTagEvens.splice(this.CaseRowLists[index].dynamicTagEvens.indexOf(tag), 1)
      }
    },

    // input框显示
    showInput1 (index) {
      this.CaseRowLists[index].inputVisible1 = true
      this.$nextTick(_ => {
        // this.$refs.saveTagInput1.$refs.input.focus()
      })
    },
    showInput2 (index) {
      this.CaseRowLists[index].inputVisible2 = true
      this.$nextTick(_ => {
        // this.$refs.saveTagInput1.$refs.input.focus()
      })
    },
    // 处理输入确定标签
    handleInputConfirm (index) {
      const inputValue1 = this.CaseRowLists[index].inputValue1
      if (inputValue1) {
        this.CaseRowLists[index].dynamicTagOdds.push(inputValue1)
      }
      this.CaseRowLists[index].inputVisible1 = false
      this.CaseRowLists[index].inputValue1 = ''
    },
    handleInputConfirm1 (index) {
      const inputValue2 = this.CaseRowLists[index].inputValue2
      if (inputValue2) {
        this.CaseRowLists[index].dynamicTagEvens.push(inputValue2)
      }
      this.CaseRowLists[index].inputVisible2 = false
      this.CaseRowLists[index].inputValue2 = ''
    },
    // 添加一个新增的列表
    newCaseRow: function () {
      var arr = { type: '', inputType: 0, dynamicTagOdds: [], dynamicTagEvens: [], inputVisible1: false, inputValue1: '', inputVisible2: false, inputValue2: '', title: '', content: '' }
      this.CaseRowLists.push(arr)
    },
    // 点击下来框发生改变的事件
    RadioChange: function (data, index) {
      console.log(data)
      if (data === 'input') {
        this.CaseRowLists[index].inputType = 0
      } else if (data === 'radio') {
        this.CaseRowLists[index].inputType = 1
      } else {
        this.CaseRowLists[index].inputType = 2
      }
    },
    //  删除当前行
    deleteRow: function (index) {
      this.CaseRowLists.splice(index, 1)
    },
    deleteCase: async function (id) {
      const result = await this.$confirm('是否要删除该病例?', '删除提示', {
        confirmButtonText: '确定删除',
        cancelButtonText: '取消',
        type: 'warning'
      }).catch(err => err)
      if (result !== 'confirm') return this.$message.info('取消删除')

      // 点击确定删除,发起删除请求
      const data = await caseSet.deleteCase(this, id)
      if (data.code !== 0) return this.$message.error('删除病例失败')
      this.$message.success('删除病例成功')
      // 重新请求数据,刷新页面
      this.getCaseList()
    },
    editInfoDialog: async function (id) {
      const data = await caseSet.caseInfo(this, id)
      console.log(data)
      if (data.code !== 0) return this.$message.error('获取单一病例列表失败')
      this.editForm = data.result
      this.CaseRowLists = []
      var list = data.result.answer_list
      for (var i = 0; i < list.length; i++) {
        var arr = { type: '', inputType: 0, dynamicTagOdds: [], dynamicTagEvens: [], inputVisible1: false, inputValue1: '', inputVisible2: false, inputValue2: '', title: '', content: '' }
        if (list[i].type === 'input') {
          arr.type = 'input'
          arr.title = list[i].key
          arr.content = list[i].value
          arr.inputType = 0
        } else if (list[i].type === 'radio') {
          arr.type = 'radio'
          arr.title = list[i].key
          arr.dynamicTagOdds = list[i].value
          arr.inputType = 1
        } else if (list[i].type === 'checkout') {
          arr.type = 'checkout'
          arr.title = list[i].key
          arr.dynamicTagEvens = list[i].value
          arr.inputType = 2
        }
        this.CaseRowLists.push(arr)
      }
      // 显示编辑用户对话框
      this.editDialogVisible = true
      console.log('当前')
      console.log(this.CaseRowLists)
    },
    // 确定修改病例
    editCaseInfo: async function () {
      this.editForm.answer_list = []
      var arr = this.CaseRowLists
      for (var i = 0; i < arr.length; i++) {
        if (arr[i].type === 'input') {
          var obj = {
            key: arr[i].title,
            value: arr[i].content,
            type: arr[i].type
          }
          this.editForm.answer_list.push(obj)
        } else if (arr[i].type === 'radio') {
          var obj1 = {
            key: arr[i].title,
            value: arr[i].dynamicTagOdds,
            type: arr[i].type
          }
          this.editForm.answer_list.push(obj1)
        } else if (arr[i].type === 'checkout') {
          var obj2 = {
            key: arr[i].title,
            value: arr[i].dynamicTagEvens,
            type: arr[i].type
          }
          this.editForm.answer_list.push(obj2)
        }
      }
      var editFormInfo = {
        id: this.editForm.id,
        answer_list: this.editForm.answer_list,
        case_type_name: this.editForm.case_type_name
      }
      const data = await caseSet.editCase(this, editFormInfo)
      if (data.code !== 0) {
        this.$message.error('修改病例信息失败')
        this.editDialogVisible = false
        return
      }
      this.$message.success('修改病例信息成功')
      this.editDialogVisible = false
      this.getCaseList()
    }
  }
}
</script>

<style scoped>
  .el-tag + .el-tag {
    margin-left: 10px;
  }
  .button-new-tag {
    margin-left: 10px;
    height: 32px;
    line-height: 30px;
    padding-top: 0;
    padding-bottom: 0;
  }
  .input-new-tag {
    width: 90px;
    margin-left: 10px;
    vertical-align: bottom;
  }
</style>
