<template>
  <div>
    <el-row style="margin: 0 10px">
      <el-col :span="5" style="font-size: 18px">{{$t('sh.sh')}}</el-col>
      <el-col :span="5" style="float: right;">
        <!--面包屑导航-->
        <el-breadcrumb separator="/">
          <el-breadcrumb-item>{{$t('sh.sh')}}</el-breadcrumb-item>
        </el-breadcrumb>
      </el-col>
    </el-row>
    <el-card>
            <el-form :inline="true" :model="form" class="demo-form-inline">
<!--              <el-form-item>-->
<!--                <el-input v-model="form.keyword" placeholder="id/微信昵称"></el-input>-->
<!--              </el-form-item>-->
              <el-form-item :label="$t('sh.lx')">
                <el-select v-model="form.type" :placeholder="$t('sh.lx')" @change="getList">
                  <el-option :label="$t('sh.qb')" value=""></el-option>
                  <el-option :label="$t('imgM.tp')" value="1"></el-option>
                  <el-option :label="$t('video.sp')" value="2"></el-option>
                </el-select>
              </el-form-item>
<!--              <el-form-item>-->
<!--                <el-button type="primary" @click="getProductList">查询</el-button>-->
<!--              </el-form-item>-->
            </el-form>
      <!--      <el-button type="primary" @click="addGroup">新增分组</el-button>-->
      <el-table border :data="productList" height="670px">
        <el-table-column :label="$t('devDetail.wjmc')" prop="name"></el-table-column>
<!--        <el-table-column label="分组id" prop="group_id"></el-table-column>-->
        <el-table-column :label="$t('sh.lx')" prop="type">
          <template #default="scope">
            <span>{{scope.row.type == 1 ? $t('imgM.tp') : $t('video.sp')}}</span>
          </template>
        </el-table-column>
        <el-table-column :label="$t('sh.wjdx')" prop="size"></el-table-column>
        <el-table-column :label="$t('sh.zs')" prop="path">
          <template width="100px" slot-scope="scope">
            <el-image
              v-if="scope.row.type == 1"
              style="height: 100px"
              :src="url + scope.row.path"
              :preview-src-list="[url + scope.row.path]">
            </el-image>
            <video v-else controls muted style="width: auto;height: auto;max-height: 100px;max-width: 100px" :src="url + scope.row.path"></video>
          </template>
        </el-table-column>
        <el-table-column :label="$t('devDetail.zt')" prop="status">
          <template #default="scope">
            <el-tag v-if="scope.row.status == 0" type="warning">{{$t('sh.dsh')}}</el-tag>
            <el-tag v-if="scope.row.status == 1" type="success">{{$t('sh.shtg')}}</el-tag>
            <el-tag v-if="scope.row.status == 2" type="danger">{{$t('sh.shbtg')}}</el-tag>
          </template>
        </el-table-column>
        <el-table-column :label="$t('devDetail.cjsj')" prop="create_time"></el-table-column>
<!--        <el-table-column label="更新时间" prop="update_time"></el-table-column>-->
        <el-table-column :label="$t('home.cz')" width="180px">
          <template #default="scope">
            <el-button size="mini" type="primary" @click="verify(scope.row.id)">{{$t('sh.sh')}}</el-button>
          </template>
        </el-table-column>
      </el-table>
      <pagination :queryInfos="queryInfo" @pageChange="PageChange" queryUrl="Img/Examine" :pamams="'type=' + (form.type || '')"></pagination>
    </el-card>
    <el-dialog
      title="审核"
      :visible.sync="editDialogVisible"
      width="50%"
      @close="editDialogClosed"
    >
      <div style="display: flex;justify-content: center">
        <el-button type="primary" @click="verifyStatus(1)">{{$t('sh.shtg')}}</el-button>
        <el-button type="danger" @click="verifyStatus(2)">{{$t('sh.shbtg')}}</el-button>
      </div>
<!--      <el-form label-width="150px" :model="infoData" ref="editProductFormRef">-->
<!--        <el-form-item prop="name">-->
<!--          <el-button type="primary" @click="verifyStatus(0)"></el-button>-->
<!--          <el-button type="primary" @click="verifyStatus(1)">审核通过</el-button>-->
<!--          <el-button type="danger" @click="verifyStatus(2)">审核不通过</el-button>-->
<!--        </el-form-item>-->
<!--        <el-form-item label="描述" prop="desc">-->
<!--          <el-input v-model="infoData.desc"></el-input>-->
<!--        </el-form-item>-->
<!--        <el-form-item label="排名" prop="sort">-->
<!--          <el-input v-model="infoData.sort"></el-input>-->
<!--        </el-form-item>-->
<!--        <el-form-item>-->
<!--          <el-button @click="editDialogClosed">取 消</el-button>-->
<!--          <el-button type="primary" @click="editProduct">确 定</el-button>-->
<!--        </el-form-item>-->
<!--      </el-form>-->
    </el-dialog>
  </div>
</template>
<script>
import * as getVerifyList from '../../../interface/Img/Examine'
import pagination from '../../common/Pagination'
export default {
  name: 'Examine',
  components: { pagination },
  data: function () {
    return {
      url: this.url,
      productList: [],
      queryInfo: {
        query: '',
        pageNo: 1,
        pageSize: 2,
        total: 0
      },
      form: {},
      editDialogVisible: false,
      infoData: {},
      id: ''
    }
  },
  created () {
    this.getList()
  },
  methods: {
    // 下一页
    PageChange: function (e) {
      this.productList = e.productList
      this.queryInfo = e.queryInfo
    },
    // 获取列表
    getList: async function () {
      var p = '?type=' + (this.form.type || '')
      const data = await getVerifyList.verifyList(this, p)
      if (data.code !== 0) return this.$message.error(data.msg)
      this.productList = data.result.data
      this.queryInfo.total = data.result.totalCount
      this.queryInfo.pageNo = data.result.pageNo
      this.queryInfo.pageSize = data.result.pageSize
    },
    // 审核
    verify: function (id) {
      this.editDialogVisible = true
      this.id = id
    },
    editDialogClosed: function () {
      this.editDialogVisible = false
    },
    verifyStatus: async function (status) {
      var that = this
      that.$confirm(this.$t('sh.sfqrsh'), this.$t('home.ts'), {
        confirmButtonText: this.$t('home.qd'),
        cancelButtonText: this.$t('home.qx'),
        type: 'warning'
      }).then(async (res) => {
        console.log(res)
        const data = await getVerifyList.verifyBtn(this, { id: that.id, status: status })
        if (data.code !== 0) return that.$message.error(data.msg)
        that.$message.success(this.$t('sh.shwc'))
        that.getList()
        that.editDialogVisible = false
      }).catch(() => {
        that.$message({
          type: 'info',
          message: this.$t('sh.yqxsh')
        })
      })
    }
  }
}
</script>
<style scoped></style>
