<template>
    <div>
      <el-row style="margin: 0 10px">
        <el-col :span="2" style="font-size: 17px">收件登记</el-col>
        <el-col :span="5" style="float: right;">
          <!--面包屑导航-->
          <el-breadcrumb separator="/">
            <el-breadcrumb-item>售后管理</el-breadcrumb-item>
            <el-breadcrumb-item><a>收件登记</a></el-breadcrumb-item>
          </el-breadcrumb>
        </el-col>
      </el-row>
      <el-card>
        <el-button v-if="isGoBack" type="success" @click="returnMsg">返回</el-button>
        <p class="title">中亿医疗售后服务维修工单</p>
        <el-form label-position="left" ref="form" :model="form" label-width="100px" style="width: 70%;margin: auto">
          <div style="border: 1px solid #eee">
            <el-row>
              <el-col :span="7">
                <el-form-item label="维修点">
                  <el-select v-model="form.repair_id" placeholder="请选择维修点" :disabled="isDisabled">
                    <el-option v-for="(item, index) in getRepairData" :key="index" :label="item.title" :value="item.id"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="7">
                <el-form-item label="日期">
                  <el-input v-model="date" readonly :value="date"></el-input>
<!--                  <el-date-picker-->
<!--                    v-model="form.date"-->
<!--                    type="date"-->
<!--                    placeholder="选择日期">-->
<!--                  </el-date-picker>-->
                </el-form-item>
              </el-col>
              <el-col :span="7">
                <el-form-item label="维修单号">
                  <el-input v-model="form.order_no" readonly></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="7">
                <el-form-item label="维修方式">
                  <el-radio-group v-model="form.type" :disabled="isDisabled">
                    <el-radio :label="1" >邮寄</el-radio>
                    <el-radio :label="2">送修</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
              <el-col :span="7">
                <el-form-item label="物流公司">
                  <el-select v-model="form.express_id" placeholder="请选择物流公司" :disabled="isDisabled">
                    <el-option v-for="(item, index) in getExpressData" :key="index"  :label="item.title" :value="item.id"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="7">
                <el-form-item label="物流单号">
                  <el-input v-model="form.express_no" :readonly="isDisabled"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="7">
                <el-form-item label="客户姓名">
                  <el-input v-model="form.consignee" :readonly="isDisabled"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="7">
                <el-form-item label="联系电话">
                  <el-input v-model="form.consignee_mobile" :readonly="isDisabled"></el-input>
                </el-form-item>
              </el-col>
<!--              <el-col :span="7">-->
<!--                <el-form-item label="邮寄地址">-->
<!--                  <el-input v-model="form.name"></el-input>-->
<!--                </el-form-item>-->
<!--              </el-col>-->
            </el-row>
            <el-row>
              <el-col :span="22">
                <el-form-item label="邮寄地址">
                  <el-input v-model="form.consignee_address" :readonly="isDisabled"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
          <el-row class="title">送修产品信息</el-row>
          <div style="border: 1px solid #eee">
            <el-row>
              <el-col :span="10" style="margin-right: 70px">
                <el-form-item label="产品">
                  <el-select v-model="form.equipment_type_id" placeholder="请选择产品(必选)" :disabled="isDisabled" @change="selectCP">
                    <el-option v-for="(item, index) in getAllEqData" :key="index" :label="item.title" :value="item.id"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="10">
                <el-form-item label="产品编号">
                  <el-input v-model="form.equipment_no" :readonly="isDisabled"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </div>

          <el-row class="title">维修项目信息</el-row>
          <div>
<!--            <el-row>-->
<!--              <el-table :data="tableData" border>-->
<!--                <el-table-column type="index" label="序号" width="55"></el-table-column>-->
<!--                <el-table-column prop="date" label="项目/配件"></el-table-column>-->
<!--                <el-table-column prop="date" label="外观"></el-table-column>-->
<!--                <el-table-column prop="date" label="故障描述"></el-table-column>-->
<!--                <el-table-column prop="date" label="检修结论"></el-table-column>-->
<!--                <el-table-column prop="date" label="处理意见"></el-table-column>-->
<!--              </el-table>-->
<!--            </el-row>-->
            <div  style="border: 1px solid #eee">
              <div v-for="(item, index) in list" :key="index">
                <el-row>
                  <el-col :span="5">
                    <el-form-item label="外观">
                      <el-select v-model="item.appearance_id" placeholder="请选择外观" :disabled="isDisabled">
                        <el-option v-for="(item1, index1) in repairAppearanceData" :key="index1" :label="item1.title" :value="item1.id"></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="5">
                    <el-form-item label="故障描述">
                      <el-select v-model="item.error_desc_id" placeholder="请选择故障" :disabled="isDisabled">
                        <el-option v-for="(item2, index2) in RepairErrorDescData" :key="index2" :label="item2.title" :value="item2.id"></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="4" v-if="isDisabled">
                    <el-form-item label="检修结论">
                      <el-select v-model="item.conclusion_id" placeholder="请选择检修结论" :disabled="isShowHJ">
                        <el-option v-for="(item3, index3) in RepairConclusionData" :key="index3" :label="item3.title" :value="item3.id"></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="4" v-if="isDisabled">
                    <el-form-item label="处理意见">
                      <el-select v-model="item.resolution_id" placeholder="请选择处理意见" :disabled="isShowHJ">
                        <el-option v-for="(item4, index4) in RepairResolutionData" :key="index4" :label="item4.title" :value="item4.id"></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="2" v-if="!isDisabled">
                    <el-button v-if="index != 0" type="danger" @click="deleteMeal(index)">删除</el-button>
                  </el-col>
                </el-row>
<!--                <el-row v-if="isShowRepair">-->
<!--                  <el-col :span="20" v-if="!isDisabled">-->
<!--                    <el-button v-if="index != 0" type="danger" @click="deleteMeal(index)">删除</el-button>-->
<!--                  </el-col>-->
<!--                </el-row>-->
              </div>
              <el-row style="display: flex;justify-content: center;padding-bottom: 20px;" v-if="!isDisabled">
                <el-button type="primary" @click="addSubmit">新增</el-button>
              </el-row>
            </div>
          </div>
          <el-row class="title" v-if="isShowPJ">配件信息</el-row>
<!--          <el-row v-if="isShowPJ">-->
<!--            <el-table :data="tableData" border>-->
<!--              <el-table-column type="index" label="序号" width="55"></el-table-column>-->
<!--              <el-table-column prop="date" label="配件名称"></el-table-column>-->
<!--              <el-table-column prop="date" label="单位"></el-table-column>-->
<!--              <el-table-column prop="date" label="数量"></el-table-column>-->
<!--              <el-table-column prop="date" label="单价"></el-table-column>-->
<!--              <el-table-column prop="date" label="总价"></el-table-column>-->
<!--              <el-table-column prop="date" label="备注"></el-table-column>-->
<!--            </el-table>-->
<!--          </el-row>-->
          <div style="border: 1px solid #eee" v-if="isShowPJ">
            <div v-for="(item, index) in pjList" :key="index">
              <el-row>
                <el-col :span="2" v-if="index == 0">
                  <el-button type="primary" @click="addSubmitPJ">新增</el-button>
                </el-col>
                <div>
                  <el-col :span="2" v-if="index != 0">
                    <el-button type="danger" @click="deleteMealPJ(index)">删除</el-button>
                  </el-col>
                  <el-col :span="4">
                    <el-form-item label="配件名称">
                      <el-select v-model="item.parts_id" placeholder="请选择配件名称" :disabled="isShowHJ">
                        <el-option v-for="(item4, index4) in RepairPartsData" :key="index4" :label="item4.name" :value="item4.id" @click.native ="selectPJ(index, index4)"></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="3">
                    <el-form-item label="数量" label-width="60px">
                      <el-input v-model="item.num" @keyup.enter.native="inputNum(index)" :readonly="isShowHJ"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="3">
                    <el-form-item label="单位" label-width="60px">
                      <el-input v-model="item.unit" :readonly="true"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="4">
                    <el-form-item label="单价" label-width="60px">
                      <el-input v-model="item.price" :readonly="true"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="4">
                    <el-form-item label="总价" label-width="60px">
                      <el-input v-model="item.total_price" :readonly="isShowHJ"></el-input>
                    </el-form-item>
                  </el-col>
                </div>
              </el-row>
              <el-row>
                <el-col :span="20">
                  <el-form-item label="备注">
                    <el-input v-model="item.remoke" :readonly="isShowHJ"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </div>
          </div>
          <el-row class="title" v-if="isShowHJ">回寄信息</el-row>
          <div style="border: 1px solid #eee" v-if="isShowHJ">
            <el-row>
              <el-col :span="7">
                <el-form-item label="收件人姓名">
                  <el-input v-model="form1.consignee"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="7">
                <el-form-item label="收件人电话">
                  <el-input v-model="form1.consignee_mobile"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="7">
                <el-form-item label="回寄地址">
                  <el-input v-model="form1.consignee_address"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="10" style="margin-right: 70px">
                <el-form-item label="物流公司">
                  <el-select v-model="form1.express_id" placeholder="请选择物流公司">
                    <el-option v-for="(item, index) in getExpressData" :key="index"  :label="item.title" :value="item.id"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="10">
                <el-form-item label="物流单号">
                  <el-input v-model="form1.express_no"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
<!--            <el-row>-->
<!--              <el-col :span="22">-->
<!--                <el-form-item label="备注">-->
<!--                  <el-input type="textarea" v-model="form1.desc"></el-input>-->
<!--                </el-form-item>-->
<!--              </el-col>-->
<!--            </el-row>-->
<!--            <el-row>-->
<!--              <el-col :span="7">-->
<!--                <el-form-item label="收件人">-->
<!--                  <el-input v-model="form1.name"></el-input>-->
<!--                </el-form-item>-->
<!--              </el-col>-->
<!--              <el-col :span="7">-->
<!--                <el-form-item label="维修检测">-->
<!--                  <el-input v-model="form1.name"></el-input>-->
<!--                </el-form-item>-->
<!--              </el-col>-->
<!--              <el-col :span="7">-->
<!--                <el-form-item label="发货人">-->
<!--                  <el-input v-model="form1.name"></el-input>-->
<!--                </el-form-item>-->
<!--              </el-col>-->
<!--            </el-row>-->
          </div>
          <el-row style="display: flex;justify-content: center;padding-top: 30px;">
            <el-button type="primary" @click="onSubmit">提交</el-button>
          </el-row>
        </el-form>
      </el-card>
    </div>
</template>

<script>
import * as getReceiptRegister from '../../interface/AfterSalesManage/ReceiptRegister'
export default {
  name: 'ReceiptRegister',
  data: function () {
    return {
      isGoBack: false,
      type: '',
      date: '',
      form: { type: 1 },
      form1: {},
      formData: {},
      orderParts: {},
      tableData: [],
      timeId: '',
      getAllEqData: [],
      getExpressData: [],
      getRepairData: [],
      repairAppearanceData: [],
      RepairConclusionData: [],
      RepairErrorDescData: [],
      RepairPartsData: [],
      RepairResolutionData: [],
      isShowRepair: false,
      isShowPJ: false,
      isShowHJ: false,
      isDisabled: false,
      list: [
        { appearance_id: '', error_desc_id: '', conclusion_id: '', resolution_id: '' }
      ],
      pjList: [
        { parts_id: '', num: '', price: '', total_price: '', remoke: '', order_id: this.$route.query.id }
      ]
    }
  },
  created () {
    this.getId()
    this.getAllEq()
  },
  methods: {
    // 获取单号
    getOrderNos: async function () {
      const data = await getReceiptRegister.getOrderNoAndTime(this)
      if (data.code !== 0) return this.$message.error('获取单号失败')
      this.form.order_no = data.result.order_no
    },
    returnMsg: function () {
      this.$router.go(-1)
    },
    getAllEq: async function () {
      // 获取全部产品
      const data = await getReceiptRegister.equipmentTypeAll(this)
      if (data.code !== 0) return this.$message.error('获取全部产品列表失败')
      this.getAllEqData = data.result
      // 获取物流
      const data1 = await getReceiptRegister.getExpressList(this)
      if (data1.code !== 0) return this.$message.error('获取物流列表失败')
      this.getExpressData = data1.result
      // 获取维修点列表
      const data2 = await getReceiptRegister.getRepairList(this)
      if (data2.code !== 0) return this.$message.error('获取维修点失败')
      this.getRepairData = data2.result.data
    },
    // 选择产品
    selectCP: async function () {
      console.log(this.form.equipment_type_id)
      this.list = [{}]
      // 获取外观选项-列表
      const data3 = await getReceiptRegister.repairAppearanceList(this, this.form.equipment_type_id)
      if (data3.code !== 0) return this.$message.error('获取外观选项失败')
      this.repairAppearanceData = data3.result
      // 检修结论-列表
      const data4 = await getReceiptRegister.RepairConclusionList(this, this.form.equipment_type_id)
      if (data4.code !== 0) return this.$message.error('获取检修结论失败')
      this.RepairConclusionData = data4.result
      // 故障描述-列表
      const data5 = await getReceiptRegister.RepairErrorDescList(this, this.form.equipment_type_id)
      if (data5.code !== 0) return this.$message.error('获取故障描述失败')
      this.RepairErrorDescData = data5.result
      // 处理意见-列表
      const data6 = await getReceiptRegister.RepairResolutionlist(this, this.form.equipment_type_id)
      if (data6.code !== 0) return this.$message.error('获取处理意见失败')
      this.RepairResolutionData = data6.result
      // 获取全部配件
      const data7 = await getReceiptRegister.RepairPartsAll(this, this.form.equipment_type_id)
      if (data7.code !== 0) return this.$message.error('获取配件失败')
      this.RepairPartsData = data7.result
    },
    getId: function () {
      console.log(this.$route.query.type)
      this.type = this.$route.query.type
      // this.getOrderNo
      if (this.$route.query.type === '1') {
        // 维修
        console.log('维修')
        this.isShowRepair = true
        this.isDisabled = true
        this.isShowPJ = true
        this.getRepairOrderinfo()
        this.isGoBack = true
      } else if (this.$route.query.type === '2') {
        // 发货
        console.log('发货')
        this.isShowRepair = true
        this.isDisabled = true
        this.isShowPJ = true
        this.isShowHJ = true
        this.isGoBack = true
        this.getRepairOrderinfo()
      } else {
        console.log('11111')
        this.isShowRepair = false
        this.isDisabled = false
        this.isShowPJ = false
        this.isShowHJ = false
        this.isGoBack = false
        this.list = [
          { appearance_id: '', error_desc_id: '', conclusion_id: '', resolution_id: '' }
        ]
        this.form = { type: 1 }
        this.getOrderNos()
      }
    },
    // 获取订单详情
    getRepairOrderinfo: async function () {
      const data = await getReceiptRegister.RepairOrderInfo(this, this.$route.query.id)
      if (data.code !== 0) return this.$message.error('获取维修订单详情失败')
      this.form = data.result
      this.selectCP()
      var list = []
      var list1 = []
      for (var i = 0; i < data.result.orderItemList.length; i++) {
        var arr = {
          order_id: this.$route.query.id,
          appearance_id: data.result.orderItemList[i].appearance_id,
          error_desc_id: data.result.orderItemList[i].error_desc_id,
          conclusion_id: data.result.orderItemList[i].conclusion_id,
          resolution_id: data.result.orderItemList[i].resolution_id
        }
        list.push(arr)
      }
      for (var j = 0; j < data.result.partsList.length; j++) {
        var arr1 = {
          order_id: this.$route.query.id,
          parts_id: data.result.partsList[j].parts_id,
          num: data.result.partsList[j].num,
          unit: data.result.partsList[j].unit,
          price: data.result.partsList[j].price,
          total_price: data.result.partsList[j].total_price,
          remoke: data.result.partsList[j].remoke
        }
        list1.push(arr1)
      }
      if (data.result.address) {
        var arr2 = {
          order_id: this.$route.query.id,
          consignee: data.result.address.consignee,
          consignee_mobile: data.result.address.consignee_mobile,
          consignee_address: data.result.address.consignee_address,
          express_id: data.result.address.express_id,
          express_no: data.result.address.express_no
        }
        this.form1 = arr2
      }
      this.list = list
      console.log(this.list)
      if (list1.length === 0) list1 = [{ parts_id: '', num: '', price: '', total_price: '', remoke: '', order_id: this.$route.query.id }]
      this.pjList = list1
    },
    padaDate: function (value) {
      return value < 10 ? '0' + value : value
    },
    showTime: function () {
      const nowtime = new Date()
      const year = nowtime.getFullYear()
      const month = this.padaDate(nowtime.getMonth() + 1)
      const date = this.padaDate(nowtime.getDate())
      const h = this.padaDate(nowtime.getHours())
      const m = this.padaDate(nowtime.getMinutes())
      const s = this.padaDate(nowtime.getSeconds())
      return year + '年' + month + '月' + date + '日' + ' ' + h + ':' + m + ':' + s
    },
    // 确认创建
    onSubmit: async function () {
      if (this.type === '1') {
        // 维修
        this.formData.orderParts = this.pjList
        this.formData.id = this.$route.query.id
        this.formData.orderItem = this.list
        this.formData.orderItem.order_id = this.$route.query.id
        const data = await getReceiptRegister.RepairOrderRepair(this, this.formData)
        if (data.code !== 0) return this.$message.error(data.msg)
        this.$message.success('维修提交成功')
        this.$router.push('MaintenanceList')
      } else if (this.type === '2') {
        // 发货
        var arrList = {}
        this.form1.order_id = this.$route.query.id
        arrList.addressInfo = this.form1
        arrList.id = this.$route.query.id
        const data = await getReceiptRegister.repairOrderAddAddress(this, arrList)
        if (data.code !== 0) return this.$message.error(data.msg)
        this.$message.success('发货提交成功')
        this.$router.push('MaintenanceList')
      } else {
        // 添加
        var list = []
        for (var i = 0; i < this.list.length; i++) {
          var arr = {
            appearance_id: this.list[i].appearance_id,
            error_desc_id: this.list[i].error_desc_id
          }
          list.push(arr)
        }
        this.form.order_item = list
        const data = await getReceiptRegister.addRepairOrder(this, this.form)
        if (data.code !== 0) return this.$message.error(data.msg)
        this.$message.success('创建成功')
        this.form = {}
        this.list = [{ appearance_id: '', error_desc_id: '', conclusion_id: '', resolution_id: '' }]
      }
    },
    // 增加维修项
    addSubmit: function () {
      this.list.push({ appearance_id: '', error_desc_id: '', conclusion_id: '', resolution_id: '' })
    },
    // 增加配置
    addSubmitPJ: function () {
      this.pjList.push({ parts_id: '', num: '', price: '', total_price: '', remoke: '', order_id: this.$route.query.id })
    },
    // 删除维修项
    deleteMeal: function (i) {
      this.list.splice(i, 1)
    },
    // 删除配置项
    deleteMealPJ: function (i) {
      this.pjList.splice(i, 1)
    },
    // 选择配件
    selectPJ: async function (index, index4) {
      var arr1 = {
        order_id: this.$route.query.id,
        parts_id: this.RepairPartsData[index4].id,
        num: this.RepairPartsData[index4].num,
        unit: this.RepairPartsData[index4].unit,
        price: this.RepairPartsData[index4].price,
        total_price: this.RepairPartsData[index4].total_price,
        remoke: this.RepairPartsData[index4].remoke
      }
      this.pjList[index] = arr1
      console.log(this.pjList)
    },
    // 获取数量
    inputNum: function (index) {
      this.pjList[index].total_price = parseInt(this.pjList[index].num) * parseFloat(this.pjList[index].price)
    }
  },
  mounted () {
    var that = this
    this.timeId = setInterval(function () {
      that.date = that.showTime()
    }, 1000)
  },
  beforeDestroy () {
    if (this.timeId) {
      clearInterval(this.timeId)
    }
  }
}
</script>

<style scoped>
  .el-col{
    float: left;
    margin-left: 20px;
    margin-top: 15px;
    /*border: 1px solid black;*/
  }
  .title{
    font-size: 20px;
    line-height: 40px;
    font-weight: 600;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  .el-date-editor{
    width: 100% !important;
  }
  .el-select{
    width: 100%;
  }
</style>
