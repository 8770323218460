<template>
  <div>
    <el-button type="primary" @click="dialogVisible=true">{{$t('add.tj') + ' ' + addNames}}</el-button>
    <el-dialog
      :title="$t('add.tj') + ' ' +addNames"
      :visible.sync="dialogVisible"
      width="50%"
      @close="addProductDialogClosed"
    >
      <!--添加用户的表单-->
      <el-form
        label-width="150px"
        :model="addProductForm"
        :rules="addProductRules"
        ref="addProductFormRef"
      >
        <el-form-item
          v-for="item in addProductFormList"
          :key="item.index"
          :label="item.title"
          :prop="item.prop"
        >
          <el-input v-model="addProductForm[item.prop]" v-if="item.title===$t('admin.mm')" type="password" style="position: fixed; bottom: -99999px;display: none"></el-input>
          <el-input v-model="addProductForm[item.prop]" auto-complete="new-password" v-if="item.title===$t('admin.mm')" type="password" @input="change()"></el-input>
          <el-radio-group v-else-if="item.title==='是否默认'" v-model="addProductForm[item.prop]">
            <el-radio :label=0>不设为默认</el-radio>
            <el-radio :label=1>设为默认</el-radio>
          </el-radio-group>
          <el-radio-group v-else-if="item.title===$t('devDetail.zt')" v-model="addProductForm[item.prop]">
            <el-radio :label="0">{{$t('admin.zc')}}</el-radio>
            <el-radio :label="1">{{$t('admin.jy')}}</el-radio>
          </el-radio-group>
          <el-select v-else-if="item.title==='维修点人员'" v-model="user_ids" placeholder="维修点人员">
            <el-option label="全部" value=""></el-option>
            <el-option v-for="(item, index) in adminLists" :key="index" :label="item.username" :value="item.id"></el-option>
          </el-select>
<!--          <div class="block" v-else-if="item.title==='一级代理' || item.title==='二级代理' || item.title==='三级代理' || item.title==='场地'">-->
<!--            <span class="demonstration">请选择代理</span>-->
<!--            <el-cascader-->
<!--              v-model="getIdArr"-->
<!--              :options="options"-->
<!--              @change="handleChange"></el-cascader>-->
<!--          </div>-->
          <el-select v-else-if="item.title===$t('home.khmc')" v-model="addProductForm[item.prop]" :placeholder="$t('common.sel')" @change="((val)=> {selectId(val ,0)})" filterable>
            <!-- <el-option :label="$t('add.qxz')" value=""></el-option> -->
            <el-option
              v-for="(item, index) in getItemAll" :key="index" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
<!--          <el-select v-else-if="item.title==='客户名称'" v-model="addProductForm[item.prop]" placeholder="客户名称" @change="((val)=> {selectId(val ,0)})">-->
<!--            <el-option label="请选择" value=""></el-option>-->
<!--            <el-option v-for="(item, index) in getItemAll" :key="index" :label="item.name" :value="item.id"></el-option>-->
<!--          </el-select>-->
<!--          <el-select v-else-if="item.title==='一级代理'" v-model="addProductForm[item.prop]" placeholder="一级代理" @change="((val)=> {selectId(val ,0)})">-->
<!--            <el-option label="请选择" value=""></el-option>-->
<!--            <el-option v-for="(item, index) in getItemAll" :key="index" :label="item.name" :value="item.id"></el-option>-->
<!--          </el-select>-->
          <el-select v-else-if="item.title==='二级代理'" v-model="addProductForm[item.prop]" placeholder="二级代理" @change="((val)=> {selectId(val ,1)})">
            <el-option label="请选择" value=""></el-option>
            <el-option v-for="(item, index) in getAgentAll" :key="index" :label="item.name" :value="item.id"></el-option>
          </el-select>
          <el-select v-else-if="item.title==='三级代理'" v-model="addProductForm[item.prop]" placeholder="三级代理" @change="((val)=> {selectId(val ,2)})">
            <el-option label="请选择" value=""></el-option>
            <el-option v-for="(item, index) in getCityAll" :key="index" :label="item.name" :value="item.id"></el-option>
          </el-select>
          <el-select v-else-if="item.title==='场地'" v-model="addProductForm[item.prop]" placeholder="场地" @change="((val)=> {selectId(val ,3)})">
            <el-option label="请选择" value=""></el-option>
            <el-option v-for="(item, index) in getSceneAll" :key="index" :label="item.name" :value="item.id"></el-option>
          </el-select>
<!--          省市区联动-->
          <el-cascader
            size="large"
            :options="optionsAgent"
            v-model="selectedOptions"
            @change="handleChange" v-else-if="item.title==='选择省市区'" style="width: 100%"></el-cascader>
<!--          维修点人员列表-->
          <el-checkbox-group v-else-if="item.title==='维修点人员'" v-model="user_ids">
            <el-checkbox v-for="item in adminList" :key="item.id" :label="item.id">{{item.name}}</el-checkbox>
          </el-checkbox-group>
          <el-select v-else-if="item.title===$t('role.js')" v-model="addProductForm[item.prop]" :placeholder="$t('role.js')">
            <el-option :label="$t('sh.qb')" value=""></el-option>
            <el-option v-for="(item, index) in adminUserList" :key="index" :label="item.name" :value="item.id"></el-option>
          </el-select>
          <el-input
            v-else-if="item.title==='支付宝私钥'"
            type="textarea"
            :rows="3"
            placeholder="请输入内容"
            v-model="addProductForm[item.prop]">
          </el-input>
          <el-input
            v-else-if="item.title==='支付宝公钥'"
            type="textarea"
            :rows="3"
            placeholder="请输入内容"
            v-model="addProductForm[item.prop]">
          </el-input>
          <el-select v-else-if="item.title===$t('common.type')" v-model="addProductForm[item.prop]" :placeholder="$t('common.sel')">
            <el-option v-for="item in typeList" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
          <el-input v-model="addProductForm[item.prop]" autocomplete="off" v-else @input="change()"></el-input>
        </el-form-item>
      </el-form>

      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogVisible = false">{{$t('home.qx')}}</el-button>
          <el-button :type="isBtn ? 'primary' : ''" @click="addProduct">{{$t('home.qd')}}</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import * as getDevice from '../../interface/Img/DeviceLists'
import { regionData, CodeToText } from 'element-china-area-data'
export default {
  props: ['addForm', 'addRules', 'addApi', 'addProductFormLists', 'addName', 'adminLists', 'isBtns', 'groupId'],
  data: function () {
    return {
      dialogVisible: false,
      addProductForm: this.addForm,
      addProductRules: this.addRules,
      addProductFormList: this.addProductFormLists,
      addNames: this.addName,
      options: regionData,
      selectedOptions: [],
      addtions: {},
      adminList: [],
      user_ids: '',
      isBtn: true,
      getItemAll: [],
      getAgentAll: [],
      getCityAll: [],
      getSceneAll: [],
      getIdArr: ['', '', '', ''],
      adminUserList: [],
      // 类型列表
      typeList: []
    }
  },
  created () {
    for (const keys in this.addProductFormLists) {
      if (this.addProductFormLists[keys].title === '维修点人员') {
        this.getAdmin()
      }
      if (this.addProductFormLists[keys].title === this.$t('role.js')) {
        this.getAdminUser()
      }
      if (this.addProductFormLists[keys].title === this.$t('common.type')) {
        this.getTypeList()
      }
    }
    this.Agent()
  },
  methods: {
    // 获取类型列表
    async getTypeList(){
      const data = await getDevice.getTypeList(this, '')
      if (data.code !== 0) return this.$message.error('获取类型列表失败')
      this.typeList = data.result
    },
    // 获取角色
    getAdminUser: async function () {
      const resAll = await import('../../interface/' + this.addApi)
      const data = await resAll.adminGetAll(this)
      if (data.code !== 0) return this.$message.error(this.$t('role.hqjssbs'))
      this.adminUserList = data.result
    },
    // 获取代理
    Agent: function () {
      this.Agent1()
      this.Agent2()
      this.Agent3()
      this.Agent4()
    },
    Agent1: async function () {
      // 获取一级代理
      const data = await getDevice.getItemAll(this, '')
      if (data.code !== 0) return this.$message.error('获取一级代理失败')
      this.getItemAll = data.result
    },
    Agent2: async function () {
      // 获取二级代理
      const data1 = await getDevice.getAgentAll(this, '?item_id=' + this.getIdArr[0])
      if (data1.code !== 0) return this.$message.error('获取二级代理失败')
      this.getAgentAll = data1.result
    },
    Agent3: async function () {
      // 获取三级代理
      const data2 = await getDevice.getCityAll(this, '?item_id=' + this.getIdArr[0] + '&agent_id=' + this.getIdArr[1])
      if (data2.code !== 0) return this.$message.error('获取三级代理失败')
      this.getCityAll = data2.result
    },
    Agent4: async function () {
      // 获取场地
      const data3 = await getDevice.getSceneAll(this, '?item_id=' + this.getIdArr[0] + '&agent_id=' + this.getIdArr[1] + '&city_id=' + this.getIdArr[2])
      if (data3.code !== 0) return this.$message.error('获取三级代理失败')
      this.getSceneAll = data3.result
    },
    selectId: function (id, index) {
      // console.log(index)
      this.getIdArr[index] = id
      console.log(this.getIdArr)
      if (index === 0) {
        this.Agent2()
        this.Agent3()
        this.Agent4()
      } else if (index === 1) {
        this.Agent3()
        this.Agent4()
      } else if (index === 2) {
        this.Agent4()
      }
    },
    // 获取管理员列表
    getAdmin: async function () {
      const resAll = await import('../../interface/' + this.addApi)
      const data = await resAll.adminList(this)
      console.log('获取所有管理员列表')
      console.log(data)
      this.adminList = data.result
    },
    addProductDialogClosed () {
      this.$refs.addProductFormRef.resetFields()
    },
    addProduct () {
      if (!this.isBtn) return false
      this.isBtn = false
      this.$refs.addProductFormRef.validate(async valid => {
        console.log(this.addProductForm)
        if (this.user_ids !== '') {
          this.addProductForm.user_ids = []
          this.addProductForm.user_ids.push(this.user_ids)
        }
        // 验证失败
        if (!valid) {
          this.isBtn = true
          return this.$message.error(this.$t('edit.qtxwzxx'))
        }
        if ((this.groupId && this.groupId !== '') || this.groupId === 0) this.addProductForm.group_id = this.groupId
        // 提交添加用户的请求
        const resAll = await import('../../interface/' + this.addApi)
        const data = await resAll.addProduct(this, this.addProductForm)
        if (data.code !== 0) {
          this.isBtn = true
          this.$message.error(this.$t('add.tjsb') + ',' + data.msg)
          // this.dialogVisible = false
          return
        }
        this.$message.success(this.$t('add.tjcg'))
        // 关闭对话框
        this.dialogVisible = false
        this.isBtn = true
        // 重新请求数据
        // this.getProductList()
        this.$emit('gainMsgs')
      })
    },
    change: function () {
      this.$forceUpdate()
    },
    handleChange (value) {
      // 我们选择地址后，selectedOptions 会保存对应的区域码，例如北京的区域码为'110000'
      // 我们要取出区域码对应的汉字，就需要用CodeToText(区域码)进行输出
      this.addtions.selectedOptions = value
      var name = ''
      for (var i = 0; i < this.selectedOptions.length; i++) {
        name = name + CodeToText[this.selectedOptions[i]] + '/'
        if (i === 0) {
          this.addProductForm.province = CodeToText[this.selectedOptions[i]]
        } else if (i === 1) {
          this.addProductForm.city = CodeToText[this.selectedOptions[i]]
        } else if (i === 2) {
          this.addProductForm.district = CodeToText[this.selectedOptions[i]]
        }
      }
      // this.selectedOptions.map(item => name += CodeToText[item] + '/')
      this.addtions.names = name
      console.log(this.addtions.names)
      console.log(this.addtions)
    }
  }
}
</script>

<style scoped>

</style>
