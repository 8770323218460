// api 地址
const api = {
  productList: 'admin/user/list',
  addProduct: 'admin/user/add',
  editProductDialog: 'admin/user/info?id=',
  editProduct: 'admin/user/edit?id=',
  deleteProduct: 'admin/user/del?id=',
  setStatusList: 'admin/user/setStatus?id=',
  delUserList: 'admin/User/del?id='
}
// 获取产品列表
export async function productList (ele, param) {
  var { data } = await ele.$http.get(api.productList + param)
  return data
}
export async function pageChange (ele, param) {
  for (var i in param) {
    var { data } = await ele.$http.get(api.productList + '?' + i + '=' + param[i])
  }
  return data
}
export async function pageChanges (ele, param, pamams) {
  var p = []
  for (var i in param) {
    p.push(i + '=' + param[i])
  }
  var { data } = await ele.$http.get(api.productList + '?' + p[0] + '&' + p[1] + '&' + pamams)
  return data
}
// 添加产品
export async function addProduct (ele, param) {
  var { data } = await ele.$http.post(api.addProduct, param)
  return data
}

// 根据id查询产品信息
export async function editProductDialog (ele, param) {
  var { data } = await ele.$http.get(api.editProductDialog + param)
  return data
}

// 提交产品修改的信息
export async function editProduct (ele, param1, param2) {
  var { data } = await ele.$http.put(api.editProduct + param1, param2)
  return data
}
// 删除产品的信息
export async function deleteProduct (ele, param) {
  var { data } = await ele.$http.delete(api.deleteProduct + param)
  return data
}
// 冻结/解冻
export async function setStatus (ele, param) {
  const { data } = await ele.$http.put(api.setStatusList + param)
  return data
}
// 删除
export async function delUser (ele, param) {
  const { data } = await ele.$http.delete(api.delUserList + param)
  return data
}
