const api = {
  getRepairOrderList: 'admin/RepairOrder/list'
}
// 获取维修列表
export async function getRepairOrder (ele, param) {
  var api1
  if (param) {
    api1 = api.getRepairOrderList + param
  } else {
    api1 = api.getRepairOrderList
  }
  const { data } = await ele.$http.get(api1)
  return data
}
export async function pageChanges (ele, param, pamams) {
  var p = []
  for (var i in param) {
    p.push(i + '=' + param[i])
  }
  var { data } = await ele.$http.get(api.getRepairOrderList + '?' + p[0] + '&' + p[1] + '&' + pamams)
  return data
}
