<template>
    <div>
      <el-row style="margin: 0 10px">
        <el-col :span="2" style="font-size: 18px">病例列表</el-col>
        <el-col :span="5" style="float: right;">
          <!--面包屑导航-->
          <el-breadcrumb separator="/">
            <el-breadcrumb-item>会员管理</el-breadcrumb-item>
            <el-breadcrumb-item><a>病例列表</a></el-breadcrumb-item>
          </el-breadcrumb>
        </el-col>
      </el-row>
      <el-card>
<!--        <el-button type="primary" @click="addPop">新增</el-button>-->
        <el-form :inline="true" :model="data" class="demo-form-inline">
          <el-form-item>
            <el-input v-model="data.id" placeholder="会员编号"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="getUserCase(data.id)">查询</el-button>
          </el-form-item>
        </el-form>
        <el-table
          ref="filterTable"
          :data="preEntryData"
          border
          style="width: 100%">
          <el-table-column type="index" label="序号" width="50px"></el-table-column>
          <el-table-column prop="user_id" label="会员编号"></el-table-column>
          <el-table-column prop="levels" label="会员级别"></el-table-column>
          <el-table-column prop="card_name" label="会员姓名"></el-table-column>
          <el-table-column prop="sexs" label="性别"></el-table-column>
          <el-table-column prop="case_type" label="病例类别"></el-table-column>
          <el-table-column prop="create_time" label="创建时间"></el-table-column>
          <el-table-column prop="updated_at" label="操作">
            <template #default="scope">
              <el-button type="success" @click="detail(scope.row.id)">详细信息</el-button>
            </template>
          </el-table-column>
        </el-table>
        <page :queryInfos="queryInfo" @pageChange="PageChange" queryUrl="memberManage/CaseList" :pamams="'user_id=' + data.id"></page>
      </el-card>
    </div>
</template>

<script>
import * as getCaseList from '../../interface/memberManage/CaseList'
import Pagination from '../common/Pagination'
export default {
  name: 'CaseList',
  components: {
    page: Pagination
  },
  data: function () {
    return {
      preEntryData: [],
      queryInfo: {
        query: '',
        pageNo: 1,
        pageSize: 2,
        total: 0
      },
      data: {}
    }
  },
  created () {
    this.getUserCase()
  },
  methods: {
    PageChange (e) {
      var preEntryData = e.productList
      for (var i = 0; i < preEntryData.length; i++) {
        var levels = preEntryData[i].level === 0 ? '普通会员' : (preEntryData[i].level === 1 ? '已绑定设备会员' : '其他会员')
        var sexs = preEntryData[i].sex === 0 ? '未知' : (preEntryData[i].sex === 1 ? '男' : '女')
        preEntryData[i].levels = levels
        preEntryData[i].sexs = sexs
      }
      this.preEntryData = preEntryData
      this.queryInfo = e.queryInfo
      // this.getUserCase()
    },
    detail: function (id) {
      this.$router.push('caseDetailList')
      sessionStorage.setItem('caseId', id)
    },
    addPop: function () {
      this.$message.success('功能开发中')
    },
    // 获取病例列表
    getUserCase: async function (id) {
      var p = ''
      if (id) p = '?user_id=' + id
      const data = await getCaseList.getUserCase(this, p)
      if (data.code !== 0) return this.$message.error('获取病例列表失败')
      var preEntryData = data.result.data
      this.queryInfo.total = data.result.totalCount
      this.queryInfo.pageNo = data.result.pageNo
      this.queryInfo.pageSize = data.result.pageSize
      for (var i = 0; i < preEntryData.length; i++) {
        var levels = ''
        var sexs = ''
        switch (preEntryData[i].level) {
          case 0:
            levels = '普通会员'
            break
          case 1:
            levels = '已绑定设备会员'
            break
          case 2:
            levels = '其他会员'
            break
        }
        switch (preEntryData[i].sex) {
          case 0:
            sexs = '未知'
            break
          case 1:
            sexs = '男'
            break
          case 2:
            sexs = '女'
            break
        }
        preEntryData[i].levels = levels
        preEntryData[i].sexs = sexs
      }
      this.preEntryData = preEntryData
    }
  }
}
</script>

<style scoped>

</style>
