// api 地址
const api = {
  agentList: 'admin/Agent/list?agent_type=1',
  salesman: 'admin/Agent/list?agent_type=2',
  store: 'admin/Agent/list?agent_type=3',
  addAgentList: 'admin/Agent/add',
  getTree: 'admin/Agent/getAgentTree',
  editList: 'admin/Agent/edit',
  deleteList: '/admin/agent/del?id=',
  getInfo: 'admin/Agent/info',
  getAllMerchant: '/admin/Merchant/getAll'
}
// 获取代理商
export async function getAgentList (ele, param) {
  var { data } = await ele.$http.get(api.agentList + param)
  return data
}
// 获取业务员
export async function getSalesman (ele, param) {
  var { data } = await ele.$http.get(api.salesman + param)
  return data
}
// 获取店铺管理员
export async function getStore (ele, param) {
  var { data } = await ele.$http.get(api.store + param)
  return data
}
// 增加代理商
export async function addAgent (ele, param) {
  var { data } = await ele.$http.post(api.addAgentList, param)
  return data
}
// 获取代理
export async function getList (ele) {
  var { data } = await ele.$http.get(api.getTree)
  return data
}
// 编辑代理
export async function editAgent (ele, param) {
  var { data } = await ele.$http.put(api.editList, param)
  return data
}
// 删除
export async function deleteAgentList (ele, param) {
  var { data } = await ele.$http.delete(api.deleteList + param)
  return data
}
// 查询
export async function getAgentListInfo (ele, param) {
  var { data } = await ele.$http.get(api.getInfo + param)
  return data
}
export async function pageChange (ele, param) {
  for (var i in param) {
    var { data } = await ele.$http.get(api.agentList + '?' + i + '=' + param[i])
  }
  return data
}
export async function pageChanges (ele, param, pamams) {
  var p = []
  for (var i in param) {
    p.push(i + '=' + param[i])
  }
  var { data } = await ele.$http.get(api.agentList + '&' + p[0] + '&' + p[1] + '&' + pamams)
  return data
}
// 获取全部代理商
export async function getAllMerchant (ele) {
  var { data } = await ele.$http.get(api.getAllMerchant)
  return data
}
