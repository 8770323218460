<template>
  <div>
    <el-row style="margin: 0 10px">
      <el-col :span="4" style="font-size: 18px">{{$t('devDetail.sbxq')}}</el-col>
      <el-col :span="12" style="float: right;">
        <!--面包屑导航-->
        <el-breadcrumb separator="/">
          <el-breadcrumb-item>{{$t('home.sbgl')}}</el-breadcrumb-item>
          <el-breadcrumb-item>{{$t('home.sblb')}}</el-breadcrumb-item>
          <el-breadcrumb-item>{{$t('devDetail.sbxq')}}</el-breadcrumb-item>
        </el-breadcrumb>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="8" style="padding-right: 20px">
        <el-card style="padding: 0 20px !important;">
          <div slot="header" class="clearfix">
            <span>{{$t('devDetail.sbxq')}}</span>
          </div>
          <div>
            <table border="1" :cellpadding="0" :cellspacin="0" style="width: 100%;margin: 0 auto;text-align: center;border-color: #EBEEF5;">
              <tr style="height: 45px" v-for="item in ProductFormList" :key="item.index">
                <td style="text-align: center">{{item.title}}</td>
                <td>
                  <div v-if="item.value == $t('home.lx') || item.value == $t('home.zx')">
                    <el-tag :type="item.value == $t('home.lx') ? 'danger' : 'success'" style="margin-right: 10px">{{item.value}}</el-tag>
                  </div>
                  <div v-else class="textCenter">{{item.value}}</div>
                </td>
              </tr>
            </table>
            <div style="padding: 20px 0;display: flex;justify-content: center;flex-wrap: wrap">
              <el-button style="margin-bottom: 10px" type="primary" @click="eleQuantityPop">{{$t('devDetail.szyl')}}</el-button>
<!--              <el-button type="primary" @click="backlightPop">设置亮度</el-button>-->
<!--              <el-button type="primary" @click="timeStartPop">设置定时启动</el-button>-->
              <el-button style="margin-bottom: 10px" type="primary" @click="resetPop">{{$t('devDetail.cq')}}</el-button>
              <el-button style="margin-bottom: 10px" type="primary" @click="upgradePop">{{$t('devDetail.sjgj')}}</el-button>
              <el-button style="margin-bottom: 10px" type="primary" @click="upgradeAppPop">{{$t('devDetail.sjbb')}}</el-button>
              <el-button style="margin-bottom: 10px" type="primary" @click="changePop">{{$t('devDetail.qhfwq')}}</el-button>
            </div>
          </div>
        </el-card>
      </el-col>
      <el-col :span="16" style="float: right">
        <el-card style="padding: 20px">
          <el-button type="primary" @click="goBack" style="margin-right: 10px">{{$t('devDetail.fh')}}</el-button>
<!--          <el-button type="primary" @click="addFile">上传</el-button>-->
          <upload-img @getList="addEquipmentFile" type="selectImg"></upload-img>
          <upload-img @getList="addEquipmentFile" isVideo="1" type="selectImg"></upload-img>
<!--          <el-button type="primary" isVideo="1">上传视频</el-button>-->
          <el-button type="primary" @click="addFile">{{$t('devDetail.dqbfwj')}}</el-button>
          <div style="padding: 20px 0">
            {{$t('devDetail.sbsywj')}}
          </div>
          <!--      <el-form :inline="true" :model="preEntryForm" class="demo-form-inline" style="margin-top: 20px">-->
          <!--        <el-form-item label="机身编号">-->
          <!--          <el-input v-model="preEntryForm.getTitle" clearable placeholder="机身编号"></el-input>-->
          <!--        </el-form-item>-->
          <!--        <el-form-item>-->
          <!--          <el-button type="primary" @click="getData">查询</el-button>-->
          <!--          <el-button type="primary" @click="goSB">设备下拨</el-button>-->
          <!--        </el-form-item>-->
          <!--      </el-form>-->
          <el-table
            ref="filterTable"
            :data="listData"
            height="700px"
            border
            style="width: 100%">
            <el-table-column type="index" :label="$t('home.xh')" width="80px"></el-table-column>
            <el-table-column prop="file_name" :label="$t('devDetail.wjmc')"></el-table-column>
            <el-table-column prop="file_path" :label="$t('devDetail.wj')">
              <template width="100px" slot-scope="scope">
                <el-image
                  v-if="scope.row.file_type == 1"
                  style="height: 100px"
                  :src="url + scope.row.file_path"
                  :preview-src-list="[url + scope.row.file_path]">
                </el-image>
                <video controls muted  style="width: auto;height: auto;max-height: 100px;max-width: 100px" v-if="scope.row.file_type == 2" :src="url + scope.row.file_path"></video>
              </template>
            </el-table-column>
            <el-table-column prop="status" :label="$t('devDetail.zt')">
              <template #default="scope">
                <el-tag :type="scope.row.status == 0 ? 'danger' : 'success'" style="margin-right: 10px">{{scope.row.status == 0 ? $t('devDetail.dfb') : $t('devDetail.yfb')}}</el-tag>
              </template>
            </el-table-column>
            <el-table-column prop="create_time" :label="$t('devDetail.cjsj')" width="160px"></el-table-column>
            <el-table-column :label="$t('home.cz')" width="250px">
              <template #default="scope">
                <el-button v-if="scope.row.status == 0" type="success" size="mini" @click="release(scope.row.id)">{{$t('devDetail.fb')}}</el-button>
                <el-button type="danger" size="mini" @click="delList(scope.row.id)">{{$t('home.sc')}}</el-button>
<!--                <el-button type="primary" size="mini" @click="yulan(scope.row.id)">预览</el-button>-->
              </template>
            </el-table-column>
          </el-table>
          <pagination :queryInfos="queryInfo" @pageChange="PageChange" queryUrl="Img/DeviceDetail"></pagination>
        </el-card>
      </el-col>
    </el-row>
    <el-dialog
      :title="$t('devDetail.szyl')"
      :visible.sync="eleQuantityVisible"
      width="50%"
      @close="eleQuantityClosed"
    >
      <el-form
        label-width="150px"
        :model="addProductForm"
        :rules="addProductRules"
        ref="addProductFormRef"
      >
        <el-form-item :label="$t('devDetail.yl')" prop="ele_quantity">
          <el-input type="number" v-model="addProductForm.ele_quantity" @input="inputMax"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button @click="eleQuantityVisible = false">{{$t('home.qx')}}</el-button>
          <el-button type="primary" @click="addProduct">{{$t('home.qd')}}</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog
      title="设置亮度"
      :visible.sync="backlightVisible"
      width="50%"
      @close="backlightClosed"
    >
      <el-form
        label-width="150px"
        :model="addProductForm1"
        :rules="addProductRules1"
        ref="addProductFormRef"
      >
        <el-form-item label="亮度" prop="backlight">
          <el-input type="number" v-model="addProductForm1.backlight" @input="inputMax1"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button @click="backlightVisible = false">取 消</el-button>
          <el-button type="primary" @click="addProduct1">确 定</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog
      title="设置定时启动时间"
      :visible.sync="timeStartVisible"
      width="50%"
      @close="timeStartClosed"
    >
      <el-form
        label-width="150px"
        :model="addProductForm2"
        :rules="addProductRules2"
        ref="addProductFormRef"
      >
        <el-form-item label="定时启动时间" prop="start_time">
          <el-input v-model="addProductForm2.start_time" placeholder="HH-mm-ss"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button @click="timeStartVisible = false">取 消</el-button>
          <el-button type="primary" @click="addProduct2">确 定</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog
      :title="$t('devDetail.sjgj')"
      :visible.sync="isUpgrade"
      width="50%"
    >
      <el-form
        label-width="150px"
        :model="addProductForm3"
        :rules="addProductRules3"
      >
        <el-form-item :label="$t('home.sbbh')" prop="equipment_no">
          <span>{{equipment_no}}</span>
        </el-form-item>
<!--        <el-form-item label="placeholder">-->
<!--          <span>ePDKv100.img</span>-->
<!--        </el-form-item>-->
        <el-form-item :label="$t('devDetail.gjwj')" prop="file_path">
          <el-input v-model="addProductForm3.file_path" placeholder="ePDKv100.img"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button @click="isUpgrade = false">{{$t('home.qx')}}</el-button>
          <el-button type="primary" @click="addUpgrade">{{$t('home.qd')}}</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog
      :title="$t('devDetail.sjbb')"
      :visible.sync="isUpgradeApp"
      width="50%"
    >
      <el-form
        label-width="150px"
        :model="addProductForm4"
        :rules="addProductRules3"
      >
        <el-form-item :label="$t('home.sbbh')" prop="equipment_no">
          <span>{{equipment_no}}</span>
        </el-form-item>
<!--        <el-form-item label="placeholder">-->
<!--          <span>cdb_app</span>-->
<!--        </el-form-item>-->
        <el-form-item :label="$t('devDetail.wjmc')" prop="file_path">
          <el-input v-model="addProductForm4.file_path" placeholder="cdb_app"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button @click="isUpgradeApp = false">{{$t('home.qx')}}</el-button>
          <el-button type="primary" @click="addUpgradeApp">{{$t('home.qd')}}</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog
      :title="$t('devDetail.qhfwq')"
      :visible.sync="isChange"
      width="50%"
    >
      <el-form
        label-width="150px"
        :model="addProductForm5"
        :rules="addProductRules5"
      >
        <el-form-item :label="$t('home.sbbh')" prop="equipment_no">
          <span>{{equipment_no}}</span>
        </el-form-item>
        <el-form-item :label="$t('devDetail.xz')">
          <el-radio-group v-model="showIndex"  @change="selectInput">
            <el-radio :label="1">{{$t('devDetail.ym')}}</el-radio>
            <el-radio :label="2" checked="true">{{$t('devDetail.ipdz')}}</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item :label="showIp" prop="server_address">
          <el-input v-model="addProductForm5.server_address"></el-input>
        </el-form-item>
        <el-form-item :label="$t('devDetail.dk')" prop="port">
          <el-input v-model="addProductForm5.port"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button @click="isChange = false">{{$t('home.qx')}}</el-button>
          <el-button type="primary" @click="changeModel">{{$t('home.qd')}}</el-button>
<!--          <el-button type="primary" @click="changeModels">永久跳转</el-button>-->
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
import * as getDevice from '../../../interface/Img/DeviceLists'
import * as getDetail from '../../../interface/Img/DeviceDetail'
import UploadImg from '../../common/UploadImg'
import Pagination from '../../common/Pagination'

export default {
  name: 'DeviceDetail',
  components: { Pagination, UploadImg },
  data: function () {
    return {
      isUpgradeApp: false,
      isUpgrade: false,
      url: this.url,
      id: this.$route.query.id,
      equipment_no: this.$route.query.equipment_no,
      name: this.$route.query.name,
      ProductFormList: [],
      eleQuantityVisible: false,
      backlightVisible: false,
      timeStartVisible: false,
      isChange: false,
      addProductForm: {},
      addProductForm1: {},
      addProductForm2: {},
      addProductForm3: {},
      addProductForm4: {},
      addProductForm5: {},
      addProductRules: {
        ele_quantity: [
          { required: true, message: this.$t('devDetail.qsryl'), trigger: 'blur' }
        ]
      },
      addProductRules1: {
        backlight: [
          { required: true, message: this.$t('devDetail.qsrld'), trigger: 'blur' }
        ]
      },
      addProductRules2: {
        start_time: [
          { required: true, message: this.$t('devDetail.qsrsj'), trigger: 'blur' }
        ]
      },
      addProductRules3: {
        file_path: [
          { required: true, message: this.$t('devDetail.qsrwjmc'), trigger: 'blur' }
        ]
      },
      addProductRules5: {
        server_address: [
          { required: true, message: this.$t('devDetail.qsrym'), trigger: 'blur' }
        ],
        port: [
          { required: true, message: this.$t('devDetail.qsrdk'), trigger: 'blur' }
        ]
      },
      listData: [],
      addForm: {},
      queryInfo: {
        query: '',
        pageNo: 1,
        pageSize: 2,
        total: 0
      },
      showIp: this.$t('devDetail.ipdz'),
      showIndex: 2
    }
  },
  created () {
    this.getDetail(this.id)
    this.getList()
  },
  methods: {
    // 升级版本
    upgradeAppPop: function () {
      this.isUpgradeApp = true
    },
    // 切换
    changePop: function () {
      this.isChange = true
    },
    selectInput: function (e) {
      this.showIndex = e
      if (e === 1) {
        // 域名
        this.showIp = this.$t('devDetail.ym')
      } else {
        // 地址
        this.showIp = this.$t('devDetail.ipdz')
      }
    },
    changeModel: async function () {
      this.addProductForm5.equipment_no = this.equipment_no
      var p = this.addProductForm5
      console.log(this.showIndex)
      console.log(p)
      if (this.showIndex === 2) {
        const data = await getDetail.change(this, p)
        console.log(data)
        if (data.code !== 0) return this.$message.error(this.$t('devDetail.qhfwqdzsb'), data.msg)
        this.$message.success(this.$t('devDetail.qhcg'))
        this.isChange = false
        var that = this
        that.$confirm(this.$t('devDetail.sfqryjqh'), this.$('home.ts'), {
          confirmButtonText: this.$t('home.qd'),
          cancelButtonText: this.$t('home.qx'),
          type: 'warning'
        }).then(async (res) => {
          console.log(res)
          const data2 = await getDetail.change2(this, p)
          console.log(data2)
          if (data2.code !== 0) return this.$message.error(this.$t('devDetail.qhfwqdzsb'), data2.msg)
          this.$message.success(this.$t('devDetail.yjqhcg'))
        }).catch(() => {
          that.$message({
            type: 'info',
            message: this.$t('devDetail.yqxqh')
          })
        })
      } else {
        const data1 = await getDetail.change1(this, p)
        console.log(data1)
        if (data1.code !== 0) return this.$message.error(this.$t('devDetail.qhfwqdzsb'), data1.msg)
        this.$message.success(this.$t('devDetail.qhcg'))
        this.isChange = false
      }
    },
    addUpgradeApp: async function () {
      this.addProductForm4.equipment_no = this.equipment_no
      console.log('升级版本', this.addProductForm4)
      var p = '?equipment_no=' + this.equipment_no + '&file_path=' + this.addProductForm4.file_path
      const data = await getDetail.upgradeApp(this, p)
      if (data.code !== 0) return this.$message.error(this.$t('devDetail.sjbbsb'), data.msg)
      this.$message.success(this.$t('devDetail.sjcg'))
    },
    // 升级固件
    upgradePop: function () {
      this.isUpgrade = true
    },
    addUpgrade: async function () {
      this.addProductForm3.equipment_no = this.equipment_no
      console.log('升级固件', this.addProductForm3)
      var p = '?equipment_no=' + this.equipment_no + '&file_path=' + this.addProductForm3.file_path
      const data = await getDetail.upgrade(this, p)
      if (data.code !== 0) return this.$message.error(this.$t('devDetail.sjgjsb'), data.msg)
      this.$message.success(this.$t('devDetail.sjcg'))
    },
    // 下一页
    PageChange: function (e) {
      this.listData = e.productList
      this.queryInfo = e.queryInfo
    },
    // 发布
    release: function (id) {
      var that = this
      that.$confirm(this.$t('devDetail.sfqrfb'), this.$t('home.ts'), {
        confirmButtonText: this.$t('home.qd'),
        cancelButtonText: this.$t('home.qx'),
        type: 'warning'
      }).then(async (res) => {
        console.log(res)
        const data = await getDetail.release(this, id)
        if (data.code !== 0) return this.$message.error(data.msg)
        this.$message.success(this.$t('devDetail.fbcg'))
        that.getList()
      }).catch(() => {
        that.$message({
          type: 'info',
          message: this.$t('devDetail.yqxfb')
        })
      })
    },
    // 删除
    delList: function (id) {
      var that = this
      that.$confirm(this.$t('home.sfqrsc'), this.$t('home.ts'), {
        confirmButtonText: this.$t('home.qd'),
        cancelButtonText: this.$t('home.qx'),
        type: 'warning'
      }).then(async (res) => {
        console.log(res)
        const data = await getDetail.equipmentFileDel(this, id)
        if (data.code !== 0) return this.$message.error(data.msg)
        this.$message.success(this.$t('home.sccg'))
        that.getList()
      }).catch(() => {
        that.$message({
          type: 'info',
          message: this.$t('home.yqxsc')
        })
      })
    },
    // 预览
    yulan: function () {},
    // 上传文件
    addEquipmentFile: async function (e) {
      console.log(e)
      if (e !== undefined) {
        this.addForm.equipment_no = this.ProductFormList[1].value
        this.addForm.file_name = e.file_name
        this.addForm.file_id = e.file_id
        const data = await getDetail.equipmentFileAdd(this, this.addForm)
        if (data.code !== 0) return this.$message.error(data.msg)
        this.$message.success(this.$t('devDetail.sccg'))
      }
      this.getList()
    },
    getList: async function () {
      var p = '?equipment_no=' + this.equipment_no
      const data = await getDetail.equipmentFileList(this, p)
      if (data.code !== 0) return this.$message.error(data.msg)
      this.listData = data.result.data
      this.queryInfo.total = data.result.totalCount
      this.queryInfo.pageNo = data.result.pageNo
      this.queryInfo.pageSize = data.result.pageSize
    },
    // 上传
    addFile: function () {},
    inputMax: function () {
      if (this.addProductForm.ele_quantity > 60) this.addProductForm.ele_quantity = 60
      if (this.addProductForm.ele_quantity < 0) this.addProductForm.ele_quantity = 0
    },
    inputMax1: function () {
      if (this.addProductForm1.backlight > 15) this.addProductForm1.backlight = 15
      if (this.addProductForm1.backlight < 1) this.addProductForm1.backlight = 1
    },
    goBack: function () {
      this.$router.go(-1)
    },
    // 详情
    getDetail: async function (id) {
      const data = await getDevice.editProductDialog(this, id)

      if (data.code !== 0) return this.$message.error(data.msg)

      console.log(data)

      var arr = [
        { title: this.$t('devDetail.fz'), value: this.name },
        { title: this.$t('home.sbbh'), value: data.result.equipment_no },
        { title: this.$t('devDetail.zhxtsj'), value: data.result.last_login_time },
        { title: this.$t('home.sfzx'), value: data.result.line_status === 1 ? '在线' : '离线' },
        { title: this.$t('devDetail.cqsj'), value: data.result.start_time },
        { title: this.$t('devDetail.yl'), value: data.result.ele_quantity },
        // { title: '亮度', value: data.result.backlight },
        { title: this.$t('devDetail.gxsj'), value: data.result.update_time },
        { title: this.$t('home.khmc'), value: data.result.item_name },
        { title: this.$t('home.bz'), value: data.result.remark }
      ]
      // 切换服务器 表单
      this.addProductForm5 = {
        server_address: data.result.server_address,
        port: data.result.port
      }
      this.ProductFormList = arr
    },
    // 设置电量弹窗
    eleQuantityPop: function () {
      this.eleQuantityVisible = true
    },
    eleQuantityClosed: function () {
      this.eleQuantityVisible = false
    },
    // 设置电量
    addProduct: async function () {
      this.addProductForm.equipment_no = this.ProductFormList[1].value
      console.log(this.addProductForm)
      const data = await getDevice.eleQuantity(this, this.addProductForm)
      if (data.code !== 0) return this.$message.error(data.msg)
      this.$message.success('设置成功')
      this.getDetail(this.id)
    },
    // 设置亮度弹窗
    backlightPop: function () {
      this.backlightVisible = true
    },
    backlightClosed: function () {
      this.backlightVisible = false
    },
    addProduct1: async function () {
      this.addProductForm1.equipment_no = this.ProductFormList[1].value
      console.log(this.addProductForm1)
      const data = await getDevice.backlight(this, this.addProductForm1)
      if (data.code !== 0) return this.$message.error(data.msg)
      this.$message.success(this.$t('devDetail.szcg'))
      this.backlightVisible = false
      this.getDetail(this.id)
    },
    // 设置定时
    timeStartPop: function () {
      this.timeStartVisible = true
    },
    timeStartClosed: function () {
      this.timeStartVisible = false
    },
    addProduct2: async function () {
      this.addProductForm2.equipment_no = this.ProductFormList[1].value
      const data = await getDevice.timeStart(this, this.addProductForm2)
      if (data.code !== 0) return this.$message.error(data.msg)
      this.$message.success(this.$t('devDetail.szcg'))
      this.timeStartVisible = false
      this.getDetail(this.id)
    },
    // 重启
    resetPop: function () {
      var that = this
      that.$confirm(this.$t('devDetail.sfqrcq'), this.$t('home.ts'), {
        confirmButtonText: this.$t('home.qd'),
        cancelButtonText: this.$t('home.qx'),
        type: 'warning'
      }).then(async (res) => {
        console.log(res)
        console.log(that.ProductFormList[1].value)
        const data = await getDevice.reset(this, that.ProductFormList[1].value)
        if (data.code !== 0) return this.$message.error(data.msg)
        this.$message.success(this.$t('devDetail.cqcg'))
        that.getDetail(this.id)
      }).catch(() => {
        that.$message({
          type: 'info',
          message: this.$t('devDetail.yqxcq')
        })
      })
    }
  }
}
</script>
<style scoped>
  .textCenter{
    text-align: center;
  }
  .el-image img{
    width: auto !important;
    height:auto !important;
    max-width:100px;
    max-height:100px;
    border:none;
  }
</style>
