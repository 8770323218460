<template>
    <div>
      <el-row style="margin: 0 10px">
        <el-col :span="2" style="font-size: 18px">经销商</el-col>
        <el-col :span="5" style="float: right;">
          <!--面包屑导航-->
          <el-breadcrumb separator="/">
            <el-breadcrumb-item>市场管理</el-breadcrumb-item>
            <el-breadcrumb-item><a>经销商</a></el-breadcrumb-item>
          </el-breadcrumb>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="11" style="margin-right: 25px;float: left">
          <el-card>
            <el-row><p style="font-size: 20px;margin: 0;text-align: center">个人</p></el-row>
            <el-form ref="form" :model="form" label-width="120px">
              <p style="font-size: 18px;font-weight: 600">基本信息</p>
              <el-form-item label="经销商编号">
                <el-input v-model="form.name"></el-input>
              </el-form-item>
              <el-form-item label="经销商名称">
                <el-input v-model="form.name"></el-input>
              </el-form-item>
              <el-form-item label="联系电话">
                <el-input v-model="form.name"></el-input>
              </el-form-item>
              <el-form-item label="联系人">
                <el-input v-model="form.name"></el-input>
              </el-form-item>
              <el-form-item label="收货地址">
                <el-input v-model="form.name"></el-input>
              </el-form-item>
              <el-form-item label="合同编号">
                <el-input v-model="form.name"></el-input>
              </el-form-item>
              <p style="font-size: 18px;font-weight: 600">售后维修信息</p>
              <el-form-item label="收货人">
                <el-input v-model="form.name"></el-input>
              </el-form-item>
              <el-form-item label="收货电话">
                <el-input v-model="form.name"></el-input>
              </el-form-item>
              <el-form-item label="收货地址">
                <el-input v-model="form.name"></el-input>
              </el-form-item>
            </el-form>
          </el-card>
        </el-col>
        <el-col :span="12">
          <el-card>
            <el-row><p style="font-size: 20px;margin: 0;text-align: center">企业</p></el-row>
            <el-form ref="form" :model="form" label-width="120px">
              <p style="font-size: 18px;font-weight: 600">基本信息</p>
              <el-form-item label="经销商编号">
                <el-input v-model="form.name"></el-input>
              </el-form-item>
              <el-form-item label="公司名称">
                <el-input v-model="form.name"></el-input>
              </el-form-item>
              <el-form-item label="联系电话">
                <el-input v-model="form.name"></el-input>
              </el-form-item>
              <el-form-item label="联系人">
                <el-input v-model="form.name"></el-input>
              </el-form-item>
              <el-form-item label="收货地址">
                <el-input v-model="form.name"></el-input>
              </el-form-item>
              <el-form-item label="合同编号">
                <el-input v-model="form.name"></el-input>
              </el-form-item>
              <el-form-item label="开票信息">
                <el-input v-model="form.name"></el-input>
              </el-form-item>
              <p style="font-size: 18px;font-weight: 600">售后维修信息</p>
              <el-form-item label="收货人">
                <el-input v-model="form.name"></el-input>
              </el-form-item>
              <el-form-item label="收货电话">
                <el-input v-model="form.name"></el-input>
              </el-form-item>
              <el-form-item label="收货地址">
                <el-input v-model="form.name"></el-input>
              </el-form-item>
            </el-form>
          </el-card>
        </el-col>
      </el-row>

    </div>
</template>

<script>
export default {
  name: 'Distributor',
  data: function () {
    return {
      form: {}
    }
  }
}
</script>

<style scoped>

</style>
