module.exports = {
  lan: 'English',
  common: {
    type: 'type',
    sel: 'please select'
  },
  login: {
    htglxt: 'Backstage Management System',
    dl: 'Sign in',
    cz: 'Reset',
    qsryhm: 'Please enter login name',
    zfcd: '6 to 15 characters in length'
  },
  home: {
    xmgl: 'Project Management',
    qh: 'switch',
    sblb: 'Equipment List',
    sbgl: 'Equipment Management',
    fzgl: 'Group Management',
    sbbh: 'Equipment No',
    xc: 'search',
    sb: 'equipment',
    xh: 'Numder',
    sfzx: 'Online Or Not',
    zx: 'online',
    lx: 'offline',
    khmc: 'Customer Name',
    zhdlsj: 'Last Login Time',
    bz: 'Remarks',
    cz: 'Operation',
    xq: 'Details',
    sc: 'Delete',
    qbfz: 'Group All',
    sfqrsc: 'are you sure you want to delete?',
    ts: 'Prompt',
    qd: 'OK',
    qx: 'Cancel',
    sccg: 'deletion succeeded',
    yqxsc: 'deletion canceled',
    bj: 'edit',
    grzx: 'Personal Center',
    tc: 'Exit',
    gl: 'Management',
    sbid: 'Equipment Id'
  },
  edit: {
    xg: 'modify',
    hqxxsb: 'failed to obtain information',
    qtxwzxx: 'please fill in the complete information',
    xgsb: 'modification failed,',
    xgcg: 'modified successfully'
  },
  add: {
    tj: 'Add',
    tjsb: 'add failed',
    tjcg: 'successfully added',
    qxz: 'please select'
  },
  devDetail: {
    sbxq: 'Equipment Details',
    szyl: 'Set Volume',
    cq: 'Restart',
    sjgj: 'Upgrade Firmware',
    sjbb: 'Upgrade Version',
    qhfwq: 'Switch Server',
    fh: 'Return to the previous level',
    dqbfwj: 'currently playing file',
    sbsywj: 'all files of the device',
    wjmc: 'file name',
    wj: 'file',
    zt: 'status',
    dfb: 'to be published',
    yfb: 'published',
    cjsj: 'creation time',
    fb: 'publish',
    yl: 'volume',
    gjwj: 'firmware file',
    xz: 'select',
    ym: 'domain name',
    ipdz: 'Ip address',
    dk: 'port',
    qsryl: 'please input the volume',
    qsrld: 'please input brightness',
    qsrsj: 'please enter the time',
    qsrwjmc: 'please enter the file name',
    qsrym: 'please enter the domain name or ip address',
    qsrdk: 'please enter the port',
    qhfwqdzsb: 'failed to switch server address',
    qhcg: 'switching succeeded',
    sfqryjqh: 'confirm permanent handover?',
    yjqhcg: 'permanent handover succeeded',
    yqxqh: 'switch canceled',
    sjbbsb: 'upgrade version failed',
    sjcg: 'upgrade succeeded',
    sjgjsb: 'failed to upgrade firmware',
    sfqrfb: 'confirm publishing?',
    fbcg: 'Publishing succeeded',
    yqxfb: 'unpublished',
    sccg: 'upload succeeded',
    fz: 'grouping',
    zhxtsj: 'last heartbeat time',
    cqsj: 'restart time',
    gxsj: 'update time',
    szcg: 'set successfully',
    sfqrcq: 'are you sure you want to restart?',
    cqcg: 'restart succeeded',
    yqxcq: 'restart canceled'
  },
  img: {
    scsp: 'upload video',
    sctp: 'upload picture',
    xzwj: 'select file',
    qr: 'confirm',
    djtp: '(click image / video selection)',
    qx: 'select all',
    znscwj: '(only MP4 files can be uploaded, and no more than 100MB)',
    znsctwj: '(only jpg/jpge files can be uploaded, and not more than 100MB)',
    wjscgs: 'the uploaded file can only be in MP4 format',
    wjsctgs: 'the uploaded file can only be in jpg/jpge format',
    scwjdx: 'upload file size cannot exceed 100MB',
    scsb: 'upload failed',
    dqxzsl: 'currently selected quantity:',
    qxzsl: ', please select a quantity of 1'
  },
  group: {
    mc: 'name',
    ms: 'description',
    px: 'sort',
    xgfz: 'modify grouping',
    qsrmc: 'please enter a name',
    qsrms: 'please enter a description',
    qsrpx: 'please enter sorting',
    bjcg: 'edited successfully'
  },
  video: {
    spgl: 'Video Management',
    sp: 'video',
    dx: 'size',
    scsj: 'upload time'
  },
  imgM: {
    tpgl: 'Image Management',
    tp: 'picture'
  },
  lygl: {
    lygl: 'Bluetooth Management',
    yljl: 'Bluetooth record',
    z: 'To',
    ksrq: 'start date',
    jsrq: 'end date',
    lymc: 'Bluetooth name',
    lydz: 'Bluetooth address',
    qbjl: 'all records'
  },
  sh: {
    sh: 'Audit',
    lx: 'type',
    qb: 'all',
    wjdx: 'file size',
    zs: 'display',
    dsh: 'pending approval',
    shtg: 'approved',
    shbtg: 'approval failed',
    sfqrsh: 'confirm approval?',
    shwc: 'approval completed',
    yqxsh: 'approval canceled'
  },
  khgl: {
    khgl: 'Customer Management',
    kh: 'customer'
  },
  role: {
    jsgl: 'Role Management',
    xtgl: 'System Management',
    jsmc: 'role name',
    jsms: 'role description',
    fpqx: 'assign permissions',
    qsrjsmc: 'please enter the role name',
    cdzf: 'length between 3 and 10 characters',
    qsrjsms: 'please enter the role description',
    cdzfm: 'at least 3 characters long',
    js: 'role',
    hqjssb: 'failed to get the permission information of the current role',
    hqqxsb: 'failed to obtain permission tree',
    fpqxsb: 'failed to allocate permissions',
    fpqxcg: 'successfully assigned permissions',
    hqjssbs: 'failed to get role'
  },
  power: {
    qxgl: 'Permission Management',
    tjqx: 'Add permission',
    jdmc: 'node name',
    jdms: 'node description',
    tb: 'icon',
    zcd: 'main menu',
    s: 'yes',
    f: 'no',
    qdjdgz: 'front end node rules',
    hdjdgz: 'backend node rules',
    fjcd: 'parent menu',
    qsrjdmc: 'please enter the node name',
    qsrjdms: 'please enter the node description',
    qsrqdjd: 'please enter the front-end node rule',
    qsrhdjd: 'please enter the backend node rule',
    qsrtb: 'please enter an icon',
    qsrpx: 'please enter the sorting number',
    sfcd: 'is it a menu',
    cd: 'menu',
    fcd: 'non menu',
    bjqx: 'edit permission',
    qsrqxbt: 'please enter the permission title',
    cdzf: 'length between 2 and 10 characters',
    qsrqxms: 'please enter permission description',
    cdzfs: 'length is at least 2 characters',
    qxzqxtb: 'please select the permission icon',
    cdzfo: 'length is at least 1 character',
    qtxwzqx: 'please fill in the complete permission information'
  },
  admin: {
    glylb: 'Administrator List',
    sjh: 'mobile number',
    yhdlmc: 'user login name',
    gskh: 'belonging to customer',
    yx: 'mailbox',
    qsrhfyx: 'please enter a legal email',
    qsrhfsjhm: 'please enter a legal mobile phone number',
    gly: 'administrator',
    dlmc: 'login name',
    mm: 'password',
    qsrdlmc: 'please enter login name',
    qsrmm: 'please enter the password',
    qsryx: 'please enter email',
    qsrsjh: 'please enter your mobile number',
    zc: 'normal',
    jy: 'disable'
  },
  cp: {
    cplb: 'Product List',
    cpbt: 'product title',
    cp: 'product',
    cpms: 'product description',
    scrq: 'production date',
    jg: 'price'
  },
  system: {
    xtrz: 'System Log',
    qsrip: 'please enter IP',
    qqdz: 'request address',
    qqlx: 'request type',
    qqsj: 'request data',
    yhm: 'user name',
    qqsjs: 'request time'
  }
}
