<template>
    <div>
      <el-row style="margin: 0 10px">
        <el-col :span="2" style="font-size: 18px">物流公司设置</el-col>
        <el-col :span="5" style="float: right;">
          <!--面包屑导航-->
          <el-breadcrumb separator="/">
            <el-breadcrumb-item>售后管理</el-breadcrumb-item>
            <el-breadcrumb-item><a>维修项目设置</a></el-breadcrumb-item>
            <el-breadcrumb-item><a>物流公司设置</a></el-breadcrumb-item>
          </el-breadcrumb>
        </el-col>
      </el-row>
      <el-card>
        <el-row>
          <addBtn
            @gainMsgs="PageChange"
            :addForm="addProductForm"
            :addRules="addProductRules"
            :addApi="path"
            :addProductFormLists="addProductFormList"
            :addName="addProductName"
            style="float: left"
          ></addBtn>
          <el-button type="success" @click="returnMsg" style="float: left;margin-left: 20px">返回</el-button>
        </el-row>
        <el-table border :data="companyList" height="670px">
          <el-table-column label="序号" type="index" width="80px"></el-table-column>
          <el-table-column label="物流公司名称" prop="title"></el-table-column>
          <el-table-column label="物流公司描述" prop="desc"></el-table-column>
          <el-table-column label="默认" prop="status">
            <template #default="scope">
              <el-switch v-model="scope.row.status" :active-value="1" :inactive-value="0" @change="userStateChange(scope.row)"></el-switch>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="150px">
            <template #default="scope">
              <!--              编辑的按钮-->
              <editBtn
                :editId="scope.row.id"
                :editForm="editProductForm"
                :addProductFormList="addProductFormList"
                :editRules="editProductFormRules"
                @gainMsg="PageChange"
                :editApi="path"
                :editProductFormLists="editProductFormList"
                :editName="addProductName"
              ></editBtn>
              <!--              删除的按钮-->
              <deleteBtn :deleteId="scope.row.id" @gainMsg="PageChange" :deleteApi="path"></deleteBtn>
            </template>
          </el-table-column>
        </el-table>
        <page :queryInfos="queryInfo" :queryUrl="path" @pageChange="queryChange2"></page>
      </el-card>
    </div>
</template>

<script>
import * as maintenance from '../../interface/AfterSalesManage/LogisticsCompanySetting'
import Pagination from '../common/Pagination'
import EditBtn from '../common/EditBtn'
import DeleteBtn from '../common/DeleteBtn'
import AddBtn from '../common/AddBtn'
export default {
  name: 'LogisticsCompanySetting',
  components: {
    page: Pagination,
    editBtn: EditBtn,
    deleteBtn: DeleteBtn,
    addBtn: AddBtn
  },
  data: function () {
    return {
      companyList: [],
      path: 'AfterSalesManage/LogisticsCompanySetting',
      queryInfo: {
        query: '',
        pageNo: 1,
        pageSize: 2,
        total: 0
      },
      addProductName: '物流公司',
      addProductForm: {
        title: '',
        desc: '',
        status: 0
      },
      addProductFormList: [
        {
          title: '物流公司名称',
          prop: 'title'
        },
        {
          title: '物流公司描述',
          prop: 'desc'
        },
        {
          title: '是否默认',
          prop: 'status'
        }
      ],
      addProductRules: {
        title: [
          { required: true, message: '请输入物流公司名称', trigger: 'blur' },
          { min: 2, max: 10, message: '长度在 2 到 10 个字符', trigger: 'blur' }
        ],
        desc: [
          { required: true, message: '请输入物流公司描述', trigger: 'blur' },
          { min: 2, message: '长度至少2个字符', trigger: 'blur' }
        ]
      },
      editDialogVisible: false,
      editProductForm: {
        id: '',
        title: '',
        desc: '',
        status: 0
      },
      editProductFormList: [
        {
          title: '物流公司名称',
          prop: 'title'
        },
        {
          title: '物流公司描述',
          prop: 'desc'
        },
        {
          title: '是否默认',
          prop: 'status'
        }
      ],
      editProductFormRules: {
        title: [
          { required: true, message: '请输入物流公司名称', trigger: 'blur' },
          { min: 2, max: 10, message: '长度在 2 到 10 个字符', trigger: 'blur' }
        ],
        desc: [
          { required: true, message: '请输入物流公司描述', trigger: 'blur' },
          { min: 2, message: '长度至少2个字符', trigger: 'blur' }
        ]
      }
    }
  },
  created () {
    this.getCompanyList()
  },
  methods: {
    getCompanyList: async function () {
      const res = await maintenance.companyList(this)
      if (res.code !== 0) return this.$message.error('获取物流公司信息失败')
      console.log(res)
      this.companyList = res.result.data
      this.queryInfo.total = res.result.totalCount
      this.queryInfo.pageNo = res.result.pageNo
      this.queryInfo.pageSize = res.result.pageSize
    },
    returnMsg: function () {
      this.$router.go(-1)
    },
    PageChange: function () {
      this.getCompanyList()
    },
    queryChange2: function (e) {
      this.companyList = e.productList
    },
    //  是否设置为默认
    setDefault: async function (id) {
      const res = await maintenance.editDefault(this, id, { status: 1 })
      console.log(res)
    },
    async userStateChange (row) {
      console.log(row.status)
      var obj = {
        id: row.id
      }
      if (row.status) {
        obj.status = 1
      } else {
        obj.status = 0
      }
      const data = await maintenance.editDefault(this, obj)
      if (data.code !== 0) return this.$message.error('修改默认状态失败')
      this.$message.success(data.msg)
      this.getCompanyList()
    }
  }
}
</script>

<style scoped>

</style>
