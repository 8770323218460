import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './plugins/element.js'
// 导入全局的样式
import './assets/css/global.css'
// 导入图标需要使用的css
import './assets/fonts/iconfont.css'
// 导入第三方组件
import axios from 'axios'
import Nprogress from 'nprogress'
import 'nprogress/nprogress.css'
import Print from 'vue-print-nb'
// 语言
// import VueI18n from 'vue-i18n'
// Vue.use(VueI18n)
// const i18n = new VueI18n({
//   locale: 'en-US', // 标识当前所用的语言
//   messages: {
//     // 将定义好的语言包导入
//     'zh-CN': require('./assets/lang/zh.js'), // 已经定义好的中文包
//     'en-US': require('./assets/lang/en.js') // 已经定义好的英文包
//   }
// })
import locale from 'element-ui/lib/locale'
import VueI18n from 'vue-i18n'
import zh from './assets/lang/zh'
import en from './assets/lang/en'
import zhLocale from 'element-ui/lib/locale/lang/zh-CN'
import enLocale from 'element-ui/lib/locale/lang/en'
Vue.use(VueI18n)
const messages = {
  en: {
    ...en,
    ...enLocale
  },
  zh: {
    ...zh,
    ...zhLocale
  }
}
const i18n = new VueI18n({
  locale: 'zh-CN',
  messages,
  silentTranslationWarn: true
})
locale.i18n((key, value) => i18n.t(key, value))

// 设置请求的基准地址
// axios.defaults.baseURL = 'http://192.168.0.245:8028/'
// Vue.prototype.url = 'http://192.168.0.245:8028'
// Vue.prototype.url = 'http://erp.zhenyoukeji.com'
// axios.defaults.baseURL = 'http://erp.zhenyoukeji.com/'
// Vue.prototype.url = 'http://137.184.127.55'
Vue.prototype.url = 'https://vapi.bapalm.com'
// axios.defaults.baseURL = 'http://137.184.127.55/'
axios.defaults.baseURL = 'https://vapi.bapalm.com/'
// axios.defaults.baseURL = '/admin'
Vue.prototype.$http = axios
Vue.config.productionTip = false
// 使用打印插件
Vue.use(Print)
// 全局配置每次发请求都带上Authorization : token. 因为这个请求的前提
axios.interceptors.request.use(config => {
  // 发请求,则开始
  Nprogress.start()
  config.headers.Authorization = window.sessionStorage.getItem('token')
  return config
})

axios.interceptors.response.use(config => {
  // 收到响应,则结束
  Nprogress.done()
  if (config.code === '4001') {
    window.sessionStorage.setItem('token', '')
    this.$router.push('/login')
    return false
  }
  return config
})

new Vue({
  router,
  i18n,
  render: h => h(App)
}).$mount('#app')
