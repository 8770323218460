// api 地址
const api = {
  adminList: 'admin/adminUser/list',
  addProduct: 'admin/adminUser/add',
  editProductDialog: 'admin/adminUser/info?id=',
  editProduct: 'admin/adminUser/edit?id=',
  deleteProduct: 'admin/adminUser/del?id=',
  adminGetAll: 'admin/adminRole/getAll'
}
// 获取角色
export async function adminGetAll (ele) {
  var { data } = await ele.$http.get(api.adminGetAll)
  return data
}
// 获取产品列表
export async function adminList (ele, param) {
  var { data } = await ele.$http.get(api.adminList + param)
  return data
}

// 添加产品
export async function addProduct (ele, param) {
  var { data } = await ele.$http.post(api.addProduct, param)
  return data
}

// 根据id查询产品信息
export async function editProductDialog (ele, param) {
  var { data } = await ele.$http.get(api.editProductDialog + param)
  return data
}

// 提交产品修改的信息
export async function editProduct (ele, param1, param2) {
  var { data } = await ele.$http.put(api.editProduct + param1, param2)
  return data
}
// 删除产品的信息
export async function deleteProduct (ele, param) {
  var { data } = await ele.$http.delete(api.deleteProduct + param)
  return data
}
export async function pageChanges (ele, param, pamams) {
  var p = []
  for (var i in param) {
    p.push(i + '=' + param[i])
  }
  var { data } = await ele.$http.get(api.adminList + '?' + p[0] + '&' + p[1] + '&' + pamams)
  return data
}
