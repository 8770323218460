<template>
    <div>
      <el-row>
        <el-col :span="2" style="font-size: 18px">菜单管理</el-col>
        <el-col :span="5" style="float: right;">
          <!--面包屑导航-->
          <el-breadcrumb separator="/">
            <el-breadcrumb-item>系统管理</el-breadcrumb-item>
            <el-breadcrumb-item><a>菜单管理</a></el-breadcrumb-item>
          </el-breadcrumb>
        </el-col>
      </el-row>
      <div style="overflow: hidden">
<!--        左侧菜单列表-->
        <el-card style="width: 40%;float: left;margin-right: 25px;">
          <!--        树形菜单列表-->
          <el-button type="primary" @click="onExpand">{{this.expandAll?'收缩':'展开'}}所有节点</el-button>
          <el-tree
            :data="menuLists"
            node-key="id"
            default-expand-all
            :expand-on-click-node="true"
            style="margin-top: 20px"
            ref="tree"
          >
            <span class="custom-tree-node" slot-scope="{ node, data }">
              <span>
                <i :class="data.icon" style="padding-right: 5px"></i>
                {{ data.name }}
                <span style="padding-left: 15px;color: rgb(89, 166, 255);font-size: 12px">http://192.168.0.120:8020/admin/{{ data.rule }}</span>
              </span>
              <span>
                <el-button size="mini" @click="() => edit(node, data)" class="el-icon-edit" type="text"></el-button>
                <el-button size="mini" @click="() => remove(node, data)" class="el-icon-delete" type="text" style="color: red"></el-button>
              </span>
            </span>
          </el-tree>
        </el-card>
<!--        右侧新增菜单-->
        <el-card>
          <el-row style="border-bottom: 1px solid #eee;">
            <el-col :span="2" style="font-size: 18px;padding-bottom: 10px;color: #409EFF">新增菜单</el-col>
          </el-row>
          <el-form ref="ruleForm" :model="form" label-width="80px" style="width: 80%;margin: 0 auto;margin-top: 20px">
            <el-form-item label="父级菜单">
              <el-cascader
                v-model="form.parentMenu"
                :options="menuLists"
                :props="menuProps"
                clearable
                style="width: 100%"
              >
              </el-cascader>
            </el-form-item>
            <el-form-item label="标题">
              <el-input type="text" v-model="form.title" placeholder="请输入标题"></el-input>
            </el-form-item>
            <el-form-item label="图标">
              <div style="float:left; margin-right:5px;border-radius:5px;height: 38px;line-height: 40px;width:50px;background-color: #f5f7fa;border: 1px solid #dcdfe6;">
                <i :class="this.form.select" style="padding: 0 20px;height:40px;"></i>
              </div>
              <el-select v-model="form.select" placeholder="请输入图标" style="width: 90.6%">
                <el-option v-for="item in iconList" :key="item.value" :value="item.value">
                  <i :class="item.label"><span style="margin-left: 10px">{{item.value}}</span></i>
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="路径">
              <el-input type="text" v-model="form.path" placeholder="请输入路径"></el-input>
            </el-form-item>
            <el-form-item label="排序">
              <el-input v-model="form.sort"></el-input>
            </el-form-item>
            <el-form-item label="角色">
              <el-select v-model="form.role" placeholder="请选择角色" style="width: 100%">
                <el-option label="admin" value="shanghai"></el-option>
                <el-option label="common" value="beijing"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="权限">
              <el-select v-model="form.power" placeholder="请选择权限" style="width: 100%">
                <el-option label="增加商品列表" value="shanghai"></el-option>
                <el-option label="修改商品列表" value="beijing"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="onSubmit">立即新增</el-button>
              <el-button @click="resetForm('ruleForm')">重置</el-button>
            </el-form-item>
          </el-form>
        </el-card>
      </div>
    </div>
</template>

<script>
// import menu from '../../assets/prower.json'
import icons from '../../assets/fonts/iconfont.json'
import * as menu from '../../interface/systemManage/MenuManage'
let id = 1000
export default {
  name: 'menuManage',
  data: function () {
    return {
      menuLists: [],
      expandAll: true,
      form: {
        select: ''
      },
      menuProps: {
        label: 'name',
        value: 'id',
        children: 'children',
        expandTrigger: 'hover',
        checkStrictly: false
      },
      iconList: []
    }
  },
  created () {
    this.getMenuList()

    var ico = JSON.parse(JSON.stringify(icons.glyphs))
    var iconText = []
    for (var i = 0; i < ico.length; i++) {
      var text = {}
      text.value = 'icon iconfont icon-' + ico[i].font_class
      text.label = 'icon iconfont icon-' + ico[i].font_class
      iconText.push(text)
    }
    this.iconList = iconText
  },
  methods: {
    // 获取菜单列表
    getMenuList: async function () {
      const data = await menu.powerList(this)
      console.log(data)
      this.menuLists = data.result
    },
    edit (data) {
      const newChild = { id: id++, label: 'testtest', children: [] }
      if (!data.children) {
        this.$set(data, 'children', [])
      }
      data.children.push(newChild)
    },

    remove (node, data) {
      const parent = node.parent
      const children = parent.data.children || parent.data
      const index = children.findIndex(d => d.id === data.id)
      children.splice(index, 1)
    },
    // 节点展开
    async onExpand () {
      try {
        await this.$confirm('确认要展开所有节点?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
        this.expandAll = !this.expandAll
        // 改变每个节点的状态
        this.changeTreeNodeStatus(this.$refs.tree.store.root)
      } catch (e) {
        this.$message({
          type: 'info',
          message: e === 'cancel' ? '取消操作' : '操作失败'
        })
      }
    },
    // 改变节点的状态
    changeTreeNodeStatus (node) {
      node.expanded = this.expandAll
      for (let i = 0; i < node.childNodes.length; i++) {
        // 改变节点的自身expanded状态
        node.childNodes[i].expanded = this.expandAll
        // 遍历子节点
        if (node.childNodes[i].childNodes.length > 0) {
          this.changeTreeNodeStatus(node.childNodes[i])
        }
      }
    },
    // 提交新增的表单
    onSubmit () {
    },
    //  重置表单
    resetForm (formName) {
      this.$refs[formName].resetFields()
    }
  }
}
</script>

<style scoped>
  .custom-tree-node {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    padding-right: 8px;
  }
  .dialog-footer{
    float: right;
  }
</style>
