// api 地址
const api = {
  modularList: 'admin/equipmentServer/info',
  modularListParam: 'admin/equipmentServer/info?keyword=',
  modularAdd: 'admin/modularChangeLog/add'
}
// 获取产品列表
export async function modularList (ele, param) {
  if (param !== '') {
    var { data } = await ele.$http.get(api.modularListParam + param)
    return data
  } else {
    var { data1 } = await ele.$http.get(api.modularList)
    return data1
  }
}

// 添加产品
export async function modularAdd (ele, param) {
  var { data } = await ele.$http.post(api.modularAdd, param)
  return data
}
