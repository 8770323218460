<template>
    <div>
      <el-row style="margin: 0 10px">
        <el-col :span="2" style="font-size: 18px">设备类型</el-col>
        <el-col :span="5" style="float: right;">
          <!--面包屑导航-->
          <el-breadcrumb separator="/">
            <el-breadcrumb-item>售后管理</el-breadcrumb-item>
            <el-breadcrumb-item><a>维修项目</a></el-breadcrumb-item>
            <el-breadcrumb-item><a>设备类型</a></el-breadcrumb-item>
          </el-breadcrumb>
        </el-col>
      </el-row>
      <el-card>
        <el-row>
          <el-button type="success" @click="returnMsg">返回</el-button>
        </el-row>
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="外观选项" name="first">
            <el-row style="margin-top: 15px">
              <el-col :span="5" style="line-height: 40px;font-size: 20px">外观选项</el-col>
              <el-col :span="10">
                <el-button type="primary">新增</el-button>
              </el-col>
            </el-row>
            <el-table border :data="productList" height="450px">
              <el-table-column label="序号" type="index" width="80px"></el-table-column>
              <el-table-column label="外观" prop="title"></el-table-column>
              <el-table-column label="操作" prop="desc">
                <el-button type="warning">修改</el-button>
                <el-button type="danger">删除</el-button>
              </el-table-column>
            </el-table>
          </el-tab-pane>
          <el-tab-pane label="故障描述" name="second">
            <el-row style="margin-top: 15px">
              <el-col :span="5" style="line-height: 40px;font-size: 20px">故障描述</el-col>
              <el-col :span="10">
                <el-button type="primary">新增</el-button>
              </el-col>
            </el-row>
            <el-table border :data="productList" height="450px">
              <el-table-column label="序号" type="index" width="80px"></el-table-column>
              <el-table-column label="故障描述" prop="title"></el-table-column>
              <el-table-column label="操作" prop="desc">
                <el-button type="warning">修改</el-button>
                <el-button type="danger">删除</el-button>
              </el-table-column>
            </el-table>
          </el-tab-pane>
          <el-tab-pane label="检修结论" name="third">
            <el-row style="margin-top: 15px">
              <el-col :span="5" style="line-height: 40px;font-size: 20px">检修结论</el-col>
              <el-col :span="10">
                <el-button type="primary">新增</el-button>
              </el-col>
            </el-row>
            <el-table border :data="productList" height="450px">
              <el-table-column label="序号" type="index" width="80px"></el-table-column>
              <el-table-column label="检修结论" prop="title"></el-table-column>
              <el-table-column label="操作" prop="desc">
                <el-button type="warning">修改</el-button>
                <el-button type="danger">删除</el-button>
              </el-table-column>
            </el-table>
          </el-tab-pane>
          <el-tab-pane label="处理意见" name="fourth">
            <el-row style="margin-top: 15px">
              <el-col :span="5" style="line-height: 40px;font-size: 20px">处理意见</el-col>
              <el-col :span="10">
                <el-button type="primary">新增</el-button>
              </el-col>
            </el-row>
            <el-table border :data="productList" height="450px">
              <el-table-column label="序号" type="index" width="80px"></el-table-column>
              <el-table-column label="处理意见" prop="title"></el-table-column>
              <el-table-column label="操作" prop="desc">
                <el-button type="warning">修改</el-button>
                <el-button type="danger">删除</el-button>
              </el-table-column>
            </el-table>
          </el-tab-pane>
        </el-tabs>
      </el-card>
    </div>
</template>

<script>
export default {
  name: 'EquipmentType',
  data () {
    return {
      activeName: 'first',
      productList: []
    }
  },
  methods: {
    handleClick (tab, event) {
      console.log(tab, event)
    },
    returnMsg: function () {
      this.$router.go(-1)
    }
  }
}
</script>

<style scoped>

</style>
