<template>
  <div>
    <!--面包屑-->
    <el-row style="margin: 0 10px">
      <el-col :span="2" style="font-size: 18px">设备下拨</el-col>
      <el-col :span="5" style="float: right;">
        <!--面包屑导航-->
        <el-breadcrumb separator="/">
          <el-breadcrumb-item>生产管理</el-breadcrumb-item>
          <el-breadcrumb-item><a>设备列表</a></el-breadcrumb-item>
          <el-breadcrumb-item><a>设备下拨</a></el-breadcrumb-item>
        </el-breadcrumb>
      </el-col>
    </el-row>
    <el-card>
      <el-button type="success" @click="returnMsg">返回</el-button>
      <el-form class="elForm" :model="form" label-width="135px" :rules="rulesProp" ref="addFormRef">
        <el-row>
          <el-col :span="20">
            <el-form-item label="出库至代理" prop="parent_id">
              <el-input v-model="agent_ids" @click="selectPop" disabled></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-button type="primary" @click="selectPop" style="margin-left: 20px">选择</el-button>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="20">
<!--            <el-form-item label="订单编号">-->
<!--              <el-input  v-model="form.orderId" placeholder="订单编号"></el-input>-->
<!--            </el-form-item>-->
            <el-form-item label="产品">
              <el-select v-model="form.equipment_type_id" placeholder="请选择产品(必选)" @change="selectCP">
                <el-option v-for="(item, index) in getAllEqData" :key="index" :label="item.title" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
<!--          <el-col :span="4">-->
<!--            <el-button type="primary" @click="surePop" style="margin-left: 20px">确认</el-button>-->
<!--          </el-col>-->
        </el-row>
        <el-form-item label="门店">
          <el-select v-model="form.shop_id" placeholder="请选择门店" @change="selectCP">
            <el-option v-for="(item, index) in getAllShopData" :key="index" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
<!--        <el-form-item label="有效时间" prop="imei">-->
<!--          <el-date-picker-->
<!--            v-model="date"-->
<!--            type="datetimerange"-->
<!--            range-separator="至"-->
<!--            start-placeholder="开始日期"-->
<!--            end-placeholder="结束日期"-->
<!--            value-format="yyyy-MM-dd HH:mm:ss">-->
<!--          </el-date-picker>-->
<!--        </el-form-item>-->
<!--        <el-form-item label="产品">-->
<!--          <el-select v-model="form.equipment_type_id" placeholder="请选择产品(必选)" @change="selectCP">-->
<!--            <el-option v-for="(item, index) in getAllEqData" :key="index" :label="item.title" :value="item.id"></el-option>-->
<!--          </el-select>-->
<!--        </el-form-item>-->
      </el-form>
      <div class="transferClass">
        <el-transfer
          filterable
          filter-placeholder="请输入SN"
          v-model="value"
          :data="dataSN"
          :titles="['库存', '已选择']"
          :props=teacherListTransferProps
          @change="changeData">
        </el-transfer>
      </div>
      <div style="padding: 30px 0;display: flex;justify-content: center">
        <el-button type="primary" @click="submitBind">提交</el-button>
      </div>
    </el-card>
    <!--      代理商-->
    <div class="getPop" v-if="getAgentPop">
      <div class="popModel">
        <el-tree :data="data" :props="defaultProps" @node-click="handleNodeClick" :default-expand-all="true"></el-tree>
        <div style="display: flex;justify-content: center">
          <el-button @click="sure" type="primary" style="margin: 20px 10px">确认</el-button>
          <el-button @click="clean" style="margin: 20px 10px">取消</el-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import * as agentList from '../../interface/agentManage/mercantileAgent'
import * as getReceiptRegister from '../../interface/AfterSalesManage/ReceiptRegister'
import * as getEquipment from '../../interface/deviceManage/EquipmentAllocation'
export default {
  name: 'EquipmentAllocation',
  data () {
    return {
      getAgentPop: false,
      agent_id: '',
      agent_ids: '',
      data: [],
      date: [],
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      addForm: {},
      dataSN: [],
      form: {},
      value: [],
      teacherListTransferProps: {
        key: 'id',
        label: 'equipment_no'
      },
      getAllEqData: [],
      rulesProp: {
        parent_id: [
          { required: true, message: '请选择代理', trigger: 'blur' }
        ]
      },
      getAllShopData: []
    }
  },
  created () {
    this.getData()
    this.getAllData()
  },
  methods: {
    // 所有门店
    getAllData: async function () {
      const data = await getEquipment.getAllShop(this)
      if (data.code !== 0) return this.$message.error('获取所有门店失败')
      this.getAllShopData = data.result
    },
    returnMsg: function () {
      this.$router.go(-1)
    },
    // 提交
    submitBind: async function () {
      this.form.equipment_start_time = this.date[0]
      this.form.equipment_end_time = this.date[1]
      const data = await getEquipment.agentBindEquipment(this, this.form)
      if (data.code !== 0) return this.$message.error(data.msg)
      this.$message.success('绑定成功')
      this.$router.push('DeviceList')
      // this.$refs.addFormRef.validate(async valid => {
      //   if (!valid) return this.$message.error('请填写完整信息')
      //   const data = await getEquipment.agentBindEquipment(this, this.form)
      //   if (data.code !== 0) return this.$message.error(data.msg)
      //   this.$message.success('绑定成功')
      //   this.$router.push('DeviceList')
      // })
    },
    // 选择产品
    selectCP: async function () {
      var p = ''
      if (this.form.equipment_type_id) p = '?equipment_type_id=' + this.form.equipment_type_id
      if (this.form.equipment_type_id === 'all') p = ''
      const data = await getEquipment.getEquipmentByType(this, p)
      if (data.code !== 0) return this.m.erarr('获取设备类型失败')
      console.log(data)
      this.dataSN = data.result
    },
    // 获取产品类型
    getData: async function () {
      const data = await getReceiptRegister.equipmentTypeAll(this)
      if (data.code !== 0) return this.$message.error('获取全部产品列表失败')
      this.getAllEqData = data.result
    },
    // 选择经销商
    selectPop: async function () {
      var that = this
      that.getAgentPop = true
      const res = await agentList.getList(this)
      if (res.code !== 0) return that.$message.error('获取信息失败')
      console.log(res)
      that.data = res.result
      res.result.forEach(function (item, index, array) {
        that.data[index].label = item.name
        if (item.children) {
          item.children.forEach(function (item1, index1, array1) {
            that.data[index].children[index1].label = item1.name
            if (item1.children) {
              item1.children.forEach(function (item2, index2, array2) {
                that.data[index].children[index1].children[index2].label = item2.name
                delete that.data[index].children[index1].children[index2].children
              })
            }
          })
        }
      })
      // that.data = data
      console.log(that.data)
    },
    // 选择经销商id
    handleNodeClick: function (e) {
      console.log(e)
      this.agent_id = e.id
      this.agent_ids = e.label
      // this.getAgentPop = true
    },
    // 确认选择
    sure: function () {
      this.form.agent_id = this.agent_id
      this.getAgentPop = false
    },
    // 取消
    clean: function () {
      this.getAgentPop = false
    },
    // 选择
    changeData: function (value, direction) {
      console.log(value)
      this.form.equipmentList = value
    }
  }
}
</script>
<style scoped>
  .el-form-item__content {
    display: flex !important;
  }
  .getPop{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.2);
    z-index: 99999;
  }
  .el-tree {
    padding: 10px 0;
  }
  .popModel{
    width: 40%;
    position: relative;
    margin: 20% auto 50px;
    background: #FFF;
    border-radius: 2px;
    box-sizing: border-box;
  }
  .transferClass{
    width: 1000px;
    margin: 0 auto;
  }
  .transferClass >>> .el-transfer-panel{
    width: 400px;
  }
  .elForm{
    width: 700px;
    margin: 0 auto;
  }
  .el-select {
    width: 450px;
  }
  .el-date-editor--datetimerange.el-input__inner {
    width: 450px;
  }
</style>
