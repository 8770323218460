<template>
    <div>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.pageNo"
        :page-sizes="[5, 10, 15, 20]"
        :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="queryInfo.total">
      </el-pagination>
    </div>
</template>

<script>

export default {
  props: ['currentPage', 'queryInfos', 'queryUrl', 'pamams', 'type'],
  data: function () {
    return {
      queryInfo: this.queryInfos,
      queryPath: this.queryUrl
    }
  },
  methods: {
    async handleSizeChange (newSize) {
      const resAll = await import('../../interface/' + this.queryPath)
      // const data = await resAll.pageChange(this, { pageSize: newSize })
      var data = {}
      console.log(this.pamams, this.type)
      if (this.type) {
        data = await resAll.pageChanges(this, { pageSize: newSize, pageNo: 1 }, this.pamams, this.type)
      } else {
        if (this.pamams) {
          data = await resAll.pageChanges(this, { pageSize: newSize, pageNo: 1 }, this.pamams)
        } else {
          data = await resAll.pageChanges(this, { pageSize: newSize, pageNo: 1 })
        }
      }
      var productList = data.result.data
      this.queryInfo.total = data.result.totalCount
      this.queryInfo.pageNo = data.result.pageNo
      this.queryInfo.pageSize = data.result.pageSize
      this.$emit('pageChange', { productList: productList, queryInfo: this.queryInfo })
    },
    async handleCurrentChange (current) {
      const resAll = await import('../../interface/' + this.queryPath)
      // const data = await resAll.pageChange(this, { pageNo: current })
      var data = {}
      if (this.type) {
        data = await resAll.pageChanges(this, { pageNo: current, pageSize: this.queryInfo.pageSize }, this.pamams, this.type)
      } else {
        if (this.pamams) {
          data = await resAll.pageChanges(this, { pageNo: current, pageSize: this.queryInfo.pageSize }, this.pamams)
        } else {
          data = await resAll.pageChanges(this, { pageNo: current, pageSize: this.queryInfo.pageSize })
        }
      }
      var productList = data.result.data
      this.queryInfo.total = data.result.totalCount
      this.queryInfo.pageNo = data.result.pageNo
      this.queryInfo.pageSize = data.result.pageSize
      this.$emit('pageChange', { productList: productList, queryInfo: this.queryInfo })
    }
  }
}
</script>

<style scoped>

</style>
