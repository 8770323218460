<template>
  <div>
    <el-row style="margin: 0 10px">
      <el-col :span="2" style="font-size: 18px">投诉建议</el-col>
      <el-col :span="5" style="float: right;">
        <!--面包屑导航-->
        <el-breadcrumb separator="/">
          <el-breadcrumb-item>投诉建议</el-breadcrumb-item>
        </el-breadcrumb>
      </el-col>
    </el-row>
    <el-card>
      <el-form :inline="true" :model="data" class="demo-form-inline">
        <el-form-item>
          <el-date-picker
            v-model="data.create_time"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd HH:mm:ss">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="类型">
          <el-select v-model="data.type" placeholder="类型">
            <el-option label="全部" value=""></el-option>
            <el-option label="投诉" value="1"></el-option>
            <el-option label="建议" value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="用户昵称">
          <el-input v-model="data.user_name" placeholder="用户昵称"></el-input>
        </el-form-item>
        <el-form-item label="商户">
          <el-select v-model="data.merchant_id" placeholder="商户">
            <el-option label="全部" value=""></el-option>
            <el-option v-for="(item, index) in MerchantData" :key="index" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="getList()">查询</el-button>
        </el-form-item>
      </el-form>
      <el-table
        ref="filterTable"
        :data="complaintData"
        border
        style="width: 100%">
        <el-table-column prop="types" label="类型"></el-table-column>
        <el-table-column prop="user_name" label="用户昵称"></el-table-column>
        <el-table-column prop="merchant_name" label="所属商户"></el-table-column>
        <el-table-column prop="title" label="标题"></el-table-column>
<!--        <el-table-column prop="content" label="内容"></el-table-column>-->
        <el-table-column prop="create_time" label="创建时间"></el-table-column>
<!--        <el-table-column prop="tocontent" label="商家回复内容"></el-table-column>-->
        <el-table-column prop="status" label="处理结果">
          <template #default="scope">
            <el-tag v-if="scope.row.status == 0" type="warning">未处理</el-tag>
            <el-tag v-if="scope.row.status == 1" class="el-tag--success">已处理</el-tag>
          </template>
        </el-table-column>
<!--        <el-table-column prop="create_time" label="创建时间"></el-table-column>-->
        <el-table-column label="操作">
          <template #default="scope">
            <detail-pop title="投诉建议" :type="scope.row.status == 0 ? 1 : 2" :id="scope.row.id" :ProductFormList="ProductFormList" @infoData="infoData" @submitBtn="getList"></detail-pop>

          </template>
        </el-table-column>
      </el-table>
      <page :queryInfos="queryInfo" @pageChange="PageChanges" query-url="Complaint/ComplaintList" :pamams="'type=' + (this.data.type || '') + '&user_name=' + (this.data.user_name || '') + '&merchant_id=' + (this.data.merchant_id || '') + '&create_time=' + (this.data.create_time || '')"></page>
    </el-card>
  </div>
</template>

<script>
import * as getComplaint from '../../interface/Complaint/ComplaintList'
import Pagination from '../common/Pagination'
import DetailPop from '../common/detailPop'
import * as getSalesOrder from '../../interface/systemManage/SalesOrder'
export default {
  name: 'ComplaintList',
  components: {
    DetailPop,
    page: Pagination
  },
  data: function () {
    return {
      complaintData: [],
      queryInfo: {
        region: '',
        pageNo: 1,
        pageSize: 2,
        total: 0
      },
      ProductFormList: [],
      url: 'http://wl.zhenyoukeji.com',
      dataName: '',
      data: {},
      MerchantData: []
    }
  },
  created () {
    this.getList()
    this.getAllMerchant()
  },
  methods: {
    // 获取全部商户
    getAllMerchant: async function () {
      const data = await getSalesOrder.getAllMerchant(this)
      if (data.code !== 0) return this.$message.error('获取全部商户信息')
      this.MerchantData = data.result
    },
    // 获取建议列表
    getList: async function () {
      var p = '?type=' + (this.data.type || '') + '&user_name=' + (this.data.user_name || '') + '&merchant_id=' + (this.data.merchant_id || '') + '&create_time=' + (this.data.create_time || '')
      const data = await getComplaint.ComplaintLists(this, p)
      if (data.code !== 0) return this.$message.error(data.msg)
      var complaintData = data.result.data
      for (var i = 0; i < complaintData.length; i++) {
        complaintData[i].types = complaintData[i].type === 1 ? '投诉' : '建议'
      }
      this.complaintData = complaintData
      this.queryInfo.pageNo = data.result.pageNo
      this.queryInfo.pageSize = data.result.pageSize
      this.queryInfo.total = data.result.totalCount
    },
    PageChanges: function (e) {
      var complaintData = e.productList
      for (var i = 0; i < complaintData.length; i++) {
        complaintData[i].types = complaintData[i].type === 1 ? '投诉' : '建议'
      }
      this.complaintData = complaintData
      this.queryInfo = e.queryInfo
    },
    // 获取详情
    infoData: async function (id) {
      var imgShow = []
      const data = await getComplaint.ComplaintInfo(this, id)
      if (data.code !== 0) return this.$message.error('获取详情失败')
      if (data.result.img) {
        for (var j = 0; j < data.result.img.length; j++) {
          imgShow.push(this.url + data.result.img[j])
        }
      }
      this.ProductFormList = [
        { title: '类型', value: data.result.type === 1 ? '投诉' : '建议' },
        { title: '用户昵称', value: data.result.user_name },
        { title: '所属商户', value: data.result.merchant_name },
        { title: '标题', value: data.result.title },
        { title: '内容', value: data.result.content },
        { title: '处理结果', value: data.result.status === 0 ? '未处理' : '已处理' },
        { title: '创建时间', value: data.result.create_time },
        { title: '图片', value: data.result.img, imgs: imgShow }
      ]
      if (data.result.status === 0) {
        this.ProductFormList.push({ title: '回复' })
      } else {
        this.ProductFormList.push({ title: '回复内容', value: data.result.to_content || '暂无' }, { title: '处理时间', value: data.result.update_time })
      }
    }
  }
}
</script>

<style scoped>
  .el-tag + .el-tag {
    margin-left: 10px;
  }
  .button-new-tag {
    margin-left: 10px;
    height: 32px;
    line-height: 30px;
    padding-top: 0;
    padding-bottom: 0;
  }
  .input-new-tag {
    width: 90px;
    margin-left: 10px;
    vertical-align: bottom;
  }
</style>
