<template>
    <div>
      <el-row style="margin: 0 10px">
        <el-col :span="2" style="font-size: 18px">维修列表</el-col>
        <el-col :span="5" style="float: right;">
          <!--面包屑导航-->
          <el-breadcrumb separator="/">
            <el-breadcrumb-item>售后管理</el-breadcrumb-item>
            <el-breadcrumb-item><a>维修列表</a></el-breadcrumb-item>
          </el-breadcrumb>
        </el-col>
      </el-row>
      <el-card>
        <el-row style="padding-top: 20px">
          <el-form :inline="true" class="login_form" :model="form" ref="preEntrRef">
            <el-form-item prop="imei">
              <el-date-picker
                v-model="form.create_time"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy-MM-dd">
              </el-date-picker>
            </el-form-item>
            <el-form-item prop="mobile" label="状态" style="margin-left: 20px">
              <el-select v-model="form.status" placeholder="状态">
                <el-option label="全部" value=""></el-option>
                <el-option label="收件登记" value="0"></el-option>
                <el-option label="维修中" value="1"></el-option>
                <el-option label="财务审核" value="2"></el-option>
                <el-option label="已发货" value="3"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="mobile" class="showW">
              <el-input style="width: 350px !important;" placeholder="维修单号/机身编号/联系电话/快递单号" type="text" v-model="form.keyword"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" style="margin-left: 30px" @click="search">查询</el-button>
            </el-form-item>
          </el-form>
        </el-row>
        <el-table
          ref="filterTable"
          :data="formData"
          border
          style="width: 100%">
          <el-table-column type="index" label="序号"></el-table-column>
          <el-table-column prop="repair_name" label="维修点"></el-table-column>
          <el-table-column prop="express_no" label="维修单号"></el-table-column>
          <el-table-column prop="status1" label="状态"></el-table-column>
          <el-table-column prop="equipment_no" label="机身编号"></el-table-column>
          <el-table-column prop="consignee" label="姓名"></el-table-column>
          <el-table-column prop="consignee_mobile" label="手机号"></el-table-column>
          <el-table-column prop="create_time" label="创建时间"></el-table-column>
          <el-table-column prop="order_type1" label="工单类型"></el-table-column>
          <el-table-column prop="admin_user1_name" label="操作人员"></el-table-column>
          <el-table-column label="操作">
            <template  #default="scope">
              <el-button v-if="scope.row.status == 1" type="success" size="mini" @click="goReceiptRegister(scope.row.id)" style="margin-left: 5px !important;margin-right: 5px;margin-bottom: 5px;">维修</el-button>
              <el-button v-if="scope.row.status == 2" type="primary" size="mini" @click="goReceiptRegisters(scope.row.id)" style="margin-left: 5px !important;margin-right: 5px">发货</el-button>
            </template>
          </el-table-column>
        </el-table>
        <page :queryInfos="queryInfo" @pageChange="PageChange" queryUrl="AfterSalesManage/MaintenanceList" :pamams="'create_time=' + (form.create_time || '') + '&status=' + (form.status || '') + '&keyword=' + (form.keyword || '')"></page>
      </el-card>
    </div>
</template>

<script>
import * as getMaintenanceList from '../../interface/AfterSalesManage/MaintenanceList'
import Pagination from '../common/Pagination'
export default {
  name: 'MaintenanceList',
  components: {
    page: Pagination
  },
  data: function () {
    return {
      form: {
        keyword: '',
        status: ''
      },
      formData: [],
      queryInfo: {
        query: '',
        pageNo: 1,
        pageSize: 2,
        total: 0
      },
      arr: [3, 54, 7]
    }
  },
  created () {
    this.getRepairOrder('')
  },
  methods: {
    // 下一页
    PageChange: function (e) {
      // this.getRepairOrder()
      var formData = e.productList
      for (var i = 0; i < formData.length; i++) {
        var status1 = ''
        switch (formData[i].status) {
          case 0:
            status1 = '收件登记'
            break
          case 1:
            status1 = '维修中'
            break
          case 2:
            status1 = '财务审核'
            break
          case 3:
            status1 = '已发货'
            break
        }
        formData[i].type1 = formData[i].type === 1 ? '邮寄' : '送修'
        formData[i].order_type1 = formData[i].order_type === 1 ? '系统工单' : '收件工单'
        formData[i].status1 = status1
      }
      this.formData = formData
      this.queryInfo = e.queryInfo
    },
    // 维修跳转
    goReceiptRegister: function (id) {
      this.$router.push({
        path: '../ReceiptRegister',
        query: {
          type: 1,
          id: id
        }
      })
    },
    // 发货跳转
    goReceiptRegisters: function (id) {
      this.$router.push({
        path: '../ReceiptRegister',
        query: {
          type: 2,
          id: id
        }
      })
    },
    // 获取维修订单列表
    getRepairOrder: async function (p) {
      const data = await getMaintenanceList.getRepairOrder(this, p)
      if (data.code !== 0) return this.$message.error('获取维修订单列表错误')
      var formData = data.result.data
      for (var i = 0; i < formData.length; i++) {
        var status1 = ''
        switch (formData[i].status) {
          case 0:
            status1 = '收件登记'
            break
          case 1:
            status1 = '维修中'
            break
          case 2:
            status1 = '财务审核'
            break
          case 3:
            status1 = '已发货'
            break
        }
        formData[i].type1 = formData[i].type === 1 ? '邮寄' : '送修'
        formData[i].order_type1 = formData[i].order_type === 1 ? '系统工单' : '收件工单'
        formData[i].status1 = status1
      }
      this.formData = formData
      this.queryInfo.total = data.result.totalCount
      this.queryInfo.pageNo = data.result.pageNo
      this.queryInfo.pageSize = data.result.pageSize
      // console.log(this.formData)
    },
    // 查询
    search: async function () {
      console.log(this.form)
      // if (this.form.create_time)
      var p = ''
      p = '?create_time=' + (this.form.create_time || '') + '&status=' + (this.form.status || '') + '&keyword=' + (this.form.keyword || '')
      this.getRepairOrder(p)
    }
  }
}
</script>

<style scoped>

</style>
