<template>
  <div class="editContainer">
<!--    修改的按钮-->
    <el-button v-if="type == 1" size="mini" type="primary" @click="showEditDialog()">{{$t('home.bj')}}</el-button>
    <el-button v-else size="mini" class="el-icon-edit" type="primary" @click="showEditDialog()"></el-button>
<!--    修改的弹出框-->
    <el-dialog
      :title="$t('edit.xg') + ' ' +editNames"
      :visible.sync="editDialogVisible"
      width="50%"
      @close="editDialogClosed"
    >
      <!--编辑用户的表单-->
      <el-form label-width="150px" :model="editProductForm" :rules="editProductFormRules" ref="editProductFormRef">
        <el-form-item
          v-for="item in editProductFormList"
          :key="item.index"
          :label="item.title"
          :prop="item.prop">
          <el-radio-group v-if="item.title==='是否默认'" v-model="editProductForm[item.prop]">
            <el-radio :label=0>不设为默认</el-radio>
            <el-radio :label=1>设为默认</el-radio>
          </el-radio-group>
          <el-radio-group v-else-if="item.title===$t('devDetail.zt')" v-model="editProductForm[item.prop]">
            <el-radio :label="0">{{$t('admin.zc')}}</el-radio>
            <el-radio :label="1">{{$t('admin.jy')}}</el-radio>
          </el-radio-group>
          <el-select v-else-if="item.title=== $t('home.khmc')" v-model="editProductForm[item.prop]" :placeholder="$t('home.khmc')" @change="((val)=> {selectId(val ,0)})" filterable>
            <!-- <el-option :label="$t('add.qxz')" :value="0"></el-option> -->
            <el-option v-for="(item, index) in getItemAll" :key="index" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
          <el-select style="float: left" v-else-if="item.title===$t('role.js')" v-model="editProductForm[item.prop]" :placeholder="$t('role.js')">
            <el-option :label="$t('sh.qb')" :value="0"></el-option>
            <el-option v-for="(item, index) in adminUserList" :key="index" :label="item.name" :value="item.id"></el-option>
          </el-select>
<!--          <el-select v-else-if="item.title==='客户名称'" v-model="editProductForm[item.prop]" placeholder="客户名称" @change="((val)=> {selectId(val ,0)})">-->
<!--            <el-option label="请选择" :value="0"></el-option>-->
<!--            <el-option v-for="(item, index) in getItemAll" :key="index" :label="item.name" :value="item.id"></el-option>-->
<!--          </el-select>-->
<!--          <el-select v-else-if="item.title==='一级代理'" v-model="editProductForm[item.prop]" placeholder="一级代理" @change="((val)=> {selectId(val ,0)})">-->
<!--            <el-option label="请选择" :value="0"></el-option>-->
<!--            <el-option v-for="(item, index) in getItemAll" :key="index" :label="item.name" :value="item.id"></el-option>-->
<!--          </el-select>-->
          <el-select v-else-if="item.title==='二级代理'" v-model="editProductForm[item.prop]" placeholder="二级代理" @change="((val)=> {selectId(val ,1)})">
            <el-option label="请选择" :value="0"></el-option>
            <el-option v-for="(item, index) in getAgentAll" :key="index" :label="item.name" :value="item.id"></el-option>
          </el-select>
          <el-select v-else-if="item.title==='三级代理'" v-model="editProductForm[item.prop]" placeholder="三级代理" @change="((val)=> {selectId(val ,2)})">
            <el-option label="请选择" :value="0"></el-option>
            <el-option v-for="(item, index) in getCityAll" :key="index" :label="item.name" :value="item.id"></el-option>
          </el-select>
          <el-select v-else-if="item.title==='场地'" v-model="editProductForm[item.prop]" placeholder="场地" @change="((val)=> {selectId(val ,3)})">
            <el-option label="请选择" :value="0"></el-option>
            <el-option v-for="(item, index) in getSceneAll" :key="index" :label="item.name" :value="item.id"></el-option>
          </el-select>
          <!--          省市区联动-->
          <el-cascader
            size="large"
            :options="options"
            v-model="selectedOptions"
            @change="handleChange" v-else-if="item.title==='选择省市区'" style="width: 100%"></el-cascader>
          <!--          维修点人员列表-->
<!--          <el-checkbox-group v-else-if="item.title==='维修点人员'" v-model="editProductForm[item.prop]">-->
<!--            <el-checkbox style="float: left" v-for="item in adminList" :key="item.id" :label="item.id" :true-label="1" :false-label="0">{{item.name}}</el-checkbox>-->
<!--          </el-checkbox-group>-->
          <el-select v-else-if="item.title==='维修点人员'" v-model="user_ids" placeholder="维修点人员" style="width: 100%">
            <el-option label="全部" value=""></el-option>
            <el-option v-for="(item, index) in adminLists" :key="index" :label="item.username" :value="item.id"></el-option>
          </el-select>
          <el-input
            v-else-if="item.title==='支付宝私钥'"
            type="textarea"
            :rows="3"
            placeholder="请输入内容"
            v-model="editProductForm[item.prop]">
          </el-input>
          <el-input
            v-else-if="item.title==='支付宝公钥'"
            type="textarea"
            :rows="3"
            placeholder="请输入内容"
            v-model="editProductForm[item.prop]">
          </el-input>
          <el-input v-model="editProductForm[item.prop]" v-else-if="item.title==='密码'" type="password"></el-input>
          <el-select v-else-if="item.title===$t('common.type')" v-model="editProductForm[item.prop]" :placeholder="$t('common.sel')">
            <el-option v-for="item in typeList" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
          <el-input v-else v-model="editProductForm[item.prop]" :disabled="item.disabled ? item.disabled : false"></el-input>
        </el-form-item>
      </el-form>

      <template #footer>
        <span class="dialog-footer">
          <el-button @click="editDialogVisible = false">{{$t('home.qx')}}</el-button>
          <el-button :type="isBtn ? 'primary' : ''" @click="editProduct">{{$t('home.qd')}}</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { regionData, CodeToText, TextToCode } from 'element-china-area-data'
import * as getDevice from '../../interface/Img/DeviceLists'

export default {
  props: ['editId', 'editForm', 'editRules', 'editApi', 'editProductFormLists', 'editName', 'equipment_type_id', 'adminLists', 'type'],
  data: function () {
    return {
      editDialogVisible: false,
      editProductForm: this.editForm,
      editProductFormRules: this.editRules,
      editProductFormList: this.editProductFormLists,
      editNames: this.editName,
      options: regionData,
      selectedOptions: [],
      addtions: {},
      adminList: [],
      user_ids: '',
      isBtn: true,
      getItemAll: [],
      getAgentAll: [],
      getCityAll: [],
      getSceneAll: [],
      getIdArr: ['', '', '', ''],
      adminUserList: [],
      // 类型列表
      typeList: []
    }
  },
  created () {
    for (const keys in this.editProductFormLists) {
      if (this.editProductFormLists[keys].title === '维修点人员') {
        this.getAdmin()
      }
      if (this.editProductFormLists[keys].title === '一级代理') {
        this.Agent()
      }
      if (this.editProductFormLists[keys].title === this.$t('role.js')) {
        this.getAdminUser()
      }
      if (this.editProductFormLists[keys].title === this.$t('common.type')) {
        this.getTypeList()
      }
    }
  },
  methods: {
    // 获取类型列表
    async getTypeList(){
      const data = await getDevice.getTypeList(this, '')
      if (data.code !== 0) return this.$message.error('获取类型列表失败')
      this.typeList = data.result
    },
    // 获取角色
    getAdminUser: async function () {
      const resAll = await import('../../interface/' + this.editApi)
      const data = await resAll.adminGetAll(this)
      if (data.code !== 0) return this.$message.error(this.$t('role.hqjssbs'))
      this.adminUserList = data.result
    },
    // 获取代理
    Agent: function () {
      this.Agent1()
      this.Agent2()
      this.Agent3()
      this.Agent4()
    },
    Agent1: async function () {
      // 获取一级代理
      const data = await getDevice.getItemAll(this, '')
      if (data.code !== 0) return this.$message.error('获取一级代理失败')
      this.getItemAll = data.result
    },
    Agent2: async function () {
      // 获取二级代理
      const data1 = await getDevice.getAgentAll(this, '?item_id=' + this.getIdArr[0])
      if (data1.code !== 0) return this.$message.error('获取二级代理失败')
      this.getAgentAll = data1.result
    },
    Agent3: async function () {
      // 获取三级代理
      const data2 = await getDevice.getCityAll(this, '?item_id=' + this.getIdArr[0] + '&agent_id=' + this.getIdArr[1])
      if (data2.code !== 0) return this.$message.error('获取三级代理失败')
      this.getCityAll = data2.result
    },
    Agent4: async function () {
      // 获取场地
      const data3 = await getDevice.getSceneAll(this, '?item_id=' + this.getIdArr[0] + '&agent_id=' + this.getIdArr[1] + '&city_id=' + this.getIdArr[2])
      if (data3.code !== 0) return this.$message.error('获取三级代理失败')
      this.getSceneAll = data3.result
    },
    selectId: function (id, index) {
      // console.log(index)
      this.getIdArr[index] = id
      console.log(this.getIdArr)
      if (index === 0) {
        this.Agent2()
        this.Agent3()
        this.Agent4()
        this.editProductForm.agent_id = ''
        this.editProductForm.city_id = ''
        this.editProductForm.scene_id = ''
      } else if (index === 1) {
        this.Agent3()
        this.Agent4()
        this.editProductForm.city_id = ''
        this.editProductForm.scene_id = ''
      } else if (index === 2) {
        this.Agent4()
        this.editProductForm.scene_id = ''
      }
    },
    // 获取管理员列表
    getAdmin: async function () {
      const resAll = await import('../../interface/' + this.editApi)
      const data = await resAll.adminList(this)
      console.log('获取所有管理员列表')
      console.log(data)
      this.adminList = data.result
    },
    // 查询编辑对话框的内的信息
    showEditDialog: async function () {
      if (!this.isBtn) return false
      this.isBtn = false
      console.log('弹出框', this.editId)
      const resAll = await import('../../interface/' + this.editApi)
      const data = await resAll.editProductDialog(this, this.editId)
      console.log('获取信息失败')
      console.log(data)
      console.log(this.editProductForm)
      if (data.code !== 0) {
        this.isBtn = true
        return this.$message.error(this.$t('edit.hqxxsb'))
      }
      var addProductD = {}
      for (var i = 0; i < this.editProductFormList.length; i++) {
        var name = this.editProductFormList[i].prop
        addProductD[name] = data.result[name]
      }
      addProductD.id = data.result.id
      addProductD.equipment_type_id = data.result.equipment_type_id
      console.log(addProductD)
      if (addProductD.item_id || addProductD.item_id === 0) {
        this.getIdArr = [addProductD.item_id, addProductD.agent_id, addProductD.city_id, addProductD.scene_id]
        this.Agent()
      }
      this.isBtn = true
      console.log('获取到西悉尼')
      if (addProductD.password) { delete addProductD.password }
      this.editProductForm = addProductD
      this.editDialogVisible = true
      // for (var j = 0; j < this.adminList.length; j++) {
      //   if (this.editProductForm.user_ids.indexOf(this.adminList[j]) === -1) {
      //     this.adminList[j] = 0
      //   } else {
      //     this.adminList[j] = 1
      //   }
      // }
      // this.editProductForm.user_ids = this.adminList
      this.editProductForm.user_ids = []
      // 联动默认选中
      if (data.result.province || data.result.city || data.result.district) {
        this.editProductForm.province = data.result.province
        this.editProductForm.city = data.result.city
        this.editProductForm.district = data.result.district
        this.selectedOptions = [
          TextToCode[this.editProductForm.province].code,
          TextToCode[this.editProductForm.province][this.editProductForm.city].code,
          TextToCode[this.editProductForm.province][this.editProductForm.city][this.editProductForm.district].code
        ]
      }
    },
    // 弹出层关闭
    editDialogClosed: function () {
      this.$refs.editProductFormRef.resetFields()
    },
    // 提交修改产品信息
    editProduct: function () {
      this.$refs.editProductFormRef.validate(async valid => {
        console.log(this.user_ids)
        console.log(this.editProductForm)
        if (this.user_ids) {
          this.editProductForm.user_ids = []
          this.editProductForm.user_ids.push(this.user_ids)
        }
        // 校验不通过
        if (!valid) return this.$message.error(this.$t('edit.qtxwzxx'))
        // 通过,则发起修改用户的请求
        const resAll = await import('../../interface/' + this.editApi)
        var id = this.editProductForm.id
        if (this.editProductForm.equipment_type_id) id = this.editProductForm.equipment_type_id
        const res = await resAll.editProduct(this, id, this.editProductForm)
        // 修改之后的用户反馈
        if (res.code !== 0) return this.$message.error(this.$t('edit.xgsb') + ',' + res.msg)
        this.$message.success(this.$t('edit.xgcg'))
        // 关闭对话框
        this.editDialogVisible = false
        // 重新请求数据,刷新页面
        this.$emit('gainMsg')
        // this.getProductList()
      })
    },
    handleChange (value) {
      // 我们选择地址后，selectedOptions 会保存对应的区域码，例如北京的区域码为'110000'
      // 我们要取出区域码对应的汉字，就需要用CodeToText(区域码)进行输出
      this.addtions.selectedOptions = value
      var name = ''
      for (var i = 0; i < this.selectedOptions.length; i++) {
        name = name + CodeToText[this.selectedOptions[i]] + '/'
        if (i === 0) {
          this.editProductForm.province = CodeToText[this.selectedOptions[i]]
        } else if (i === 1) {
          this.editProductForm.city = CodeToText[this.selectedOptions[i]]
        } else if (i === 2) {
          this.editProductForm.district = CodeToText[this.selectedOptions[i]]
        }
      }
      // this.selectedOptions.map(item => name += CodeToText[item] + '/')
      this.addtions.names = name
      console.log(this.addtions.names)
      console.log(this.addtions)
    }
  }
}
</script>

<style scoped>
  .editContainer{
    /*float: left;*/
    display: inline-block;
    margin-right: 10px;
  }
  .el-form-item {
    text-align: left !important;
  }
</style>
