const api = {
  equipmentTypeAll: 'admin/equipmentType/getAll',
  addSaleOrder: 'admin/SaleOrder/add',
  SaleOrderList: 'admin/Order/list',
  saleOrderInfo: 'admin/Order/info?id=',
  submitRefund: 'admin/Order/orderRefund',
  getAllEquipment: 'admin/equipmentType/getAll',
  getAllMerchant: 'admin/Merchant/getAll'
}
// 获取全部产品类型
export async function equipmentTypeAll (ele) {
  const { data } = await ele.$http.get(api.equipmentTypeAll)
  return data
}
// 创建订单
export async function addSaleOrder (ele, param) {
  const { data } = await ele.$http.post(api.addSaleOrder, param)
  return data
}
// 获取销售订单列表
export async function SaleOrderList (ele, param) {
  const { data } = await ele.$http.get(api.SaleOrderList + param)
  return data
}
export async function pageChanges (ele, param, params) {
  var p = []
  for (var i in param) {
    p.push(i + '=' + param[i])
  }
  var { data } = await ele.$http.get(api.SaleOrderList + '?' + p[0] + '&' + p[1] + '&' + params)
  return data
}

// 获取退款的详情信息
export async function saleOrderInfo (ele, param) {
  const { data } = await ele.$http.get(api.saleOrderInfo + param)
  return data
}
// 提交退款的金额
export async function submitRefund (ele, param) {
  const { data } = await ele.$http.get(api.submitRefund + param)
  return data
}
// 获取全部设备类型
export async function getAllEquipment (ele) {
  const { data } = await ele.$http.get(api.getAllMerchant)
  return data
}
// 获取全部商户
export async function getAllMerchant (ele) {
  const { data } = await ele.$http.get(api.getAllMerchant)
  return data
}
