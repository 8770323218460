// 地址
const api = {
  verifyList: 'admin/FileManagement/verifyList',
  verifyBtn: 'admin/FileManagement/verify'
}
// 获取列表
export async function verifyList (ele, params) {
  const { data } = await ele.$http.get(api.verifyList + params)
  return data
}
export async function pageChanges (ele, param, params) {
  var p = []
  for (var i in param) {
    p.push(i + '=' + param[i])
  }
  var { data } = await ele.$http.get(api.verifyList + '?' + p[0] + '&' + p[1] + '&' + params)
  return data
}
// 审核
export async function verifyBtn (ele, params) {
  const { data } = await ele.$http.put(api.verifyBtn, params)
  return data
}
// 编辑
export async function editProduct (ele, id, params) {
  const { data } = await ele.$http.put(api.editProduct + '?id=' + id, params)
  return data
}
