<template>
  <div>
    <el-row style="margin: 0 10px">
      <el-col :span="2" style="font-size: 18px">主页</el-col>
      <el-col :span="5" style="float: right;">
        <!--面包屑导航-->
        <el-breadcrumb separator="/">
          <el-breadcrumb-item>首页</el-breadcrumb-item>
        </el-breadcrumb>
      </el-col>
    </el-row>
    <el-row style="margin: 30px 0">
      <el-col :span="3" v-for="(item, index) in dataTotal" :key="index">
        <div class="ibox float-e-margins" style="color: white;margin: 20px;">
          <div class="ibox-title" style="background: #1ab394;">
            <h5>{{item.countKey}}</h5>
          </div>
          <div class="ibox-content" style="background: #1ab394;">
            <h1 class="no-margins">{{item.countValue}}</h1>
          </div>
        </div>
      </el-col>
    </el-row>
    <el-row class="showEchartTitle">
      <el-col :span="24">
        <span>统计</span>
      </el-col>
    </el-row>
    <el-row style="background-color: #ffffff">
      <el-col :span="24" style="display: flex;justify-content: center">
        <div id="showLine" style="width: 800px;height: 350px"></div>
      </el-col>
    </el-row>
    <el-row class="bottomShow">
      <span>{{bottomTitle}}</span>
    </el-row>
  </div>
</template>

<script>
import * as ECharts from 'echarts'
import * as getData from '../interface/Welcome'
// eslint 会让js顶格.  但是ctrl+alt+l,它又会让js有空格.冲突
// 解决办法: 让js代码不缩进
// File->Settings->Editor->Code Style->HTML->Do not indent children of->添加script
export default {
  name: 'Welcome',
  data: function () {
    return {
      bottomTitle: 'Powered by zhengyou · v1.0.1',
      dataTotal: [],
      showTotal: []
    }
  },
  created () {
    this.getData()
  },
  methods: {
    getData: async function () {
      const data = await getData.dataCount(this)
      if (data.code !== 0) return this.$message.error('获取统计数据失败')
      this.showTotal = data.result
      var dataTotal = []
      for (var i = 0; i < data.result.countKey.length; i++) {
        dataTotal.push({ countKey: data.result.countKey[i], countValue: data.result.countValue[i] })
      }
      this.dataTotal = dataTotal
      console.log(this.dataTotal)
    }
  },
  mounted () {
    // this.getData()
    this.$nextTick(() => {
      console.log(this)
      console.log(this.showTotal)
    })
    console.log(this.showTotal)
    // 基于准备好的dom，初始化echarts实例
    var myChart = ECharts.init(document.getElementById('showLine'))
    // 绘制图表
    myChart.setOption({
      xAxis: {
        data: ['A', 'B', 'C', 'D', 'E']
      },
      yAxis: {},
      series: [
        {
          data: [10, 22, 28, 23, 19, 35],
          type: 'line',
          areaStyle: {
            color: '#409EFF'
          }
        }
      ]
    })
  }
}
</script>

<style scoped>
  .topModel{
    padding: 30px;
    display: flex;
    justify-content: flex-end;
    background-color: #ffffff;
  }
  .text {
    font-size: 14px;
  }

  .item {
    margin-bottom: 18px;
  }

  .clearfix:before,
  .clearfix:after {
    display: table;
    content: "";
  }
  .clearfix:after {
    clear: both
  }

  .el-card {
    /*width: 480px;*/
    background-color: #1ab394 !important;
    color: #ffffff;
  }
  .ibox {
    clear: both;
    margin-bottom: 25px;
    margin-top: 0;
    padding: 0;
    color: white;
  }
  .ibox-title {
    -moz-border-bottom-colors: none;
    -moz-border-left-colors: none;
    -moz-border-right-colors: none;
    -moz-border-top-colors: none;
    background-color: #ffffff;
    border-color: #e7eaec;
    border-image: none;
    border-style: solid solid none;
    border-width: 2px 0 0;
    color: inherit;
    margin-bottom: 0;
    padding: 15px 15px 7px;
    min-height: 48px;
  }
  .ibox-title h5{
    text-align: center;
  }
  .ibox-content {
    background-color: #ffffff;
    color: inherit;
    padding: 15px 20px 20px 20px;
    border-color: #e7eaec;
    border-image: none;
    border-style: solid solid none;
    border-width: 1px 0;
  }
  .ibox-content {
    clear: both;
  }
  .no-margins {
    margin: 0 !important;
    text-align: center;
  }
  .stat-percent {
    float: right;
  }
  .font-bold {
    font-weight: 600;
  }
  .showEchartTitle{
    background-color: #ffffff;
    border-bottom: solid 1px #e9e9e9;
    padding: 20px;
  }
  .bottomShow{
    padding: 40px 20px 20px 20px;
    display: flex;
    justify-content: center;
  }
</style>
