<template>
    <div>
      <el-row style="margin: 0 10px">
        <el-col :span="2" style="font-size: 18px">会员信息变更</el-col>
        <el-col :span="5" style="float: right;">
          <!--面包屑导航-->
          <el-breadcrumb separator="/">
            <el-breadcrumb-item>会员管理</el-breadcrumb-item>
            <el-breadcrumb-item><a>会员信息变更</a></el-breadcrumb-item>
          </el-breadcrumb>
        </el-col>
      </el-row>
      <el-card>
        <el-button type="primary" @click="addMemberInfo">新增</el-button>
        <el-table
          ref="filterTable"
          :data="preEntryData"
          border
          style="width: 100%">
<!--          <el-table-column type="selection" width="55"></el-table-column>-->
          <el-table-column type="index" label="序号" width="50px"></el-table-column>
          <el-table-column prop="order_no" label="变更单号"></el-table-column>
          <el-table-column prop="mobiles" label="会员账号"></el-table-column>
<!--          <el-table-column prop="mobile" label="修改内容"></el-table-column>-->
          <el-table-column prop="user_accounts" label="原信息"></el-table-column>
          <el-table-column prop="user_accounted" label="修改信息"></el-table-column>
          <el-table-column prop="update_time" label="修改时间"></el-table-column>
          <el-table-column prop="statusType" label="状态"></el-table-column>
          <el-table-column prop="admin_user1_name" label="制单"></el-table-column>
          <el-table-column prop="admin_user2_name" label="审核"></el-table-column>
          <el-table-column prop="updated_at" label="操作">
            <template #default="scope">
<!--              <el-button type="primary" size="mini">详情</el-button>-->
              <el-button size="mini" v-if="scope.row.status != 1" type="success" @click="examine(scope.row.id)">审核</el-button>
            </template>
          </el-table-column>
        </el-table>
        <page :queryInfos="queryInfo" @pageChange="PageChange" queryUrl="memberManage/MemberInfo"></page>
      </el-card>
    </div>
</template>

<script>
import * as getMemberInfo from '../../interface/memberManage/MemberInfo'
import Pagination from '../common/Pagination'
export default {
  name: 'MemberInfo',
  components: {
    page: Pagination
  },
  data: function () {
    return {
      preEntryData: [],
      queryInfo: {
        query: '',
        pageNo: 1,
        pageSize: 2,
        total: 0
      },
      verifyFrom: {}
    }
  },
  created () {
    this.getUserVerifyList()
  },
  methods: {
    PageChange (e) {
      this.getUserVerifyList(e)
      // this.getUserVerifyList()
    },
    addMemberInfo: function () {
      this.$router.push('addMemberInfo')
    },
    examine: function (id) {
      this.$router.push({
        path: '../AddMemberInfo',
        query: {
          id: id
        }
      })
    },
    // 获取会员列表
    getUserVerifyList: async function (e) {
      var preEntryData
      if (e) {
        preEntryData = e.productList
        this.queryInfo = e.queryInfo
      } else {
        const res = await getMemberInfo.userVerifyList(this)
        if (res.code !== 0) return this.$message.error('获取会员变更信息失败')
        preEntryData = res.result.data
        this.queryInfo.total = res.result.totalCount
        this.queryInfo.pageNo = res.result.pageNo
        this.queryInfo.pageSize = res.result.pageSize
      }
      for (var i = 0; i < preEntryData.length; i++) {
        var arr = {}
        if (preEntryData[i].bank_address !== '' && preEntryData[i].bank_address !== null) {
          preEntryData[i].bank_address = preEntryData[i].bank_address + ','
          arr.bank_address = preEntryData[i].user_account.bank_address + ','
        } else {
          preEntryData[i].bank_address = ''
          arr.bank_address = ''
        }
        if (preEntryData[i].bank_name !== '' && preEntryData[i].bank_name !== null) {
          preEntryData[i].bank_name = preEntryData[i].bank_name + ','
          arr.bank_name = preEntryData[i].user_account.bank_name + ','
        } else {
          preEntryData[i].bank_name = ''
          arr.bank_name = ''
        }
        if (preEntryData[i].bank_number !== '' && preEntryData[i].bank_number !== null) {
          preEntryData[i].bank_number = preEntryData[i].bank_number + ','
          arr.bank_number = preEntryData[i].user_account.bank_number + ','
        } else {
          preEntryData[i].bank_number = ''
          arr.bank_number = ''
        }
        if (preEntryData[i].card_name !== '' && preEntryData[i].card_name !== null) {
          preEntryData[i].card_name = preEntryData[i].card_name + ','
          arr.card_name = preEntryData[i].user_account.card_name + ','
        } else {
          preEntryData[i].card_name = ''
          arr.card_name = ''
        }
        if (preEntryData[i].card_number !== '' && preEntryData[i].card_number !== null) {
          preEntryData[i].card_number = preEntryData[i].card_number + ','
          arr.card_number = preEntryData[i].user_account.card_number + ','
        } else {
          preEntryData[i].card_number = ''
          arr.card_number = ''
        }
        // if (preEntryData[i].id !== '' && preEntryData[i].id !== null) {
        //   preEntryData[i].id = preEntryData[i].id + ','
        //   arr.id = preEntryData[i].user_account.id
        // } else {
        //   preEntryData[i].id = ''
        //   arr.id = ''
        // }
        // if (preEntryData[i].level !== '') {
        //   preEntryData[i].level = preEntryData[i].level + ','
        //   arr.level = preEntryData[i].user_account.level + ','
        // }
        if (preEntryData[i].mobile !== '' && preEntryData[i].mobile !== null) {
          preEntryData[i].mobile = preEntryData[i].mobile + ','
          arr.mobile = preEntryData[i].user_account.mobile + ','
        } else {
          preEntryData[i].mobile = ''
          arr.mobile = ''
        }
        // if (preEntryData[i].nickname !== '') {
        //   preEntryData[i].nickname = preEntryData[i].nickname + ','
        //   arr.nickname = preEntryData[i].user_account.nickname
        // }
        preEntryData[i].user_accounts = arr.bank_address + arr.bank_name + arr.bank_number + arr.card_name + arr.card_number + arr.mobile
        preEntryData[i].user_accounts = preEntryData[i].user_accounts.substring(0, preEntryData[i].user_accounts.length - 1)
        preEntryData[i].user_accounted = preEntryData[i].bank_address + preEntryData[i].bank_name + preEntryData[i].bank_number + preEntryData[i].card_name + preEntryData[i].card_number + preEntryData[i].mobile
        preEntryData[i].user_accounted = preEntryData[i].user_accounted.substring(0, preEntryData[i].user_accounted.length - 1)
        switch (preEntryData[i].status) {
          case 0:
            preEntryData[i].statusType = '审核中'
            break
          case 1:
            preEntryData[i].statusType = '审核通过'
            break
          case 2:
            preEntryData[i].statusType = '不通过'
            break
        }
        preEntryData[i].mobiles = preEntryData[i].user_account.mobile
      }
      // console.log(preEntryData)
      this.preEntryData = preEntryData
    }
  }
}
</script>

<style scoped>

</style>
