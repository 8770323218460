<template>
  <div>
    <el-row style="margin: 0 10px">
      <el-col :span="2" style="font-size: 18px">会员列表</el-col>
      <el-col :span="5" style="float: right;">
        <!--面包屑导航-->
        <el-breadcrumb separator="/">
          <el-breadcrumb-item>产品管理</el-breadcrumb-item>
          <el-breadcrumb-item><a>会员列表</a></el-breadcrumb-item>
        </el-breadcrumb>
      </el-col>
    </el-row>
    <el-card>
      <el-form :inline="true" :model="form" class="demo-form-inline">
        <el-form-item>
          <el-input v-model="form.keyword" placeholder="id/微信昵称"></el-input>
        </el-form-item>
        <el-form-item>
          <el-select v-model="form.merchant_id" placeholder="商户">
            <el-option label="全部" value=""></el-option>
            <el-option v-for="(item, index) in MerchantData" :key="index" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select v-model="form.level" placeholder="会员级别">
            <el-option label="全部" value=""></el-option>
            <el-option label="普通" value="0"></el-option>
            <el-option label="会员" value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="getProductList">查询</el-button>
        </el-form-item>
      </el-form>

      <el-table border :data="productList" height="670px">
<!--        <el-table-column label="全选" type="selection" width="80px"></el-table-column>-->
        <el-table-column label="id" prop="id" width="120px"></el-table-column>
        <el-table-column label="头像" prop="head_img" width="80px">
          <template width="40" slot-scope="scope">
            <img v-if="scope.row.head_img" style="width:40px;height:40px;border:none;" :src="scope.row.head_img">
            <img v-else style="width:40px;height:40px;border:none;" src="../../img/head_img.png">
          </template>
        </el-table-column>
        <el-table-column label="用户昵称" prop="nickname"></el-table-column>
<!--        <el-table-column label="微信认证名" prop="card_name"></el-table-column>-->
<!--        <el-table-column label="手机号" prop="mobile" ></el-table-column>-->
        <el-table-column label="会员级别" prop="levels"></el-table-column>
        <el-table-column label="开通时间" prop="create_time"></el-table-column>
        <el-table-column label="商户名称" prop="merchant_name"></el-table-column>
        <el-table-column label="最后登录时间" prop="last_login_time"></el-table-column>
        <el-table-column label="最后登录ip" prop="last_login_ip"></el-table-column>
       <el-table-column label="操作" width="180px">
        <template #default="scope">
           <el-button size="mini" type="primary" @click="setStatus(scope.row.id, scope.row.showTitle)">{{scope.row.showTitle}}</el-button>
            <el-button size="mini" type="danger" @click="deleteUser(scope.row.id)">删除</el-button>
<!--           <deleteBtn size="mini" :deleteId="scope.row.id" @gainMsg="PageChange" :deleteApi="path"></deleteBtn>-->
         </template>
     </el-table-column>
      </el-table>
      <page :queryInfos="queryInfo" @pageChange="PageChange" queryUrl="memberManage/MemberList" :pamams="'keyword=' + (form.keyword || '') + '&level=' + (form.level || '') + '&merchant_id=' + (form.merchant_id || '')"></page>
    </el-card>
  </div>
</template>

<script>
import * as member from '../../interface/memberManage/MemberList'
import Pagination from '../common/Pagination'
import * as getSalesOrder from '../../interface/systemManage/SalesOrder'
// import EditBtn from '../common/EditBtn'
// import DeleteBtn from '../common/DeleteBtn'
// import AddBtn from '../common/AddBtn'
export default {
  name: 'MemberList',
  components: {
    page: Pagination
    // editBtn: EditBtn,
    // deleteBtn: DeleteBtn
    // addBtn: AddBtn
  },
  data: function () {
    return {
      form: {},
      addProductName: '会员',
      queryInfo: {
        query: '',
        query1: '',
        pageNo: 1,
        pageSize: 2,
        total: 0
      },
      path: 'memberManage/MemberList',
      total: 0,
      productList: [],
      dialogVisible: false,
      addProductForm: {
        title: '',
        desc: ''
      },
      addProductFormList: [
        {
          title: '产品标题',
          prop: 'title'
        },
        {
          title: '产品描述',
          prop: 'desc'
        }
      ],
      addProductRules: {
        title: [
          { required: true, message: '请输入产品标题', trigger: 'blur' },
          { min: 3, max: 10, message: '长度在 3 到 10 个字符', trigger: 'blur' }
        ],
        desc: [
          { required: true, message: '请输入产品描述', trigger: 'blur' },
          { min: 3, message: '长度至少3个字符', trigger: 'blur' }
        ]
      },
      editDialogVisible: false,
      editProductForm: {
        id: '',
        title: '',
        desc: ''
      },
      editProductFormList: [
        {
          title: '产品标题',
          prop: 'title'
        },
        {
          title: '产品描述',
          prop: 'desc'
        }
      ],
      editProductFormRules: {
        title: [
          { required: true, message: '请输入产品标题', trigger: 'blur' },
          { min: 3, max: 10, message: '长度在 3 到 10 个字符', trigger: 'blur' }
        ],
        desc: [
          { required: true, message: '请输入产品描述', trigger: 'blur' },
          { min: 3, message: '长度至少3个字符', trigger: 'blur' }
        ]
      },
      MerchantData: []
    }
  },
  created () {
    this.getProductList()
    this.getAllMerchant()
  },
  methods: {
    // 获取全部商户
    getAllMerchant: async function () {
      const data = await getSalesOrder.getAllMerchant(this)
      if (data.code !== 0) return this.$message.error('获取全部商户信息')
      this.MerchantData = data.result
    },
    // 删除
    deleteUser: async function (id) {
      var that = this
      that.$confirm('是否确认删除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const data = await member.delUser(this, id)
        if (data.code !== 0) return that.$message.error('删除失败')
        that.$message.success('删除成功')
        that.getProductList()
      }).catch(() => {
        that.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    // 冻结/解冻
    setStatus: function (id, showTitle) {
      var that = this
      that.$confirm('是否确认' + showTitle + '?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const data = await member.setStatus(this, id)
        if (data.code !== 0) return this.$message.error('操作失败')
        that.$message.success('操作成功')
        that.getProductList()
      }).catch(() => {
        that.$message({
          type: 'info',
          message: '已取消' + showTitle
        })
      })
    },
    PageChange: function (e) {
      // console.log(e)
      var productList = e.productList
      for (var i = 0; i < productList.length; i++) {
        productList[i].showTitle = productList[i].status === 0 ? '冻结' : '解冻'
        productList[i].levels = productList[i].level === 0 ? '普通' : '会员'
      }
      this.productList = productList
      this.queryInfo = e.queryInfo
      // this.getList()
    },
    getProductList: async function () {
      var p = '?keyword=' + (this.form.keyword || '') + '&level=' + (this.form.level || '') + '&merchant_id=' + (this.form.merchant_id || '')
      // console.log(member)
      const data = await member.productList(this, p)
      if (data.code !== 0) return this.$message.error('获取会员列表失败')
      var productList = data.result.data
      for (var i = 0; i < productList.length; i++) {
        if (productList[i].status === 0) {
          productList[i].showTitle = '冻结'
        } else {
          productList[i].showTitle = '解冻'
        }
        productList[i].levels = productList[i].level === 0 ? '普通' : '会员'
      }
      this.productList = productList
      this.queryInfo.total = data.result.totalCount
      this.queryInfo.pageNo = data.result.pageNo
      this.queryInfo.pageSize = data.result.pageSize
    }
  }
}
</script>

<style scoped>
  .el-pagination{
    margin-top: 10px;
  }
</style>
