// 地址
const api = {
  userCaseList: 'admin/UserCase/list',
  userCaseInfo: 'admin/UserCase/info?id='
}
// 病例列表
export async function getUserCase (ele, param) {
  const { data } = await ele.$http.get(api.userCaseList + param)
  return data
}
// 病例详情
export async function getUserCaseInfo (ele, param) {
  const { data } = await ele.$http.get(api.userCaseInfo + param)
  return data
}
export async function pageChange (ele, param) {
  for (var i in param) {
    var { data } = await ele.$http.get(api.userCaseList + '?' + i + '=' + param[i])
  }
  return data
}
export async function pageChanges (ele, param, pamams) {
  var p = []
  for (var i in param) {
    p.push(i + '=' + param[i])
  }
  var { data } = await ele.$http.get(api.userCaseList + '?' + p[0] + '&' + p[1] + '&' + pamams)
  return data
}
