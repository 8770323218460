import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../components/Home'
import Login from '../components/Login'
import Welcome from '../components/Welcome'
import PreEntry from '../components/processManage/PreEntry'
import ProductList from '../components/product/ProductList'
import EquipmentBind from '../components/processManage/EquipmentBind'
import ChangeEquipmentModular from '../components/processManage/ChangeEquipmentModular'
import WareHousing from '../components/storeHouse/WareHousing'
import AddWareHousing from '../components/storeHouse/AddWareHousing'
import ExWarehouse from '../components/storeHouse/ExWarehouse'
import AddExWarehouse from '../components/storeHouse/AddExWarehouse'
import MenuManage from '../components/systemManage/MenuManage'
import PowerManage from '../components/systemManage/PowerManage'
import RoleManage from '../components/systemManage/RoleManage'
import AdminManage from '../components/systemManage/AdminManage'
import MemberList from '../components/memberManage/MemberList'
import ModuleList from '../components/processManage/CountList/ModuleList'
import ProductType from '../components/product/ProductType'
import ProjectSetting from '../components/storeHouse/ProjectSetting'
import CountList from '../components/processManage/CountList'
import FinishList from '../components/processManage/CountList/FinishList'
import BindList from '../components/processManage/CountList/BindList'
import InventoryCount from '../components/storeHouse/InventoryCount'
import DetailedList from '../components/storeHouse/DetailedList'
import MemberInfo from '../components/memberManage/MemberInfo'
import CaseList from '../components/memberManage/CaseList'
import CaseSetting from '../components/memberManage/CaseSetting'
import DeviceList from '../components/deviceManage/DeviceList'
import FaultList from '../components/deviceManage/FaultList'
import Distributor from '../components/MarketManage/Distributor'
import SalesOrder from '../components/MarketManage/SalesOrder'
import ReceiptRegister from '../components/AfterSalesManage/ReceiptRegister'
import MaintenanceList from '../components/AfterSalesManage/MaintenanceList'
import MaintenanceSetting from '../components/AfterSalesManage/MaintenanceSetting'
import AgingTestList from '../components/processManage/AgingTestList'
import AgingFaultList from '../components/processManage/AgingFaultList'
import LogisticsCompanySetting from '../components/AfterSalesManage/LogisticsCompanySetting'
import MaintenancePointSetting from '../components/AfterSalesManage/MaintenancePointSetting'
import EquipmentType from '../components/AfterSalesManage/EquipmentType'
import ShowWareHousing from '../components/storeHouse/ShowWareHousing'
import AddMemberInfo from '../components/memberManage/AddMemberInfo'
import CaseDetailList from '../components/memberManage/caseDetailList'
import MercantileAgent from '../components/agentManage/mercantileAgent'
import Salesman from '../components/agentManage/salesman'
import StoreManager from '../components/agentManage/storeManager'
import Shop from '../components/agentManage/shop'
import SetMeal from '../components/agentManage/setMeal'
import SystemLog from '../components/systemManage/SystemLog'
import EquipmentAllocation from '../components/deviceManage/EquipmentAllocation'
import Merchant from '../components/agentManage/Merchant'
import ComplaintList from '../components/Complaint/ComplaintList'
import Divide from '../components/financialManage/Divide'
import BatteryList from '../components/deviceManage/BatteryList'
import DeviceLists from '../components/Img/DeviceLists/DeviceLists'
import Bluetooth from '../components/Img/Bluetooth/Bluetooth'
import ImgManage from '../components/Img/ImgManage/ImgManage'
import Video from '../components/Img/Video/Video'
import FileGroup from '../components/Img/FileGroup/FileGroup'
import DeviceDetail from '../components/Img/DeviceDetail/DeviceDetail'
import Examine from '../components/Img/Examine/Examine'
import Customer from '../components/Img/Customer/Customer'
Vue.use(VueRouter)

const routes = [
  { path: '/', redirect: '/login' },
  { path: '/login', component: Login },
  {
    path: '/home',
    component: Home,
    redirect: '/DeviceLists',
    children: [
      { path: '/DeviceLists', component: DeviceLists },
      { path: '/Customer', component: Customer },
      { path: '/Examine', component: Examine },
      { path: '/Bluetooth', component: Bluetooth },
      { path: '/ImgManage', component: ImgManage },
      { path: '/Video', component: Video },
      { path: '/FileGroup', component: FileGroup },
      { path: '/DeviceDetail', component: DeviceDetail },
      { path: '/welcome', component: Welcome },
      { path: '/preEntry', component: PreEntry },
      { path: '/productList', component: ProductList },
      { path: '/equipmentBind', component: EquipmentBind },
      { path: '/changeEquipmentModular', component: ChangeEquipmentModular },
      { path: '/wareHousing', component: WareHousing },
      { path: '/addWareHousing', component: AddWareHousing },
      { path: '/exWarehouse', component: ExWarehouse },
      { path: '/addExWarehouse', component: AddExWarehouse },
      { path: '/menuManage', component: MenuManage },
      { path: '/powerManage', component: PowerManage },
      { path: '/roleManage', component: RoleManage },
      { path: '/adminManage', component: AdminManage },
      { path: '/memberList', component: MemberList },
      { path: '/moduleList', component: ModuleList },
      { path: '/productType', component: ProductType },
      { path: '/projectSetting', component: ProjectSetting },
      { path: '/countList', component: CountList },
      { path: '/finishList', component: FinishList },
      { path: '/bindList', component: BindList },
      { path: '/InventoryCount', component: InventoryCount },
      { path: '/detailedList', component: DetailedList },
      { path: '/memberInfo', component: MemberInfo },
      { path: '/caseList', component: CaseList },
      { path: '/caseSetting', component: CaseSetting },
      { path: '/deviceList', component: DeviceList },
      { path: '/faultList', component: FaultList },
      { path: '/distributor', component: Distributor },
      { path: '/salesOrder', component: SalesOrder },
      { path: '/receiptRegister', component: ReceiptRegister },
      { path: '/maintenanceList', component: MaintenanceList },
      { path: '/maintenanceSetting', component: MaintenanceSetting },
      { path: '/agingTestList', component: AgingTestList },
      { path: '/agingFaultList', component: AgingFaultList },
      { path: '/logisticsCompanySetting', component: LogisticsCompanySetting },
      { path: '/maintenancePointSetting', component: MaintenancePointSetting },
      { path: '/equipmentType', component: EquipmentType },
      { path: '/showWareHousing', component: ShowWareHousing },
      { path: '/addMemberInfo', component: AddMemberInfo },
      { path: '/caseDetailList', component: CaseDetailList },
      { path: '/mercantileAgent', component: MercantileAgent },
      { path: '/Salesman', component: Salesman },
      { path: '/storeManager', component: StoreManager },
      { path: '/shop', component: Shop },
      { path: '/SetMeal', component: SetMeal },
      { path: '/SystemLog', component: SystemLog },
      { path: '/EquipmentAllocation', component: EquipmentAllocation },
      { path: '/Merchant', component: Merchant },
      { path: '/ComplaintList', component: ComplaintList },
      { path: '/Divide', component: Divide },
      { path: '/BatteryList', component: BatteryList }
    ]
  }
]

const router = new VueRouter({
  routes
})
// 路由守卫
router.beforeEach(async (to, from, next) => {
  // 如果访问的地址就是/login 放行
  if (to.path === '/login') return next()

  // 是否登录的判断.
  // 先获取token,看有没有.  有就会获取一个字符串
  const tokenStr = window.sessionStorage.getItem('token')
  // 如果没有,取反则为真.  强制跳转到/login
  if (!tokenStr) return next('/login')
  // 上面的if判断都不成立,直接放行.  访问的不是login页面,且已登录
  next()
})

export default router
