<template>
    <div>
      <el-row style="margin: 0 10px">
        <el-col :span="2" style="font-size: 18px">订单列表</el-col>
        <el-col :span="5" style="float: right;">
          <!--面包屑导航-->
          <el-breadcrumb separator="/">
            <el-breadcrumb-item>财务管理</el-breadcrumb-item>
            <el-breadcrumb-item><a>订单列表</a></el-breadcrumb-item>
          </el-breadcrumb>
        </el-col>
      </el-row>
      <el-card>
        <el-form :inline="true" :model="preEntryForm" class="demo-form-inline">
          <el-form-item label="订单编号">
            <el-input v-model="preEntryForm.order_no" placeholder="订单编号"></el-input>
          </el-form-item>
          <el-form-item label="设备编号">
            <el-input v-model="preEntryForm.equipment_no" placeholder="设备编号"></el-input>
          </el-form-item>
          <el-form-item label="设备类型">
            <el-select v-model="preEntryForm.equipment_type_id" placeholder="设备类型">
              <el-option label="全部" value=""></el-option>
              <el-option v-for="(item, index) in equipmentData" :key="index" :label="item.title" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="商户">
            <el-select v-model="preEntryForm.merchant_id" placeholder="商户">
              <el-option label="全部" value=""></el-option>
              <el-option v-for="(item, index) in MerchantData" :key="index" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="订单状态">
            <el-select v-model="preEntryForm.status" placeholder="订单状态">
              <el-option label="全部" value=""></el-option>
              <el-option label="未支付" value="0"></el-option>
              <el-option label="已支付" value="1"></el-option>
              <el-option label="已退款" value="2"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <el-form :inline="true" class="demo-form-inline">
          <el-form-item>
            <get-pop @getId="getId" :agent_id="dataName"></get-pop>
          </el-form-item>
          <el-form-item label="用户id">
            <el-input v-model="preEntryForm.user_id" placeholder="用户id"></el-input>
          </el-form-item>
          <el-form-item label="用户昵称">
            <el-input v-model="nickname" placeholder="用户昵称"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="selectList">查询</el-button>
          </el-form-item>
        </el-form>
        <el-table
          ref="filterTable"
          :data="preEntryData"
          border
          style="width: 100%">
<!--          <el-table-column type="index" label="序号" width="50px"></el-table-column>-->
<!--          <el-table-column prop="order_no" label="订单编号" width="200px"></el-table-column>-->
          <el-table-column prop="order_no" label="订单编号" width="200px">
            <template slot-scope="scope">
              <div slot="reference" class="name-wrapper">
                <!--                  <el-tag size="medium">-->
                <!--                    <detail-pop :id="scope.row.id" :order_no="scope.row.order_no" @infoData="infoData" :ProductFormList="detailData"></detail-pop>-->
                <!--                  </el-tag>-->
                <el-tag type="success" class="aShow">
                  <detail-pop :id="scope.row.id" :order_no="scope.row.order_no" @infoData="infoData" :ProductFormList="detailData" title="订单"></detail-pop>
                </el-tag>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="equipment_no" label="设备编号"></el-table-column>
          <el-table-column prop="equipment_name" label="设备类型" width="120px"></el-table-column>
<!--          <el-table-column prop="start_time" label="开始时间"></el-table-column>-->
<!--          <el-table-column prop="end_time" label="结束时间"></el-table-column>-->
          <el-table-column prop="time" label="使用时长"></el-table-column>
          <el-table-column prop="user_name" label="用户昵称"></el-table-column>
          <el-table-column prop="user_id" label="UID"></el-table-column>
          <el-table-column prop="merchant_name" label="商户"></el-table-column>
          <el-table-column prop="agent_name" label="经销商" width="140px"></el-table-column>
          <el-table-column prop="shop_name" label="门店"></el-table-column>
          <el-table-column prop="total_price" label="金额"></el-table-column>
<!--          <el-table-column prop="refund_time" label="归还场地"></el-table-column>-->
          <el-table-column prop="status" label="订单状态">
            <template #default="scope">
              <el-tag v-if="scope.row.status == 0" type="warning">未支付</el-tag>
              <el-tag v-if="scope.row.status == 1" class="el-tag--success">已支付</el-tag>
              <el-tag v-if="scope.row.status == 2" type="danger">已退款</el-tag>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="200px">
            <template #default="scope">
<!--              <el-button type="primary" size="mini" @click="edit(scope.row.id)">编辑</el-button>-->
              <el-button v-if="scope.row.is_end == 0" type="success" size="mini">结束订单</el-button>
              <el-button type="danger" size="mini" @click="getSaleOrderInfo(scope.row.id)">退款</el-button>
<!--              <el-button type="danger" size="mini">充电宝是否存在</el-button>-->
            </template>
          </el-table-column>
        </el-table>
        <page :queryInfos="queryInfo" queryUrl="systemManage/SalesOrder" @pageChange="PageChange" :pamams="'type=' + (preEntryForm.type || '') + '&consignee=' + (preEntryForm.consignee || '') + '&equipment_type_id=' + (preEntryForm.equipment_type_id || '')"></page>
      </el-card>
      <!--      新增-->
      <div>
        <el-dialog
          title="新增销售订单"
          width="70%"
          :visible.sync="addOrderPop"
          @close="resetForm"
        >
          <el-form label-width="135px" :model="addForm" :rules="addPopRules" ref="addFormRef">
            <el-form-item label="订单类型" prop="">
              <el-radio-group v-model="addForm.type">
                <el-radio :label="0">系统订单</el-radio>
                <el-radio :label="1">销售开单</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-row>
              <el-col :span="10">
                <el-form-item label="所属代理" prop="parent_id">
                  <el-input v-model="agent_ids" @click="selectPop" disabled></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="4">
                <el-button type="primary" @click="selectPop" style="margin-left: 20px">选择</el-button>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="10">
                <el-form-item label="客户名称" prop="">
                  <el-input v-model="addForm.consignee" placeholder="客户名称"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="10">
                <el-form-item label="联系电话" prop="">
                  <el-input v-model="addForm.mobile" placeholder="联系电话"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="16">
                <el-form-item label="收货地址" class="inputAddress" prop="">
                  <el-input v-model="addForm.address" placeholder="请输入地址"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="10">
                <el-form-item label="产品总价">
                  <el-input v-model="addForm.total_price"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <div>
              <div v-for="(item, index) in list" :key="index" class="lines">
                <el-row>
                  <el-col :span="7">
                    <el-form-item label="产品名称">
                      <el-select v-model="item.equipment_type_id" placeholder="请选择产品名称">
                        <el-option v-for="(item1, index1) in RepairPartsData" :key="index1" :label="item1.title" :value="item1.id" @click.native ="selectCP(index, index1)"></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="7">
                    <el-form-item label="产品数量" label-width="100px">
                      <el-input v-model="item.num" @input="inputNum(index)" type="number"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="7">
                    <el-form-item :label="'产品单价:￥' + item.price" label-width="150px">
<!--                      <el-input v-model="item.price" :readonly="true"></el-input>-->
                    </el-form-item>
                  </el-col>
                  <el-col :span="3">
                    <el-button v-if="index != 0" type="danger" @click="deleteMeal(index)" style="margin-left: 20px">删除</el-button>
                  </el-col>
                </el-row>
              </div>
            </div>
            <el-form-item>
              <el-button type="primary" @click="addCP">增加产品</el-button>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="onSubmit('addForm')">添加</el-button>
              <el-button @click="resetForm('addForm')">重置</el-button>
            </el-form-item>
          </el-form>
        </el-dialog>
      </div>
<!--      代理商-->
      <div class="getPop" v-if="getAgentPop">
        <div class="popModel">
          <el-tree :data="data" :props="defaultProps" @node-click="handleNodeClick" :default-expand-all="true"></el-tree>
          <div style="display: flex;justify-content: center">
            <el-button @click="sure" type="primary" style="margin: 20px 10px">确认</el-button>
            <el-button @click="clean" style="margin: 20px 10px">取消</el-button>
          </div>
        </div>
      </div>
      <!--  退款的弹出框-->
      <el-dialog
        title="退款信息"
        :visible.sync="dialogVisible"
        width="30%"
        @close="resetDialogVisible">
        <el-form :model="refundForm" label-width="100px" ref="refundFormRef">
          <el-form-item label="订单编号"><span style="padding-left: 50px">{{refundForm.order_no}}</span></el-form-item>
          <el-form-item label="微信授权单号"><span style="padding-left: 50px">{{refundForm.transaction_id}}</span></el-form-item>
          <el-form-item label="设备编号"><span style="padding-left: 50px">{{refundForm.equipment_no}}</span></el-form-item>
          <el-form-item label="模块编号"><span style="padding-left: 50px">{{refundForm.imei}}</span></el-form-item>
          <el-form-item label="金额"><span style="padding-left: 50px">{{refundForm.total_price}}</span></el-form-item>
          <el-form-item v-if="refundForm.status==2" label="退款金额">
            <span style="padding-left: 50px">{{refundForm.refund_price}}</span>
          </el-form-item>
          <el-form-item v-if="parseFloat(refundForm.total_price) > parseFloat(refundForm.refund_price) || refundForm.status==1" label="退款金额">
            <el-input style="padding-left: 50px;width: 80%" v-model="refundForm.refund_price1" placeholder="请输入退款的金额"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button :type="isBtn ? 'primary' : ''" @click="submitRefund">确 定</el-button>
        </div>
      </el-dialog>
    </div>
</template>

<script>
import * as getSalesOrder from '../../interface/systemManage/SalesOrder'
import * as agentList from '../../interface/agentManage/mercantileAgent'
import Pagination from '../common/Pagination'
import GetPop from '../common/getPop'
import DetailPop from '../common/detailPop'
export default {
  name: 'SalesOrder',
  components: {
    DetailPop,
    GetPop,
    page: Pagination
  },
  data: function () {
    return {
      preEntryData: [],
      preEntryForm: {},
      addForm: { type: 0 },
      addOrderPop: false,
      addPopRules: {},
      selectedOptions: [],
      list: [{ num: 1, price: '0.00' }],
      RepairPartsData: [],
      getAgentPop: false,
      agent_id: '',
      agent_ids: '',
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      data: [],
      queryInfo: {
        query: '',
        pageNo: 1,
        pageSize: 2,
        total: 0
      },
      dialogVisible: false,
      refundForm: {},
      MerchantData: [],
      equipmentData: [],
      dataName: '',
      nickname: '',
      detailData: {},
      isBtn: true
    }
  },
  created () {
    this.getSaleOrderList()
    this.getSalesOrderAll()
    this.getAllMerchant()
    this.getAllEquipment()
  },
  methods: {
    // 查询列表
    infoData: async function (id) {
      const data = await getSalesOrder.saleOrderInfo(this, id)
      console.log('详情信息', data)
      this.detailData = [
        { title: '订单编号', value: data.result.order_no },
        { title: '微信授权单号', value: data.result.transaction_id },
        { title: '开始时间', value: data.result.start_time },
        { title: '结束时间', value: data.result.end_time },
        { title: '金额', value: data.result.total_price },
        { title: '设备编号', value: data.result.equipment_no },
        { title: '模块编号', value: data.result.imei },
        { title: '状态', value: data.result.status === 1 ? '已支付' : '已退款' },
        { title: '支付时间', value: data.result.pay_time },
        { title: '退款金额', value: data.result.refund_price },
        { title: '使用时长', value: data.result.time },
        { title: '商户名称', value: data.result.merchant_name },
        { title: '经销商', value: data.result.agent_name },
        { title: '用户昵称', value: data.result.user_name },
        { title: '门店', value: data.result.shop_name },
        { title: '设备名称', value: data.result.equipment_name }
      ]
    },
    // 获取代理
    getId: function (e) {
      console.log(e)
      this.preEntryForm.agent_id = e.dataId
      this.dataName = e.dataName
    },
    // 获取全部商户
    getAllMerchant: async function () {
      const data = await getSalesOrder.getAllMerchant(this)
      if (data.code !== 0) return this.$message.error('获取全部商户信息')
      this.MerchantData = data.result
    },
    // 获取全部设备
    getAllEquipment: async function () {
      const data = await getSalesOrder.getAllEquipment(this)
      if (data.code !== 0) return this.$message.error('获取全部设备类型信息')
      this.equipmentData = data.result
    },
    // 点击编辑弹出框
    handleClose (done) {
      this.$confirm('确认关闭？')
        .then(_ => {
          done()
        })
        .catch(_ => {})
    },
    // 下一页
    PageChange (e) {
      var preEntryData = e.productList
      for (var i = 0; i < preEntryData.length; i++) {
        preEntryData[i].types = preEntryData[i].type === 0 ? '系统订单' : '销售开单'
        preEntryData[i].pay_types = preEntryData[i].pay_type === 1 ? '微信' : (preEntryData[i].pay_type === 2 ? '支付宝' : '线下')
        preEntryData[i].statusd = preEntryData[i].status === 0 ? '待付款' : (preEntryData[i].status === 1 ? '已支付' : '已退款')
      }
      this.preEntryData = preEntryData
      this.queryInfo = e.queryInfo
    },
    // 查询
    selectList: function () {
      var p = '?nickname=' + (this.nickname || '')
      for (var item in this.preEntryForm) {
        p += '&' + item + '=' + (this.preEntryForm[item] || '')
      }
      console.log(p)
      this.getSaleOrderList(p)
    },
    // 获取销售订单列表
    getSaleOrderList: async function (p) {
      var p1 = ''
      if (p) p1 = p
      const data = await getSalesOrder.SaleOrderList(this, p1)
      if (data.code !== 0) return this.$message.error('获取销售订单错误')
      var preEntryData = data.result.data
      for (var i = 0; i < data.result.data.length; i++) {
        preEntryData[i].types = data.result.data[i].type === 0 ? '系统订单' : '销售开单'
        preEntryData[i].pay_types = data.result.data[i].pay_type === 1 ? '微信' : (data.result.data[i].pay_type === 2 ? '支付宝' : '线下')
        preEntryData[i].statusd = data.result.data[i].status === 0 ? '待付款' : (data.result.data[i].status === 1 ? '已支付' : '已退款')
      }
      this.preEntryData = preEntryData
      this.queryInfo.total = data.result.totalCount
      this.queryInfo.pageNo = data.result.pageNo
      this.queryInfo.pageSize = data.result.pageSize
    },
    // 获取退款的详情信息
    getSaleOrderInfo: async function (id) {
      this.dialogVisible = true
      this.isBtn = true
      const data = await getSalesOrder.saleOrderInfo(this, id)
      console.log('退款详情信息', data)
      this.refundForm = data.result
    },
    // 新增
    addOrderPops: function () {
      this.addOrderPop = true
      // this.addForm.type = 0
    },
    resetForm: function () {
      this.$refs.addFormRef.resetFields()
      this.addForm = {}
      // this.addForm.type = 0
      this.list = [{ num: 1, price: '0.00' }]
      this.agent_ids = ''
    },
    // 增加产品
    addCP: function () {
      this.list.push({ num: 1, price: '0.00' })
    },
    // 删除产品
    deleteMeal: function (i) {
      this.list.splice(i, 1)
      this.total()
    },
    // 添加
    onSubmit: async function () {
      var formData = this.addForm
      formData.orderEquipmentList = this.list
      const data = await getSalesOrder.addSaleOrder(this, formData)
      if (data.code !== 0) return this.$message.error(data.msg)
      this.$message.success('添加成功')
      this.resetForm()
      this.addOrderPop = false
      this.getSaleOrderList()
    },
    // 获取全部产品
    getSalesOrderAll: async function () {
      const data = await getSalesOrder.equipmentTypeAll(this)
      if (data.code !== 0) return this.$message.error('获取全部产品失败')
      this.RepairPartsData = data.result
    },
    // 选择产品
    selectCP: function (index, index1) {
      this.list[index].price = this.RepairPartsData[index1].price || '0.00'
      this.total()
    },
    // 计算总价
    total: function () {
      var totalNum = 0
      for (var i = 0; i < this.list.length; i++) {
        totalNum += parseInt(this.list[i].num) * parseFloat(this.list[i].price)
      }
      this.addForm.total_price = totalNum
    },
    // 输入数量
    inputNum: function (index) {
      if (this.list[index].num > 10000) this.list[index].num = 10000
      if (this.list[index].num < 0) this.list[index].num = 0
      this.total()
    },
    // 选择经销商
    selectPop: async function () {
      var that = this
      that.getAgentPop = true
      const res = await agentList.getList(this)
      if (res.code !== 0) return that.$message.error('获取信息失败')
      console.log(res)
      that.data = res.result
      res.result.forEach(function (item, index, array) {
        that.data[index].label = item.name
        if (item.children) {
          item.children.forEach(function (item1, index1, array1) {
            that.data[index].children[index1].label = item1.name
            if (item1.children) {
              item1.children.forEach(function (item2, index2, array2) {
                that.data[index].children[index1].children[index2].label = item2.name
                delete that.data[index].children[index1].children[index2].children
              })
            }
          })
        }
      })
      // that.data = data
      console.log(that.data)
    },
    // 选择经销商id
    handleNodeClick: function (e) {
      console.log(e)
      this.agent_id = e.id
      this.agent_ids = e.label
      // this.getAgentPop = true
    },
    // 确认选择
    sure: function () {
      // this.addForm.parent_id = this.dataId
      this.addForm.agent_id = this.agent_id
      this.getAgentPop = false
    },
    // 取消
    clean: function () {
      this.getAgentPop = false
    },
    // 重置退款内容的金额
    resetDialogVisible: function () {
      this.$refs.refundFormRef.resetFields()
    },
    // 提交退款
    submitRefund: async function () {
      if (this.isBtn) {
        this.isBtn = false
        if (this.refundForm.total_price < this.refundForm.refund_price1) {
          this.$message.error('退款的金额不能不大于总金额')
          this.isBtn = true
        } else if (this.refundForm.total_price === this.refundForm.refund_price) {
          this.isBtn = true
          this.dialogVisible = false
        } else {
          var params = '?order_no=' + this.refundForm.order_no + '&refund_price=' + this.refundForm.refund_price1
          console.log(params)
          const data = await getSalesOrder.submitRefund(this, params)
          if (data.code === 0) {
            this.$message.success(data.msg)
            this.dialogVisible = false
          } else {
            this.$message.error(data.msg)
            this.dialogVisible = false
            this.isBtn = true
          }
          this.getSaleOrderList()
          this.getSalesOrderAll()
        }
      }
    }
  }
}
</script>

<style scoped>
  .getPop{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.2);
    z-index: 99999;
  }
  .el-tree {
    padding: 10px 0;
  }
  .popModel{
    width: 40%;
    position: relative;
    margin: 20% auto 50px;
    background: #FFF;
    border-radius: 2px;
    box-sizing: border-box;
  }
  name-wrapper{
    color: #409EFF;
  }
  .a-hover{
    text-decoration: underline;
  }
</style>
