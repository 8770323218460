<template>
    <div>
      <el-row style="margin: 0 10px">
        <el-col :span="3" style="font-size: 18px">{{title}}</el-col>
        <el-col :span="5" style="float: right;">
          <!--面包屑导航-->
          <el-breadcrumb separator="/">
            <el-breadcrumb-item>会员管理</el-breadcrumb-item>
            <el-breadcrumb-item><a>会员信息变更</a></el-breadcrumb-item>
            <el-breadcrumb-item><a>{{title}}</a></el-breadcrumb-item>
          </el-breadcrumb>
        </el-col>
      </el-row>
      <el-card>
<!--        审核-->
        <el-form v-if="isExamine" :model="formData" label-width="100px">
          <el-row style="width: 60%;margin: auto">
            <el-col :span="2"><el-button type="success" @click="returnMsg">返回</el-button></el-col>
            <el-col :span="2"><el-button type="primary" @click="verifys(1)">通过</el-button></el-col>
            <el-col :span="2"><el-button type="danger" @click="verifys(2)">不通过</el-button></el-col>
            <!--            <el-col :span="2"><el-button type="info" @click="returnMsg">打印申请表</el-button></el-col>-->
          </el-row>
          <div style="width: 60%;margin: auto">
            <el-row><p style="font-size: 22px;text-align: center;font-weight: 600;">会员资料变更申请表</p></el-row>
            <el-row style="margin-top: 20px">
              <el-col :span="11">
                <el-form-item label="申请日期">
                  <el-date-picker
                    v-model="formData.create_time"
                    type="date"
                    style="width: 100%"
                    placeholder="选择日期" :readonly="true">
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="11">
                <el-form-item label="表格编号">
                  <el-input v-model="formData.order_no" :readonly="true"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="11">
                <el-form-item label="会员账号">
                  <el-input v-model="formData.user_account.mobile" :readonly="true"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="11">
                <el-form-item label="会员级别">
                  <el-input v-model="formData.levels" :readonly="true"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="11">
                <el-form-item label="会员姓名">
                  <el-input v-model="formData.card_name" :readonly="true"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="5">
                <el-form-item label="修改项目">
                </el-form-item>
              </el-col>
              <el-col :span="10">
                <el-form-item label="原账号信息">
                </el-form-item>
              </el-col>
              <el-col :span="9">
                <el-form-item label="现账号信息">
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-form-item label="会员姓名">
                <el-col :span="10" style="margin-right: 40px"><el-input v-model="formData.user_account.card_name" :readonly="true"></el-input></el-col>
                <el-col :span="11"><el-input v-model="formData.card_name" :readonly="true"></el-input></el-col>
              </el-form-item>
            </el-row>
            <el-row>
              <el-form-item label="身份证号">
                <el-col :span="10" style="margin-right: 40px"><el-input v-model="formData.user_account.card_number" :readonly="true"></el-input></el-col>
                <el-col :span="11"><el-input v-model="formData.card_number" :readonly="true"></el-input></el-col>
              </el-form-item>
            </el-row>
            <el-row>
              <el-form-item label="手机号码">
                <el-col :span="10" style="margin-right: 40px"><el-input v-model="formData.user_account.mobile" :readonly="true"></el-input></el-col>
                <el-col :span="11"><el-input v-model="formData.mobile" :readonly="true"></el-input></el-col>
              </el-form-item>
            </el-row>
            <el-row>
              <el-form-item label="开户银行">
                <el-col :span="10" style="margin-right: 40px"><el-input v-model="formData.user_account.bank_name" :readonly="true"></el-input></el-col>
                <el-col :span="11"><el-input v-model="formData.bank_name" :readonly="true"></el-input></el-col>
              </el-form-item>
            </el-row>
            <el-row>
              <el-form-item label="开户地址">
                <el-col :span="10" style="margin-right: 40px"><el-input v-model="formData.user_account.bank_address" :readonly="true"></el-input></el-col>
                <el-col :span="11"><el-input v-model="formData.bank_address" :readonly="true"></el-input></el-col>
              </el-form-item>
            </el-row>
            <el-row>
              <el-form-item label="银行卡号">
                <el-col :span="10" style="margin-right: 40px"><el-input v-model="formData.user_account.bank_number" :readonly="true"></el-input></el-col>
                <el-col :span="11"><el-input v-model="formData.bank_number" :readonly="true"></el-input></el-col>
              </el-form-item>
            </el-row>
            <el-row>
              <el-col :span="22">
                <el-form-item label="原因">
                  <el-input v-model="formData.reason"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
        </el-form>
<!--        添加-->
        <el-form v-else ref="form" :model="form" label-width="100px">
          <el-row style="width: 60%;margin: auto">
            <el-col :span="2"><el-button type="success" @click="returnMsg">返回</el-button></el-col>
            <el-col :span="2"><el-button type="primary" @click="submit">提交</el-button></el-col>
<!--            <el-col :span="2"><el-button type="info" @click="returnMsg">打印申请表</el-button></el-col>-->
          </el-row>
          <div style="width: 60%;margin: auto">
            <el-row><p style="font-size: 22px;text-align: center;font-weight: 600;">会员资料变更申请表</p></el-row>
            <el-row>
              <el-col :span="14">
                <el-form-item label="会员账号">
                  <el-input v-model="mobiles"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="4">
                <el-button type="primary" @click="getInfo" style="margin-left: 20px">确认</el-button>
              </el-col>
            </el-row>
            <el-row style="margin-top: 20px">
              <el-col :span="11">
                <el-form-item label="申请日期">
<!--                  <el-date-picker-->
<!--                    v-model="form.create_time"-->
<!--                    type="date"-->
<!--                    style="width: 100%"-->
<!--                    placeholder="选择日期">-->
<!--                  </el-date-picker>-->
                  <el-input v-model="showTime" readonly></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="11">
                <el-form-item label="表格编号">
                  <el-input p v-model="form1.order_no" readonly></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="11">
                <el-form-item label="会员账号">
                  <el-input v-model="form.mobile" :readonly="true"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="11">
                <el-form-item label="会员级别">
                  <el-input v-model="form.levels" :readonly="true"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="11">
                <el-form-item label="会员姓名">
                  <el-input v-model="form.card_name" :readonly="true"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="5">
                <el-form-item label="修改项目">
                </el-form-item>
              </el-col>
              <el-col :span="10">
                <el-form-item label="原账号信息">
                </el-form-item>
              </el-col>
              <el-col :span="9">
                <el-form-item label="现账号信息">
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-form-item label="会员姓名">
                <el-col :span="10" style="margin-right: 40px"><el-input v-model="form.card_name" :readonly="true"></el-input></el-col>
                <el-col :span="11"><el-input v-model="form1.card_name"></el-input></el-col>
              </el-form-item>
            </el-row>
            <el-row>
              <el-form-item label="身份证号">
                <el-col :span="10" style="margin-right: 40px"><el-input v-model="form.card_number" :readonly="true"></el-input></el-col>
                <el-col :span="11"><el-input v-model="form1.card_number"></el-input></el-col>
              </el-form-item>
            </el-row>
            <el-row>
              <el-form-item label="手机号码">
                <el-col :span="10" style="margin-right: 40px"><el-input v-model="form.mobile" :readonly="true"></el-input></el-col>
                <el-col :span="11"><el-input v-model="form1.mobile"></el-input></el-col>
              </el-form-item>
            </el-row>
            <el-row>
              <el-form-item label="开户银行">
                <el-col :span="10" style="margin-right: 40px"><el-input v-model="form.bank_name" :readonly="true"></el-input></el-col>
                <el-col :span="11"><el-input v-model="form1.bank_name"></el-input></el-col>
              </el-form-item>
            </el-row>
            <el-row>
              <el-form-item label="开户地址">
                <el-col :span="10" style="margin-right: 40px"><el-input v-model="form.bank_address" :readonly="true"></el-input></el-col>
                <el-col :span="11"><el-input v-model="form1.bank_address"></el-input></el-col>
              </el-form-item>
            </el-row>
            <el-row>
              <el-form-item label="银行卡号">
                <el-col :span="10" style="margin-right: 40px"><el-input v-model="form.bank_number" :readonly="true"></el-input></el-col>
                <el-col :span="11"><el-input v-model="form1.bank_number"></el-input></el-col>
              </el-form-item>
            </el-row>
<!--            <el-row>-->
<!--              <el-form-item label="修改密码">-->
<!--                <el-col :span="10" style="margin-right: 40px"><el-input v-model="form.name"></el-input></el-col>-->
<!--                <el-col :span="11"><el-input v-model="form.name"></el-input></el-col>-->
<!--              </el-form-item>-->
<!--            </el-row>-->
<!--            <el-row>-->
<!--              <el-col :span="22">-->
<!--                <el-form-item label="注意事项">-->
<!--                  <el-input v-model="form.name"></el-input>-->
<!--                </el-form-item>-->
<!--              </el-col>-->
<!--            </el-row>-->
<!--            <el-row>-->
<!--              <el-col :span="22">-->
<!--                <el-form-item label="会员签字">-->
<!--                  <el-input v-model="form.name"></el-input>-->
<!--                </el-form-item>-->
<!--              </el-col>-->
<!--            </el-row>-->
<!--            <el-row>-->
<!--              <el-col :span="22">-->
<!--                <el-form-item label="公司意见">-->
<!--                  <el-input v-model="form.name"></el-input>-->
<!--                </el-form-item>-->
<!--              </el-col>-->
<!--            </el-row>-->
          </div>
        </el-form>
      </el-card>
    </div>
</template>

<script>
import * as getMemberInfo from '../../interface/memberManage/MemberInfo'
import * as store from '../../interface/storeHouse/ExWarehouse'
export default {
  name: 'AddMemberInfo',
  data: function () {
    return {
      mobiles: '',
      form: {},
      form1: { order_no: '' },
      isExamine: false,
      formData: {},
      title: '添加会员信息变更',
      showTime: '',
      order_no: ''
    }
  },
  created () {
    this.getId()
    this.showTimes()
    this.getNo()
  },
  methods: {
    // 获取表格编码
    getNo: async function () {
      const data = await store.getWarehouseNo(this)
      this.form1.order_no = data.result.order_no
      console.log(this.form1.order_no)
    },
    // 审核
    verifys: async function (status) {
      var verifyFrom = {
        id: this.$route.query.id,
        status: status,
        reason: this.formData.reason
      }
      const data = await getMemberInfo.verify(this, verifyFrom)
      if (data.code !== 0) return this.$message.error(data.msg)
      this.$message.success('操作成功')
      this.returnMsg()
    },
    // 审核
    getId: function () {
      console.log(this.$route.query.id)
      if (this.$route.query.id) {
        this.isExamine = true
        this.title = '审核会员信息变更'
        this.userVerifyInfo()
      }
    },
    // 获取审核信息
    userVerifyInfo: async function () {
      const data = await getMemberInfo.userVerifyInfo(this, this.$route.query.id)
      if (data.code !== 0) return this.$message.error('获取审核信息失败')
      console.log(data.result)
      this.formData = data.result
      this.formData.levels = data.result.user_account.level === 0 ? '普通会员' : (data.result.user_account.level === 1 ? '已绑定设备会员' : '其他会员')
    },
    returnMsg: function () {
      this.$router.go(-1)
    },
    // 获取会员信息
    getInfo: async function () {
      const res = await getMemberInfo.getUserInfo(this, this.mobiles)
      if (res.code !== 0) return this.$message.error('获取会员信息失败')
      var form = res.result
      if (form.level === 0) {
        form.levels = '普通会员'
      } else if (form.level === 1) {
        form.levels = '已绑定设备会员'
      } else if (form.level === 2) {
        form.levels = '其他会员'
      }
      this.form = form
      this.form1.user_id = form.id
    },
    // 提交
    submit: async function () {
      const data = await getMemberInfo.addUserInfo(this, this.form1)
      if (data.code !== 0) return this.$message.error(data.msg)
      this.$message.success('添加成功')
      this.$router.push('MemberInfo')
    },
    // 获取时间
    showTimes: function () {
      const nowtime = new Date()
      const year = nowtime.getFullYear()
      const month = this.padaDate(nowtime.getMonth() + 1)
      const date = this.padaDate(nowtime.getDate())
      this.showTime = year + '-' + month + '-' + date
      // console.log(this.showTime)
    },
    padaDate: function (value) {
      return value < 10 ? '0' + value : value
    }
  }
}
</script>

<style scoped>
</style>
