// api 地址
const api = {
  productList: 'admin/warehouse/list',
  addProduct: 'admin/warehouse/add',
  editProductDialog: 'admin/warehouse/info?id=',
  editProduct: 'admin/warehouse/edit?id=',
  deleteProduct: 'admin/warehouseOutType/del?id=',
  delWarehouse: 'admin/warehouse/del?id=',
  delWarehouseInType: 'admin/warehouseInType/del?id=',
  storeManager: 'admin/warehouse/getWarehouseUser?id=',
  getAllUser: 'admin/adminUser/getAll',
  getWarehouseUser: 'admin/warehouse/getWarehouseUser',
  saveWarehouseUser: 'admin/warehouse/saveWarehouseUser'
}
// 获取产品列表
export async function productList (ele) {
  var { data } = await ele.$http.get(api.productList)
  return data
}
// 获取根据页面显示的条数(页数)来查询产品列表
export async function pageChange (ele, param) {
  for (var i in param) {
    var { data } = await ele.$http.get(api.productList + '?' + i + '=' + param[i])
  }
  return data
}
// 添加产品
export async function addProduct (ele, param) {
  var { data } = await ele.$http.post(api.addProduct, param)
  return data
}

// 根据id查询产品信息
export async function editProductDialog (ele, param) {
  var { data } = await ele.$http.get(api.editProductDialog + param)
  return data
}

// 提交产品修改的信息
export async function editProduct (ele, param1, param2) {
  var { data } = await ele.$http.put(api.editProduct + param1, param2)
  return data
}
// 删除仓库
export async function delWarehouse (ele, params) {
  var { data } = await ele.$http.delete(api.delWarehouse + params)
  return data
}
// 删除入库
export async function delWarehouseInType (ele, param) {
  var { data } = await ele.$http.delete(api.delWarehouseInType + param)
  return data
}
// 删除出库
export async function deleteProduct (ele, param) {
  var { data } = await ele.$http.delete(api.deleteProduct + param)
  return data
}

// 获取所有仓管员的信息
export async function storeManager (ele, param) {
  var { data } = await ele.$http.post(api.storeManager + param)
  return data
}
export async function pageChanges (ele, param) {
  var p = []
  for (var i in param) {
    p.push(i + '=' + param[i])
  }
  var { data } = await ele.$http.get(api.productList + '?' + p[0] + '&' + p[1])
  return data
}
// 获取全部管理员
export async function getAllUser (ele) {
  var { data } = await ele.$http.get(api.getAllUser)
  return data
}
// 获取已选择管理员
export async function getWarehouseUser (ele, param) {
  var { data } = await ele.$http.post(api.getWarehouseUser, param)
  return data
}
// 设置管理员
export async function saveWarehouseUser (ele, param) {
  var { data } = await ele.$http.post(api.saveWarehouseUser, param)
  return data
}
