<template>
  <div>
    <el-row style="margin: 0 10px">
      <el-col :span="4" style="font-size: 18px">{{$t('home.sblb')}}</el-col>
      <el-col :span="9" style="float: right;">
        <!--面包屑导航-->
        <el-breadcrumb separator="/">
          <el-breadcrumb-item>{{$t('home.sbgl')}}</el-breadcrumb-item>
          <el-breadcrumb-item>{{$t('home.sblb')}}</el-breadcrumb-item>
        </el-breadcrumb>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="5" style="padding-right: 20px;">
        <el-card style="padding: 0 !important;">
          <div slot="header" class="clearfix">
            <span>{{$t('home.fzgl')}}</span>
            <el-button style="float: right; padding: 3px 0" type="text" @click="collapseBtn"><i class="el-icon-s-operation" style="font-size: 18px"></i></el-button>
          </div>
          <div v-for="(item,index) in GroupAll" :key="index" @click="chooseClick(index)" :class="{active:index==isActive}" :style="productListcss" class="productList">
            <i class="el-icon-s-grid" style="padding-right: 10px"></i>{{item.name}}
          </div>
        </el-card>
      </el-col>
      <el-col :span="19" style="float: right;">
        <el-card style="padding: 20px">
          <div style="margin-bottom: 20px">
            {{name}}
          </div>
            <el-form :inline="true" :model="searchForm" class="demo-form-inline" style="margin-top: 20px">
              <el-form-item :label="$t('home.sbbh')">
                <el-input v-model="searchForm.equipment_no" clearable :placeholder="$t('home.sbbh')"></el-input>
              </el-form-item>
              <el-form-item label="imei">
                <el-input v-model="searchForm.imei" clearable placeholder="imei"></el-input>
              </el-form-item>
<!--              <el-form-item label="是否在线">-->
<!--                <el-select placeholder="是否在线" filterable v-model="searchForm.line_status">-->
<!--                  <el-option label="离线" value="0"></el-option>-->
<!--                  <el-option label="在线" value="1"></el-option>-->
<!--                </el-select>-->
<!--              </el-form-item>-->
              <el-form-item>
                <el-button type="primary" @click="getList">{{$t('home.xc')}}</el-button>
              </el-form-item>
            </el-form>
          <add-btn
            :groupId="form.group_id"
            :addName="$t('home.sb')"
            @gainMsgs="getList"
            :addForm="getU == 0 ? addProductForm : addProductForm1"
            :addRules="addProductRules"
            :addApi="path"
            :addProductFormLists="getU == 0 ? addProductFormList : addProductFormList1"
          ></add-btn>
          <el-table
            ref="filterTable"
            :data="listData"
            border
            style="width: 100%;min-height: 700px">
            <el-table-column type="index" :label="$t('home.xh')" width="80px"></el-table-column>
<!--            <el-table-column prop="imei" label="模块编号" width="160px"></el-table-column>-->
            <el-table-column prop="equipment_no" :label="$t('home.sbbh')" width="160px"></el-table-column>
            <el-table-column prop="imei" label="imei" width="160px"></el-table-column>
            <el-table-column prop="line_status" :label="$t('home.sfzx')">
              <template #default="scope">
                <el-tag :type="scope.row.line_status == 0 ? 'danger' : 'success'" style="margin-right: 10px">{{scope.row.line_status == 0 ? $t('home.lx') : $t('home.zx')}}</el-tag>
              </template>
            </el-table-column>
            <el-table-column :label="$t('home.cz')" width="250px">
              <template #default="scope">
                <edit-btn
                  type="1"
                  :editId="scope.row.id"
                  :editForm="getU == 0 ? editProductForm : editProductForm1"
                  :addProductFormList="getU == 0 ? addProductFormList : addProductFormList1"
                  :editRules="editProductFormRules"
                  @gainMsg="getList"
                  :editApi="path"
                  :editProductFormLists="getU == 0 ? editProductFormList : editProductFormList1"
                  :editName="$t('home.sb')"
                ></edit-btn>
<!--                <detail-pop :type="3" title="设备" :ProductFormList="ProductFormList" :id="scope.row.id" @infoData="getDetail"></detail-pop>-->
                <el-button type="success" size="mini" @click="detailGo(scope.row.id,scope.row.equipment_no)">{{$t('home.xq')}}</el-button>
                <el-button type="danger" size="mini" @click="deleteList(scope.row.id)">{{$t('home.sc')}}</el-button>
                <!--                <el-button type="primary" size="mini" @click="detailPop(scope.row.equipment_no)">设备操作</el-button>-->
              </template>
            </el-table-column>
<!--            <el-table-column prop="agent_name" label="二级代理名称"></el-table-column>-->
<!--            <el-table-column prop="city_name" label="三级代理名称"></el-table-column>-->
<!--            <el-table-column prop="scene_name" label="场地代理名称"></el-table-column>-->
<!--            <el-table-column prop="update_time" label="更新时间" width="180px"></el-table-column>-->
            <el-table-column prop="last_login_time" :label="$t('home.zhdlsj')" width="180px"></el-table-column>
            <el-table-column prop="remark" :label="$t('home.bz')" width="250px"></el-table-column>
            <el-table-column prop="item_name" :label="$t('home.khmc')"></el-table-column>
          </el-table>
          <pagination :queryInfos="queryInfo" @pageChange="PageChange" queryUrl="Img/DeviceLists" :pamams="'group_id=' + form.group_id + '&equipment_no=' + (searchForm.equipment_no || '')"></pagination>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import * as getImg from '../../../interface/Img/ImgManage'
import * as getDevice from '../../../interface/Img/DeviceLists'
import AddBtn from '../../common/AddBtn'
import EditBtn from '../../common/EditBtn'
import Pagination from '../../common/Pagination'
export default {
  name: 'DeviceLists',
  components: { Pagination, EditBtn, AddBtn },
  data: function () {
    return {
      searchForm: {},
      queryInfo: {
        query: '',
        pageNo: 1,
        pageSize: 2,
        total: 0
      },
      isActive: -1,
      productListcss: 'display:block',
      GroupAll: [],
      listData: [],
      form: {
        group_id: 0
      },
      name: this.$t('home.qbfz'),
      getU: window.sessionStorage.getItem('item_id'),
      addProductForm: {
        equipment_no: '',
        remark: '',
        item_id: '',
        agent_id: '',
        city_id: '',
        scene_id: '',
        group_id: ''
      },
      addProductFormList: [
        {
          title: this.$t('home.sbbh'),
          prop: 'equipment_no'
        },
        {
          title: this.$t('home.sbid'),
          prop: 'imei'
        },
        {
          title: this.$t('common.type'),
          prop: 'type'
        },
        {
          title: this.$t('home.khmc'),
          prop: 'item_id'
        },
        {
          title: this.$t('home.bz'),
          prop: 'remark'
        }
      ],
      addProductRules: {
        type: [
          { required: true, message: this.$t('edit.qtxwzxx'), trigger: 'change' }
        ],
      },
      path: 'Img/DeviceLists',
      editProductForm: {
        equipment_no: '',
        remark: '',
        item_id: '',
        group_id: ''
      },
      editProductFormRules: {
        type: [
          { required: true, message: this.$t('edit.qtxwzxx'), trigger: 'change' }
        ],
      },
      editProductFormList: [
        {
          title: this.$t('home.sbbh'),
          prop: 'equipment_no'
        },
        {
          title: this.$t('home.sbid'),
          prop: 'imei',
          disabled: true
        },
        {
          title: this.$t('common.type'),
          prop: 'type'
        },
        {
          title: this.$t('home.khmc'),
          prop: 'item_id'
        },
        {
          title: this.$t('home.bz'),
          prop: 'remark'
        }
      ],
      ProductFormList: [],
      addProductForm1: {
        equipment_no: '',
        remark: '',
        item_id: window.sessionStorage.getItem('item_id')
      },
      addProductFormList1: [
        {
          title: this.$t('home.sbbh'),
          prop: 'equipment_no'
        },
        {
          title: this.$t('home.sbid'),
          prop: 'imei',
          disabled: true
        },
        {
          title: this.$t('common.type'),
          prop: 'type'
        },
        {
          title: this.$t('home.bz'),
          prop: 'remark'
        }
      ],
      editProductForm1: {
        equipment_no: '',
        remark: '',
        group_id: '',
        item_id: window.sessionStorage.getItem('item_id')
      },
      editProductFormList1: [
        {
          title: this.$t('home.sbbh'),
          prop: 'equipment_no'
        },
        {
          title: this.$t('home.sbid'),
          prop: 'imei',
          disabled: true
        },
        {
          title: this.$t('common.type'),
          prop: 'type'
        },
        {
          title: this.$t('home.bz'),
          prop: 'remark'
        }
      ]
    }
  },
  created () {
    this.getGroupAll()
    // this.getUser()
  },
  methods: {
    getUser: function () {
      console.log('item_id', window.sessionStorage.getItem('item_id'))
      if (window.sessionStorage.getItem('item_id') === 0) {
        console.log('111', window.sessionStorage.getItem('item_id'))
        this.addProductForm = { equipment_no: '', remark: '', item_id: '' }
        this.addProductFormList = [
          {
            title: this.$t('home.sbbh'),
            prop: 'equipment_no'
          },
          {
            title: this.$t('home.bz'),
            prop: 'remark'
          },
          {
            title: this.$t('home.khmc'),
            prop: 'item_id'
          }
        ]
        this.editProductForm = { equipment_no: '', remark: '', item_id: '' }
        this.editProductFormList = [
          {
            title: this.$t('home.sbbh'),
            prop: 'equipment_no'
          },
          {
            title: this.$t('home.bz'),
            prop: 'remark'
          },
          {
            title: this.$t('home.khmc'),
            prop: 'item_id'
          }
        ]
      } else {
        this.addProductForm = { equipment_no: '', remark: '' }
        this.addProductFormList = [
          {
            title: this.$t('home.sbbh'),
            prop: 'equipment_no'
          },
          {
            title: this.$t('home.bz'),
            prop: 'remark'
          }
        ]
        this.editProductForm = { equipment_no: '', remark: '' }
        this.editProductFormList = [
          {
            title: this.$t('home.sbbh'),
            prop: 'equipment_no'
          },
          {
            title: this.$t('home.bz'),
            prop: 'remark'
          }
        ]
      }
    },
    // 点击按钮实现产品列表的显示和隐藏
    collapseBtn: function () {
      if (this.productListcss === 'display:block') {
        this.productListcss = 'display:none'
        return
      }
      this.productListcss = 'display:block'
    },
    // 获取全部分组
    getGroupAll: async function () {
      const data = await getImg.fileGroupAll(this)
      if (data.code !== 0) return this.$message.error(data.msg)
      this.GroupAll = data.result
      // this.form.group_id = data.result[0].id
      this.chooseClick(0)
      // this.getList()
    },
    // 选择
    chooseClick: function (index) {
      console.log(index)
      this.isActive = index
      this.form.group_id = this.GroupAll[index].id
      this.name = this.GroupAll[index].name
      this.getList()
    },
    getList: async function () {
      var p = '?group_id=' + this.form.group_id + '&equipment_no=' + (this.searchForm.equipment_no || '') + '&imei=' + (this.searchForm.imei || '')
      const data = await getDevice.EquipmentList(this, p)
      if (data.code !== 0) return this.$message.error(data.msg)
      this.listData = data.result.data
      this.queryInfo.total = data.result.totalCount
      this.queryInfo.pageNo = data.result.pageNo
      this.queryInfo.pageSize = data.result.pageSize
    },
    // 下一页
    PageChange: function (e) {
      this.listData = e.productList
      this.queryInfo = e.queryInfo
    },
    // 删除
    deleteList: function (id) {
      var that = this
      that.$confirm(this.$t('home.sfqrsc'), this.$t('home.ts'), {
        confirmButtonText: this.$t('home.qd'),
        cancelButtonText: this.$t('home.qx'),
        type: 'warning'
      }).then(async (res) => {
        console.log(res)
        const data = await getDevice.delEquipment(this, id)
        if (data.code !== 0) return this.$message.error(data.msg)
        this.$message.success(this.$t('home.sccg'))
        that.getList()
      }).catch(() => {
        that.$message({
          type: 'info',
          message: this.$t('home.yqxsc')
        })
      })
    },
    detailGo: function (id, equipmentnos) {
      this.$router.push({ path: 'DeviceDetail', query: { id: id, name: this.name, equipment_no: equipmentnos } })
    }
  }
}
</script>
<style scoped>
  .productList{
    padding: -20px !important;
    font-size: 14px;
    width: 100%;
    height: 40px;
    line-height: 40px;
    box-sizing: border-box;
    padding-left: 20px;
    text-align: left;
    display: block;
  }
  .active{
    background-color: #409EFF;
    color: white;
  }
</style>
