<template>
  <div>
    <el-row style="margin: 0 10px">
      <el-col :span="5" style="font-size: 18px">{{$t('khgl.khgl')}}</el-col>
      <el-col :span="5" style="float: right;">
        <!--面包屑导航-->
        <el-breadcrumb separator="/">
          <el-breadcrumb-item>{{$t('khgl.khgl')}}</el-breadcrumb-item>
        </el-breadcrumb>
      </el-col>
    </el-row>
    <el-card>
      <el-form :inline="true" :model="form" class="demo-form-inline">
        <el-form-item :label="$t('home.khmc')">
          <el-select :placeholder="$t('home.khmc')" @change="selectId" filterable v-model="form.name">
            <el-option :label="$t('sh.qb')" :value="0"></el-option>
            <el-option v-for="(item, index) in getItemAll" :key="index" :label="item.name" :value="item.name">
            </el-option>
          </el-select>
        </el-form-item>
        <!--              <el-form-item>-->
        <!--                <el-input v-model="form.keyword" placeholder="id/微信昵称"></el-input>-->
        <!--              </el-form-item>-->
<!--        <el-form-item label="类型">-->
<!--          <el-select v-model="form.type" placeholder="类型" @change="getList">-->
<!--            <el-option label="全部" value=""></el-option>-->
<!--            <el-option label="图片" value="1"></el-option>-->
<!--            <el-option label="视频" value="2"></el-option>-->
<!--          </el-select>-->
<!--        </el-form-item>-->
        <el-form-item>
          <el-button type="primary" @click="getList">{{$t('home.xc')}}</el-button>
        </el-form-item>
      </el-form>
<!--      <el-button type="primary" @click="addCustomer">新增客户</el-button>-->
      <add-btn
        :addName="$t('khgl.kh')"
        @gainMsgs="getList"
        :addForm="addProductForm"
        :addRules="addProductRules"
        :addApi="path"
        :addProductFormLists="addProductFormList"
      ></add-btn>
      <el-table border :data="productList">
        <el-table-column :label="$t('group.mc')" prop="name"></el-table-column>
        <el-table-column :label="$t('group.ms')" prop="content"></el-table-column>
        <el-table-column :label="$t('home.cz')" width="180px">
          <template #default="scope">
<!--            <el-button size="mini" type="primary" @click="editC(scope.row.id)">编辑</el-button>-->
            <edit-btn
              type="1"
              :editId="scope.row.id"
              :editForm="editProductForm"
              :addProductFormList="addProductFormList"
              :editRules="editProductFormRules"
              @gainMsg="getList"
              :editApi="path"
              :editProductFormLists="editProductFormList"
              :editName="$t('khgl.kh')"
            ></edit-btn>
            <el-button size="mini" type="danger" @click="deleteC(scope.row.id)">{{$t('home.sc')}}</el-button>
          </template>
        </el-table-column>
      </el-table>
      <pagination :queryInfos="queryInfo" @pageChange="PageChange" queryUrl="Img/Customer" :pamams="'name=' + (form.name || '')"></pagination>
    </el-card>
  </div>
</template>
<script>
import * as getCustomer from '../../../interface/Img/Customer'
import pagination from '../../common/Pagination'
import AddBtn from '../../common/AddBtn'
import EditBtn from '../../common/EditBtn'
import * as getDevice from '../../../interface/Img/DeviceLists'
export default {
  name: 'Customer',
  components: { EditBtn, AddBtn, pagination },
  data: function () {
    return {
      getItemAll: [],
      productList: [],
      queryInfo: {
        query: '',
        pageNo: 1,
        pageSize: 2,
        total: 0
      },
      form: {},
      addProductForm: {
        name: '',
        content: ''
      },
      addProductFormList: [
        {
          title: this.$t('group.mc'),
          prop: 'name'
        },
        {
          title: this.$t('group.ms'),
          prop: 'content'
        }
      ],
      addProductRules: {},
      path: 'Img/Customer',
      editProductForm: {
        name: '',
        content: '',
        id: ''
      },
      editProductFormRules: {},
      editProductFormList: [
        {
          title: this.$t('group.mc'),
          prop: 'name'
        },
        {
          title: this.$t('group.ms'),
          prop: 'content'
        }
      ]
    }
  },
  created () {
    this.getList()
    this.Agent1()
  },
  methods: {
    selectId: function (name) {
      this.form.name = name
      console.log('name', name)
    },
    Agent1: async function () {
      // 获取一级代理
      const data = await getDevice.getItemAll(this, '')
      if (data.code !== 0) return this.$message.error('获取一级代理失败')
      this.getItemAll = data.result
      // console.log(this.getItemAll)
    },
    // 下一页
    PageChange: function (e) {
      this.productList = e.productList
      this.queryInfo = e.queryInfo
    },
    // 获取列表
    getList: async function () {
      var p = '?name=' + (this.form.name || '')
      const data = await getCustomer.ItemList(this, p)
      if (data.code !== 0) return this.$message.error(data.msg)
      this.productList = data.result.data
      this.queryInfo.total = data.result.totalCount
      this.queryInfo.pageNo = data.result.pageNo
      this.queryInfo.pageSize = data.result.pageSize
    },
    // 删除
    deleteC: function (id) {
      var that = this
      that.$confirm(this.$t('home.sfqrsc'), this.$t('home.ts'), {
        confirmButtonText: this.$t('home.qd'),
        cancelButtonText: this.$t('home.qx'),
        type: 'warning'
      }).then(async (res) => {
        console.log(res)
        const data = await getCustomer.delItem(this, id)
        if (data.code !== 0) return this.$message.error(data.msg)
        this.$message.success(this.$t('home.sccg'))
        that.getList()
      }).catch(() => {
        that.$message({
          type: 'info',
          message: this.$t('home.yqxsc')
        })
      })
    }
  }
}
</script>
<style scoped></style>
