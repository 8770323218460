<template>
  <div>
    <el-row style="margin: 0 10px">
      <el-col :span="2" style="font-size: 18px">商户</el-col>
      <el-col :span="5" style="float: right;">
        <!--面包屑导航-->
        <el-breadcrumb separator="/">
          <el-breadcrumb-item>商户管理</el-breadcrumb-item>
          <el-breadcrumb-item><a>商户</a></el-breadcrumb-item>
        </el-breadcrumb>
      </el-col>
    </el-row>
    <el-card>
<!--      <el-form :inline="true" :model="queryInfo" class="demo-form-inline">-->
<!--        <el-form-item label="业务员名称">-->
<!--          <el-input v-model="queryInfo.name" placeholder="业务员名称"></el-input>-->
<!--        </el-form-item>-->
<!--        <el-form-item label="手机号">-->
<!--          <el-input v-model="queryInfo.phone" placeholder="手机号"></el-input>-->
<!--        </el-form-item>-->
<!--        <el-form-item label="状态">-->
<!--          <el-select v-model="queryInfo.region" placeholder="状态">-->
<!--            <el-option label="正常" :value="0"></el-option>-->
<!--            <el-option label="禁用" :value="1"></el-option>-->
<!--          </el-select>-->
<!--        </el-form-item>-->
<!--        <el-form-item>-->
<!--          <el-button type="primary" @click="getList('search')">查询</el-button>-->
<!--        </el-form-item>-->
<!--      </el-form>-->
<!--      <addPop addType="salesman" @getLists="getList"></addPop>-->
<!--      <el-button type="primary" @click="addList">新增</el-button>-->
      <el-row>
        <addBtn
          :isBtns="true"
          @gainMsgs="PageChange"
          :addForm="addProductForm"
          :addRules="addProductRules"
          :addApi="path"
          :addProductFormLists="addProductFormList"
          :addName="addProductName"
          style="float: left"
        ></addBtn>
      </el-row>
      <el-table
        ref="filterTable"
        :data="merchantData"
        border
        style="width: 100%">
        <el-table-column prop="mch_id" label="商户编号"></el-table-column>
        <el-table-column prop="name" label="商户名称"></el-table-column>
        <el-table-column prop="app_id" label="小程序或公众号appId"></el-table-column>
        <el-table-column prop="head_name" label="项目标题"></el-table-column>
        <el-table-column label="操作" width="275px">
          <template #default="scope">
            <el-button size="mini" style="margin-right: 15px" @click="getDetails(scope.row.id)">详情</el-button>
            <editBtn
              :editId="scope.row.id"
              :editForm="editProductForm"
              :addProductFormList="addProductFormList"
              :editRules="editProductFormRules"
              @gainMsg="PageChange"
              :editApi="path"
              :editProductFormLists="editProductFormList"
              :editName="addProductName"
            ></editBtn>
            <el-button type="danger" size="mini" @click="deleteCase(scope.row.id)" style="margin-top: 10px">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <page :queryInfos="queryInfo" @pageChange="PageChanges" :queryUrl="path"></page>
    </el-card>
    <el-dialog
      title="详情"
      :visible.sync="showDetail"
      width="50%"
      @close="showDetailClosed"
    >
      <el-form label-width="220px" ref="showProductFormRef" style="margin: 0 auto">
        <el-form-item v-for="item in detailsList" :key="item.index" :label="item.title + ':' ">
          <span>{{item.value || '暂无'}}</span>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import * as getMerchant from '../../interface/agentManage/Merchant'
import Pagination from '../common/Pagination'
import AddBtn from '../common/AddBtn'
import EditBtn from '../common/EditBtn'
export default {
  name: 'Merchant',
  components: {
    page: Pagination,
    addBtn: AddBtn,
    editBtn: EditBtn
  },
  data: function () {
    return {
      path: 'agentManage/Merchant',
      merchantData: [],
      queryInfo: {
        region: '',
        pageNo: 1,
        pageSize: 2,
        total: 0
      },
      showPop: false,
      addProductForm: {
        name: '',
        mch_id: '',
        key: '',
        service_id: '',
        api_v3: '',
        serial_no: '',
        api_cert: '',
        api_key: '',
        app_id: '',
        secret: '',
        head_name: '',
        subject: '',
        url: '',
        alipay_app_id: '',
        alipay_pid: '',
        alipay_private_key: '',
        alipay_public_key: '',
        alipay_email: '',
        alipay_service_id: ''
      },
      editProductForm: {
        name: '',
        mch_id: '',
        key: '',
        service_id: '',
        api_v3: '',
        serial_no: '',
        api_cert: '',
        api_key: '',
        app_id: '',
        secret: '',
        head_name: '',
        subject: '',
        url: '',
        alipay_app_id: '',
        alipay_pid: '',
        alipay_private_key: '',
        alipay_public_key: '',
        alipay_email: '',
        alipay_service_id: ''
      },
      addProductFormList: [
        {
          title: '商户名称',
          prop: 'name'
        },
        {
          title: '商户编号',
          prop: 'mch_id'
        },
        {
          title: 'api_key',
          prop: 'key'
        },
        {
          title: '免押服务id',
          prop: 'service_id'
        },
        {
          title: 'api_v3',
          prop: 'api_v3'
        },
        {
          title: '证书编号',
          prop: 'serial_no'
        },
        {
          title: '证书pem',
          prop: 'api_cert'
        },
        {
          title: '证书key',
          prop: 'api_key'
        },
        {
          title: '小程序或公众号appId',
          prop: 'app_id'
        },
        {
          title: '小程序或公众号秘钥',
          prop: 'secret'
        },
        {
          title: '项目标题',
          prop: 'head_name'
        },
        {
          title: '项目描述',
          prop: 'subject'
        },
        {
          title: '地址',
          prop: 'url'
        },
        {
          title: '支付宝小程序id',
          prop: 'alipay_app_id'
        },
        {
          title: '支付宝pid',
          prop: 'alipay_pid'
        },
        {
          title: '支付宝私钥',
          prop: 'alipay_private_key'
        },
        {
          title: '支付宝公钥',
          prop: 'alipay_public_key'
        },
        {
          title: '支付宝邮箱',
          prop: 'alipay_email'
        },
        {
          title: '芝麻信用服务id',
          prop: 'alipay_service_id'
        }
      ],
      editProductFormList: [
        {
          title: '商户名称',
          prop: 'name'
        },
        {
          title: '商户编号',
          prop: 'mch_id'
        },
        {
          title: 'api_key',
          prop: 'key'
        },
        {
          title: '免押服务id',
          prop: 'service_id'
        },
        {
          title: 'api_v3',
          prop: 'api_v3'
        },
        {
          title: '证书编号',
          prop: 'serial_no'
        },
        {
          title: '证书pem',
          prop: 'api_cert'
        },
        {
          title: '证书key',
          prop: 'api_key'
        },
        {
          title: '小程序或公众号appId',
          prop: 'app_id'
        },
        {
          title: '小程序或公众号秘钥',
          prop: 'secret'
        },
        {
          title: '项目标题',
          prop: 'head_name'
        },
        {
          title: '项目描述',
          prop: 'subject'
        },
        {
          title: '地址',
          prop: 'url'
        },
        {
          title: '支付宝小程序id',
          prop: 'alipay_app_id'
        },
        {
          title: '支付宝pid',
          prop: 'alipay_pid'
        },
        {
          title: '支付宝私钥',
          prop: 'alipay_private_key'
        },
        {
          title: '支付宝公钥',
          prop: 'alipay_public_key'
        },
        {
          title: '支付宝邮箱',
          prop: 'alipay_email'
        },
        {
          title: '芝麻信用服务id',
          prop: 'alipay_service_id'
        }
      ],
      addProductName: '商户',
      addProductRules: {},
      editProductFormRules: {},
      showDetail: false,
      detailsList: []
    }
  },
  created () {
    this.getList()
  },
  methods: {
    // 刷新
    PageChange: function () {
      this.getList()
    },
    // 下一页
    PageChanges: function () {

    },
    getList: async function () {
      const data = await getMerchant.MerchantList(this)
      if (data.code !== 0) return this.$message.error('获取商户列表失败')
      this.merchantData = data.result.data
      this.queryInfo.pageNo = data.result.pageNo
      this.queryInfo.pageSize = data.result.pageSize
      this.queryInfo.total = data.result.totalCount
    },
    // 新增
    addList: function () {
      this.showPop = true
    },
    // 删除
    deleteCase: function (id) {
      var that = this
      this.$confirm('是否确认删除', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const data = await getMerchant.delProduct(this, id)
        if (data.code !== 0) return that.$message.error(data.msg)
        that.$message.success('删除成功')
        that.getList()
      }).catch(() => {
        console.log('22222')
        // this.$message({
        //   type: 'info',
        //   message: '已取消删除'
        // })
      })
    },
    // 关闭
    showDetailClosed: function () {
      this.$refs.showProductFormRef.resetFields()
    },
    // 详情
    getDetails: async function (id) {
      var detailsList = []
      const data = await getMerchant.editProductDialog(this, id)
      if (data.code !== 0) return this.$.error(data.msg)
      detailsList = [{ title: '商户名称', value: data.result.name }, { title: '商户编号', value: data.result.mch_id },
        { title: 'api_key', value: data.result.key },
        { title: '免押服务id', value: data.result.service_id },
        { title: 'api_v3', value: data.result.api_v3 },
        { title: '证书编号', value: data.result.serial_no },
        { title: '证书pem', value: data.result.api_cert },
        { title: '证书key', value: data.result.api_key },
        { title: '小程序或公众号appId', value: data.result.app_id },
        { title: '小程序或公众号秘钥', value: data.result.secret },
        { title: '项目标题', value: data.result.head_name },
        { title: '项目描述', value: data.result.subject },
        { title: '支付宝小程序id', value: data.result.alipay_app_id },
        { title: '支付宝pid', value: data.result.alipay_pid },
        { title: '支付宝私钥', value: data.result.alipay_private_key },
        { title: '支付宝公钥', value: data.result.alipay_public_key },
        { title: '支付宝邮箱', value: data.result.alipay_email },
        { title: '芝麻信用服务id', value: data.alipay_service_id }]
      this.detailsList = detailsList
      this.showDetail = true
    }
  }
}
</script>

<style scoped>
  .el-tag + .el-tag {
    margin-left: 10px;
  }
  .button-new-tag {
    margin-left: 10px;
    height: 32px;
    line-height: 30px;
    padding-top: 0;
    padding-bottom: 0;
  }
  .input-new-tag {
    width: 90px;
    margin-left: 10px;
    vertical-align: bottom;
  }
</style>
