// 统计列表里面的数据统计
const api = {
  modularList: '/admin/modular/list',
  modularLists: '/admin/modular/list?is_bind=',
  changeModularList: 'admin/modularChangeLog/list',
  addChangeModular: 'admin/modularChangeLog/add',
  finishList: 'admin/equipmentServer/list',
  finishListAll: 'admin/equipmentType/getAll'
}
// 获取模块列表
export async function modularList (ele, param) {
  var { data } = await ele.$http.get(api.modularList + param)
  return data
}

export async function modularLists (ele, param) {
  console.log(param)
  var { data } = await ele.$http.get(api.modularLists + param)
  return data
}

// 获取模块变更列表
export async function changeModularList (ele, param) {
  var { data } = await ele.$http.get(api.changeModularList + param)
  return data
}

// 新增模块变更
export async function addChangeModular (ele, param) {
  var { data } = await ele.$http.post(api.addChangeModular)
  return data
}

// 获取成品列表
export async function finishList (ele, param) {
  var { data } = await ele.$http.get(api.finishList + param)
  return data
}
// 获取全部成品列表
export async function finishListAll (ele) {
  var { data } = await ele.$http.get(api.finishListAll)
  return data
}
export async function pageChanges (ele, param, pamams) {
  var p = []
  for (var i in param) {
    p.push(i + '=' + param[i])
  }
  var { data } = await ele.$http.get(api.changeModularList + '?' + p[0] + '&' + p[1] + '&' + pamams)
  return data
}
