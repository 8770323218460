// api 地址
const api = {
  caseList: 'admin/userCaseType/list',
  addCase: 'admin/userCaseType/add',
  caseInfo: 'admin/userCaseType/info?id=',
  editCase: 'admin/userCaseType/edit',
  deleteCase: 'admin/userCaseType/del?id='
}
// 获取病例列表
export async function caseList (ele) {
  var { data } = await ele.$http.get(api.caseList)
  return data
}
// 新增病例列表
export async function addCase (ele, param) {
  var { data } = await ele.$http.post(api.addCase, param)
  return data
}

// 根据id查询获取病例列表信息
export async function caseInfo (ele, param) {
  var { data } = await ele.$http.get(api.caseInfo + param)
  return data
}

// 提交病例修改的信息
export async function editCase (ele, param) {
  var { data } = await ele.$http.put(api.editCase, param)
  return data
}
// 删除病例的信息
export async function deleteCase (ele, param) {
  var { data } = await ele.$http.delete(api.deleteCase + param)
  return data
}
